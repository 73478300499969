import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useMutation } from '@tanstack/react-query';
import { Button, KIND, SIZE } from 'baseui/button';
import { ArrowLeft } from 'baseui/icon';
import { Input } from 'baseui/input';
import { Banner, KIND as BannerKind } from 'components/Banner';
import { FormController } from 'components/FormController';
import Text from 'components/Text';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { fetcher, FetcherMutatergs } from 'services/fetcher';
import TokenManager from 'storage/TokenManager';
import { useStitches } from 'theme';

type FormFields = {
  email: string;
};
type SendResetEmailPayload = FetcherMutatergs<{ email: string }>;
function sendResetEmail(args: SendResetEmailPayload) {
  return fetcher('api/v1/auth/forgot_password', {
    body: JSON.stringify({
      email: args.payload.email,
      code: '0',
      password: '',
    }),
    method: 'post',
  });
}
const useSendResetEmail = () => useMutation(sendResetEmail);

export default function ForgotPassword() {
  const { css, theme } = useStitches();
  const sendEmail = useSendResetEmail();
  const { control, handleSubmit } = useForm<FormFields>();
  const history = useHistory();

  const onSubmit = handleSubmit(data => {
    sendEmail.mutate(
      {
        payload: {
          email: data.email,
        },
      },
      {
        onSuccess: response => {
          const success = !!response?.success === true;
          if (success) {
            history.replace(
              `/forgot-password/reset?email=${encodeURIComponent(data.email)}`,
            );
          }
        },
      },
    );
  });
  const isError =
    sendEmail.isError ||
    (sendEmail.isSuccess && !!sendEmail.data?.success === false);
  const isSuccess = sendEmail.isSuccess && !!sendEmail.data?.success === true;

  useEffect(() => {
    if (TokenManager.isAuthenticated()) {
      history.replace('/inspections');
    }
  }, [history]);

  return (
    <div
      className={css({
        layout: 'row',
        height: '$screen-h',
        overflow: 'hidden',
      })}
    >
      <div className={css({ flex: 1 })}>
        <img
          src="https://www.stantec.com/content/dam/stantec/images/projects/0032/mike-ocallaghan-pat-tillman-memorial-bridge-inspection-84775.jpg"
          alt=""
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          }}
        />
      </div>
      <div
        className={css({
          flex: 1,
          layout: 'stack',
          alignItems: 'center',
          justifyContent: 'center',
          p: '$scale600',
        })}
      >
        <div
          className={css({
            maxWidth: '$w-sm',
            spaceY: '$scale600',
          })}
        >
          <div className={css({ layout: 'stack', alignItems: 'center' })}>
            <div
              className={css({
                width: theme.sizing.scale1600,
                height: theme.sizing.scale1600,
                borderRadius: '$full',
                layout: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '$primary',
                color: '$white',
              })}
            >
              <LockOutlinedIcon color="inherit" />
            </div>
            <Text
              variant="HeadingSmall"
              weight="bold"
              css={{ textAlign: 'center' }}
            >
              Forgot Password?
            </Text>
            <Text variant="ParagraphSmall" css={{ textAlign: 'center' }}>
              Enter your email and we'll send you a link to reset your password
            </Text>
          </div>
          <Banner title="Success" kind={BannerKind.positive} show={isSuccess}>
            Check your email address for instructions to complete your password
            reset.
          </Banner>
          <Banner title="Error" kind={BannerKind.negative} show={isError}>
            Failed to complete action. Try again.
          </Banner>
          <form className={css({ width: '$full' })} onSubmit={onSubmit}>
            <FormController
              control={control}
              name="email"
              defaultValue=""
              label="Email"
              rules={{ required: 'Enter email.' }}
            >
              {({ field }) => <Input {...field} />}
            </FormController>

            <Button
              type="submit"
              className={css({ width: '$full' })}
              isLoading={sendEmail.isLoading}
            >
              Reset Password
            </Button>
          </form>
          <div
            className={css({
              width: '$full',
              layout: 'row',
              justifyContent: 'center',
            })}
          >
            <Button
              to="/login"
              type="button"
              // @ts-ignore
              kind={KIND.tertiary}
              size={SIZE.mini}
              startEnhancer={ArrowLeft}
              $as={Link}
            >
              Back to log in
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
