import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { Colors } from "config/StyleConfig";
import { DRAWER_WIDTH } from "./SideDrawer";
import { connect } from "react-redux";
import { toggleMobileOpen, logout } from "./MenuAction";
import TokenManager from "storage/TokenManager";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Strings } from "config/Strings";
import { showConfirmationDialog } from "components/Dialog/DialogAction";

const styles = (theme) => ({
  appBar: {
    backgroundColor: Colors.topBar,
    color: Colors.text,
    // marginLeft: DRAWER_WIDTH,
    // [theme.breakpoints.up("sm")]: {
    //   width: `calc(100% - ${DRAWER_WIDTH}px)`,
    // },
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
});

class TopBar extends Component {
  state = {
    username: "",
  };

  render() {
    const { classes } = this.props;

    return (
      <AppBar position="static" elevation={0} className={classes.appBar} style={{ boxShadow: "0 0 35px 0 rgba(154,161,171,.15)" }}>
        <Toolbar>
          <IconButton color="inherit" aria-label="Open drawer" onClick={() => this.props.toggleMobileOpen()} className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap className={classes.title}>
            {TokenManager.getCompanyName()}
          </Typography>
          <Typography color="inherit" noWrap>
            {this.props.username}
          </Typography>
          <IconButton
            style={{ marginLeft: 10 }}
            aria-label="log-out"
            onClick={() => {
              const pendingAction = logout();
              this.props.confirmLogout(pendingAction);
            }}
          >
            <ExitToAppIcon fontSize="inherit" />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMobileOpen: () => dispatch(toggleMobileOpen()),
    confirmLogout: (pendingAction) =>
      dispatch(showConfirmationDialog(Strings.getInstance().getString("dialog_logout_title"), Strings.getInstance().getString("dialog_logout_description"), pendingAction)),
  };
};

const mapStateToProps = (state) => {
  if ("inspector" in state.LoginReducer.userData) {
    return {
      username: state.LoginReducer.userData.inspector.firstName + " " + state.LoginReducer.userData.inspector.lastName,
    };
  } else {
    return {};
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TopBar));
