// Maps data coming from Store to UI and from UI to API

import { FIELD_ID, FIELD_STRUCTURE_NAME} from "./components/SGRHeatMap";
import { createSelector } from "reselect";

const getAnalyticsData = (state) => state.AnalyticsReducer.data;
const getProject = (state) => state.AnalyticsReducer.projectData;

const mapProjectName = (singleResponse) => {
  var mapResult = {};
  mapResult[singleResponse["id"]] = singleResponse["name"];
  return mapResult;
}

export const mapMultipleRows = createSelector([getAnalyticsData], (response) => {
  var temp_map = {};
  for (let i = 0; i < response.length; i++) {
    const element = response[i];
    if(!temp_map.hasOwnProperty(element['structureId'])){
      temp_map[element['structureId']] = {};
      temp_map[element['structureId']]['structureCode'] = element['structureCode'];
      temp_map[element['structureId']]['projects'] = {};
    }

    if(element.hasOwnProperty('projectId') && element.hasOwnProperty('sgrRating')){
      temp_map[element['structureId']]['projects'][element['projectId']] = Math.min(parseFloat(element['sgrRating']), temp_map[element['structureId']]['projects'][element['projectId']] || 100.0)
    }
  }

  var rows = []

  for(let structureId in temp_map){
    var row = {};
    row[FIELD_ID]  = structureId;
    row[FIELD_STRUCTURE_NAME]  = temp_map[structureId]['structureCode'];
    for (let project in temp_map[structureId]['projects']){
      row[project] = temp_map[structureId]['projects'][project];
    }
    rows.push(row);
  }

  return rows
});

export const mapProjectNames = createSelector([getProject], (response) => {
  return response.map((x) => mapProjectName(x));  
});




