import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Colors } from "config/StyleConfig";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = {
  button: {
    color: Colors.primary,
  },
  error: {
    fontSize: "small",
    marginTop: 15,
    color: Colors.error,
  },
};

class PasswordDialog extends Component {
  static optimizationKey = 0;
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      open: false,
      password: "",
      confirmPassword: "",
      confirmInputCallback: null,
      dismissDialogCallback: null,
      error: "",
    };
  }

  dismissDialog() {
    this.setState({ confirmPassword: "", password: "", error: "" });
    this.props.dismissDialogCallback();
  }

  isPasswordValid(password) {
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/;
    return regex.test(password);
  }

  confirmInput() {
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ error: "Passwords do not match" });
      return;
    }

    if (!this.isPasswordValid(this.state.password)) {
      this.setState({ error: "Password does not respect guidelines" });
      return;
    }

    this.props.confirmInputCallback(this.state.password);
    this.dismissDialog();
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;

    const actions = [];
    actions.push(
      <Button
        key={this.optimizationKey++}
        onClick={() => this.confirmInput()}
        className={classes.button}
      >
        Confirm
      </Button>
    );
    actions.push(
      <Button
        key={this.optimizationKey++}
        onClick={() => this.dismissDialog()}
        className={classes.button}
      >
        Cancel
      </Button>
    );

    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.dismissDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.props.description}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            name="password"
            label="Password"
            type="password"
            required
            onChange={this.handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            required
            onChange={this.handleChange}
            fullWidth
          />
          <Typography className={classes.error}>{this.state.error}</Typography>
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(PasswordDialog);
