import React, { Component } from "react";
import EditableTreeItem from "./EditableTreeItem";
import { TYPE } from "helper/Validator/ValidatorFieldBuilder";
import { Strings } from "config/Strings";

export default class SubComponentItem extends Component {
  fields = [
    {
      label: Strings.getInstance().getString("preferences_sub_component_name"),
      name: "name",
      type: TYPE.ANY,
      style: { flex: 1, marginRight: 30 },
    },
    {
      label: Strings.getInstance().getString("preferences_sub_component_bhi"),
      name: "fdotBhiValue",
      type: TYPE.NUMBER,
      style: { flex: 1, marginRight: 30 },
    },
    {
      label: Strings.getInstance().getString("preferences_sub_component_measure_unit"),
      name: "measureUnit",
      type: TYPE.TEXT,
      options: Strings.getInstance().getString("measure_unit"),
      style: { flex: 1 },
    },
  ];

  render() {
    return <EditableTreeItem nodeId={this.props.nodeId} data={this.props.data} fields={this.fields} {...this.props} />;
  }
}
