import Helper from "helper/Helper";

export const FETCH_PREFERENCES_STARTED = "FETCH_PREFERENCES_STARTED";
export const FETCH_PREFERENCES_SUCCESS = "FETCH_PREFERENCES_SUCCESS";

export const UPDATE_MODIFIED_DATA = "UPDATE_MODIFIED_DATA";
export const UPDATE_CREATED_DATA = "UPDATE_CREATED_DATA";
export const RESET_MODIFIED_AND_CREATED_DATA = "RESET_MODIFIED_AND_CREATED_DATA";

export const CANCEL_MODIFIED_DATA = "CANCEL_MODIFIED_DATA";
export const CANCEL_CREATED_DATA = "CANCEL_CREATED_DATA";

export const SUBMIT_MODIFIED_DATA_STARTED = "SUBMIT_MODIFIED_DATA_STARTED";
export const SUBMIT_MODIFIED_DATA_SUCCESS = "SUBMIT_MODIFIED_DATA_SUCCESS";
export const SUBMIT_MODIFIED_DATA_FAILED = "SUBMIT_MODIFIED_DATA_FAILED";

const initialState = {
  data: [],
  modifiedData: {},
  createdData: {},
  loading: true,
};

const recursiveClean = (newCreatedData, dataElement) => {
  const parentId = dataElement.type || dataElement.componentId || dataElement.subcomponentId;

  // Remove element from parent.
  if (parentId in newCreatedData) {
    for (var i = 0; i < newCreatedData[parentId].length; i++) {
      if (newCreatedData[parentId][i].id === dataElement.id) {
        newCreatedData[parentId].splice(i, 1);
        if (newCreatedData[parentId].length === 0) {
          delete newCreatedData[parentId];
        }
        break;
      }
    }
  }

  // Remove all element inside current element recursively and element itself.
  if (dataElement.id in newCreatedData) {
    newCreatedData[dataElement.id].forEach((element) => {
      recursiveClean(newCreatedData, element);
    });
    delete newCreatedData[dataElement.id];
  }

  return newCreatedData;
};

const pushOrReplace = (arr, element) => {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i]["id"] === element["id"]) {
      arr[i] = element;
      return arr;
    }
  }

  arr.push(element);
  return arr;
};

const PreferencesReducer = (state = initialState, action) => {
  var parentId = undefined;
  if (action.dataElement !== undefined) {
    parentId = action.dataElement.type || action.dataElement.componentId || action.dataElement.subcomponentId;
  }

  var newModifiedData = undefined;
  var newCreatedData = undefined;

  switch (action.type) {
    case FETCH_PREFERENCES_STARTED:
      return { ...state, loading: true };
    case FETCH_PREFERENCES_SUCCESS:
      return { ...state, data: action.data, loading: false };
    case UPDATE_MODIFIED_DATA:
      newModifiedData = Helper.deepCopy(state.modifiedData);
      newModifiedData[action.dataElement.id] = action.dataElement;

      return { ...state, modifiedData: newModifiedData };
    case UPDATE_CREATED_DATA:
      newCreatedData = Helper.deepCopy(state.createdData);
      if (!(parentId in newCreatedData)) {
        newCreatedData[parentId] = [];
      }
      newCreatedData[parentId] = pushOrReplace(newCreatedData[parentId], action.dataElement);
      return { ...state, createdData: newCreatedData };
    case CANCEL_MODIFIED_DATA:
      newModifiedData = Helper.deepCopy(state.modifiedData);
      delete newModifiedData[action.dataElement.id];
      return { ...state, modifiedData: newModifiedData };
    case CANCEL_CREATED_DATA:
      newCreatedData = recursiveClean(Helper.deepCopy(state.createdData), action.dataElement);
      return { ...state, createdData: newCreatedData };
    case RESET_MODIFIED_AND_CREATED_DATA:
      return { ...state, modifiedData: {}, createdData: {} };
    case SUBMIT_MODIFIED_DATA_STARTED:
      return { ...state, loading: true };
    case SUBMIT_MODIFIED_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        modifiedData: {},
        createdData: {},
        data: action.data,
      };
    default:
      return state;
  }
};

export { PreferencesReducer };
