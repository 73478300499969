import React from "react";
import visibilityJson from "./visibility_flag.json"
import TokenManager from "storage/TokenManager";

export const VisibilityFlag = (function () {
  var instance;

  return {
    getInstance: function () {
      if (!instance) {
        instance = new VisibilityFlagInstance();
      }
      return instance;
    },
  };
})();



class VisibilityFlagInstance {
  constructor() {
    this.visibility = visibilityJson
  }

  isVisible(key) {
    var companyId = TokenManager.getCompanyId();
    if (companyId in this.visibility) {
      var result = this.visibility[companyId][key];
      if (result !== undefined) {
        return result;
      }
    }
    return true;
  }
}
