import React, { Component } from "react";
import Table from "components/Table";
import { connect } from "react-redux";
import { showInfoDialog, showConfirmationDialog } from "components/Dialog/DialogAction";

import { mapStructureTypes, mapMultipleRows } from "./StructuresMapper";
import { watchStructuresTableData, addStructure, updateStructure, deleteStructure } from "../Structures/StructuresAction";
import ValidatorArray from "helper/Validator/ValidatorArray";
import ValidatorFieldBuilder, { TYPE } from "helper/Validator/ValidatorFieldBuilder";
import Navigator from "Navigator";
import ColumnBuilder from "../../helper/ColumnBuilder";
import TokenManager from "../../storage/TokenManager";
import { Strings } from "../../config/Strings";
import { VisibilityFlag } from "config/VisibilityFlag"

export const FIELD_ID = "id";
export const FIELD_CODE = "code";
export const FIELD_NAME = "name";
export const FIELD_TYPE = "type";
export const FIELD_STRUCTURE_NUMBER = "structure_number";
export const FIELD_POST_MILE = "post_mile";
export const FIELD_BEGIN_STATIONING = "begin_stationing";
export const FIELD_END_STATIONING = "end_stationing";

class Structures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };

    this.structureTypes = mapStructureTypes(TokenManager.getStructureTypes());
  }

  viewOnly = this.props.viewOnly;

  validateFields = (structureId, structureName, structureType, structureNumber, postMile, beginStationing, endStationing) => {
    var validator = new ValidatorArray();
    validator.push(new ValidatorFieldBuilder(structureId, TYPE.ANY).canBeEmpty(false).build());
    validator.push(new ValidatorFieldBuilder(structureName, TYPE.ANY).canBeEmpty(false).build());
    validator.push(new ValidatorFieldBuilder(structureType, TYPE.ANY).canBeEmpty(false).build());
    validator.push(new ValidatorFieldBuilder(structureNumber, TYPE.ANY).build());
    validator.push(new ValidatorFieldBuilder(postMile, TYPE.ANY).build());
    validator.push(new ValidatorFieldBuilder(beginStationing, TYPE.ANY).build());
    validator.push(new ValidatorFieldBuilder(endStationing, TYPE.ANY).build());
    return validator;
  };

  getColumns = () => {
    var columns = [];
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_structures_table_structure_id"), FIELD_CODE).build());
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_structures_table_structure_name"), FIELD_NAME).build());
    columns.push(
      new ColumnBuilder(Strings.getInstance().getString("client_structures_table_structure_type"), FIELD_TYPE).lookup(this.structureTypes).build()
    );
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_structures_table_structure_number"), FIELD_STRUCTURE_NUMBER).build());
    if (TokenManager.hasBridges()) {
      columns.push(new ColumnBuilder(Strings.getInstance().getString("client_structures_table_post_mile"), FIELD_POST_MILE).build());
      columns.push(new ColumnBuilder(Strings.getInstance().getString("client_structures_table_begin_stationing"), FIELD_BEGIN_STATIONING).build());
      columns.push(new ColumnBuilder(Strings.getInstance().getString("client_structures_table_end_stationing"), FIELD_END_STATIONING).build());
    }

    return columns;
  };

  componentDidMount() {
    this.props.watchStructuresTableData(this.props.companyId);
  }

  onRowClick = (event, rowData) => {
    Navigator.navigateToStructure(rowData.id);
  };

  getActions = () => {
    var actions = [];
    actions.push({
      icon: "refresh",
      tooltip: "Refresh Data",
      isFreeAction: "true",
      onClick: () => {
        this.props.watchStructuresTableData(this.props.companyId);
      },
    });
    if (!this.viewOnly) {
      actions.push({
        icon: "delete_outlined",
        tooltip: "Remove",
        onClick: (event, rowData) => {
          const pendingAction = deleteStructure(rowData["id"]);
          this.props.confirmDeleteStructure(pendingAction);
        },
      });
    }

    return actions;
  };

  getEditable = () => {
    if (this.viewOnly) {
      return {};
    }
    return this.editable;
  };

  editable = {
    onRowAdd: (newData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          let validator = this.validateFields(
            newData[FIELD_CODE],
            newData[FIELD_NAME],
            newData[FIELD_TYPE],
            newData[FIELD_STRUCTURE_NUMBER],
            newData[FIELD_POST_MILE],
            newData[FIELD_BEGIN_STATIONING],
            newData[FIELD_END_STATIONING]
          );
          if (validator.validate()) {
            resolve();
            this.props.addStructure(
              newData[FIELD_CODE],
              newData[FIELD_NAME],
              newData[FIELD_TYPE],
              newData[FIELD_STRUCTURE_NUMBER],
              newData[FIELD_POST_MILE],
              newData[FIELD_BEGIN_STATIONING],
              newData[FIELD_END_STATIONING],
              this.props.companyId
            );
          } else {
            this.props.showError("Invalid Input", validator.generateColumnErrorDescription(this.getColumns()));
            reject();
          }
        }, 100);
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          let validator = this.validateFields(
            newData[FIELD_CODE],
            newData[FIELD_NAME],
            newData[FIELD_TYPE],
            newData[FIELD_STRUCTURE_NUMBER],
            newData[FIELD_POST_MILE],
            newData[FIELD_BEGIN_STATIONING],
            newData[FIELD_END_STATIONING]
          );
          if (validator.validate()) {
            resolve();
            this.props.updateStructure(
              newData[FIELD_ID],
              newData[FIELD_CODE],
              newData[FIELD_NAME],
              newData[FIELD_TYPE],
              newData[FIELD_STRUCTURE_NUMBER],
              newData[FIELD_POST_MILE],
              newData[FIELD_BEGIN_STATIONING],
              newData[FIELD_END_STATIONING],
              this.props.companyId
            );
          } else {
            reject();
            this.props.showError("Invalid Input", validator.generateColumnErrorDescription(this.getColumns()));
          }
        }, 100);
      }),
  };

  render() {
    return (
      <Table
        id="STRUCTURES_TABLE"
        title={Strings.getInstance().getString("client_structures_table_title")}
        columns={this.getColumns()}
        options={{ actionsColumnIndex: -1 }}
        data={this.props.data}
        actions={this.getActions()}
        editable={this.getEditable()}
        onRowClick={this.onRowClick}
        isLoading={this.props.loading}
        disableHideColumnOption
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: mapMultipleRows(state),
    loading: state.StructuresReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    watchStructuresTableData: (companyId) => dispatch(watchStructuresTableData(companyId)),
    addStructure: (structureCode, structureName, structureType, structureNumber, postMile, beingStationing, endStationing, companyId) =>
      dispatch(addStructure(structureCode, structureName, structureType, structureNumber, postMile, beingStationing, endStationing, companyId)),
    updateStructure: (id, structureCode, structureName, structureType, structureNumber, postMile, beingStationing, endStationing, clientId) =>
      dispatch(updateStructure(id, structureCode, structureName, structureType, structureNumber, postMile, beingStationing, endStationing, clientId)),
    showError: (title, description) => dispatch(showInfoDialog(title, description)),
    confirmDeleteStructure: (pendingAction) =>
      dispatch(showConfirmationDialog(Strings.getInstance().getString("dialog_delete_structure_title"), Strings.getInstance().getString("dialog_delete_structure_description"), pendingAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Structures);
