import TokenManager from "storage/TokenManager";

import { LOGIN_USER_STARTED, LOGIN_USER_ERROR, LOGIN_USER_SUCCESS } from "./LoginReducer";
import AuthenticationAPIController from "services/api/AuthenticationAPIController";

const loginUserSuccess = (userData) => {
  return {
    type: LOGIN_USER_SUCCESS,
    userData: userData,
  };
};

const userIsAuthenticated = (userData) => {
  return {
    type: LOGIN_USER_SUCCESS,
    userData: userData,
  };
};

const userIsNotAuthenticated = () => {
  return {
    type: LOGIN_USER_ERROR,
    error: "",
  };
};

const loginUserError = (error) => {
  return {
    type: LOGIN_USER_ERROR,
    error: error,
  };
};

const loginUserStarted = () => {
  return {
    type: LOGIN_USER_STARTED,
  };
};

const login = (email, password) => {
  return function (dispatch) {
    dispatch(loginUserStarted());
    AuthenticationAPIController.login(email, password)
      .then((response) => {
        if ([1, 2].includes(response["code"])) {
          dispatch(loginUserError("Invalid email address or password"));
        } else {
          TokenManager.setToken(response);
          dispatch(loginUserSuccess(response));
        }
      })
      .catch((error) => {
        dispatch(loginUserError("Something went wrong. Please try again."));
        console.log(error);
      });
  };
};

const isAuthenticated = () => {
  return function (dispatch) {
    if (TokenManager.isAuthenticated()) {
      dispatch(userIsAuthenticated(TokenManager.getToken()));
    } else {
      dispatch(userIsNotAuthenticated());
    }
  };
};

export { login, isAuthenticated };
