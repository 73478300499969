import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CircularProgress,
  DialogContentText,
  MenuItem,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  Theme,
  WithStyles,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { useCloneInspection } from 'services/api/inspection/mutation';
import { useInspection } from 'services/api/inspection/queries';
import { useProjects } from 'services/api/project/queries';
import { useAllUsers } from 'services/api/user/queries';

import { useQuerystring } from 'utils/hooks';
import Helper from 'helper/Helper';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    gridGap: theme.spacing(2),
  },
  widthFull: { width: '100%' },
}));

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

type FormFields = {
  structureId: string;
  structureName: string;
  structureCode: string;
  spans: number;
  startDate: Date;
  endDate?: Date;
  SGR: number;
  term: number;
  project: number;
};

const schema = yup.object({
  project: yup.number().test('is-required', 'Select project', function (value) {
    if (value === undefined) return false;
    if (value === 0) return false;
    return true;
  }),
});

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CloneInspection() {
  const searchParams = useQuerystring();
  const isParamOpen = searchParams.has('clone')
    ? Boolean(searchParams.get('clone'))
    : false;

  const { isLoading, data: inspection } = useInspection({
    params: {
      inspectionId: searchParams.get('clone') ?? '',
    },
  });
  const cloneInpsectionMutation = useCloneInspection();
  const hasError = cloneInpsectionMutation.isError;

  useEffect(() => {
    setOpen(isParamOpen);
  }, [isParamOpen]);

  const [open, setOpen] = useState(isParamOpen);
  const history = useHistory();

  const handleClose = () => {
    if (cloneInpsectionMutation.isLoading) return;
    setOpen(false);
    setTimeout(() => {
      cloneInpsectionMutation.reset();
      searchParams.delete('clone');
      history.replace(`?${searchParams.toString()}`);
    }, 100);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Clone Inpsection"
      aria-describedby="Clone an existing inspection"
      maxWidth="sm"
      fullWidth
      open={open}
    >
      <DialogTitle id="clone-inspection" onClose={handleClose}>
        Clone Inspection
      </DialogTitle>
      <DialogContent>
        {hasError && (
          <Alert severity="error" variant="filled">
            <AlertTitle>Error</AlertTitle>
            There was an error encountered while cloning this inspection, try
            again
          </Alert>
        )}
        <DialogContentText>
          Cloning {inspection?.structureCode} #{inspection?.uuid}
        </DialogContentText>
        {isLoading ? (
          <Box display="flex" justifyContent="center" width="100%" p={1}>
            <CircularProgress />
          </Box>
        ) : inspection !== undefined ? (
          <InspectionForm
            initialValue={{
              structureId: inspection.structureId,
              structureName: inspection.structureName,
              structureCode: inspection.structureCode,
              project: 0,
              SGR: 0,
              spans: inspection.spansCount,
              startDate: new Date(inspection.startDate),
              endDate: undefined,
              term: 1,
            }}
            onSubmit={(data) => {
              const inspectionId = searchParams.get('clone');
              const date = Helper.convertToDBDateFormat(
                data.startDate.toISOString(),
              );
              if (inspectionId && date) {
                cloneInpsectionMutation.mutate(
                  {
                    existingInspectionUuid: inspectionId.toString(),
                    projectId: data.project,
                    startDate: date,
                    // inspectors:
                  },
                  {
                    onSuccess: () => {
                      handleClose();
                    },
                  },
                );
              }
            }}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          disabled={cloneInpsectionMutation.isLoading}
          form="clone-inspection-form"
        >
          {cloneInpsectionMutation.isLoading
            ? '  Cloning inspection'
            : '  Clone inspection'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type InspectionFormProps = {
  onSubmit: (data: FormFields) => void;
  initialValue?: FormFields;
};
function InspectionForm({
  initialValue,
  onSubmit: onSubmitHandler,
}: InspectionFormProps) {
  const classes = useStyles();
  const { control, handleSubmit } = useForm<FormFields>({
    defaultValues: initialValue ?? {},
    resolver: yupResolver(schema),
  });
  const onSubmit = handleSubmit((data) => {
    onSubmitHandler(data);
  });

  return (
    <form id="clone-inspection-form" onSubmit={onSubmit} autoComplete="off">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="project"
              defaultValue={0}
              render={({ field, fieldState }) => (
                <ProjectSelect
                  value={field.value}
                  onChange={field.onChange}
                  error={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="structureId"
              defaultValue=""
              render={({ field }) => {
                return (
                  <TextField
                    id={field.name}
                    label="Structure ID"
                    {...field}
                    classes={{ root: classes.widthFull }}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="structureName"
              defaultValue=""
              render={({ field }) => {
                return (
                  <TextField
                    id={field.name}
                    label="Structure name"
                    {...field}
                    classes={{ root: classes.widthFull }}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="spans"
              defaultValue={0}
              render={({ field }) => {
                return (
                  <TextField
                    id={field.name}
                    label="Spans"
                    classes={{ root: classes.widthFull }}
                    {...field}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="startDate"
              render={({ field }) => {
                return (
                  <KeyboardDatePicker
                    format="MM/dd/yyyy"
                    id={field.name}
                    label="Start Date"
                    // classes={{ root: classes.widthFull }}
                    {...field}
                    onChange={(date) => field.onChange(date)}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="endDate"
              defaultValue={undefined}
              render={({ field }) => {
                return (
                  <TextField
                    id={field.name}
                    label="End Date"
                    classes={{ root: classes.widthFull }}
                    {...field}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="SGR"
              defaultValue={undefined}
              render={({ field }) => {
                return (
                  <TextField
                    id={field.name}
                    label="SGR"
                    classes={{ root: classes.widthFull }}
                    {...field}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name="term"
              defaultValue={undefined}
              render={({ field }) => {
                return (
                  <TextField
                    id={field.name}
                    label="Term"
                    classes={{ root: classes.widthFull }}
                    {...field}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </form>
  );
}

type ProjectSelectProps = {
  value: number;
  onChange: (id: number) => void;
  error?: string;
};

function ProjectSelect(props: ProjectSelectProps) {
  const classes = useStyles();
  const { value, onChange, error: errorMessage } = props;
  const { isError, data } = useProjects();

  if (isError) {
    return <div>Get Projects Error</div>;
  }

  if (data !== undefined) {
    const options = data.map((project) => ({
      value: project.id,
      label: project.name,
    }));

    return (
      <TextField
        id="inpsection-project"
        select
        label="Project"
        helperText={
          errorMessage
            ? errorMessage
            : 'Select the project to associate this inspection with'
        }
        value={value}
        classes={{
          root: classes.widthFull,
        }}
        onChange={(e) => {
          onChange(Number(e.target.value));
        }}
        error={!!errorMessage}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }
  return <Skeleton variant="rect" width={210} height={118} />;
}

type InspectorSelectProps = {
  value: number;
  onChange: (id: number) => void;
  error?: string;
};

function InspectorSelect(props: InspectorSelectProps) {
  const classes = useStyles();
  const { value, onChange, error: errorMessage } = props;
  const { isError, data } = useAllUsers();

  if (isError) {
    return <div>Get All Users Error</div>;
  }

  if (data !== undefined) {
    const options = data.map((user) => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    }));

    return (
      <TextField
        id="inpsection-project"
        select
        label="Project"
        helperText={errorMessage}
        value={value}
        classes={{
          root: classes.widthFull,
        }}
        onChange={(e) => {
          onChange(Number(e.target.value));
        }}
        error={!!errorMessage}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  return <Skeleton variant="rect" width={210} height={118} />;
}
