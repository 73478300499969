import InspectionAPIController from 'services/api/InspectionAPIController';
import StructureAPIController from 'services/api/StructureAPIController';
import ClientAPIController from 'services/api/ClientAPIController';
import ProjectAPIController from 'services/api/ProjectAPIController';
import ObservationAPIController from 'services/api/ObservationAPIController';
import PreferencesAPIController from 'services/api/PreferencesAPIController';
import TemplateAPIController from '../../services/api/TemplateAPIController';
import { queryClient } from 'config/queryClient';

export const DOWNLOAD_INSPECTION_STARTED = 'DOWNLOAD_INSPECTION_STARTED';
export const DOWNLOAD_INSPECTION_SUCCESS = 'DOWNLOAD_INSPECTION_SUCCESS';

export const DOWNLOAD_IMAGES_STARTED = 'DOWNLOAD_IMAGES_STARTED';
export const DOWNLOAD_IMAGES_SUCCESS = 'DOWNLOAD_IMAGES_SUCCESS';

export const FETCH_OBSERVATIONS_STARTED = 'FETCH_OBSERVATION_STARTED';
export const FETCH_OBSERVATIONS_SUCCESS = 'FETCH_OBSERVATIONS_SUCCESS';
export const FETCH_OBSERVATIONS_ERROR = 'FETCH_OBSERVATION_ERROR';

export const FETCH_DATA_STARTED = 'FETCH_DATA_STARTED';
export const FETCH_INSPECTION_SUCCESS = 'FETCH_INSPECTION_SUCCESS';
export const FETCH_STRUCTURE_SUCCESS = 'FETCH_STRUCTURE_SUCCESS';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_CLIENT_SUCCESS = 'FETCH_CLIENT_SUCCESS';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'FETCH_DATA_ERROR';
export const FETCH_PREFERENCES_SUCCESS = 'FETCH_PREFERENCES_SUCCESS';
export const FETCH_SUB_COMPONENTS_OF_INSPECTION_SUCCESS =
  'FETCH_SUB_COMPONENTS_OF_INSPECTION_SUCCESS';
export const FETCH_SUB_COMPONENT_OF_STRUCTURE_SUCCESS =
  'FETCH_SUB_COMPONENT_OF_STRUCTURE_SUCCESS';

export const UPDATE_OBSERVATION_STARTED = 'UPDATE_OBSERVATION_STARTED';
export const UPDATE_OBSERVATION_SUCCESS = 'UPDATE_OBSERVATION_SUCCESS';
export const UPDATE_OBSERVATION_ERROR = 'UPDATE_OBSERVATION_ERROR';

export const UPDATE_IMAGE_RECTANGLE_STARTED = 'UPDATE_IMAGE_RECTANGLE_STARTED';
export const UPDATE_IMAGE_RECTANGLE_SUCCESS = 'UPDATE_IMAGE_RECTANGLE_SUCCESS';
export const UPDATE_IMAGE_RECTANGLE_ERROR = 'UPDATE_IMAGE_RECTANGLE_ERROR';

export const DELETE_IMAGE_STARTED = 'DELETE_IMAGE_STARTED';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
export const DELETE_IMAGE_ERROR = 'DELETE_IMAGE_ERROR';

export const UPLOAD_IMAGE_STARTED = 'UPLOAD_IMAGE_STARTED';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';

export const DELETE_OBSERVATION_STARTED = 'DELETE_OBSERVATION_STARTED';
export const DELETE_OBSERVATION_SUCCESS = 'DELETE_OBSERVATION_SUCCESS';

export const UPDATE_INSPECTION_SUMMARY_STARTED =
  'UPDATE_INSPECTION_SUMMARY_STARTED';
export const UPDATE_INSPECTION_SUMMARY_SUCCESS =
  'UPDATE_INSPECTION_SUMMARY_SUCCESS';
export const UPDATE_INSPECTION_SUMMARY_ERROR =
  'UPDATE_INSPECTION_SUMMARY_ERROR';

export const UPDATE_LOCATION_INSPECTED_STARTED =
  'UPDATE_LOCATION_INSPECTED_STARTED';
export const UPDATE_LOCATION_INSPECTED_SUCCESS =
  'UPDATE_LOCATION_INSPECTED_SUCCESS';
export const UPDATE_LOCATION_INSPECTED_ERROR =
  'UPDATE_LOCATION_INSPECTED_ERROR';

export const ADD_LOCATION_STARTED = 'ADD_LOCATION_STARTED';
export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';
export const ADD_LOCATION_ERROR = 'ADD_LOCATION_ERROR';

export const DELETE_LOCATION_STARTED = 'DELETE_LOCATION_STARTED';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_ERROR = 'DELETE_LOCATION_ERROR';

export const UPDATE_LOCATION_STARTED = 'UPDATE_LOCATION_STARTED';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_ERROR = 'UPDATE_LOCATION_ERROR';

export const fetchSubComponentOfStructureSuccess = (data) => {
  return {
    type: FETCH_SUB_COMPONENT_OF_STRUCTURE_SUCCESS,
    data: data,
  };
};

export const fetchSubComponentsOfInspectionSuccess = (data) => {
  return {
    type: FETCH_SUB_COMPONENTS_OF_INSPECTION_SUCCESS,
    data: data,
  };
};

const deleteLocationStarted = () => {
  return {
    type: DELETE_LOCATION_STARTED,
  };
};

const deleteLocationError = () => {
  return {
    type: DELETE_LOCATION_ERROR,
  };
};

const deleteLocationSuccess = (locationUUID) => {
  return {
    type: DELETE_LOCATION_SUCCESS,
    locationUUID: locationUUID,
  };
};

const fetchInspectionDataSuccess = (inspectionData) => {
  return {
    type: FETCH_INSPECTION_SUCCESS,
    inspectionData: inspectionData,
  };
};

const fetchDataStarted = () => {
  return {
    type: FETCH_DATA_STARTED,
  };
};

const fetchDataSuccess = () => {
  return {
    type: FETCH_DATA_SUCCESS,
  };
};

const fetchStructureDataSuccess = (structureData) => {
  return {
    type: FETCH_STRUCTURE_SUCCESS,
    structureData: structureData,
  };
};

const fetchPreferenceDataSuccess = (preferencesData) => {
  return {
    type: FETCH_PREFERENCES_SUCCESS,
    preferencesData: preferencesData,
  };
};

const updateLocationStarted = () => {
  return {
    type: UPDATE_LOCATION_STARTED,
  };
};

const updateLocationSuccess = (location) => {
  return {
    type: UPDATE_LOCATION_SUCCESS,
    location: location,
  };
};

const updateLocationInspectedStarted = () => {
  return {
    type: UPDATE_LOCATION_INSPECTED_STARTED,
  };
};

const updateLocationInspectedSuccess = (location) => {
  return {
    type: UPDATE_LOCATION_INSPECTED_SUCCESS,
    location: location,
  };
};

const updateLocationError = (code) => {
  return {
    type: UPDATE_LOCATION_ERROR,
    errorCode: code,
  };
};

const updateLocationInspectedError = (code) => {
  return {
    type: UPDATE_LOCATION_INSPECTED_ERROR,
    errorCode: code,
  };
};

const uploadImageStarted = () => {
  return {
    type: UPLOAD_IMAGE_STARTED,
  };
};

const uploadImageError = (code) => {
  return {
    type: UPLOAD_IMAGE_ERROR,
    errorCode: code,
  };
};

const uploadImageSuccess = (componentSubcomponentId, observationId, photo) => {
  return {
    type: UPLOAD_IMAGE_SUCCESS,
    componentSubcomponentId: componentSubcomponentId,
    observationId: observationId,
    photo: photo,
  };
};

const updateInspectionSummaryStarted = () => {
  return {
    type: UPDATE_INSPECTION_SUMMARY_STARTED,
  };
};

const updateInspectionSummarySuccess = (inspectionData) => {
  return {
    type: UPDATE_INSPECTION_SUMMARY_SUCCESS,
    inspectionData: inspectionData,
  };
};

const updateInspectionSummaryError = (code) => {
  return {
    type: UPDATE_INSPECTION_SUMMARY_ERROR,
    errorCode: code,
  };
};

const updateObservationStarted = () => {
  return {
    type: UPDATE_OBSERVATION_STARTED,
  };
};

const updateObservationSuccess = (observation, componentSubcomponentId) => {
  return {
    type: UPDATE_OBSERVATION_SUCCESS,
    observation: observation,
    componentSubcomponentId: componentSubcomponentId,
  };
};

const updateObservationError = (code) => {
  return {
    type: UPDATE_OBSERVATION_ERROR,
    errorCode: code,
  };
};

const addLocationStarted = () => {
  return {
    type: ADD_LOCATION_STARTED,
  };
};

const addLocationSuccess = (location) => {
  return {
    type: ADD_LOCATION_SUCCESS,
    location: location,
  };
};

const addLocationError = (code) => {
  return {
    type: ADD_LOCATION_ERROR,
    errorCode: code,
  };
};

const updateImageRectangleStarted = () => {
  return {
    type: UPDATE_IMAGE_RECTANGLE_STARTED,
  };
};

const updateImageRectangleSuccess = (
  componentSubcomponentId,
  observationId,
  photoId,
  drawable,
) => {
  return {
    type: UPDATE_IMAGE_RECTANGLE_SUCCESS,
    componentSubcomponentId: componentSubcomponentId,
    observationId: observationId,
    photoId: photoId,
    rectangle: drawable,
  };
};

const updateImageRectangleError = (code) => {
  return {
    type: UPDATE_IMAGE_RECTANGLE_ERROR,
    errorCode: code,
  };
};

const deleteImageStarted = () => {
  return {
    type: DELETE_IMAGE_STARTED,
  };
};

const deleteImageSuccess = (
  componentSubcomponentId,
  observationId,
  photoId,
) => {
  return {
    type: DELETE_IMAGE_SUCCESS,
    componentSubcomponentId: componentSubcomponentId,
    observationId: observationId,
    photoId: photoId,
  };
};

const deleteImageError = (code) => {
  return {
    type: DELETE_IMAGE_ERROR,
    errorCode: code,
  };
};

const fetchObservationsStarted = () => {
  return {
    type: FETCH_OBSERVATIONS_STARTED,
  };
};

const fetchObservationsSuccess = (inspectionData) => {
  return {
    type: FETCH_OBSERVATIONS_SUCCESS,
    inspectionData: inspectionData,
  };
};

const fetchObservationsError = (code) => {
  return {
    type: FETCH_OBSERVATIONS_ERROR,
    errorCode: code,
  };
};

const fetchProjectDataSuccess = (projectData) => {
  return {
    type: FETCH_PROJECT_SUCCESS,
    projectData: projectData,
  };
};

const fetchClientDataSuccess = (clientData) => {
  return {
    type: FETCH_CLIENT_SUCCESS,
    clientData: clientData,
  };
};

const fetchDataError = (code) => {
  return {
    type: FETCH_DATA_ERROR,
    errorCode: code,
  };
};

const downloadInspectionReportStarted = () => {
  return {
    type: DOWNLOAD_INSPECTION_STARTED,
  };
};

const downloadInspectionReportSuccess = () => {
  return {
    type: DOWNLOAD_INSPECTION_SUCCESS,
  };
};

const downloadInspectionImagesStarted = () => {
  return {
    type: DOWNLOAD_IMAGES_STARTED,
  };
};

const downloadInspectionImagesSuccess = () => {
  return {
    type: DOWNLOAD_IMAGES_SUCCESS,
  };
};

const downloadInspectionReport = (inspectionID) => {
  return function (dispatch) {
    dispatch(downloadInspectionReportStarted());
    InspectionAPIController.getInspectionReport(inspectionID).then((blob) => {
      if (typeof window !== 'undefined') {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = 'inspection.docx';
        a.click();
        dispatch(downloadInspectionReportSuccess());
      }
    });
  };
};

const downloadInspectionPhotosZip = (inspectionID, fileName) => {
  return function (dispatch) {
    dispatch(downloadInspectionImagesStarted());
    InspectionAPIController.getInspectionPhotosZip(inspectionID).then(
      (blob) => {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName + '.zip';
        a.click();
        dispatch(downloadInspectionImagesSuccess());
      },
    );
  };
};

const deleteObservationStarted = () => {
  return {
    type: DELETE_OBSERVATION_STARTED,
  };
};

const deleteObservationSuccess = (observationId) => {
  return {
    type: DELETE_OBSERVATION_SUCCESS,
    observationId: observationId,
  };
};

const getInspectionData = (inspectionId) => {
  return function (dispatch) {
    dispatch(fetchDataStarted());

    PreferencesAPIController.getStructurePreferences()
      .then((response) => {
        dispatch(fetchPreferenceDataSuccess(response));
        queryClient.setQueryData(['dictionary'], response);
      })
      .then(() => {
        InspectionAPIController.getInspection(inspectionId)
          .then((response) => {
            dispatch(fetchInspectionDataSuccess(response));
            return response;
          })
          .then((response) => {
            const promiseA = StructureAPIController.getStructure(
              response.structureId,
            )
              .then((response) => {
                dispatch(fetchStructureDataSuccess(response));
                return response;
              })
              .then((response) => {
                ClientAPIController.getClient(response.clientId)
                  .then((response) => {
                    dispatch(fetchClientDataSuccess(response));
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch((error) => {
                console.log(error);
              });
            const promiseB = ProjectAPIController.getProject(response.projectId)
              .then((response) => {
                dispatch(fetchProjectDataSuccess(response));
              })
              .catch((error) => {
                console.log(error);
              });

            const promiseC = TemplateAPIController.getSubComponentsOfStructure(
              response.structureId,
            )
              .then((response) => {
                dispatch(fetchSubComponentOfStructureSuccess(response));
              })
              .catch((error) => {
                console.log(error);
              });

            const promiseD = TemplateAPIController.getSubComponentsOfInspection(
              response.uuid,
            )
              .then((response) => {
                dispatch(fetchSubComponentsOfInspectionSuccess(response));
              })
              .catch((error) => {
                console.log(error);
              });

            Promise.all([promiseA, promiseB, promiseC, promiseD])
              .then(() => {
                dispatch(fetchDataSuccess());
              })
              .catch((error) => {
                console.log(error);
                dispatch(fetchDataError(error.code));
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const refreshObservationsData = (inspectionId) => {
  return function (dispatch) {
    dispatch(fetchObservationsStarted());
    InspectionAPIController.getInspection(inspectionId)
      .then((response) => {
        dispatch(fetchObservationsSuccess(response));
        queryClient.invalidateQueries([
          'inspection',
          inspectionId,
          'related-cloned-inspections',
        ]);
        return response;
      })
      .catch((error) => {
        if (error.code === 401) {
          dispatch(fetchObservationsError(error.code));
        }
      });
  };
};

const deleteObservation = (
  inspectionId,
  componentSubcomponentId,
  observationId,
) => {
  return function (dispatch) {
    dispatch(deleteObservationStarted());
    ObservationAPIController.deleteObservation(
      inspectionId,
      componentSubcomponentId,
      observationId,
    )
      .then((response) => {
        dispatch(deleteObservationSuccess(observationId));
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const updateLocation = (inspectionId, location) => {
  return function (dispatch) {
    dispatch(updateLocationStarted());
    ObservationAPIController.updateLocation(inspectionId, location)
      .then((response) => {
        dispatch(updateLocationSuccess(response));
        return response;
      })
      .catch((error) => {
        if (error.code === 401) {
          dispatch(updateLocationError(error.code));
        }
      });
  };
};

const updateObservation = (
  inspectionId,
  componentSubcomponentId,
  observation,
  compSubUpdated,
) => {
  return function (dispatch) {
    dispatch(updateObservationStarted());

    if (compSubUpdated) {
      ObservationAPIController.updateObservationCompSub(
        componentSubcomponentId,
        observation['uuid'],
      )
        .then(() => {
          ObservationAPIController.updateObservation(
            inspectionId,
            componentSubcomponentId,
            observation,
          )
            .then((response) => {
              dispatch(
                updateObservationSuccess(response, componentSubcomponentId),
              );
              return response;
            })
            .catch((error) => {
              if (error.code === 401) {
                dispatch(updateObservationError(error.code));
              }
            });
        })
        .catch((error) => {
          if (error.code === 401) {
            dispatch(updateObservationError(error.code));
          }
        });
    } else {
      ObservationAPIController.updateObservation(
        inspectionId,
        componentSubcomponentId,
        observation,
      )
        .then((response) => {
          dispatch(updateObservationSuccess(response));
          return response;
        })
        .catch((error) => {
          if (error.code === 401) {
            dispatch(updateObservationError(error.code));
          }
        });
    }
  };
};

const updateImageRectangle = (
  inspectionId,
  componentSubcomponentId,
  observationId,
  photoId,
  drawable,
) => {
  return function (dispatch) {
    dispatch(updateImageRectangleStarted());
    ObservationAPIController.updateImageRectangle(
      inspectionId,
      componentSubcomponentId,
      observationId,
      photoId,
      drawable,
    )
      .then((response) => {
        dispatch(
          updateImageRectangleSuccess(
            componentSubcomponentId,
            observationId,
            photoId,
            drawable,
          ),
        );
        return response;
      })
      .catch((error) => {
        if (error.code === 401) {
          dispatch(updateImageRectangleError(error.code));
        }
      });
  };
};

const deleteImage = (
  inspectionId,
  componentSubcomponentId,
  observationId,
  photoId,
) => {
  return function (dispatch) {
    dispatch(deleteImageStarted());
    ObservationAPIController.deleteImage(
      inspectionId,
      componentSubcomponentId,
      observationId,
      photoId,
    )
      .then((response) => {
        dispatch(
          deleteImageSuccess(componentSubcomponentId, observationId, photoId),
        );
        return response;
      })
      .catch((error) => {
        if (error.code === 401) {
          dispatch(deleteImageError(error.code));
        }
      });
  };
};

const uploadImage = (
  inspectionId,
  componentSubcomponentId,
  observationId,
  data,
) => {
  return function (dispatch) {
    dispatch(uploadImageStarted());
    ObservationAPIController.uploadImage(
      inspectionId,
      componentSubcomponentId,
      observationId,
      data,
    )
      .then((response) => {
        dispatch(
          uploadImageSuccess(componentSubcomponentId, observationId, response),
        );
        return response;
      })
      .catch((error) => {
        if (error.code === 401) {
          dispatch(uploadImageError(error.code));
        }
      });
  };
};

const addLocation = (inspectionId, location) => {
  return function (dispatch) {
    dispatch(addLocationStarted());
    ObservationAPIController.addLocation(inspectionId, location)
      .then((response) => {
        dispatch(addLocationSuccess(response));
        return response;
      })
      .catch((error) => {
        if (error.code === 401) {
          dispatch(addLocationError(error.code));
        }
      });
  };
};

const deleteLocation = (inspectionId, locationUUID) => {
  return function (dispatch) {
    dispatch(deleteLocationStarted());
    ObservationAPIController.deleteLocation(inspectionId, locationUUID)
      .then((response) => {
        dispatch(deleteLocationSuccess(locationUUID));
        return response;
      })
      .catch((error) => {
        if (error.code === 401) {
          dispatch(deleteLocationError(error.code));
        }
      });
  };
};

const updateInspectionSummary = (inspectionId, summary) => {
  return function (dispatch) {
    dispatch(updateInspectionSummaryStarted());
    InspectionAPIController.updateInspectionSummary(inspectionId, summary)
      .then((response) => {
        dispatch(updateInspectionSummarySuccess(response));
        return response;
      })
      .catch((error) => {
        if (error.code === 401) {
          dispatch(updateInspectionSummaryError(error.code));
        }
      });
  };
};

const updateLocationInspected = (
  inspectionId,
  componentSubcomponentId,
  inspected,
) => {
  return function (dispatch) {
    dispatch(updateLocationInspectedStarted());
    ObservationAPIController.updateLocationInspected(
      inspectionId,
      componentSubcomponentId,
      inspected,
    )
      .then((response) => {
        dispatch(updateLocationInspectedSuccess(response));
        return response;
      })
      .catch((error) => {
        if (error.code === 401) {
          dispatch(updateLocationInspectedError(error.code));
        }
      });
  };
};

export {
  downloadInspectionReport,
  downloadInspectionPhotosZip,
  getInspectionData,
  updateInspectionSummary,
  updateObservation,
  refreshObservationsData,
  deleteObservation,
  updateImageRectangle,
  deleteImage,
  uploadImage,
  addLocation,
  updateLocation,
  deleteLocation,
  updateLocationInspected,
};
