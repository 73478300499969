import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import GeneralCard from "components/GeneralCard";
import { Colors } from "config/StyleConfig";
import { Strings } from "config/Strings";

export default class DefectsVsNSMI extends Component {
  toPercentage(value, total) {
    return Math.round(((value / total) * 100 + Number.EPSILON) * 100) / 100;
  }

  render() {
    const title = Strings.getInstance().getString("inspections_defects_vs_nsmi");
    const defects_label = Strings.getInstance().getString("inspections_defects_vs_nsmi_defects");
    const nsmi_label = Strings.getInstance().getString("inspections_defects_vs_nsmi_nsmi");

    const data = {
      labels: [defects_label + " (" + this.props.defects + ")", nsmi_label + " (" + this.props.nsmi + ")"],
      datasets: [
        {
          data: [
            this.toPercentage(this.props.defects, this.props.nsmi + this.props.defects),
            this.toPercentage(this.props.nsmi, this.props.nsmi + this.props.defects),
          ],
          backgroundColor: [Colors.defects, Colors.nsmi],
        },
      ],
    };

    const content = <Pie data={data} height={200} legend={{ position: "right" }} />;

    return <GeneralCard title={title} content={content} />;
  }
}
