import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

import GeneralCard from "components/GeneralCard";
import { Strings } from "config/Strings";

class NumberOfObservationsCard extends Component {
  render() {
    const title = Strings.getInstance().getString("inspection_observations");
    const subtitle = this.props.subtitle;

    const content = (
      <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}
    >
        <Typography align="center" variant="h4">
          {this.props.observations}
        </Typography>
        <Typography align="center" >({this.props.imageCount} images)</Typography>
      </div>
    );

    return <GeneralCard title={title} subtitle={subtitle} content={content} icon={<VisibilityOutlinedIcon />} />;
  }
}

export default NumberOfObservationsCard;
