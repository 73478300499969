import { useQuery } from '@tanstack/react-query';
import Config from 'config/Config';
import { fetcher } from 'services/fetcher';
import { deserializeDictionary } from './deserializer';

export async function getCompleteDictionary() {
  return fetcher(Config.GET_COMPLETE_DICTIONARY).then(deserializeDictionary);
}

export function useDictionary() {
  return useQuery(['dictionary'], getCompleteDictionary, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}
