import React, { Component } from "react";
import { connect } from "react-redux";
import Helper from "helper/Helper";
import SelectableComponentTreeItem from "./SelectableComponentTreeItem";
import { Colors } from "config/StyleConfig";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SelectableSubComponentTreeItem from "./SelectableSubComponentTreeItem";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { mapPreferences, mapQuickSearchSubComponents, mapBackToAPI } from "./SubComponentPickerMapper";
import { getComponentsAndSubComponents, setSubComponentPickerVisibility, saveNewTemplate } from "./SubComponentPickerAction";
import LoadingScreen from "components/LoadingScreen";

import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  dialogPaper: {
    minHeight: "80vh",
    maxHeight: "80vh",
  },
});

class SubComponentPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modifiedSubComponents: {},
    };
  }

  componentDidMount() {
    this.props.getComponentsAndSubComponents(this.props.structureId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.structureId !== this.props.structureId) {
      this.props.getComponentsAndSubComponents(this.props.structureId);
    }

    if (!Helper.areObjectsEqual(prevProps.quickSearchSubComponentsOfStructure, this.props.quickSearchSubComponentsOfStructure)) {
      this.setState({ modifiedSubComponents: this.props.quickSearchSubComponentsOfStructure });
    }
  }

  onComponentSelected = (id, selected) => {
    let modifiedSubComponents = this.state.modifiedSubComponents;
    let subComponentsIds = [];
    let selectedCount = 0;

    for (let i = 0; i < this.props.components.length; i++) {
      const comp = this.props.components[i];
      if (comp["id"] === id) {
        for (let j = 0; j < comp["subcomponents"].length; j++) {
          const subcomponent = comp["subcomponents"][j];
          subComponentsIds.push(subcomponent["id"]);
          if (modifiedSubComponents.hasOwnProperty(subcomponent["id"])) {
            selectedCount++;
          }
        }
      }
    }

    for (let i = 0; i < subComponentsIds.length; i++) {
      const subComponentId = subComponentsIds[i];
      if (selectedCount === subComponentsIds.length) {
        delete modifiedSubComponents[subComponentId];
      } else {
        if (!modifiedSubComponents.hasOwnProperty(subComponentId)) {
          modifiedSubComponents[subComponentId] = {};
        }
      }
    }

    this.setState({ modifiedSubComponents: modifiedSubComponents });
  };

  onSubComponentSelected = (id, selected) => {
    if (!selected) {
      let modifiedSubComponents = this.state.modifiedSubComponents;
      delete modifiedSubComponents[id];
      this.setState({ modifiedSubComponents: modifiedSubComponents });
    } else {
      let modifiedSubComponents = this.state.modifiedSubComponents;
      modifiedSubComponents[id] = {};
      this.setState({ modifiedSubComponents: modifiedSubComponents });
    }
  };

  onDimensionChanged = (id, size) => {
    let modifiedSubComponents = this.state.modifiedSubComponents;
    modifiedSubComponents[id] = { size: size };
    this.setState({ modifiedSubComponents: modifiedSubComponents });
  };

  onSaveClicked = () => {
    const newTemplate = mapBackToAPI(this.props.structureId, this.state.modifiedSubComponents, this.props.quickSearchSubComponentsOfStructure);
    this.props.saveNewTemplate(newTemplate);
  };

  onSelectAll = () => {
    let modifiedSubComponents = this.state.modifiedSubComponents;
    for (let i = 0; i < this.props.components.length; i++) {
      const comp = this.props.components[i];
      for (let j = 0; j < comp["subcomponents"].length; j++) {
        const subcomponent = comp["subcomponents"][j];
        if (!modifiedSubComponents.hasOwnProperty(subcomponent["id"])) {
          modifiedSubComponents[subcomponent["id"]] = {};
        }
      }
    }

    this.setState({ modifiedSubComponents: modifiedSubComponents });
  };

  onDeselectAll = () => {
    let modifiedSubComponents = this.state.modifiedSubComponents;
    for (let i = 0; i < this.props.components.length; i++) {
      const comp = this.props.components[i];
      for (let j = 0; j < comp["subcomponents"].length; j++) {
        const subcomponent = comp["subcomponents"][j];
        delete modifiedSubComponents[subcomponent["id"]];
      }
    }

    this.setState({ modifiedSubComponents: modifiedSubComponents });
  };

  createComponent(id, name, subComponents, selected, subSelected) {
    return (
      <SelectableComponentTreeItem id={id} name={name} subSelected={subSelected} selected={selected} onSelected={this.onComponentSelected}>
        {subComponents}
      </SelectableComponentTreeItem>
    );
  }

  createSubComponent(id, name, dimension, selected) {
    return (
      <SelectableSubComponentTreeItem
        id={id}
        name={name}
        dimension={dimension}
        selected={selected}
        onSelected={this.onSubComponentSelected}
        onDimensionChanged={this.onDimensionChanged}
      />
    );
  }

  generateTree() {
    const componentTreeItems = [];

    for (let i = 0; i < this.props.components.length; i++) {
      const subComponentTreeItems = [];
      const component = this.props.components[i];
      let componentSelected = false;
      let subSelected = 0;
      const subComponents = component["subcomponents"] || [];
      for (let j = 0; j < subComponents.length; j++) {
        const subComponent = subComponents[j];
        let selected = false;
        let size = 0;
        if (this.state.modifiedSubComponents.hasOwnProperty(subComponent["id"])) {
          selected = true;
          subSelected++;
          componentSelected = true;
          size = this.state.modifiedSubComponents[subComponent["id"]]["size"];
        }
        subComponentTreeItems.push(this.createSubComponent(subComponent["id"], subComponent["name"], size, selected));
      }
      componentTreeItems.push(
        this.createComponent(
          component["id"],
          component["name"],
          subComponentTreeItems,
          componentSelected,
          "(" + subSelected + "/" + subComponents.length + ")"
        )
      );
    }

    return componentTreeItems;
  }

  render() {
    const components = this.generateTree();

    const { classes } = this.props;

    if (!this.props.visible) {
      return <div></div>;
    }

    return (
      <Dialog fullWidth dividers maxWidth={"lg"} open={true} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle id="alert-dialog-title">
          {this.props.structureName}
          {"'s components & sub-components"}
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography color="textSecondary">{"Select all components & sub-components relevant to this structure"}</Typography>
            <div>
              <Button onClick={this.onSelectAll} color="primary">
                Select All
              </Button>
              <Button onClick={this.onDeselectAll} color="primary">
                Deselect All
              </Button>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {!this.props.loading && (
            <TreeView className={classes.styles2} defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
              {components}
            </TreeView>
          )}

          {this.props.loading && <LoadingScreen />}
        </DialogContent>
        <DialogActions>
          <div>
            <Typography color={"error"}>{this.props.error}</Typography>
          </div>
          <Button onClick={() => this.props.dismissSubComponentPicker()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => this.onSaveClicked()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    components: mapPreferences(state.SubComponentPickerReducer.components, ownProps.structureType),
    subComponentsOfStructure: state.SubComponentPickerReducer.subComponentsOfStructure,
    quickSearchSubComponentsOfStructure: mapQuickSearchSubComponents(state),
    loading: state.SubComponentPickerReducer.loading,
    error: state.SubComponentPickerReducer.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getComponentsAndSubComponents: (structureId) => dispatch(getComponentsAndSubComponents(structureId)),
    dismissSubComponentPicker: () => dispatch(setSubComponentPickerVisibility(false)),
    saveNewTemplate: (template) => dispatch(saveNewTemplate(template)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SubComponentPicker));
