import React, { Component } from "react";
import Table from "components/Table";
import { connect } from "react-redux";
import { watchClientsTableData, addClient, updateClient, deleteClient } from "./ClientsAction";
import { mapMultipleRows } from "./ClientsMapper";
import { showInfoDialog, showConfirmationDialog } from "components/Dialog/DialogAction";
import ValidatorArray from "helper/Validator/ValidatorArray";
import ValidatorFieldBuilder, { TYPE } from "helper/Validator/ValidatorFieldBuilder";
import Navigator from "Navigator";
import ColumnBuilder from "helper/ColumnBuilder";
import { Strings } from "../../config/Strings";

export const FIELD_ID = "id";
export const FIELD_NAME = "name";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  validateFields = (name) => {
    var validator = new ValidatorArray();
    validator.push(new ValidatorFieldBuilder(name, TYPE.TEXT).canBeEmpty(false).build());
    return validator;
  };

  getColumns() {
    return [new ColumnBuilder(Strings.getInstance().getString("clients_table_client_name"), FIELD_NAME).build()];
  }

  componentDidMount() {
    this.props.watchClientsTableData();
  }

  actions = [
    {
      icon: "refresh",
      tooltip: "Refresh Data",
      isFreeAction: "true",
      onClick: () => {
        this.props.watchClientsTableData();
      },
    },
    {
      icon: "delete_outlined",
      tooltip: "Remove",
      onClick: (event, rowData) => {
        const pendingAction = deleteClient(rowData["id"]);
        this.props.confirmDeleteClient(pendingAction);
      },
    },
  ];

  editable = {
    onRowAdd: (newData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          let validator = this.validateFields(newData[FIELD_NAME]);

          if (validator.validate()) {
            resolve();
            this.props.addClient(newData[FIELD_NAME]);
          } else {
            this.props.showError("Invalid Input", validator.generateColumnErrorDescription(this.getColumns()));
            reject();
          }
        }, 100);
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          let validator = this.validateFields(newData[FIELD_NAME]);
          if (validator.validate()) {
            resolve();
            this.props.updateClient(oldData[FIELD_ID], newData[FIELD_NAME]);
          } else {
            this.props.showError("Invalid Input", validator.generateColumnErrorDescription(this.getColumns()));
            reject();
          }
        }, 100);
      }),
  };

  onRowClick = (event, rowData) => {
    Navigator.navigateToClient(rowData.id);
  };

  render() {
    return (
      <Table
        id="CLIENTS_TABLE"
        title={Strings.getInstance().getString("clients_table_title")}
        columns={this.getColumns()}
        options={{ actionsColumnIndex: -1 }}
        data={this.props.data}
        editable={this.editable}
        actions={this.actions}
        isLoading={this.props.loading}
        onRowClick={this.onRowClick}
        disableHideColumnOption
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: mapMultipleRows(state),
    loading: state.ClientsReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    watchClientsTableData: () => dispatch(watchClientsTableData()),
    addClient: (clientName) => dispatch(addClient(clientName)),
    updateClient: (clientId, clientName) => dispatch(updateClient(clientId, clientName)),
    showError: (title, description) => dispatch(showInfoDialog(title, description)),
    confirmDeleteClient: (pendingAction) =>
      dispatch(showConfirmationDialog(Strings.getInstance().getString("dialog_delete_client_title"), Strings.getInstance().getString("dialog_delete_client_description"), pendingAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);