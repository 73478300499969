import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Colors } from "config/StyleConfig";

const styles = () => ({
  title: { color: Colors.text },
  subtitle: { color: Colors.subtitleText },
});

class GeneralCard extends Component {
  render() {
    const { classes } = this.props;
    const centerContentStyle = this.props.centerContent
      ? { display: "flex", flexDirection: "row", alignItems: "middle", justifyContent: "center" }
      : {};

    return (
      <Card style={{ height: this.props.height || "100%", boxShadow: "0 0 35px 0 rgba(154,161,171,.15)" }}>
        <CardContent>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "middle",
              marginBottom: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                float: "left",
              }}
            >
              <Typography style={{ color: Colors.text }} className={classes.title} variant="h6">
                {this.props.title}
              </Typography>
              <Typography style={{ color: Colors.subtitleText }} className={classes.subtitle} variant="subtitle2">
                {this.props.subtitle}
              </Typography>
            </div>
            <div style={{ float: "right", color: Colors.icons }}>{this.props.icon}</div>
          </div>
          <div style={centerContentStyle || {}}>{this.props.content}</div>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(GeneralCard);
