import Helper from "helper/Helper"

export const FETCH_PROJECTS_STARTED = "FETCH_PROJECTS_STARTED";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";

export const ADD_PROJECT_STARTED = "ADD_PROJECT_STARTED"
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS"
export const ADD_PROJECT_FAILED = "ADD_PROJECT_FAILED"

export const UPDATE_PROJECT_STARTED = "UPDATE_PROJECT_STARTED"
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS"
export const UPDATE_PROJECT_FAILED = "UPDATE_PROJECT_FAILED"

export const DELETE_PROJECT_STARTED = "DELETE_PROJECT_STARTED"
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS"
export const DELETE_PROJECT_FAILED = "DELETE_PROJECT_FAILED"

const initialState = {
    data: [],
    loading: true
}

  const ProjectsReducer = (state = initialState, action) => {
    var newData = null
    switch(action.type) {
      case FETCH_PROJECTS_STARTED:
        return { ...state, loading: true};
      case FETCH_PROJECTS_SUCCESS:
        return { ...state, data: action.data, loading: false };

      case ADD_PROJECT_STARTED:
        return { ...state, loading: true};
      case ADD_PROJECT_SUCCESS:
        newData = Helper.insertItemImmutable(state.data, action.project)
        return { ...state, data: newData, loading: false};
      case ADD_PROJECT_FAILED:
          return { ...state, loading: false};
         
      case UPDATE_PROJECT_STARTED:
        return { ...state, loading: true};
      case UPDATE_PROJECT_SUCCESS:
        newData = Helper.updateItemImmutable(state.data, action.project)
        return { ...state, data: newData, loading: false};
      case UPDATE_PROJECT_FAILED:
          return { ...state, loading: false};

      case DELETE_PROJECT_STARTED:
        return { ...state, loading: true};
      case DELETE_PROJECT_SUCCESS:
        newData = Helper.removeItemImmutable(state.data, action.projectId)
        return { ...state, data: newData, loading: false};
      case DELETE_PROJECT_FAILED:
          return { ...state, loading: false};
      default:
        return state;
    }
  }



  export { ProjectsReducer }