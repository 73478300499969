import React, { Component } from "react";
import GeneralCard from "components/GeneralCard";
import { Colors } from "config/StyleConfig";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import { Strings } from "config/Strings";

const styles = () => ({
  good: {
    color: Colors.good,
  },
  average: {
    color: Colors.average,
  },
  bad: {
    color: Colors.bad,
  },
});

class TermCard extends Component {
  render() {
    const { classes } = this.props;

    const title = Strings.getInstance().getString("inspection_term");
    const subtitle = this.props.subtitle;
    var term_class = undefined;

    if (this.props.term > 3.9) {
      term_class = classes.good;
    } else if (this.props.term > 2.1) {
      term_class = classes.average;
    } else {
      term_class = classes.bad;
    }

    let termString = this.props.term;

    if (!isNaN(this.props.term)) {
      termString = this.props.term.toFixed(1);
    }

    const content = (
      <Typography align="center" className={term_class} variant="h4">
        {termString}
      </Typography>
    );

    return <GeneralCard title={title} subtitle={subtitle} content={content} icon={<AssignmentTurnedInOutlinedIcon />} />;
  }
}

export default withStyles(styles)(TermCard);
