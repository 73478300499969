import { useMutation } from '@tanstack/react-query';
import Config from 'config/Config';
import Helper from 'helper/Helper';
import { v4 as uuid } from 'uuid';
import { fetcher, FetcherMutatergs } from 'services/fetcher';
import { InspectionStatus } from './types';

type CloneInspectionPayload = {
  existingInspectionUuid: string;
  // inspectors?: [
  //   {
  //     email: string;
  //     firstName: string;
  //     id: number;
  //     lastName: string;
  //   },
  // ];
  projectId: number;
  startDate: string;
};

function cloneInspection(payload: CloneInspectionPayload) {
  return fetcher(Config.CLONE_INSPECTION_API, {
    method: 'post',
    body: JSON.stringify({ ...payload, uuid: uuid(), inspectors: [] }),
  });
}

export function useCloneInspection() {
  return useMutation(cloneInspection);
}

type UploadOpORDPayload = FetcherMutatergs<{ uuid: string; file: File }>;

function uploadOpORD(args: UploadOpORDPayload) {
  const formData = new FormData();
  formData.append('data', args.payload.file);
  return fetcher(Helper.parse(Config.UPLOAD_OPORD, args.payload.uuid), {
    method: 'post',
    body: formData,
  });
}

export function useUploadOpORD() {
  return useMutation(uploadOpORD);
}

type CreateInspectionArgs = FetcherMutatergs<{
  spans: number;
  structureId: string;
  projectId: number;
  inspectorId: number[];
  startDate: Date;
  file: File;
}>;

type AttachInspectorArgs = FetcherMutatergs<{
  inspectorId: number[];
  inspectionId: string;
}>;

function attachInspector({ payload }: AttachInspectorArgs) {
  return fetcher(Config.ASSIGN_INSPECTOR_TO_INSPECTION_API, {
    method: 'post',
    body: JSON.stringify({
      inspectionId: payload.inspectionId,
      inspectors: payload.inspectorId,
    }),
  });
}

async function createInspection({ payload }: CreateInspectionArgs) {
  const newInspection = await fetcher(Config.POST_INSPECTION_API, {
    method: 'post',
    body: JSON.stringify({
      spansCount: payload.spans,
      structureId: payload.structureId,
      startDate: new Date().toISOString(),
      uuid: uuid(),
      projectId: payload.projectId,
      status: 'IN_PROGRESS',
    }),
  });

  // TODO: Error Handling if one of these requests fail
  return Promise.all([
    attachInspector({
      payload: {
        inspectionId: newInspection.uuid,
        inspectorId: payload.inspectorId,
      },
    }),
    uploadOpORD({
      payload: { file: payload.file, uuid: newInspection.uuid },
    }),
  ]);
}

export function useCreateInspection() {
  return useMutation(createInspection);
}

type EditInspectionArgs = FetcherMutatergs<
  {
    spans: number;
    structureId: string;
    projectId: number;
    inspectorId: number[];
    startDate: Date;
    endDate?: Date;
    file?: File;
    inspectionId: string;
    status: InspectionStatus;
    termRating?: string;
    summary?: string;
  } & Record<string, any>
>;

/**
 * TODO:
 * PATCH request for inspection have cors issue
 * so still use POST
 */
async function editInspection({ payload }: EditInspectionArgs) {
  return Promise.all([
    fetcher(`${Config.POST_INSPECTION_API}`, {
      method: 'post',
      body: JSON.stringify({
        ...payload,
        spansCount: payload.spans,
        structureId: payload.structureId,
        startDate: payload.startDate.toISOString(),
        uuid: payload.inspectionId,
        projectId: payload.projectId,
        status: payload.status,
        endDate:
          payload.status === 'COMPLETED'
            ? Helper.convertToDBDateFormat(payload.endDate) ||
              Helper.getCurrentDateTime()
            : Helper.convertToDBDateFormat(payload.endDate),
        termRating: payload.termRating ?? '',
        generalSummary: payload.summary ?? '',
      }),
    }),
    attachInspector({
      payload: {
        inspectionId: payload.inspectionId,
        inspectorId: payload.inspectorId,
      },
    }),
    payload.file
      ? uploadOpORD({
          payload: { file: payload.file, uuid: payload.inspectionId },
        })
      : undefined,
  ]);
}

export function useEditInspection() {
  return useMutation(editInspection);
}

type CreateSubdivisionPayload = {
  uuid: string;
  inspectionId: string;
  name: string;
  number: string;
  sgrRating: string;
  subComponents: { id: string; measurement: number }[];
  //This property is used to be able to skip creating a new subdivision and
  // only allocate components
  createdUUID?: string;
};
/**
 * TODO:
 * Add rollback(delete create subdivision) on 'ALLOCATION_ERROR'
 */
const createSubdivision = async ({
  payload,
}: FetcherMutatergs<CreateSubdivisionPayload>) => {
  const { subComponents, ...newSubdivisionPayload } = payload;
  if (!payload.createdUUID) {
    await fetcher(
      `api/v2/inspection/${payload.inspectionId}/structureSubdivision`,
      {
        method: 'post',
        body: JSON.stringify({
          ...newSubdivisionPayload,
        }),
      },
    );
  }
  return Promise.all([
    ...subComponents.map((s) =>
      fetcher(
        `api/v2/inspection/${payload.inspectionId}/structureSubdivision/${payload.uuid}`,
        {
          method: 'post',
          body: JSON.stringify({
            uuid: uuid(),
            structureSubdivisionId: payload.uuid,
            observationId: s.id,
            dimensionNumber: s.measurement,
          }),
        },
      ).catch((error) => {
        // FIXME: improve implementation
        throw Error(
          JSON.stringify({
            id: payload.uuid,
            type: 'ALLOCATION_ERROR',
            message: error?.message,
          }),
        );
      }),
    ),
  ]);
};

export const useNewSubdivision = () => useMutation(createSubdivision);

const SubdivisionAllocationType = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  UPDATE: 'UPDATE',
} as const;

type UpdateSubdivisionPayload = Omit<
  CreateSubdivisionPayload,
  'subComponents'
> & {
  subComponents: {
    uuid?: string;
    id: string;
    measurement: number;
    type: keyof typeof SubdivisionAllocationType;
    subdivisionId: string;
  }[];
};

const updateSubdivision = async ({
  payload,
}: FetcherMutatergs<UpdateSubdivisionPayload>) => {
  const { subComponents, ...newSubdivisionPayload } = payload;
  if (!payload.createdUUID) {
    await fetcher(
      `api/v2/inspection/${payload.inspectionId}/structureSubdivision`,
      {
        method: 'post',
        body: JSON.stringify({
          ...newSubdivisionPayload,
        }),
      },
    );
  }
  return Promise.all([
    ...subComponents.map((subcomponent) =>
      fetcher(
        subcomponent.type === SubdivisionAllocationType.ADD
          ? `api/v2/inspection/${payload.inspectionId}/structureSubdivision/${subcomponent.subdivisionId}`
          : `api/v2/observationStructureSubdivision/${subcomponent.uuid}`,
        {
          method:
            subcomponent.type === SubdivisionAllocationType.ADD
              ? 'post'
              : 'delete',
          body:
            subcomponent.type === SubdivisionAllocationType.ADD
              ? JSON.stringify({
                  uuid: subcomponent.uuid ? subcomponent.uuid : uuid(),
                  structureSubdivisionId: payload.uuid,
                  observationId: subcomponent.id,
                  dimensionNumber: subcomponent.measurement,
                })
              : undefined,
        },
      ),
    ),
  ]);
};

export const useUpdateSubdivision = () => useMutation(updateSubdivision);
