import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import { dismissDialog, confirmPendingAction } from "./DialogAction";
import { Colors } from "config/StyleConfig";
import { withStyles } from "@material-ui/core/styles";

export const DIALOG_BOX_TYPE = {
  DISMISSIBLE_DIALOG: 0,
  ACCEPT_CANCEL_DIALOG: 1,
};

const styles = {
  button: {
    color: Colors.primary,
  },
};

class DialogBox extends Component {
  static optimizationKey = 0;

  constructor(props) {
    super(props);

    this.state = {
      open: this.props.open,
      title: this.props.title,
      description: this.props.description,
      dialogType: this.props.dialogType,
      pendingAction: this.props.pendingAction,
    };
  }

  render() {
    const { classes } = this.props;

    const actions = [];
    if (this.props.dialogType === DIALOG_BOX_TYPE.ACCEPT_CANCEL_DIALOG) {
      actions.push(
        <Button key={DialogBox.optimizationKey++} onClick={() => this.props.confirmPendingAction()} className={classes.button}>
          Confirm
        </Button>
      );
      actions.push(
        <Button key={DialogBox.optimizationKey++} onClick={() => this.props.dismissDialog()} className={classes.button}>
          Cancel
        </Button>
      );
    } else {
      actions.push(
        <Button key={DialogBox.optimizationKey++} onClick={() => this.props.dismissDialog()} className={classes.button}>
          Dismiss
        </Button>
      );
    }

    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.dismissDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: 9001 }}
      >
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{this.props.description}</DialogContentText>
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    open: state.DialogReducer.open,
    title: state.DialogReducer.title,
    description: state.DialogReducer.description,
    dialogType: state.DialogReducer.dialogType,
    pendingAction: state.DialogReducer.pendingAction,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dismissDialog: () => dispatch(dismissDialog()),
    confirmPendingAction: () => dispatch(confirmPendingAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DialogBox));
