import Config from 'config/Config';
import Helper from 'helper/Helper';
import { APIException } from 'Exceptions';
import SecureAPIBuilder from './SecureAPIBuilder';
import { v4 as uuid } from 'uuid';

export default class ObservationAPIController {
  // Get calls.
  static getObservationImages(urls) {
    return Promise.all(
      urls.map((url) =>
        new SecureAPIBuilder(Config.PROXY + url, SecureAPIBuilder.GET)
          .call()
          .then((fileResponse) => fileResponse.blob()),
      ),
    );
  }

  static getDefects(clientId, inspectionId, structureId) {
    var api = undefined;
    if (clientId !== undefined) {
      api = new SecureAPIBuilder(
        Helper.parse(Config.GET_DEFECTS_CLIENT_API, clientId),
        SecureAPIBuilder.GET,
      );
    } else {
      api = new SecureAPIBuilder(
        Helper.parse(Config.GET_DEFECTS_STRUCTURE_API, structureId),
        SecureAPIBuilder.GET,
      );
    }
    if (inspectionId !== undefined) {
      api.query({ inspectionId: inspectionId });
    }

    return api.call().then((response) => {
      if (!response.ok) {
        console.log(response);
        throw new APIException(response.status, response.statusText);
      }
      return response.json();
    });
  }

  // Insert calls.
  static uploadImage(
    inspectionId,
    componentSubcomponentId,
    observationId,
    data,
  ) {
    const formData = new FormData();
    /**
     * appended new image twice to satisfy the endpoints
     * validation. The endpoint expects the original image + annotated image.
     */
    formData.append('data', data);
    formData.append('data', data);

    // Getting an error: Current request is not a multipart upload.
    const url = Helper.parse(
      Config.UPLOAD_IMAGE_API,
      inspectionId,
      componentSubcomponentId,
      observationId,
    );
    const query = {
      name: data.name,
      uuid: uuid(),
      createdAt: Helper.getCurrentDateTime(),
      drawables: null,
    };

    return new SecureAPIBuilder(url, SecureAPIBuilder.POST)
      .file(formData)
      .query(query)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static addLocation(inspectionId, location) {
    const locationId = uuid();

    location['uuid'] = locationId;
    location['id'] = locationId;

    const body = JSON.stringify(location);
    const url = Helper.parse(Config.UPDATE_LOCATION_API, inspectionId);

    return new SecureAPIBuilder(url, SecureAPIBuilder.POST)
      .body(body)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  // Delete calls.
  static deleteObservation(
    inspectionId,
    componentSubComponentId,
    observationId,
  ) {
    const url = Helper.parse(
      Config.DELETE_OBSERVATION_API,
      inspectionId,
      componentSubComponentId,
      observationId,
    );

    return new SecureAPIBuilder(url, SecureAPIBuilder.DELETE)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static deleteImage(
    inspectionId,
    componentSubComponentId,
    observationId,
    photoId,
  ) {
    const url = Helper.parse(
      Config.DELETE_IMAGE_API,
      inspectionId,
      componentSubComponentId,
      observationId,
      photoId,
    );

    return new SecureAPIBuilder(url, SecureAPIBuilder.DELETE)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static deleteLocation(inspectionId, locationUUID) {
    const url = Helper.parse(
      Config.DELETE_LOCATION_API,
      inspectionId,
      locationUUID,
    );
    return new SecureAPIBuilder(url, SecureAPIBuilder.DELETE)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  // Update calls.
  static updateObservation(inspectionId, componentSubComponentId, observation) {
    const body = JSON.stringify(observation);
    const url = Helper.parse(
      Config.UPDATE_OBSERVATION_API,
      inspectionId,
      componentSubComponentId,
    );

    return new SecureAPIBuilder(url, SecureAPIBuilder.POST)
      .body(body)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static updateLocationInspected(
    inspectionId,
    componentSubComponentId,
    inspected,
  ) {
    const body = JSON.stringify({
      uuid: componentSubComponentId,
      inspected: inspected,
    });
    const url = Helper.parse(
      Config.UPDATE_OBSERVATION_INSPECTED_API,
      inspectionId,
    );

    return new SecureAPIBuilder(url, SecureAPIBuilder.POST)
      .body(body)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static updateObservationCompSub(compSubId, defectId) {
    const url = Helper.parse(
      Config.UPDATE_COMP_SUB_OF_OBSERVATION_API,
      defectId,
      compSubId,
    );
    return new SecureAPIBuilder(url, SecureAPIBuilder.PUT)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static updateLocation(inspectionId, location) {
    location['useHealthIndex'] = true;
    const body = JSON.stringify(location);
    const url = Helper.parse(Config.UPDATE_LOCATION_API, inspectionId);
    return new SecureAPIBuilder(url, SecureAPIBuilder.POST)
      .body(body)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static updateImageRectangle(
    inspectionId,
    componentSubComponentId,
    observationId,
    photoId,
    drawables,
  ) {
    const body = JSON.stringify({ drawables: drawables });
    const url = Helper.parse(
      Config.UPDATE_IMAGE_RECTANGLE,
      inspectionId,
      componentSubComponentId,
      observationId,
      photoId,
    );

    return new SecureAPIBuilder(url, SecureAPIBuilder.PUT)
      .body(body)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }
}
