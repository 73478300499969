import React, { Component } from "react";
import Structures from "features/Structures";
import Users from "features/Users";
import ComponentsHI from "features/ComponentsHI";
import Helper from "helper/Helper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TabPanel, a11yProps } from "components/TabPanel";
import { withStyles } from "@material-ui/core/styles";
import { Colors } from "config/StyleConfig";
import { getClientData } from "./ClientAction";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Defects from "features/Defects";
import ValidatorFieldBuilder, { TYPE } from "helper/Validator/ValidatorFieldBuilder";
import TokenManager from "storage/TokenManager";
import { Strings } from "../../config/Strings";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  indicator: {
    backgroundColor: Colors.primary,
  },
  tabRoot: {
    maxWidth: "300px",
  },
  title: {
    marginBottom: "20px",
  },
});

class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      clientId: this.extractClientID(),
      clientName: "",
    };
  }

  viewOnly = this.props.viewOnly;

  componentDidMount() {
    this.props.getClientData(this.state.clientId);
  }

  extractClientID() {
    const parsedURL = Helper.parseURLLocation(this.props.location.pathname + "");

    var clientId = parsedURL[parsedURL.length - 1];
    if (new ValidatorFieldBuilder(clientId, TYPE.NUMBER).build().validate() === "") {
      return clientId;
    }

    // TODO: Move TokenManager outside of this component.
    return TokenManager.getCompanyId();
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    const { classes } = this.props;

    const title =
      this.props.clientName === "" || this.props.clientName === undefined ? (
        <div />
      ) : (
        <div>
          <Typography variant="h5" style={{ marginBottom: "20px" }}>
            {this.props.clientName}
          </Typography>
          <Divider style={{ marginBottom: "20px" }} />
        </div>
      );

    return (
      <div className={classes.root}>
        {title}
        <Tabs classes={{ indicator: classes.indicator }} value={this.state.value} onChange={this.handleChange} aria-label="simple tabs example">
          <Tab label={Strings.getInstance().getString("client_tab_label_structures")} {...a11yProps(0)} />
          <Tab label={Strings.getInstance().getString("client_tab_label_com_sub")} {...a11yProps(1)} classes={{ root: classes.tabRoot }} />
          <Tab label={Strings.getInstance().getString("client_tab_label_observations")} {...a11yProps(2)} wrapped={false} />
          <Tab label={Strings.getInstance().getString("client_tab_label_users")} {...a11yProps(2)} wrapped={false} />
        </Tabs>
        <TabPanel value={this.state.value} index={0}>
          <Structures companyId={this.state.clientId} viewOnly={this.viewOnly} />
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
          <ComponentsHI companyId={this.state.clientId} />
        </TabPanel>
        <TabPanel value={this.state.value} index={2}>
          <Defects companyId={this.state.clientId} />
        </TabPanel>
        <TabPanel value={this.state.value} index={3}>
          <Users companyId={this.state.clientId} viewOnly={this.viewOnly} />
        </TabPanel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientName: state.ClientReducer.data === undefined ? "" : state.ClientReducer.data.name,
    loading: state.ClientReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClientData: (clientId) => dispatch(getClientData(clientId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Client));
