import { Inspection, ObservationDefect } from './types';

export function deserializeInspection(data: Record<string, unknown>) {
  return {
    ...data,
    startDate: new Date(data.startDate as string),
    endDate: data.endDate ? new Date(data.endDate as string) : undefined,
  } as Inspection;
}

export function deserializeObservationDefect(data: unknown) {
  return data as ObservationDefect;
}
