import React, { Component } from "react";

import LabeledText from "components/LabeledText";
import Grid from "@material-ui/core/Grid";
import GeneralCard from "components/GeneralCard";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Strings } from "config/Strings";
import Helper from "helper/Helper";

class InspectionInfoCard extends Component {
  render() {
    const title = Strings.getInstance().getString("inspection_info_title");
    var inspectorsText = "";
    this.props.inspectors.forEach((inspector) => {
      inspectorsText += inspector + "\n";
    });

    const content = (
      <div>
        <Grid container direction="row" justify={"space-between"}>
          <Grid item>
            <Grid container direction="column">
              <LabeledText
                style={{ marginBottom: 20 }}
                label={Strings.getInstance().getString("inspection_info_start_date")}
                value={Helper.extractDate(this.props.startDate)}
              />
              <LabeledText label={Strings.getInstance().getString("inspection_info_end_date")} value={Helper.extractDate(this.props.endDate)} />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <LabeledText
                style={{ marginBottom: 20 }}
                label={Strings.getInstance().getString("inspection_info_project")}
                value={this.props.project}
              />
              <LabeledText label={Strings.getInstance().getString("inspection_info_status")} value={this.props.status} />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <LabeledText multiline rowsMax={5} label={Strings.getInstance().getString("inspection_info_inspectors")} value={inspectorsText} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );

    return <GeneralCard title={title} content={content} icon={<InfoOutlinedIcon />} />;
  }
}

export default InspectionInfoCard;
