import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";

const DEFAULT_MAX_CHARACTER_LIMIT = 50;

class MultilineTextTableField extends Component {
  render() {
    let value = this.props.value || "";
    if (value.length === 0) {
      return <div />;
    }

    const maxCharacterLimit = this.props.maxCharacterLimit || DEFAULT_MAX_CHARACTER_LIMIT;

    if (value.length < maxCharacterLimit) {
      return <div>{value}</div>;
    }

    const displayedText = value.substring(0, maxCharacterLimit) + "...";
    return (
      <Tooltip title={<div style={{ fontSize: 13, lineHeight: 2 }}>{value}</div>}>
        <div>{displayedText}</div>
      </Tooltip>
    );
  }
}

export default MultilineTextTableField;
