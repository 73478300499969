import Config from "config/Config";

export default class AuthenticationAPIController {
  static logout() {
    // TODO: Invalidate Token from Backend?
    return Promise.resolve();
  }

  static login(email, password) {
    return fetch(Config.AUTHENTICATION_API, {
      crossDomain: true,
      method: "POST",
      body: JSON.stringify({
        email: email,
        password: password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
  }
}
