import { Description, Error as ErrorIcon } from '@material-ui/icons';
import { useQuery } from '@tanstack/react-query';
import { KIND as ButtonKind } from 'baseui/button';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal';
import { Spinner } from 'baseui/spinner';
import { Button } from 'components/Button';
import Text from 'components/Text';
import { APIException } from 'Exceptions';
import 'github-markdown-css/github-markdown-light.css';
import mammoth from 'mammoth';
import React, { useState } from 'react';
import { getOpOrdDoc } from 'services/api/inspection/queries';
import { fetcherBlob } from 'services/fetcher';
import { useStitches } from 'theme';
import { isAPIError } from 'utils/error';

const useOpordFile = (inspectionId: string) =>
  useQuery(
    ['opord', inspectionId, 'file'],
    async () => {
      const { link } = await getOpOrdDoc({
        params: { inspectionUUID: inspectionId },
      });
      if (!link) {
        throw APIException(
          404,
          'No inspection document uploaded for this inspection.',
        );
      }
      const blob = await fetcherBlob(link);

      const buffer = await blob.arrayBuffer();
      return mammoth
        .convertToHtml({ arrayBuffer: buffer })
        .then(function (result) {
          return {
            markup: result.value,
            blob: blob,
            link,
          };
        });
    },
    {
      enabled: !!inspectionId,
      staleTime: Infinity,
      retry(failureCount, error) {
        return failureCount > 2;
      },
    },
  );

export default function PreviewInspectionDocument({
  inspectionId,
}: {
  inspectionId: string;
}) {
  const { css, theme } = useStitches();
  const [isOpen, setIsOpen] = useState(false);
  const fileQuery = useOpordFile(isOpen ? inspectionId : '');
  function errorMessage() {
    const error = fileQuery.error;
    if (isAPIError(error)) {
      if (error.code === 404) {
        return 'Inspection Document Not found';
      }
    }
    return 'Failed to parse document';
  }
  if (!inspectionId) {
    return null;
  }

  return (
    <>
      <Description
        onClick={() => setIsOpen(true)}
        className={css({
          backgroundColor: fileQuery.isError ? 'red' : '$primary',
        })}
      />
      <Modal
        onClose={({ closeSource }) =>
          closeSource === 'closeButton' ? setIsOpen(false) : null
        }
        isOpen={isOpen}
        overrides={{
          DialogContainer: {
            style: {
              alignItems: 'center',
            },
          },
          Dialog: {
            style: {
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              height: '80vh',
              width: '80vw',
              marginLeft: theme.sizing.scale600,
              marginRight: theme.sizing.scale600,
              marginBottom: theme.sizing.scale600,
              borderBottomLeftRadius: theme.sizing.scale600,
              borderBottomRightRadius: theme.sizing.scale600,
            },
          },
        }}
      >
        <ModalHeader>Preview Document</ModalHeader>
        <ModalBody
          className={css({
            layout: 'stack',
            flex: '1 1 0',
            overflowY: 'hidden',
          })}
        >
          {fileQuery.isError ? (
            <div className={css({ textAlign: 'center' })}>
              <ErrorIcon
                classes={{
                  root: css({
                    mx: 'auto',
                    height: '48px',
                    width: '48px',
                    color: '$negative500',
                  }),
                }}
              />
              <h3
                className={css({
                  mt: '$scale200',
                  fontWeight: '$normal',
                  color: '$mono500',
                })}
              >
                Error
              </h3>
              <Text
                as="p"
                variant="ParagraphMedium"
                className={css({
                  mt: '$scale100',
                  fontSize: '12px',
                  color: '$mono500',
                })}
              >
                {errorMessage()}
              </Text>
              <div className="mt-6">
                <Button
                  type="button"
                  size="mini"
                  onClick={() => fileQuery.refetch()}
                >
                  Try again.
                </Button>
              </div>
            </div>
          ) : fileQuery.data ? (
            <div
              className={css({
                layout: 'stack',
                overflow: 'hidden',
                spaceY: '$scale600',
              })}
            >
              <a
                href={fileQuery.data.link}
                target="_blank"
                className={css({
                  ':hover': { color: '$primary' },
                })}
              >
                <Text>Download File</Text>
              </a>
              <div className={css({ overflowY: 'auto', flex: 1 })}>
                <div
                  className={`markdown-body `}
                  dangerouslySetInnerHTML={{ __html: fileQuery.data.markup }}
                />
              </div>
            </div>
          ) : (
            <div
              className={css({
                display: 'flex',
                justifyContent: 'center',
                p: '$scale600',
                flex: 1,
              })}
            >
              <Spinner />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <ModalButton
            kind={ButtonKind.tertiary}
            onClick={() => setIsOpen(false)}
          >
            Close
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
}
