import React, { Component } from "react";
import Table from "components/Table";
import ColumnBuilder from "helper/ColumnBuilder";
import Typography from "@material-ui/core/Typography";
import { Colors } from "config/StyleConfig";
import { withStyles } from "@material-ui/core/styles";
import { Strings } from "../../../config/Strings"

export const FIELD_HI_ID = "id";
export const FIELD_COMPONENT_NAME = "component_name";
export const FIELD_SUB_COMPONENT_NAME = "sub_component_name";
export const FIELD_INSPECTION_DATE = "inspection_date";
export const FIELD_CONDITION_RATING = "condition_rating";
export const FIELD_CONDITION_RATING_SCALE = "condition_rating_scale";

const styles = () => ({
  title: { color: Colors.text },
  subtitle: { color: Colors.subtitleText },
});

class SubComponentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  
  getColumns = () => {
    var columns = [];
    columns.push(new ColumnBuilder(Strings.getInstance().getString("sub_comp_table_comp_col"), FIELD_COMPONENT_NAME).build());
    columns.push(new ColumnBuilder(Strings.getInstance().getString("sub_comp_table_sub_col"), FIELD_SUB_COMPONENT_NAME).build());
    columns.push(new ColumnBuilder("Inspection Date", FIELD_INSPECTION_DATE).build());
    columns.push(new ColumnBuilder(Strings.getInstance().getString("sub_comp_table_cr_scale"), FIELD_CONDITION_RATING_SCALE)
    .render((rowData) => {
      return Strings.getInstance().getString("sub_comp_table_scale") || rowData[FIELD_CONDITION_RATING_SCALE];
    })
    .build());
    columns.push(
      new ColumnBuilder(Strings.getInstance().getString("sub_comp_table_cr"), FIELD_CONDITION_RATING)
      .render((rowData) => {
        if (rowData !== undefined) {
          return this.styleCR(rowData[FIELD_CONDITION_RATING]);
        }
      })
      .centerHeaders()
      .build());
    return columns;
  };

  styleCR(value) {
    var displayText = Strings.getInstance().mapCR(value);
    var style = {
      border: null,
      borderRadius: "1.25rem",
      padding: "2px 25px",
      color: "black",
      textAlign: "center",
      fontWeight: "bold",
    };

    if (value > 0.8) { //1
      style["backgroundColor"] = Colors.heat1;
    } else if (value > 0.6) { //2
      style["backgroundColor"] = Colors.heat2;
    } else if (value > 0.4) { //3
      style["backgroundColor"] = Colors.heat3;
    } else if (value > 0.2) { //4
      style["backgroundColor"] = Colors.heat4;
    } else if (value < 0.2) { //5
      style["backgroundColor"] = Colors.heat5;
    } else if (value === undefined) {
      displayText = "";
    }
    return <div style={style}>{displayText}</div>;
  }

  actions = [
    {
      icon: "refresh",
      tooltip: "Refresh",
      isFreeAction: "true",
      onClick: () => {
        this.props.refreshSubCompCallback();
      },
    },
    {
      icon: "edit",
      tooltip: "Edit",
      isFreeAction: "true",
      onClick: () => {
        this.props.showSubComponentPicker();
      },
    },
  ];

  render() {
    const { classes } = this.props;

    return (
      <Table
        id="SUB_COMP_HEALTH_TABLE"
        title={
          <div>
            <Typography style={{ color: Colors.text }} className={classes.title} variant="h6">
              {Strings.getInstance().getString("sub_comp_table_title")}
            </Typography>
            <Typography style={{ color: Colors.subtitleText }} className={classes.subtitle} variant="subtitle2">
              {"From Latest inspection"}
            </Typography>
          </div>
        }
        columns={this.getColumns()}
        options={{ actionsColumnIndex: -1 }}
        data={this.props.data}
        actions={this.actions}
        isLoading={this.props.loading}
        disableHideColumnOption
      />
    );
  }
}

export default withStyles(styles)(SubComponentsTable);
