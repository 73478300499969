import {
  FETCH_ANALYTICS_SGR_HEAT_MAP_TABLE_DATA_STARTED,
  FETCH_ANALYTICS_SGR_HEAT_MAP_TABLE_DATA_SUCCESS,
  FETCH_ANALYTICS_SGR_HEAT_MAP_TABLE_DATA_ERROR,
  FETCH_PROJECTS_STARTED,
  FETCH_PROJECTS_SUCCESS,
} from "./AnalyticsReducer";

import InspectionAPIController from "../../services/api/InspectionAPIController";
import ProjectAPIController from "../../services/api/ProjectAPIController";

const fetchAnalyticsTableDataSuccess = (data) => {
  return {
    type: FETCH_ANALYTICS_SGR_HEAT_MAP_TABLE_DATA_SUCCESS,
    data: data,
  };
};

const fetchAnalyticsTableDataStarted = () => {
  return {
    type: FETCH_ANALYTICS_SGR_HEAT_MAP_TABLE_DATA_STARTED,
  };
};

const fetchAnalyticsTableDataFailed = () => {
  return {
    type: FETCH_ANALYTICS_SGR_HEAT_MAP_TABLE_DATA_ERROR,
  };
};

const fetchProjectsDataSuccess = (projectData) => {
  return {
    type: FETCH_PROJECTS_SUCCESS,
    projectData: projectData,
  };
};

const fetchProjectsDataStarted = () => {
  return {
    type: FETCH_PROJECTS_STARTED,
  };
};


const watchAnalyticsTableData = () => {
  return function (dispatch) {
    dispatch(fetchAnalyticsTableDataStarted());
    InspectionAPIController.getInspections()
      .then((response) => dispatch(fetchAnalyticsTableDataSuccess(response)))
      .catch((error) => {
        console.log(error);
        dispatch(fetchAnalyticsTableDataFailed());
      });
  };
};

const watchProjectsData = () => {
  return function (dispatch) {
    dispatch(fetchProjectsDataStarted());
    ProjectAPIController.getProjects()
      .then((response) => dispatch(fetchProjectsDataSuccess(response)))
      .catch((error) => {
        console.log(error);
      });
  };
};



export { watchAnalyticsTableData, watchProjectsData };
