import { Theme } from 'baseui';
import {
  createThemedStyled,
  createThemedUseStyletron,
  createThemedWithStyle,
} from 'baseui';
import { Borders, ComponentColorTokens } from 'baseui/themes';
import { LightTheme, createTheme } from 'baseui/themes';
import { ColorTokens } from 'baseui/tokens';
import { colors } from 'baseui/tokens';

const brandColors = {
  primary: '#2C6A94',
  primary50: '#E7F1F8',
  primary100: '#D0E4F1',
  primary200: '#9DC6E2',
  primary300: '#6EABD4',
  primary400: '#3A8DC5',
  primary500: '#2C6A94',
  primary600: '#235576',
  primary700: '#1B415A',
};

const overrides: Record<
  keyof Theme,
  Partial<ComponentColorTokens | Borders>
> = {
  colors: {
    inputFill: LightTheme.colors.white,
    // inputBorder: brandColors.primary600,
  },
  animation: {},
  borders: {
    inputBorderRadius: '8px',
  },
  breakpoints: {},
  direction: {},
  grid: {},
  icons: {},
  lighting: {},
  mediaQuery: {},
  name: {},
  sizing: {},
  typography: {},
  zIndex: {},
};

type CustomTheme = Theme & {
  colorTokens: ColorTokens;
};

const theme = createTheme(
  {
    ...brandColors,
    primaryFontFamily: 'Roboto, sans-serif',
  },
  overrides,
);

const customTheme: CustomTheme = {
  ...theme,
  colorTokens: colors,
};

export default customTheme;
export const styled = createThemedStyled<typeof theme>();
export const withStyle = createThemedWithStyle<typeof theme>();
export const useStyletron = createThemedUseStyletron<typeof theme>();
