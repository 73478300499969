import React, { Component } from "react";
import { Colors } from "config/StyleConfig";
import EditButton from "components/EditButton";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import SecuredImage from "../../components/SecuredImage";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "#ecf0f1",
    position: "relative",
  },
};

const buttonStyles = (theme) => ({
  buttonStyle: {
    background: Colors.backgroundIconButton,
    "&:hover, &.Mui-focusVisible": { backgroundColor: Colors.backgroundIconButtonHover },
  },
});


class InteractiveImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rectangle: null,
      initialPoint: null,
      editable: false,
      mouseDown: false,
    };
  }

  componentDidMount() {
    this.setState({
      rectangle: this.props.rectangle,
    });
  }

  onEditButtonClicked() {
    this.setState({ editable: true });
  }

  onCancelButtonClicked() {
    this.setState({ rectangle: this.props.rectangle, editable: false });
  }

  onConfirmButtonClicked() {
    this.setState({ editable: false });
    this.props.onConfirmButtonClicked(this.props.id, this.state.rectangle);
  }

  getRectangleTLBR(rect) {
    const rectangle = [];

    rectangle[0] = Math.min(rect[2], rect[0]);
    rectangle[2] = Math.max(rect[2], rect[0]);
    rectangle[1] = Math.min(rect[1], rect[3]);
    rectangle[3] = Math.max(rect[1], rect[3]);

    return rectangle;
  }

  getRectangleStyle(rect) {
    if (rect === null || !Array.isArray(rect) || rect.length < 4) {
      return {};
    }

    const rectangle = this.getRectangleTLBR(rect);

    const left = (rectangle[0] || 0) * 100 + "%";
    const top = (rectangle[1] || 0) * 100 + "%";
    const right = (1 - rectangle[2]) * 100 + "%";
    const bottom = (1 - rectangle[3]) * 100 + "%";

    return {
      left: left,
      top: top,
      bottom: bottom,
      right: right,
      borderColor: Colors.rectangle,
      borderStyle: "solid",
      position: "absolute",
      zIndex: 99,
      pointerEvents: "none",
    };
  }

  eventToPointPercentage(event) {
    let iBounds =  [event.target.getBoundingClientRect().left, event.target.getBoundingClientRect().top];
    return [
      Math.max(Math.min((event.clientX - iBounds[0]) / event.target.offsetWidth, 1), 0),
      Math.max(Math.min((event.clientY - iBounds[1]) / event.target.offsetHeight, 1), 0),
    ];
  }

  onMouseDown = (event) => {
    if (this.state.editable && event.target.tagName === "IMG") {
      this.props.isMouseDown(true);      
      const point = this.eventToPointPercentage(event);
      this.setState({
        mouseDown: true,
        rectangle: [point[0], point[1], point[0], point[1]],
        initialPoint: [point[0], point[1]],
      });
    }
  };

  onMouseMove = (event) => {
    if (this.state.editable && this.state.mouseDown && event.target.tagName === "IMG") {
      const point = this.eventToPointPercentage(event);
      const topLeftX = Math.min(this.state.initialPoint[0], point[0]);
      const topLeftY = Math.min(this.state.initialPoint[1], point[1]);
      const bottomRightX = Math.max(this.state.initialPoint[0], point[0]);
      const bottomRightY = Math.max(this.state.initialPoint[1], point[1]);
      this.setState({
        rectangle: [topLeftX, topLeftY, bottomRightX, bottomRightY],
      });
    }
  };

  onMouseUp = (event) => {
    this.props.isMouseDown(false);
    this.setState({ mouseDown: false });
  };

  render() {
    const imageSource = this.props.imageSource;
    const { classes } = this.props;

    return (
      <div
        style={styles.container}
        onMouseDown={this.onMouseDown}
        onMouseMove={this.onMouseMove}
        onMouseUp={this.onMouseUp}
        onMouseLeave={this.onMouseUp}
        draggable={false}
      >
        {this.props.isEditable && (
          <div
            style={{ position: "absolute", right: "0", marginRight: 5, marginTop: 5, display: "flex", flexDirection: "row-reverse" }}
            draggable={false}
          >
            {!this.state.editable && (
              <IconButton className={classes.buttonStyle} style={{ marginLeft: 5 }} onClick={() => this.props.onRemoveButtonClicked(this.props.id)}>
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            )}
            <EditButton
              isEditing={this.state.editable}
              onConfirmButtonClicked={() => this.onConfirmButtonClicked()}
              onCancelButtonClicked={() => this.onCancelButtonClicked()}
              onEditButtonClicked={() => this.onEditButtonClicked()}
              background={true}
            />
          </div>
        )}
        <SecuredImage style={{ width: "100%", height: "auto", maxHeight: "80vh", objectFit: "fill" }} draggable={false} src={imageSource} />
        <div style={this.getRectangleStyle(this.state.rectangle)} />
      </div>
    );
  }
}

export default withStyles(buttonStyles)(InteractiveImage);
