import { Alert } from 'baseui/icon';
import { Option, StatefulSelect } from 'baseui/select';
import { Skeleton } from 'baseui/skeleton';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useInspection } from 'services/api/inspection/queries';
import theme from 'theme';

type SubdivisionSelectProps = {
  value: string;
  onChange: (value: string) => void;
};

export default function SubdivisionSelect({
  value,
  onChange: onChangeHandler,
}: SubdivisionSelectProps) {
  const { inspectionId } = useParams<{ inspectionId: string }>();
  const inspectionQuery = useInspection(
    { params: { inspectionId } },
    { staleTime: Infinity },
  );

  if (inspectionQuery.error) {
    return (
      <div title="Failed to fetch subdivision options">
        <Alert color={theme.colors.negative500} />
      </div>
    );
  }

  if (inspectionQuery.data) {
    const subdivisions = inspectionQuery.data.structureSubdivisions;
    const options: Option[] = subdivisions.map((subdivision) => ({
      id: subdivision.uuid,
      label: subdivision.number,
    }));
    const selectedValue = options.filter((option) => option.id === value);

    if (options.length === 0) {
      return <div>---</div>;
    }

    return (
      <StatefulSelect
        initialState={
          selectedValue
            ? {
                value: selectedValue,
              }
            : undefined
        }
        options={options}
        onChange={({ option }) => {
          onChangeHandler(option?.id?.toString() ?? '');
        }}
        clearable={false}
      />
    );
  }

  return <Skeleton height="100px" rows={1} width="500px" />;
}
