import React, { Component } from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

export default class StructureItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.data.id,
      name: this.props.data.name,
    };
  }

  render() {
    return (
      <TreeItem
        style={{ marginBottom: 10, marginTop: 10 }}
        nodeId={this.props.nodeId}
        label={
          <Card>
            <CardContent>
              <Typography>{this.state.name}</Typography>
            </CardContent>
          </Card>
        }
        {...this.props}
      />
    );
  }
}
