import {
  FormControlProps as BaseFormControlProps,
  FormControlOverrides,
} from 'baseui/form-control';
import {
  FormControl as BaseFormControl,
  StyledLabel,
} from 'baseui/form-control';
import { getOverrides, Override } from 'baseui/helpers/overrides';
import { mergeOverrides } from 'baseui';
import React from 'react';

import { stitchesConfig, useStitches, styled } from 'theme';
const Root = styled('div', {});
type OwnProps = {
  description?: string;
  isRequired?: boolean;
  overrides?: FormControlOverrides & Partial<{ Root: Override }>;
};
export type FormControlProps = OwnProps & BaseFormControlProps;

export default function FormControl(props: FormControlProps) {
  const { description, isRequired, ...formControlProps } = props;
  const [RootComponent, rootComponentProps] = getOverrides(
    formControlProps?.overrides?.Root,
    Root,
  );
  return (
    <RootComponent {...rootComponentProps}>
      <BaseFormControl
        {...formControlProps}
        overrides={mergeOverrides(
          {
            Label: {
              component: CustomLabel,
              props: {
                description,
                isRequired,
              },
            },
            LabelContainer: {
              style: {
                flexDirection: 'column',
              },
            },
          } as typeof formControlProps.overrides,
          formControlProps.overrides,
        )}
      />
    </RootComponent>
  );
}

function CustomLabel(
  props: React.ComponentProps<typeof StyledLabel> &
    OwnProps & { children: React.ReactNode },
) {
  // eslint-disable-next-line react/prop-types
  const {
    description,
    isRequired,
    $style = {},
    children,
    ...baseLabelProps
  } = props;
  const { theme, css } = useStitches();
  return (
    <>
      <StyledLabel
        {...baseLabelProps}
        $style={{ display: 'inline', ...$style }}
      >
        {children}

        {!!isRequired && (
          <span
            title="This field is required"
            className={css({ color: theme.colors.negative, lineHeight: 0 })}
          >
            *
          </span>
        )}
      </StyledLabel>
      {!!description && (
        <StyledLabel
          $as="span"
          $style={{
            color: theme.colors.mono600,
            ...theme.typography.ParagraphXSmall,
          }}
        >
          {description}
        </StyledLabel>
      )}
    </>
  );
}
