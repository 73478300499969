import { createSelector } from "reselect";
import Helper from "helper/Helper";
import {
  FIELD_ID,
  FIELD_END_DATE,
  FIELD_SGR_RATING,
  FIELD_START_DATE,
  FIELD_STATUS,
  FIELD_TERM_RATING,
  FIELD_SPANS,
  FIELD_INSPECTORS,
  FIELD_ARCHIVED,
} from "features/Inspections/components/InspectionsTable";

import {
  FIELD_HI_ID,
  FIELD_COMPONENT_NAME,
  FIELD_SUB_COMPONENT_NAME,
  FIELD_INSPECTION_DATE,
  FIELD_CONDITION_RATING,
  FIELD_CONDITION_RATING_SCALE,
} from "./components/SubComponentsTable";

const getLatestInspection = (state) => state.StructureReducer.latestInspectionData;
const getStructure = (state) => state.StructureReducer.structureData;
const getClient = (state) => state.StructureReducer.clientData;
const getInspections = (state) => state.StructureReducer.inspectionsData;
const getSubComponentsHI = (state) => state.StructureReducer.subComponentHealthIndex;
const getDefectByComponent = (state) => state.StructureReducer.defectByComponent;
const getObservationByType = (state) => state.StructureReducer.observationByType;

export const mapLatestInspection = createSelector([getLatestInspection], (response) => {
  if (response === undefined) {
    return {
      sgr: 100,
      term: 5,
      lastInspected: new Date().toString(),
    };
  } else {
    return {
      sgr: response.sgrRating || 100,
      term: response.termRating || 5,
      lastInspected: response.startDate || new Date().toString(),
    };
  }
});

export const mapStructure = createSelector([getStructure, getClient], (structureData, clientData) => {
  var result = {};
  result["structureId"] = structureData.id;
  result["structureCode"] = structureData.code || "NA";
  result["structureName"] = structureData.name || "NA";
  result["postMile"] = structureData.postmile || "NA";
  result["beginStationing"] = structureData.beginStationing || "NA";
  result["endStationing"] = structureData.endStationing || "NA";
  result["structureNumber"] = structureData.caltransBridgeNo || "NA";
  result["owner"] = clientData.name || "NA";
  result["spans"] = structureData.spans || "NA";
  result["structureType"] = structureData.type;
  return result;
});

export const mapSubComponentsHI = createSelector([getSubComponentsHI], (response) => {
  return response.map((x) => mapSingleSubComponentHI(x));
});

const mapSingleSubComponentHI = (singleResponse) => {
  var mapResult = {};
  mapResult[FIELD_HI_ID] = singleResponse["id"];
  mapResult[FIELD_COMPONENT_NAME] = singleResponse["componentName"];
  mapResult[FIELD_SUB_COMPONENT_NAME] = singleResponse["subcomponentName"];
  mapResult[FIELD_INSPECTION_DATE] = singleResponse["inspectionDate"];
  mapResult[FIELD_CONDITION_RATING] =
    singleResponse["subcomponentHealthIndex"] === undefined ? "NA" : singleResponse["subcomponentHealthIndex"].toFixed(2);
  mapResult[FIELD_CONDITION_RATING_SCALE] = "HI";
  return mapResult;
};

export const mapDefectByComponent = createSelector([getDefectByComponent], (defectByComponent) => {
  var result = {};
  for (let i = 0; i < (defectByComponent || []).length; i++) {
    const element = defectByComponent[i];
    result[element["componentName"]] = element["observationDefectsCount"];
  }
  return result;
});

export const mapObservationByType = createSelector([getObservationByType], (observationByType) => {
  var result = { structural: 0, maintenance: 0 };
  if (observationByType === undefined) {
    return result;
  }
  return observationByType;
});

export const mapCompletedVsInProgressData = createSelector([getInspections], (originalResponse) => {
  const response = originalResponse || [];
  var counts = { inProgress: 0, completed: 0 };
  for (var i = 0; i < response.length; i++) {
    const data = response[i];
    if (data["status"] === "COMPLETED") {
      counts.completed++;
    } else {
      counts.inProgress++;
    }
  }

  return counts;
});

export const mapInspections = createSelector([getInspections], (originalResponse) => {
  return originalResponse.map((x) => mapSingleRow(x));
});

const mapSingleRow = (singleResponse) => {
  var mapResult = {};
  mapResult[FIELD_ID] = singleResponse["uuid"];
  mapResult[FIELD_START_DATE] = singleResponse["startDate"];
  mapResult[FIELD_END_DATE] = singleResponse["endDate"];
  mapResult[FIELD_SGR_RATING] = isNaN(singleResponse["sgrRating"]) ? "" : (singleResponse["sgrRating"] * 1).toFixed(2) + "%";
  mapResult[FIELD_STATUS] = singleResponse["status"];
  mapResult[FIELD_SPANS] = singleResponse["spansCount"];
  mapResult[FIELD_TERM_RATING] = singleResponse["termRating"];
  mapResult[FIELD_INSPECTORS] = mapInspectors(singleResponse["inspectors"]);
  mapResult[FIELD_ARCHIVED] = singleResponse["archived"];
  return mapResult;
};

const mapInspectors = (userData) => {
  if (Helper.isEmpty(userData)) {
    return [];
  }

  return userData.map((user) => {
    return {
      id: "" + user["id"],
      name: user["firstName"] + " " + user["lastName"],
    };
  });
};