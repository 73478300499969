
import { useStitches } from 'theme';
import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import SecuredImage from 'components/SecuredImage';
import ReactPanZoom from 'react-image-pan-zoom-rotate';


const ModalImageZoom = (props: any) => {
  const { css } = useStitches();

  useEffect(() => {
    console.log(props.children);
  }, [props.isOpen, props.children]);

  return (
    <>
      <Modal
        onClose={() => {
          props.setIsOpen(false);
        }}
        isOpen={props.isOpen}
        overrides={{
          Dialog: {
            style: {
              width: '100%',
              maxWidth: '96rem',
              height: '95vh',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              backgroundColor: '#000000',
            },
          },
        }}
      >
        <ModalBody
          $style={{
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            marginTop: 0,
          }}
          className={css({
            overflow: 'hidden',
            layout: 'row',
            flex: 1,
            backgroundColor: '#000000',
          })}
        >
          <div
            className={css({
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              backgroundColor: '#000000',
            })}
            draggable={false}
          >
            <SecuredImage
                showControls={true}
              link={props.image.annotatedLink}
              className={css({
                objectFit: 'contain',
                width: '$full',
                height: '$full',
              })}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ModalImageZoom;