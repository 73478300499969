import Helper from "helper/Helper";

export const FETCH_INSPECTIONS_STARTED = "FETCH_INSPECTIONS_STARTED";
export const FETCH_INSPECTIONS_SUCCESS = "FETCH_INSPECTIONS_SUCCESS";
export const FETCH_INSPECTIONS_ERROR = "FETCH_INSPECTIONS_ERROR";
export const DOWNLOAD_INSPECTION_STARTED = "DOWNLOAD_INSPECTION_STARTED";
export const DOWNLOAD_INSPECTION_SUCCESS = "DOWNLOAD_INSPECTION_SUCCESS";

export const FETCH_STRUCTURE_STARTED = "FETCH_STRUCTURE_STARTED";
export const FETCH_STRUCTURE_SUCCESS = "FETCH_STRUCTURE_SUCCESS";
export const FETCH_PROJECT_STARTED = "FETCH_PROJECT_STARTED";
export const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";

export const ADD_INSPECTION_STARTED = "ADD_INSPECTION_STARTED";
export const ADD_INSPECTION_SUCCESS = "ADD_INSPECTION_SUCCESS";
export const ADD_INSPECTION_FAILED = "ADD_INSPECTION_FAILED";

export const UPDATE_INSPECTION_STARTED = "UPDATE_INSPECTION_STARTED";
export const UPDATE_INSPECTION_SUCCESS = "UPDATE_INSPECTION_SUCCESS";
export const UPDATE_INSPECTION_FAILED = "UPDATE_INSPECTION_FAILED";

export const DELETE_INSPECTION_STARTED = "DELETE_INSPECTION_STARTED";
export const DELETE_INSPECTION_SUCCESS = "DELETE_INSPECTION_SUCCESS";
export const DELETE_INSPECTION_FAILED = "DELETE_INSPECTION_FAILED";

export const FETCH_PREFERENCES_SUCCESS = "FETCH_PREFERENCES_SUCCESS";
export const FETCH_DEFECT_BY_COMPONENT_SUCCESS = "FETCH_DEFECT_BY_COMPONENT_SUCCESS";
export const FETCH_OBSERVATION_BY_TYPE_SUCCESS = "FETCH_OBSERVATION_BY_TYPE_SUCCESS";

export const ARCHIVE_INSPECTION_STARTED = "ARCHIVE_INSPECTION_STARTED";
export const ARCHIVE_INSPECTION_SUCCESS = "ARCHIVE_INSPECTION_SUCCESS";
export const ARCHIVE_INSPECTION_FAILED = "ARCHIVE_INSPECTION_FAILED";

const initialState = {
  data: [],
  projectData: {},
  structureData: {},
  loading: true,
  loadingSecondaryData: true,
  loadingPrimaryData: true,
  loginRequired: false,
  preferences: {},
  defectByComponent: [],
  observationByType: {},
};

const InspectionsReducer = (state = initialState, action) => {
  var newData = null;

  switch (action.type) {
    case FETCH_INSPECTIONS_STARTED:
      return { ...state, loadingPrimaryData: true };
    case FETCH_PROJECT_STARTED:
      return { ...state, loadingSecondaryData: true };
    case FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        loadingSecondaryData: false,
        loading: state.loadingSecondaryData || state.loadingPrimaryData,
        projectData: action.projectData,
      };

    case FETCH_PREFERENCES_SUCCESS:
      return { ...state, preferences: action.data };
    case ADD_INSPECTION_STARTED:
      return { ...state, loading: true };
    case ADD_INSPECTION_SUCCESS:
      newData = Helper.insertItemImmutable(state.data, action.inspection);
      return { ...state, data: newData, loading: false };
    case ADD_INSPECTION_FAILED:
      return { ...state, loading: false };

    case UPDATE_INSPECTION_STARTED:
      return { ...state, loading: true };
    case UPDATE_INSPECTION_SUCCESS:
      newData = Helper.updateItemImmutable(state.data, action.inspection, "uuid");
      return { ...state, data: newData, loading: false };
    case UPDATE_INSPECTION_FAILED:
      return { ...state, loading: false };

    case ARCHIVE_INSPECTION_STARTED:
      return { ...state, loading: true };
    case ARCHIVE_INSPECTION_SUCCESS:
      newData = Helper.updateItemImmutable(state.data, action.inspection, "uuid");
      return { ...state, data: newData, loading: false };
    case ARCHIVE_INSPECTION_FAILED:
      return { ...state, loading: false };

    case DELETE_INSPECTION_STARTED:
      return { ...state, loading: true };
    case DELETE_INSPECTION_SUCCESS:
      newData = Helper.removeItemImmutable(state.data, action.inspectionId, "uuid");
      return { ...state, data: newData, loading: false };
    case DELETE_INSPECTION_FAILED:
      return { ...state, loading: false };

    case FETCH_STRUCTURE_STARTED:
      return { ...state, loadingSecondaryData: true };
    case FETCH_STRUCTURE_SUCCESS:
      return {
        ...state,
        loadingSecondaryData: false,
        loading: state.loadingPrimaryData || state.loadingSecondaryData,
        structureData: action.structureData,
      };

    case FETCH_DEFECT_BY_COMPONENT_SUCCESS:
      return { ...state, defectByComponent: action.data };
    case FETCH_OBSERVATION_BY_TYPE_SUCCESS:
      return { ...state, observationByType: action.data };
    case DOWNLOAD_INSPECTION_STARTED:
      return { ...state, loading: true };
    case DOWNLOAD_INSPECTION_SUCCESS:
      return { ...state, loading: false };
    case FETCH_INSPECTIONS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case FETCH_INSPECTIONS_ERROR:
      switch (action.errorCode) {
        case 401:
          return { ...state, loginRequired: true, loading: false };
        default:
          return state;
      }
    default:
      return state;
  }
};

export { InspectionsReducer };
