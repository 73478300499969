import Navigator from 'Navigator';
import TokenManager from 'storage/TokenManager';

export default class SecureAPIBuilder {
  static GET = 'GET';
  static POST = 'POST';
  static DELETE = 'DELETE';
  static PUT = 'PUT';

  constructor(url, method) {
    this.urlValue = url;
    this.methodValue = method;
    this.bodyValue = undefined;
    this.queryValue = undefined;
    this.form = false;
  }

  body(value) {
    this.bodyValue = value;
    return this;
  }

  file(file) {
    this.bodyValue = file;
    this.form = true;
    return this;
  }

  query(value) {
    this.queryValue = value;
    return this;
  }

  addQueriesToURL(url, query) {
    var modifiedURL = (url += '?');
    for (var key of Object.keys(query)) {
      modifiedURL += key + '=' + query[key] + '&';
    }
    return modifiedURL.slice(0, -1);
  }

  call() {
    var url = this.urlValue;
    if (this.queryValue !== undefined) {
      url = this.addQueriesToURL(url, this.queryValue);
    }

    var headers = {
      'X-Token': TokenManager.getToken()['token'],
    };

    if (!this.form) {
      headers['Content-Type'] = 'application/json';
    }

    return fetch(url, {
      crossDomain: true,
      method: this.methodValue,
      headers: headers,
      body: this.bodyValue,
    }).then((res) => {
      if (
        res.status === 401 &&
        (window.location.pathname !== '/logout' ||
          window.location.pathname !== '/')
      ) {
        return Navigator.navigateToLogout();
      }
      return res;
    });
  }
}
