import {
  FIELD_ID,
  FIELD_DEFECT_NUMBER,
  FIELD_COMPONENT,
  FIELD_SUB_COMPONENT,
  FIELD_DEFECT_CLASSIFICATION,
  FIELD_SPAN,
  FIELD_STATIONING,
  FIELD_CLOCK_POSITION,
  FIELD_DEFECT_TYPE,
  FIELD_DESCRIPTION,
  FIELD_DIMENSION,
  FIELD_CORRECTIVE_ACTION_PLAN,
  FIELD_CS_RATING,
  FIELD_INSPECTOR_NAME,
  FIELD_THUMBNAIL_IMAGE_LINK,
  FIELD_IMAGES,
  FIELD_REPAIR_METHOD,
  FIELD_REPAIR_BY_DATE,
  FIELD_SUB_COMPONENT_UUID,
  FIELD_INSPECTED_DATE,
  FIELD_PREVIOUS_DEFECT,
  FIELD_DEFECT_STATUS,
  FIELD_DEFECT_FINDINGS
} from "./components/ObservationsTable";

import {
  FIELD_LOCATIONS_ID,
  FIELD_LOCATIONS_UUID,
  FIELD_LOCATIONS_COMPONENT,
  FIELD_LOCATIONS_SUB_COMPONENT,
  FIELD_LOCATIONS_SIZE,
  FIELD_LOCATIONS_DEFECT_NUMBER,
  FIELD_LOCATIONS_HI,
  FIELD_LOCATIONS_INSPECTED,
} from "./components/SubComponentsTable";

import Helper from "helper/Helper";
import { Strings } from "../../config/Strings";
import { createSelector } from "reselect";

const getInspectors = (state) => state.InspectionReducer.inspectionData.inspectors;
const getObservations = (state) => state.InspectionReducer.inspectionData.observations;
const getInspection = (state) => state.InspectionReducer.inspectionData;
const getStructure = (state) => state.InspectionReducer.structureData;
const getProject = (state) => state.InspectionReducer.projectData;
const getClient = (state) => state.InspectionReducer.clientData;
const getPreferences = (state) => state.InspectionReducer.preferencesData;

export const mapDrawable = (drawable) => {
  if (drawable === undefined) {
    return [];
  }

  var rectangle = [];
  var values = drawable.substring(5, drawable.length - 1);
  var rectangleString = values.split(",");
  rectangle = rectangleString.map((item) => {
    return parseFloat(item);
  });
  return rectangle;
};

export const mapObservationToAPI = (tableObservation, originalAPISubComponents, originalSubUUID) => {
  var updatedObservation = {};
  for (var i = 0; i < (originalAPISubComponents || []).length; i++) {
    const subComponent = originalAPISubComponents[i];
    if (subComponent["uuid"] === originalSubUUID) {
      for (var j = 0; j < (subComponent["observationDefects"] || []).length; j++) {
        const observation = subComponent["observationDefects"][j];
        if (observation["uuid"] === tableObservation[FIELD_ID]) {
          updatedObservation = Helper.deepCopy(observation);
          /**
           * Since the we cannot easily configure the Table value return we will always check if
           * the `criticalFindings` property has values in the shape of Array<{value:string,name:string}> to indicate that the user
           * has selected new values/edited the field
           */
       const hasSelectedNewCriticalFindings= tableObservation.criticalFindings.every(item=>item?.value !==undefined )
       
       if(hasSelectedNewCriticalFindings){
         updatedObservation.criticalFindings=tableObservation.criticalFindings.map(item=>item.value)
         updatedObservation.criticalFindingsValues=tableObservation.criticalFindings.map(item=>item.name)
       }

         if(tableObservation.subdivisionUUID) {
           updatedObservation.structureSubdivisionId=tableObservation.subdivisionUUID
         }
          
          updatedObservation["type"] = tableObservation[FIELD_DEFECT_CLASSIFICATION];
          updatedObservation["span"] = tableObservation[FIELD_SPAN];
          updatedObservation["stationMarker"] = tableObservation[FIELD_STATIONING];
          updatedObservation["clockPosition"] = tableObservation[FIELD_CLOCK_POSITION];
          if (updatedObservation["type"] === "MAINTENANCE") {
            updatedObservation["observationNameId"] = tableObservation[FIELD_DEFECT_TYPE];
            updatedObservation["defectId"] = undefined;
          } else {
            updatedObservation["observationNameId"] = undefined;
            updatedObservation["defectId"] = tableObservation[FIELD_DEFECT_TYPE];
          }
          updatedObservation["description"] = tableObservation[FIELD_DESCRIPTION];
          updatedObservation["size"] = tableObservation[FIELD_DIMENSION];
          updatedObservation["size"] = tableObservation[FIELD_DIMENSION];
          updatedObservation["observationType"] = tableObservation[FIELD_CORRECTIVE_ACTION_PLAN];
          updatedObservation["conditionId"] = tableObservation[FIELD_CS_RATING];
          updatedObservation["repairMethod"] = tableObservation[FIELD_REPAIR_METHOD];
          updatedObservation["repairDate"] = tableObservation[FIELD_REPAIR_BY_DATE];
          updatedObservation["photos"] = tableObservation[FIELD_IMAGES];
          updatedObservation["previousDefect"] = tableObservation[FIELD_PREVIOUS_DEFECT];
          updatedObservation["status"] = tableObservation[FIELD_DEFECT_STATUS] !== "CLONED" ? tableObservation[FIELD_DEFECT_STATUS] : null;
          break;
        }
      }
      if (!Helper.isEmpty(updatedObservation)) {
        break;
      }
    }
  }

  return updatedObservation;
};

export const mapImagesToGallery = (observation) => {
  var images = [];

  observation[FIELD_IMAGES].forEach((imageData) => {
    var image = {};
    image["original"] = imageData["link"];
    image["thumbnail"] = imageData["thumbLink"];
    image["drawables"] = mapDrawable(imageData["drawables"]);
    image["id"] = imageData["uuid"];
    images.push(image);
  });
  return images;
};

export const mapLocation = createSelector([getObservations], (observations) => {
  var result = [];
  (observations || []).forEach((subComponent) => {
    var locationData = {};
    const computedHealIndexValue = (value) => {
      if (Number(value).isNan === undefined) {
        return 0;
      }
      return value;
    };
    locationData[FIELD_LOCATIONS_UUID] = subComponent["uuid"];
    locationData[FIELD_LOCATIONS_ID] = subComponent["id"];
    locationData[FIELD_LOCATIONS_COMPONENT] = subComponent["structuralComponentId"];
    locationData[FIELD_LOCATIONS_SUB_COMPONENT] = subComponent["subComponentId"];
    locationData[FIELD_LOCATIONS_SIZE] = subComponent["dimensionNumber"];
    locationData[FIELD_LOCATIONS_DEFECT_NUMBER] = (subComponent["observationDefects"] || []).length;
    locationData[FIELD_LOCATIONS_HI] = subComponent["healthIndex"];
    locationData["computedHealthIndex"] = subComponent["computedHealthIndex"]?computedHealIndexValue(subComponent["computedHealthIndex"]):undefined ;
    locationData.generalSummary = subComponent?.generalSummary ?subComponent.generalSummary:undefined;
    locationData[FIELD_LOCATIONS_INSPECTED] = subComponent["inspected"] || false;
    result.push(locationData);
  });

  return result;
});

export const mapStructure = createSelector([getStructure], (structureData) => {
  var result = {id:structureData.id};
 
  result["structureCode"] = structureData.code || "NA";
  result["structureName"] = structureData.name || "NA";
  result["postMile"] = structureData.postmile || "NA";
  result["beginStationing"] = structureData.beginStationing || "NA";
  result["endStationing"] = structureData.endStationing || "NA";
  result["structureNumber"] = structureData.caltransBridgeNo || "NA";
  return result;
});

export const mapObservation = (observations) => {
  var result = [];
  (observations || []).forEach((subComponent) => {
    const subComponentUUID = subComponent["uuid"];
    const componentId = subComponent["structuralComponentId"];
    const subComponentId = subComponent["subComponentId"];
    (subComponent["observationDefects"] || []).forEach((defect) => {
      var observationData = {
        observationUUID:defect.observationId,
        rawDefect:defect,
        rawObservation:subComponent,
        subdivisionNumber:defect.structureSubdivision?.number
      };

      observationData[FIELD_ID] = defect["uuid"];
      observationData[FIELD_DEFECT_NUMBER] = defect["id"];
      observationData[FIELD_DEFECT_FINDINGS] = defect["criticalFindings"]?.length > 0 ? defect["criticalFindings"][0] : [];
      observationData[FIELD_DEFECT_STATUS] = defect['status']
      observationData[FIELD_COMPONENT] = componentId;
      observationData[FIELD_SUB_COMPONENT] = subComponentId;
      observationData['subdivisionUUID'] = defect?.structureSubdivision?.number;
      observationData[FIELD_DEFECT_CLASSIFICATION] = defect["type"];
      observationData[FIELD_SPAN] = defect["span"];
      observationData[FIELD_STATIONING] = defect["stationMarker"];
      observationData[FIELD_CLOCK_POSITION] = defect["clockPosition"] ?defect["clockPosition"]: "[]";
      observationData[FIELD_DEFECT_TYPE] = defect["defectId"] || defect["observationNameId"];
      observationData[FIELD_DESCRIPTION] = defect["description"];
      observationData[FIELD_DIMENSION] = defect["size"];
      observationData[FIELD_CORRECTIVE_ACTION_PLAN] = defect["observationType"];
      observationData[FIELD_CS_RATING] = defect["conditionId"];
      observationData[FIELD_INSPECTOR_NAME] = defect["createdBy"]["firstName"] + " " + defect["createdBy"]["lastName"];
      observationData[FIELD_REPAIR_METHOD] = defect["repairMethod"];
      observationData[FIELD_REPAIR_BY_DATE] = defect["repairDate"];
      observationData[FIELD_INSPECTED_DATE] = defect["createdAtClient"];
      observationData[FIELD_THUMBNAIL_IMAGE_LINK] =
        defect["photos"] !== undefined && defect["photos"].length > 0 ? defect["photos"][0]["thumbLink"] : "";
      observationData[FIELD_IMAGES] = defect["photos"] || [];
      observationData[FIELD_SUB_COMPONENT_UUID] = subComponentUUID;
      observationData[FIELD_PREVIOUS_DEFECT] = defect["previousDefect"] || "";
      observationData.criticalFindings = defect?.criticalFindingsValues ?? [];
      result.push(observationData);
    });
  });

  return result;
};

const mapNumberOfObservations = createSelector([getObservations], (subComponents) => {
  var count = 0;
  if (subComponents === undefined) {
    return 0;
  }

  subComponents.forEach((subComponent) => {
    count += (subComponent.observationDefects || []).length;
  });

  return count;
});

export const mapInspectors = createSelector([getInspectors], (inspectorsData) => {
  if (inspectorsData === undefined || inspectorsData.length === 0) {
    return ["NA"];
  }

  var inspectors = [];
  inspectorsData.forEach((inspector) => {
    inspectors.push(inspector["firstName"] + " " + inspector["lastName"]);
  });

  return inspectors;
});

export const mapInspection = createSelector(
  [getInspection, mapNumberOfObservations, mapInspectors],
  (inspectionData, numbOfObservations, inspectors) => {
    var result = {};

    result["uuid"] = inspectionData.uuid;
    result["sgrRating"] = inspectionData.sgrRating || 0;
    result["term"] = inspectionData.termRating || "NA";
    result["numberOfObservations"] = numbOfObservations;
    result["startDate"] = inspectionData.startDate || "NA";
    result["endDate"] = inspectionData.endDate || "NA";
    result["status"] = inspectionData.status === "COMPLETED" ? "Completed" : "In Progress";
    result["inspectors"] = inspectors;
    result["summary"] = inspectionData.generalSummary || "";
    result["spans"] = inspectionData.spansCount || 0;

    return result;
  }
);

export const mapLocationToAPI = (location) => {
  var updateLocation = {generalSummary:location.generalSummary};
  updateLocation["uuid"] = location[FIELD_LOCATIONS_UUID];
  updateLocation["structuralComponentId"] = location[FIELD_LOCATIONS_COMPONENT];
  updateLocation["subComponentId"] = location[FIELD_LOCATIONS_SUB_COMPONENT];
  updateLocation["id"] = location[FIELD_LOCATIONS_ID];
  updateLocation["dimensionNumber"] = location[FIELD_LOCATIONS_SIZE];
  updateLocation["healthIndex"] = location[FIELD_LOCATIONS_HI];
  updateLocation["inspected"] = location[FIELD_LOCATIONS_INSPECTED];

  return updateLocation;
};

export const mapLocationData = createSelector([getObservations], (observations) => {
  var result = [];
  (observations || []).forEach((subComponent) => {
    var locationData = {};
    locationData[FIELD_LOCATIONS_UUID] = subComponent["uuid"];
    locationData[FIELD_LOCATIONS_ID] = subComponent["id"];
    locationData[FIELD_LOCATIONS_COMPONENT] = subComponent["structuralComponentId"];
    locationData[FIELD_LOCATIONS_SUB_COMPONENT] = subComponent["subComponentId"];
    locationData[FIELD_LOCATIONS_SIZE] = subComponent["dimensionNumber"];
    locationData[FIELD_LOCATIONS_DEFECT_NUMBER] = (subComponent["observationDefects"] || []).length;
    locationData[FIELD_LOCATIONS_HI] = subComponent["healthIndex"];
    locationData[FIELD_LOCATIONS_INSPECTED] = subComponent["inspected"];
    result.push(locationData);
  });

  return result;
});

export const mapProject = createSelector([getProject], (projectData) => {
  var result = {};
  result["name"] = projectData.name || "NA";
  return result;
});

export const mapClient = createSelector([getClient], (clientData) => {
  var result = {};
  result["clientName"] = clientData.name || "";
  return result;
});

export const mapPreferences = createSelector([getPreferences], (preferencesData) => {
  var result = {
    criticalFindings:preferencesData?.criticalFindings ?? [],
    structures:preferencesData?.structures ? preferencesData.structures: []
  };
  result["conditions"] = {};
  result["defects"] = {};
  result["subcomponents"] = {};
  result["components"] = {};
  result["nonDefectObservations"] = {};

  (preferencesData["conditions"] || []).forEach((condition) => {
    result["conditions"][condition["id"]] = {};
    var conditionType = "";
    switch (condition["type"]) {
      case "GOOD":
        conditionType = "CS-1";
        break;
      case "FAIR":
        conditionType = "CS-2";
        break;
      case "POOR":
        conditionType = "CS-3";
        break;
      case "SEVERE":
        conditionType = "CS-4";
        break;
      default:
        conditionType = "";
    }

    result["conditions"][condition["id"]]["name"] = Strings.getInstance().mapString("client_observation_cs", conditionType);
  });

  (preferencesData["subComponents"] || []).forEach((subcomponent) => {
    result["subcomponents"][subcomponent["id"]] = {};
    result["subcomponents"][subcomponent["id"]]["name"] = subcomponent["name"];
    result["subcomponents"][subcomponent["id"]]["defects"] = subcomponent["defectIds"];
    result["subcomponents"][subcomponent["id"]]["nonDefectObservations"] = subcomponent["observationNameIds"];
    result["subcomponents"][subcomponent["id"]]["measureUnit"] = subcomponent["measureUnit"];
  });
  (preferencesData["defects"] || []).forEach((defect) => {
    result["defects"][defect["id"]] = {};
    result["defects"][defect["id"]]["name"] = defect["name"];
    result["defects"][defect["id"]]["conditions"] = defect["conditionIds"];
  });

  (preferencesData["observationNames"] || []).forEach((defect) => {
    result["nonDefectObservations"][defect["id"]] = {};
    result["nonDefectObservations"][defect["id"]]["name"] = defect["name"];
  });

  (preferencesData["structuralComponents"] || []).forEach((component) => {
    result["components"][component["id"]] = {};
    result["components"][component["id"]]["name"] = component["name"];
    result["components"][component["id"]]["type"] = component["type"];
    result["components"][component["id"]]["subcomponents"] = component["subComponentIds"];
  });

  return result;
});
