import {
    SHOW_INFO_DIALOG,
    SHOW_CONFIRMATION_DIALOG,
    DISMISS_DIALOG
} from "./DialogReducer";

export const showInfoDialog = (title, description) => {
    return {
      type: SHOW_INFO_DIALOG,
      title: title,
      description: description
    };
};

export const showConfirmationDialog = (title, description, pendingAction) => {
  return {
    type: SHOW_CONFIRMATION_DIALOG,
    title: title,
    description: description,
    pendingAction: pendingAction
  };
};

export const confirmPendingAction = () => {
  return (dispatch, getState) => {
    const cancelConfirmationAction = dismissDialog();
    if(getState().DialogReducer.pendingAction){
      const pendingConfirmAction = getState().DialogReducer.pendingAction;
      dispatch(pendingConfirmAction);
    }
      dispatch(cancelConfirmationAction);
  }
}

export const dismissDialog = () => {
  return {
    type: DISMISS_DIALOG,
    pendingAction: null
  };
};




