import React, { Component } from "react";
import { connect } from "react-redux";
import Helper from "helper/Helper";
import { getStructureData } from "./StructureAction";
import SGRCard from "features/Inspection/components/SGRCard";
import TermCard from "features/Inspection/components/TermCard";
import LastInspectedCard from "./components/LastInspectedCard";
import StructureInfoCard from "features/Inspection/components/StructureInfoCard";
import CompletedVsInProgressCard from "features/Inspections/components/CompletedVsInProgressCard";
import {
  mapLatestInspection,
  mapStructure,
  mapCompletedVsInProgressData,
  mapInspections,
  mapSubComponentsHI,
  mapDefectByComponent,
  mapObservationByType,
} from "./StructureMapper";
import InspectionsTable from "features/Inspections/components/InspectionsTable";
import SubComponentPicker from "./components/SubComponentPicker";
import LoadingScreen from "components/LoadingScreen";

import Grid from "@material-ui/core/Grid";
import SubComponentsTable from "./components/SubComponentsTable";
import { setSubComponentPickerVisibility } from "./components/SubComponentPicker/SubComponentPickerAction";

import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import TopInspectorsCard from "features/Inspections/components/TopInspectorsCard";
import DefectsVsNSMI from "features/Inspections/components/DefectsVsNSMI";
import ComponentsStatistics from "features/Inspections/components/ComponentsStatistics";
import Switch from "@material-ui/core/Switch";
import { mapMultipleRows, mapInspectorsData } from "features/Inspections/InspectionsMapper";
import { VisibilityFlag } from "../../config/VisibilityFlag"

class Structure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      inspectorsOptions: [],
      structureOptions: [],
      structureId: this.extractStructureId(),
      viewOnly: true,
      analyticView: false,
    };
  }

  updateData = () => {
    this.setState({
      viewOnly: this.props.viewOnly || this.extractStructureId() === undefined,
    });

    this.props.getStructureData(this.props.structureId);
  };

  componentDidUpdate(prevProps) {
    // Check if values needs changing because of search query changes.
    if (this.props.location !== prevProps.location) {
      this.updateData();
    }
  }

  componentDidMount() {
    this.updateData();
    this.props.getStructureData(this.state.structureId);
  }

  toggleAnalytics = () => {
    this.setState({ analyticView: !this.state.analyticView });
  };

  extractStructureId() {
    const parsedURL = Helper.parseURLLocation(this.props.location.pathname + "");
    return parsedURL[parsedURL.length - 1];
  }

  refreshSubCompCallback = () => {
    this.props.getStructureData(this.state.structureId);
  };

  showSubComponentPicker = () => {
    this.props.showSubComponentPicker();
  };

  render() {
    const subtitle = "From Latest Inspection";
    const getVisibility = (x) => VisibilityFlag.getInstance().isVisible(x)

    if (this.props.structureLoading) {
      return <LoadingScreen />;
    }

    return (
      <div>
        <SubComponentPicker
          visible={this.props.subComponentPickerVisibility}
          structureName={this.props.structureInfo.structureName}
          structureType={this.props.structureInfo.structureType}
          structureId={this.props.structureInfo.structureId}
        />

        <Grid container spacing={3}>
          <Grid item container direction="row" spacing={3}>
            <Grid item xs={2}>
              <SGRCard subtitle={subtitle} sgr={this.props.latestInspection.sgr} />
            </Grid>
            <Grid item xs={2}>
              <Grid item container direction="column" spacing={3} style={{height: '108%'}}>
                {getVisibility("structure_term_card_visible") &&
                  <Grid item xs>
                    <TermCard subtitle={subtitle} term={this.props.latestInspection.term} />
                  </Grid>
                }
                <Grid item xs>
                  <LastInspectedCard lastInspected={this.props.latestInspection.lastInspected} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              {
                <StructureInfoCard
                  structureId={this.props.structureInfo.structureCode}
                  structureName={this.props.structureInfo.structureName}
                  structureOwner={this.props.structureInfo.owner}
                  structureNumber={this.props.structureInfo.structureNumber}
                  spans={this.props.structureInfo.spans}
                  postMile={this.props.structureInfo.postMile}
                  beginStationing={this.props.structureInfo.beginStationing}
                  endStationing={this.props.structureInfo.endStationing}
                />
              }
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing={3}>
            <Grid item xs>
              <SubComponentsTable
                data={this.props.subComponentsHI}
                refreshSubCompCallback={this.refreshSubCompCallback}
                showSubComponentPicker={this.showSubComponentPicker}
              />
            </Grid>
          </Grid>
        </Grid>

        <div>
          <Divider style={{ margin: "20px 0" }} />
        </div>

        {this.extractStructureId() !== undefined && (
          <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "flex-end", marginBottom: 10 }}>
            <Typography variant="subtitle1">Insights</Typography>
            <Switch checked={this.state.analyticView} onChange={this.toggleAnalytics} name="Analytic View" />
          </div>
        )}
        <Grid item container direction="column" spacing={3}>
          {this.state.analyticView && this.extractStructureId() !== undefined && (
            <Grid item container direction="row" spacing={3}>
              {VisibilityFlag.getInstance().isVisible("structure_page_sd_vs_nsmi_visible") &&
                <Grid item xs={4}>
                  <DefectsVsNSMI defects={this.props.observationByType.structural} nsmi={this.props.observationByType.maintenance} />
                </Grid>
              }
              <Grid item xs={VisibilityFlag.getInstance().isVisible("structure_page_sd_vs_nsmi_visible") ? 8 : 12}>
                <ComponentsStatistics data={this.props.defectByComponent} />
              </Grid>
            </Grid>
          )}

          {!this.state.analyticView && (
            <Grid item container direction="row" spacing={3}>
              <Grid item xs={4}>
                <CompletedVsInProgressCard
                  inProgress={this.props.completeVsIncomplete.inProgress || 0}
                  completed={this.props.completeVsIncomplete.completed || 0}
                />
              </Grid>
              <Grid item xs={8}>
                <TopInspectorsCard data={this.props.topInspectorData} />
              </Grid>
            </Grid>
          )}

          <Grid item>
            <InspectionsTable
              viewOnly
              data={this.props.inspections}
              structureView
              updateCallback={() => this.props.getStructureData(this.state.structureId)}
              downloadInspectionCallback={this.downloadInspectionReport}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: mapMultipleRows(state),
    topInspectorData: mapInspectorsData(state),
    loading: state.InspectionsReducer.loading,
    defectByComponent: mapDefectByComponent(state),
    observationByType: mapObservationByType(state),
    latestInspection: mapLatestInspection(state),
    structureInfo: mapStructure(state),
    completeVsIncomplete: mapCompletedVsInProgressData(state),
    inspections: mapInspections(state),
    subComponentsHI: mapSubComponentsHI(state),
    structureLoading: state.StructureReducer.loading,
    subComponentPickerVisibility: state.SubComponentPickerReducer.subComponentPickerVisibility,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStructureData: (structureId) => dispatch(getStructureData(structureId)),
    showSubComponentPicker: () => dispatch(setSubComponentPickerVisibility(true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Structure);
