import React, { Component } from "react";

import TextField from "@material-ui/core/TextField";

class MultilineEditTextTableField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      originalValue: this.props.value,
      tempValue: this.props.value,
    };
  }

  onTextChange = (event) => {
    var value = event.target.value;
    this.setState({ tempValue: value });
    this.props.onChange(value);
  };

  render() {
    return (
      <TextField
        style={{ width: 300 }}
        inputProps={{ style: { fontSize: 13 } }}
        rowsMax="4"
        multiline
        value={this.state.tempValue}
        onChange={this.onTextChange}
      />
    );
  }
}

export default MultilineEditTextTableField;
