import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { UsersReducer } from "./features/Users/UsersReducer";
import { LoginReducer } from "./features/Login/LoginReducer";
import { InspectionsReducer } from "./features/Inspections/InspectionsReducer";
import { MenuReducer } from "./components/Menu/MenuReducer";
import { DialogReducer } from "./components/Dialog/DialogReducer";
import { ProjectsReducer } from "./features/Projects/ProjectsReducer";
import { StructuresReducer } from "./features/Structures/StructuresReducer";
import { ClientsReducer } from "./features/Clients/ClientsReducer";
import { ClientReducer } from "./features/Client/ClientReducer";
import { DefectsReducer } from "./features/Defects/DefectsReducer";
import { ComponentsHIReducer } from "./features/ComponentsHI/ComponentsHIReducer";
import { PreferencesReducer } from "./features/Preferences/PreferencesReducer";
import { InspectionReducer } from "./features/Inspection/InspectionReducer";
import { StructureReducer } from "./features/Structure/StructureReducer";
import { SubComponentPickerReducer } from "./features/Structure/components/SubComponentPicker/SubComponentPickerReducer";
import { AnalyticsReducer } from "./features/Analytics/AnalyticsReducer";

const rootReducer = combineReducers({
  UsersReducer,
  LoginReducer,
  InspectionsReducer,
  MenuReducer,
  DialogReducer,
  ProjectsReducer,
  StructuresReducer,
  ClientsReducer,
  ClientReducer,
  DefectsReducer,
  ComponentsHIReducer,
  PreferencesReducer,
  InspectionReducer,
  StructureReducer,
  SubComponentPickerReducer,
  AnalyticsReducer
});

const Store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
export { Store };
