import {
  FETCH_PREFERENCES_STARTED,
  FETCH_PREFERENCES_SUCCESS,
  UPDATE_MODIFIED_DATA,
  UPDATE_CREATED_DATA,
  CANCEL_MODIFIED_DATA,
  CANCEL_CREATED_DATA,
  RESET_MODIFIED_AND_CREATED_DATA,
  SUBMIT_MODIFIED_DATA_STARTED,
  SUBMIT_MODIFIED_DATA_SUCCESS,
} from "./PreferencesReducer";
import Helper from "helper/Helper";
import PreferencesAPIController from "../../services/api/PreferencesAPIController";

const fetchPreferencesSuccess = (data) => {
  return {
    type: FETCH_PREFERENCES_SUCCESS,
    data: data,
  };
};

const fetchPreferencesStarted = () => {
  return {
    type: FETCH_PREFERENCES_STARTED,
  };
};

const submitModifiedDataStarted = () => {
  return {
    type: SUBMIT_MODIFIED_DATA_STARTED,
  };
};

const submitModifiedDataSuccess = (data) => {
  return {
    type: SUBMIT_MODIFIED_DATA_SUCCESS,
    data: data,
  };
};

const updateModifiedDataSuccess = (dataElement) => {
  return {
    type: UPDATE_MODIFIED_DATA,
    dataElement: dataElement,
  };
};

const cancelModifiedDataSuccess = (dataElement) => {
  return {
    type: CANCEL_MODIFIED_DATA,
    dataElement: dataElement,
  };
};

const updateCreatedDataSuccess = (dataElement) => {
  return {
    type: UPDATE_CREATED_DATA,
    dataElement: dataElement,
  };
};

const cancelCreatedDataSuccess = (dataElement) => {
  return {
    type: CANCEL_CREATED_DATA,
    dataElement: dataElement,
  };
};

const resetModifiedAndCreatedDataSuccess = () => {
  return {
    type: RESET_MODIFIED_AND_CREATED_DATA,
  };
};

const fetchPreferences = () => {
  return function (dispatch) {
    dispatch(fetchPreferencesStarted());
    PreferencesAPIController.getPreferences()
      .then((response) => {
        dispatch(fetchPreferencesSuccess(response));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const generateNewData = (modifiedData, createdData, originalData, parentId) => {
  // Add any new data first.
  if (parentId in createdData) {
    originalData = originalData.concat(createdData[parentId]);
  }

  var result = [];
  for (var i = 0; i < originalData.length; i++) {
    // Check component
    const originalComponentData = originalData[i];
    var componentID = originalComponentData["id"];
    var data = Helper.deepCopy(originalComponentData);
    if (componentID in modifiedData) {
      // Modify original component params based on changes
      for (var param in originalComponentData) {
        data[param] = modifiedData[componentID][param];
      }

      // Add params that are not present in original if any
      // Extract missing params.
      var additionalParams = Object.keys(modifiedData[componentID]).filter((x) => !Object.keys(originalComponentData).includes(x));

      for (var index in additionalParams) {
        const param = additionalParams[index];
        data[param] = modifiedData[componentID][param];
      }
    }

    // Once that's done, go to the sub-components
    if ("subcomponents" in originalComponentData) {
      data["subcomponents"] = generateNewData(modifiedData, createdData, originalComponentData["subcomponents"], componentID);
    } else if ("defects" in originalComponentData) {
      data["defects"] = generateNewData(modifiedData, createdData, originalComponentData["defects"], componentID);
    }

    // Remove the Ids of createdData after using them.
    if (parentId in createdData) {
      for (var j = 0; j < createdData[parentId].length; j++) {
        if (createdData[parentId][j]["id"] === data["id"]) {
          delete data.id;
          break;
        }
      }
    }

    result.push(data);
  }

  return result;
};

const submitModifiedData = (modifiedData, createdData, originalData, structureTypes) => {
  return function (dispatch) {
    dispatch(submitModifiedDataStarted());
    var dictionary = {};
    createdData["components"] = [];
    structureTypes.forEach((structureType) => {
      createdData["components"] = createdData["components"].concat(createdData[structureType["id"]] || []);
      delete createdData[structureType["id"]];
    });

    dictionary["components"] = generateNewData(modifiedData, createdData, originalData["components"], "components");

    PreferencesAPIController.saveComponentsDictionary(dictionary)
      .then((response) => {
        dispatch(submitModifiedDataSuccess(response));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const updateModifiedData = (dataElement, originalDataElement) => {
  return function (dispatch) {
    if (Helper.areObjectsEqual(dataElement, originalDataElement)) {
      dispatch(cancelModifiedDataSuccess(dataElement));
    } else {
      dispatch(updateModifiedDataSuccess(dataElement));
    }
  };
};

const updateCreatedData = (dataElement) => {
  return function (dispatch) {
    if (dataElement["deleted"]) {
      dispatch(cancelCreatedDataSuccess(dataElement));
    } else {
      dispatch(updateCreatedDataSuccess(dataElement));
    }
  };
};

const resetModifiedAndCreatedData = () => {
  return function (dispatch) {
    dispatch(resetModifiedAndCreatedDataSuccess());
  };
};

export { fetchPreferences, updateModifiedData, updateCreatedData, resetModifiedAndCreatedData, submitModifiedData };
