import React, { Component } from 'react';
import Table from 'components/Table';
import ColumnBuilder from 'helper/ColumnBuilder';

import NumberTextField from 'components/NumberTextField';
import Navigator from 'Navigator';
import CheckboxTableField from 'components/CheckBoxTableField';
import ValidatorArray from 'helper/Validator/ValidatorArray';
import ValidatorFieldBuilder, {
  TYPE,
} from 'helper/Validator/ValidatorFieldBuilder';
import { Colors } from 'config/StyleConfig';
import { extractId, extractIds } from '../InspectionsMapper';
import { Strings } from 'config/Strings';
import { VisibilityFlag } from '../../../config/VisibilityFlag';
import { withRouter } from 'react-router-dom';
import Text from 'components/Text';
import { Tag, KIND, VARIANT, SIZE } from 'baseui/tag';
import { SGRBadge } from 'components/SGRBadge';
import PreviewInspectionDocument from 'features/InspectionActions/PreviewInspectionDocument';

export const FIELD_ID = 'id';
export const FIELD_START_DATE = 'start_date';
export const FIELD_END_DATE = 'end_date';
export const FIELD_STATUS = 'status';
export const FIELD_SPANS = 'spans';
export const FIELD_STRUCTURE = 'structure_id';
export const FIELD_STRUCTURE_NAME = 'structure_name';
export const FIELD_SGR_RATING = 'sgr_rating';
export const FIELD_TERM_RATING = 'term_rating';
export const FIELD_INSPECTORS = 'inspectors';
export const FIELD_GENERAL_SUMMARY = 'general_summary';
export const FIELD_ARCHIVED = 'is_archived';
class InspectionsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showArchived: false,
    };
  }

  validateFields = (structureId, spans, inspectorIds, startDate, endDate) => {
    var validator = new ValidatorArray();
    validator.push(
      new ValidatorFieldBuilder([structureId], TYPE.LOOKUP)
        .lookupOptions(this.props.structureOptions)
        .canBeEmpty(false)
        .build(),
    );
    validator.push(new ValidatorFieldBuilder(spans, TYPE.NUMBER).build());
    validator.push(
      new ValidatorFieldBuilder(inspectorIds, TYPE.LOOKUP)
        .lookupOptions(this.props.inspectorsOptions)
        .build(),
    );
    validator.push(
      new ValidatorFieldBuilder(
        endDate === undefined ||
          startDate === undefined ||
          new Date(startDate) < new Date(endDate),
        TYPE.CONDITION,
      )
        .errorMessage('Start date cannot be bigger than end date')
        .build(),
    );

    return validator;
  };

  getColumns = () => {
    var columns = [];
    const getVisibility = (x) => VisibilityFlag.getInstance().isVisible(x);
    columns.push(
      new ColumnBuilder(
        Strings.getInstance().getString('inspections_table_structure_id'),
        FIELD_STRUCTURE,
      )
        .render((rowData) => {
          return (
            <Text
              css={{ layout: 'row', alignItems: 'center', pr: '$scale400' }}
            >
              <Text css={{ flexShrink: 0, fontWeight: '$semibold' }}>
                {rowData.structure_id?.name}
              </Text>
              {rowData.isCloned && (
                <Tag
                  kind={KIND.primary}
                  variant={VARIANT.solid}
                  size={SIZE.small}
                  closeable={false}
                >
                  Cloned
                </Tag>
              )}
            </Text>
          );
        })
        .editComponent((props) => (
          <CheckboxTableField
            options={this.props.structureOptions}
            value={props.value}
            onChange={(e, v) => props.onChange(v)}
          />
        ))
        .build(),
    );
    columns.push(
      new ColumnBuilder(
        Strings.getInstance().getString('inspections_table_structure_name'),
        FIELD_STRUCTURE_NAME,
      )
        .editable('never')
        .build(),
    );
    columns.push(
      new ColumnBuilder(
        Strings.getInstance().getString('inspections_table_inspectors'),
        FIELD_INSPECTORS,
      )
        .editComponent((props) => (
          <CheckboxTableField
            multiple
            options={this.props.inspectorsOptions}
            value={props.value}
            onChange={(e, v) => props.onChange(v)}
          />
        ))
        .build(),
    );
    if (getVisibility('inspections_table_span')) {
      columns.push(
        new ColumnBuilder(
          Strings.getInstance().getString('inspections_table_span'),
          FIELD_SPANS,
        ).build(),
      );
    }
    columns.push(
      new ColumnBuilder(
        Strings.getInstance().getString('inspections_table_start_date'),
        FIELD_START_DATE,
      )
        .type('date')
        .build(),
    );
    columns.push(
      new ColumnBuilder(
        Strings.getInstance().getString('inspections_table_end_date'),
        FIELD_END_DATE,
      )
        .type('date')
        .editable('onUpdate')
        .build(),
    );
    columns.push(
      new ColumnBuilder(
        Strings.getInstance().getString('inspections_table_sgr'),
        FIELD_SGR_RATING,
      )
        .readOnly()
        .editable('never')
        .render((rowData) => {
          if (rowData !== undefined) {
            // return this.styleSGR(rowData[FIELD_SGR_RATING]);
            return <SGRBadge rating={rowData[FIELD_SGR_RATING]} />;
          }
        })
        .centerHeaders()
        .build(),
    );

    if (getVisibility('inspections_table_term')) {
      columns.push(
        new ColumnBuilder(
          Strings.getInstance().getString('inspections_table_term'),
          FIELD_TERM_RATING,
        )
          .editComponent((props) => (
            <NumberTextField
              min={0}
              max={5}
              step={0.1}
              onChange={props.onChange}
              value={props.value}
            />
          ))
          .render((rowData) => {
            if (
              rowData !== undefined &&
              rowData[FIELD_TERM_RATING] !== undefined
            ) {
              return rowData[FIELD_TERM_RATING].toFixed(1);
            }
          })
          .editable('onUpdate')
          .build(),
      );
    }
    columns.push(
      new ColumnBuilder(
        Strings.getInstance().getString('inspections_table_status'),
        FIELD_STATUS,
      )
        .lookup({
          IN_PROGRESS: 'In Progress',
          COMPLETED: 'Completed',
        })
        .editable('onUpdate')
        .render((rowData) => {
          if (rowData !== undefined) {
            return this.styleStatus(rowData[FIELD_STATUS]);
          }
        })
        .build(),
    );

    if (this.props.structureView) {
      columns.splice(0, 2);
    }

    return columns;
  };

  styleSGR(value) {
    var style = {
      border: null,
      borderRadius: '1.25rem',
      padding: '2px 25px',
      color: 'black',
      textAlign: 'center',
      fontWeight: 'bold',
    };

    var displayText = Strings.getInstance().mapSGR(parseFloat(value));

    if (parseFloat(value) > 80) {
      //1
      style['backgroundColor'] = Colors.heat1;
    } else if (parseFloat(value) > 60) {
      //2
      style['backgroundColor'] = Colors.heat2;
    } else if (parseFloat(value) > 40) {
      //3
      style['backgroundColor'] = Colors.heat3;
    } else if (parseFloat(value) > 20) {
      //4
      style['backgroundColor'] = Colors.heat4;
    } else if (parseFloat(value) < 20) {
      //5
      style['backgroundColor'] = Colors.heat5;
    } else if (value === undefined || value === '') {
      displayText = '';
    }

    return <div style={style}>{displayText}</div>;
  }

  styleTerm(value) {
    var style = {
      border: null,
      borderRadius: '1.25rem',
      padding: '2px 25px',
      color: 'white',
      textAlign: 'center',
    };

    var displayText = '';

    if (value === 0) {
      style['backgroundColor'] = Colors.good;
      displayText = 'Excellent';
    } else if (value === 1) {
      style['backgroundColor'] = Colors.good;
      displayText = 'Good';
    } else if (value === 2) {
      style['backgroundColor'] = Colors.average;
      displayText = 'Adequate';
    } else if (value === 3) {
      style['backgroundColor'] = Colors.average;
      displayText = 'Marginal';
    } else if (value === 4) {
      style['backgroundColor'] = Colors.bad;
      displayText = 'Poor';
    } else if (value === undefined) {
      displayText = '';
    }

    return <div style={style}>{displayText}</div>;
  }

  styleStatus(value) {
    var style = {
      border: null,
      borderRadius: '1.25rem',
      padding: '2px 25px',
      color: 'white',
      textAlign: 'center',
    };

    var displayText = '';

    if (value === 'IN_PROGRESS') {
      style['backgroundColor'] = Colors.inProgress;
      displayText = 'In Progress';
    } else {
      style['backgroundColor'] = Colors.completed;
      displayText = 'Completed';
    }

    return <div style={style}>{displayText}</div>;
  }

  getActions = () => {
    var actions = [];
    if (!this.props.viewOnly) {
      actions.push({
        icon: 'copy_all',
        tooltip: 'Clone inspection',
        onClick: (event, rowData) => {
          const searchParams = new URLSearchParams(this.props.location.search);
          const hasId = searchParams.has('clone');
          if (!hasId && searchParams.get('clone') !== rowData.id) {
            searchParams.append('clone', rowData.id);
            this.props.history.replace(`?${searchParams.toString()}`);
          }
        },
      });
    }

    actions.push({
      icon: 'archive',
      tooltip: this.state.showArchived ? 'Hide Archived' : 'Show Archived',
      iconProps: {
        style: { color: this.state.showArchived ? Colors.toggled : '' },
      },
      isFreeAction: 'true',
      onClick: () => {
        this.setState({ showArchived: !this.state.showArchived });
      },
    });
    actions.push({
      icon: 'refresh',
      tooltip: 'Refresh Data',
      isFreeAction: 'true',
      onClick: () => {
        this.props.updateCallback();
      },
    });
    if (!this.props.viewOnly) {
      actions.push({
        icon: 'add_box',
        tooltip: 'Add New Inspection',
        isFreeAction: 'true',
        onClick: () => {
          const handler = this.props.addCallback;
          if (handler && handler instanceof Function) {
            handler();
          }
        },
      });
    }
    if (!this.props.viewOnly) {
      actions.push((rowData) => ({
        icon: () => <PreviewInspectionDocument inspectionId={rowData.id} />,
        tooltip: 'Preview Inspection Document',
        onClick: () => {
          //
        },
      }));
    }

    if (!this.props.viewOnly) {
      actions.push({
        icon: 'delete_outlined',
        tooltip: 'Remove',
        onClick: (event, rowData) => {
          this.props.deleteInspectionCallback(rowData['id']);
        },
      });
    }
    if (!this.props.structureView) {
      actions.push({
        icon: 'assignment_outlined',
        tooltip: 'View Report',
        onClick: (event, rowData) => {
          // this.props.downloadInspectionCallback(rowData['id']);
          const searchParams = new URLSearchParams(this.props.location.search);
          const hasId = searchParams.has('modal');
          if (!hasId && searchParams.get('modal') !== rowData.id) {
            searchParams.append('modal', 'generate');
            searchParams.append('inspectionId', rowData.id);
            this.props.history.replace(`?${searchParams.toString()}`);
          }
        },
      });
    }

    if (!this.props.viewOnly) {
      actions.push((rowData) => ({
        icon: rowData[FIELD_ARCHIVED]
          ? 'unarchive_outlined'
          : 'archive_outlined',
        tooltip: rowData[FIELD_ARCHIVED] ? 'Restore' : 'Archive',
        onClick: (event, rowData) => {
          this.props.archiveInspectionCallback(
            rowData[FIELD_ID],
            !rowData[FIELD_ARCHIVED],
          );
        },
      }));
    }
    if (!this.props.viewOnly) {
      actions.push((rowData) => ({
        icon: 'mode',
        tooltip: 'Edit Inspection',
        onClick: (event, rowData) => {
          const params = new URLSearchParams(this.props.location.search);
          params.set('action', 'edit');
          params.set('inspectionId', rowData.id);
          this.props.history.replace(
            `${this.props.location.pathname}?${params.toString()}`,
          );
        },
      }));
    }

    return actions;
  };

  editable = {
    // onRowAdd: newData =>
    //   new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //       let validator = this.validateFields(
    //         newData[FIELD_STRUCTURE],
    //         newData[FIELD_SPANS],
    //         newData[FIELD_INSPECTORS],
    //         newData[FIELD_START_DATE],
    //         newData[FIELD_END_DATE],
    //       );
    //       if (validator.validate()) {
    //         resolve();
    //         this.props.addInspectionCallback(
    //           this.props.projectId,
    //           extractId(newData[FIELD_STRUCTURE]),
    //           newData[FIELD_SPANS],
    //           extractIds(newData[FIELD_INSPECTORS]),
    //           newData[FIELD_START_DATE],
    //         );
    //       } else {
    //         this.props.showError(
    //           'Invalid Input',
    //           validator.generateColumnErrorDescription(this.getColumns()),
    //         );
    //         reject();
    //       }
    //     }, 100);
    //   }),
    // onRowUpdate: (newData, oldData) =>
    //   new Promise((resolve, reject) => {
    //     setTimeout(() => {
    //       let validator = this.validateFields(
    //         newData[FIELD_STRUCTURE],
    //         newData[FIELD_SPANS],
    //         newData[FIELD_INSPECTORS],
    //         newData[FIELD_START_DATE],
    //         newData[FIELD_END_DATE],
    //       );
    //       if (validator.validate()) {
    //         resolve();
    //         this.props.updateInspectionCallback(
    //           oldData[FIELD_ID],
    //           newData[FIELD_START_DATE],
    //           this.props.projectId,
    //           extractId(newData[FIELD_STRUCTURE]),
    //           newData[FIELD_SPANS],
    //           extractIds(newData[FIELD_INSPECTORS]),
    //           newData[FIELD_STATUS],
    //           newData[FIELD_TERM_RATING],
    //           newData[FIELD_GENERAL_SUMMARY],
    //           newData[FIELD_END_DATE],
    //         );
    //       } else {
    //         this.props.showError(
    //           'Invalid Input',
    //           validator.generateColumnErrorDescription(this.getColumns()),
    //         );
    //         reject();
    //       }
    //     }, 100);
    //   }),
  };

  getEditable = () => {
    if (this.props.viewOnly) {
      return {};
    }
    return this.editable;
  };

  onRowClick = (event, rowData) => {
    Navigator.navigateToInspection(rowData[FIELD_ID]);
  };

  getTableData = () => {
    let result = [];
    this.props.data.forEach((element) => {
      if (!element[FIELD_ARCHIVED] || this.state.showArchived) {
        result.push(element);
      }
    });
    return result;
  };

  render() {
    return (
      <Table
        id="INSPECTIONS_TABLE"
        title="Inspections"
        columns={this.getColumns()}
        height={this.props.height}
        editable={this.getEditable()}
        options={{
          actionsColumnIndex: -1,
          rowStyle: (rowData) => ({
            backgroundColor: rowData[FIELD_ARCHIVED] ? Colors.archived : '',
          }),
        }}
        data={this.getTableData()}
        actions={this.getActions()}
        isLoading={this.props.isLoading}
        onRowClick={this.onRowClick}
      />
    );
  }
}

export default withRouter(InspectionsTable);
