import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { KIND as ButtonKind } from 'baseui/button';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal';

import { Banner, KIND } from 'components/Banner';

import { useUpdateSubdivision } from 'services/api/inspection/mutation';
import { StructureSubdivision } from 'services/api/inspection/types';
import { useInspection } from 'services/api/inspection/queries';

import SubDivisionForm, {
  FormFields as SubDivisionFormFields,
} from '../features/SubdivisionForm';
import { Alert } from 'baseui/icon';
import { APIException } from 'Exceptions';
import { getErrorMessage } from 'utils/error';
import { useStitches } from 'theme';

export default function EditSubdivision({
  // trigger,
  subdivision,
  isOpen: isModalOpen,
  onClose: onCloseHandler = () => {},
}: {
  trigger?: (args: { toggle: (open: boolean) => void }) => React.ReactNode;
  subdivision?: StructureSubdivision;
  isOpen?: boolean;
  onClose?: () => void;
}) {
  const { css } = useStitches();
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();
  const { inspectionId } = useParams<{ inspectionId: string }>();
  const inspectionQuery = useInspection({ params: { inspectionId } });
  const updateSubdivision = useUpdateSubdivision();

  const handleClose = () => {
    if (updateSubdivision.isLoading) return;
    setIsOpen(false);
    onCloseHandler();
  };

  useEffect(() => {
    if (isModalOpen !== undefined && isModalOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isModalOpen]);

  return (
    <>
      {/* {trigger({ toggle: (open = !isOpen) => setIsOpen(open) })} */}
      <Modal
        onClose={() => handleClose()}
        isOpen={isOpen}
        overrides={{
          Dialog: {
            style: {
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              height: '80vh',
            },
          },
        }}
      >
        <ModalHeader>Edit Sub-division</ModalHeader>
        <ModalBody
          className={css({ flex: 1, overflow: 'hidden', layout: 'stack' })}
        >
          <Banner
            artwork={{
              icon: () => <Alert />,
            }}
            show={updateSubdivision.isError}
            title="Failed to update subdivision."
            kind={KIND.negative}
          >
            {getErrorMessage(updateSubdivision.error)}, try again.
          </Banner>
          <div className={css({ flex: 1, overflowY: 'auto' })}>
            {subdivision && !!inspectionQuery.data ? (
              <SubDivisionForm
                initialValues={{
                  inspectionId: inspectionId,
                  name: subdivision.name,
                  number: subdivision.number.toString(),
                  subComponents: subdivision.observationStructureSubdivisions.reduce(
                    (all, structure) => {
                      const observation = inspectionQuery.data.observations.find(
                        observation =>
                          observation.uuid === structure.observationId,
                      );
                      return {
                        ...all,
                        [structure.observationId]: {
                          measurement: structure.dimensionNumber.toString(),
                          subComponent: {
                            id: structure.observationId,
                            selected: true,
                            dimensionNumber: Number(
                              observation?.dimensionNumber ?? '0',
                            ),
                          },
                        },
                      };
                    },
                    {} as SubDivisionFormFields['subComponents'],
                  ),
                }}
                onSubmit={data => {
                  const subComponents = Object.values(data.subComponents);
                  const newAllocations = subComponents
                    .filter(item => item.subComponent.selected)
                    .filter(item => {
                      return (
                        subdivision.observationStructureSubdivisions.find(
                          s => s.observationId === item.subComponent.id,
                        ) === undefined
                      );
                    });
                  const removedAllocation = subComponents.filter(item => {
                    return (
                      !!subdivision.observationStructureSubdivisions.find(
                        s => s.observationId === item.subComponent.id,
                      ) && !item.subComponent.selected
                    );
                  });

                  const updatedAllocations = subComponents
                    .filter(item => item.subComponent.selected)
                    .filter(item => {
                      const matchedSubdivision = subdivision.observationStructureSubdivisions.find(
                        s => s.observationId === item.subComponent.id,
                      );
                      if (matchedSubdivision) {
                        return (
                          item.measurement !==
                          matchedSubdivision?.dimensionNumber.toString()
                        );
                      }
                      return false;
                    });
                  const getItemAllocationType = (id: string) => {
                    if (newAllocations.find(v => v.subComponent.id === id)) {
                      return 'ADD';
                    } else if (
                      removedAllocation.find(v => v.subComponent.id === id)
                    ) {
                      return 'REMOVE';
                    } else if (
                      updatedAllocations.find(v => v.subComponent.id === id)
                    ) {
                      return 'ADD';
                    }
                    //FIXME: improve implementation
                    else {
                      return 'ADD';
                    }
                  };

                  updateSubdivision.mutate(
                    {
                      payload: {
                        uuid: subdivision.uuid,
                        inspectionId: inspectionId,
                        name: data.name,
                        number: data.number,
                        sgrRating: subdivision.computedSgrRating,
                        subComponents: subComponents
                          .filter(item => !!item.measurement)
                          .map(item => {
                            const matchedSubdivision = subdivision.observationStructureSubdivisions.find(
                              structure =>
                                structure.observationId ===
                                item.subComponent.id,
                            );

                            return {
                              uuid: matchedSubdivision?.uuid,
                              id: item.subComponent.id,
                              measurement: Number(item.measurement),
                              subdivisionId:
                                matchedSubdivision?.structureSubdivisionId ??
                                subdivision.uuid,
                              type: getItemAllocationType(item.subComponent.id),
                            };
                          }),
                      },
                    },
                    {
                      onSuccess() {
                        queryClient.invalidateQueries([
                          'inspection',
                          inspectionId,
                        ]);
                        handleClose();
                      },
                    },
                  );
                }}
              />
            ) : (
              <Banner>No Data found. close modal and try again.</Banner>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalButton kind={ButtonKind.tertiary} onClick={handleClose}>
            Cancel
          </ModalButton>
          <ModalButton
            form="new-subdivision-form"
            isLoading={updateSubdivision.isLoading}
          >
            Edit Sub-division
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
}
