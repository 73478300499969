export const Colors = {
  primary: "#2C6A94",
  secondary: "#f26783",
  disabled: "#D3D3D3",
  archived: "#EEE",
  error: "red",
  toggled: "#f26783",
  text: "#6c757d",
  subtitleText: "#b0b0b0",
  drawer: "#1e2e38",
  drawerItems: "#FFFFFF",
  selectedDrawerItems: "#1E2E38",
  topBar: "#FFFFFF",
  inProgress: "#ffbc00",
  completed: "#0acf97",
  markedForChange: "#0acf97",
  markedForDeletion: "#fa5c7c",
  good: "#0acf97",
  average: "#ffbc00",
  bad: "#fa5c7c",
  icons: "#e6e6e6",
  rectangle: "#00ff00",
  lightboxBackground: "rgba(0,0,0,0.7)",
  background: "#fafbfe",
  versionColor: "#6c757d",
  defects: "#ff679b",
  nsmi: "#727cf5",
  backgroundIconButton: "#FFFFFF40",
  backgroundIconButtonHover: "#FFFFFF99",
  heat1: "#00af50",
  heat2: "#ffff02",
  heat3: "#f89646",
  heat4: "#fe0000",
  heat5: "#7030a0",
};
