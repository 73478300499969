import { APIException } from 'Exceptions';
import TokenManager from 'storage/TokenManager';

/* eslint @typescript-eslint/no-explicit-any: 0 */
export default function noop() {
  return null;
}

export type ErrorWrapper = { status: 'unknown' | number; message: string };

export type FetcherJSONResponse = Record<string, unknown>;
export type FetcherQueryArgs<T extends Record<string, unknown>> = { params: T };
export type FetcherMutatergs<T extends Record<string, unknown>> = {
  payload: T;
};

const API_URL = process.env.REACT_APP_API_URL;

export const baseFetcher = async (endpoint: string, init?: RequestInit) => {
  const isFormData = init?.body instanceof FormData;
  const headers: Record<string, string> = {
    ...(!isFormData ? { 'Content-Type': 'application/json' } : {}),
  };

  const fetchInit: RequestInit = init ?? {};
  const token = TokenManager.getToken()['token'];

  if (token) {
    headers['X-Token'] = `${token}`;
  }
  fetchInit.headers = headers;
  const url = endpoint.match(/^https?:\/\//)
    ? endpoint
    : `${API_URL}${endpoint}`;
  const response = await fetch(url, fetchInit);
  return response;
};

export async function fetcher(endpoint: string, init?: RequestInit) {
  const response = await baseFetcher(endpoint, init);
  if (!response.ok) {
    let error: ErrorWrapper;
    try {
      error = await response.json();
    } catch (e) {
      error = {
        status: 'unknown' as const,
        message:
          e instanceof Error
            ? `Unexpected error (${e.message})`
            : 'Unexpected error',
      };
    }

    throw APIException(response.status, response.statusText);
  }

  return response.json();
}

export async function fetcherBlob(endpoint: string, init?: RequestInit) {
  const response = await baseFetcher(endpoint, init);
  if (!response.ok) {
    let error: ErrorWrapper;
    try {
      error = await response.json();
    } catch (e) {
      error = {
        status: 'unknown' as const,
        message:
          e instanceof Error
            ? `Unexpected error (${e.message})`
            : 'Unexpected error',
      };
    }

    throw APIException(response.status, error.message);
  }

  return response.blob();
}
