import React from "react";
import ListCell from "components/ListCell";
import Tooltip from "@material-ui/core/Tooltip";
import CheckboxTableField from "components/CheckBoxTableField"

export default class ColumnBuilder {
  constructor(title, field) {
    this.column = {
      title: title,
      field: field,
      render: this.defaultRender(field),
      customFilterAndSearch: this.defaultCustomFilterAndSearch(field),
    };
  }

  type(type) {
    this.column.type = type;
    if (type === "date") {
      this.column.render = (rowData) => {
        if (rowData !== undefined && rowData[this.column.field] !== undefined) {
          return new Date(rowData[this.column.field]).toISOString().split("T")[0];
        }
      };
    }

    return this;
  }

  readOnly() {
    this.column.readOnly = true;
    return this;
  }

  editable(editable) {
    this.column.editable = editable;
    return this;
  }

  hidden() {
    this.column.hidden = true;
    return this;
  }
  
  centerHeaders() {
    this.column.headerStyle = {textAlign: "center"};
    return this;
  }

  render(render) {
    this.column["render"] = render;
    return this;
  }

  defaultRender(field) {
    return (rowData) => {
      if (rowData !== undefined && rowData[field] !== undefined) {
        if (Array.isArray(rowData[field])) {
          return this.arrayToHTML(rowData[field].map((x) => x["name"]));
        } else if (rowData[field]["name"] === undefined) {
          if (rowData[field].length <= field.length) {
            return (
              this.column.cellStyle = {whiteSpace: 'nowrap'},
              rowData[field]
              )
          } else {
            return (
            <Tooltip title={<div style={{ fontSize: 13, lineHeight: 2 }}>{rowData[field]}</div>}>
              <div style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '60%', maxWidth: 'inherit'}}>{rowData[field]}</div>
            </Tooltip>
            )
          }
        } else {
          return rowData[field]["name"];
        }
      }
    };
  }

  arrayToHTML(array) {
    return <ListCell value={array} />;
  }

  defaultCustomFilterAndSearch(field) {
    return (term, rowData) => {
      if (rowData !== undefined && rowData[field] !== undefined) {
        if (Array.isArray(rowData[field])) {
          for (var i = 0; i < rowData[field].length; i++) {
            if (rowData[field][i]["name"] !== undefined && rowData[field][i]["name"].toLowerCase().includes(term.toLowerCase())) {
              return true;
            }
          }
        } else if (typeof(term) === "object" && Object.prototype.toString.call(term) === "[object Date]") {
          return rowData[field].includes(new Date(term).toISOString().split("T")[0])
        } else if (rowData[field]["name"] === undefined) {
          return (rowData[field] + "").toLowerCase().includes(term.toLowerCase());
        } else {
          return rowData[field]["name"] !== undefined && rowData[field]["name"].toLowerCase().includes(term.toLowerCase());
        }
      }

      return false;
    };
  }

  editComponent(editComponent) {
    this.column["editComponent"] = editComponent;
    return this;
  }

  filterAndSearch(callback) {
    this.column["customFilterAndSearch"] = callback;
    return this;
  }
  
  customSort(callback) {
    this.column["customSort"] = callback;
    return this;
  }

  lookup(lookup) {
    this.column.lookup = lookup;

    this.column.filterComponent = (props) => {
      var options = Array.from(new Set(Object.values(props.columnDef.lookup))).sort()
      for (let i = 0; i < options.length; i++) {
        options[i] = { "id" : i, "name" : options[i]};
      }
      return <CheckboxTableField options={options} multiple onChange = {(e, v) => {
        const values = []
        for (let i = 0; i < v.length; i++) {
          values.push(v[i]['name']);
        }
        props.onFilterChanged(props.columnDef.tableData.id, values)}}/>
    }

    this.column.customFilterAndSearch = (term, rowData) => {
      if (term.includes(lookup[rowData[this.column.field]]) || term.length === 0) {
        return true
      } else if (lookup[rowData[this.column.field]] !== undefined && !Array.isArray(term)) {
        return lookup[rowData[this.column.field]].toLowerCase().includes(term.toLowerCase());
      }
    };

    this.column.render = undefined;
    return this;
  }

  build() {
    return this.column;
  }
}
