import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

import GeneralCard from "components/GeneralCard";

class LastInspectedCard extends Component {
  formatDate(d) {
    let date = new Date(d);
    return (
      (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
      "/" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      "/" +
      date.getFullYear()
    );
  }

  getMonthDiffFromToday(d) {
    let date = new Date(d);
    let today = new Date();
    let months = (today.getFullYear() - date.getFullYear()) * 12;
    months -= date.getMonth();
    months += today.getMonth();
    return months;
  }

  render() {
    const title = "Last Inspected";
    const subtitle = this.props.subtitle;
    const content = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography align="center" variant="h4">
          {this.getMonthDiffFromToday(this.props.lastInspected)} months
        </Typography>
        <Typography align="center">({this.formatDate(this.props.lastInspected)})</Typography>
      </div>
    );

    return <GeneralCard title={title} subtitle={subtitle} content={content} icon={<VisibilityOutlinedIcon />} />;
  }
}

export default LastInspectedCard;