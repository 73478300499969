export const FETCH_DEFECTS_STARTED = "FETCH_DEFECTS_STARTED";
export const FETCH_DEFECTS_SUCCESS = "FETCH_DEFECTS_SUCCESS";

const initialState = {
  data: [],
  loading: true,
};

const DefectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEFECTS_STARTED:
      return { ...state, loading: true };
    case FETCH_DEFECTS_SUCCESS:
      return { ...state, data: action.data, loading: false };
    default:
      return state;
  }
};

export { DefectsReducer };
