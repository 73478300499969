import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";

const DEFAULT_MAX_CHARACTERS = 20;

class ListCell extends Component {
  render() {
    let values = this.props.value || [];
    if (values.length === 0) {
      return <div />;
    }

    let count = 0;
    let displayedValues = [];
    const maxCharacterLimit = this.props.maxCharacterLimit || DEFAULT_MAX_CHARACTERS;

    while (values.length > 0) {
      let val = values[values.length - 1];
      if (val.length + count <= maxCharacterLimit) {
        displayedValues.push(values.pop());
        count += val.length;
      } else {
        break;
      }
    }

    const hiddenValueString = values.length === 0 ? "" : " +" + values.length;

    if (hiddenValueString.length > 0) {
      return (
        <Tooltip title={<div style={{ fontSize: 15, lineHeight: 2 }}>{values.join(", ")}</div>}>
          <div>
            {displayedValues.join(", ")}
            <b>{hiddenValueString}</b>
          </div>
        </Tooltip>
      );
    } else {
      return <div>{displayedValues.join(", ")}</div>;
    }
  }
}

export default ListCell;
