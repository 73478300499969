import {
  DOWNLOAD_INSPECTION_SUCCESS,
  FETCH_STRUCTURE_STARTED,
  FETCH_STRUCTURE_SUCCESS,
  FETCH_INSPECTIONS_SUCCESS,
  FETCH_PROJECT_STARTED,
  FETCH_PROJECT_SUCCESS,
  FETCH_INSPECTIONS_STARTED,
  DOWNLOAD_INSPECTION_STARTED,
  FETCH_INSPECTIONS_ERROR,
  ADD_INSPECTION_FAILED,
  ADD_INSPECTION_STARTED,
  ADD_INSPECTION_SUCCESS,
  DELETE_INSPECTION_FAILED,
  DELETE_INSPECTION_STARTED,
  DELETE_INSPECTION_SUCCESS,
  UPDATE_INSPECTION_STARTED,
  UPDATE_INSPECTION_FAILED,
  UPDATE_INSPECTION_SUCCESS,
  FETCH_OBSERVATION_BY_TYPE_SUCCESS,
  FETCH_DEFECT_BY_COMPONENT_SUCCESS,
  FETCH_PREFERENCES_SUCCESS,
  ARCHIVE_INSPECTION_STARTED,
  ARCHIVE_INSPECTION_SUCCESS,
  ARCHIVE_INSPECTION_FAILED,
} from "./InspectionsReducer";
import { Strings } from "config/Strings";
import { showInfoDialog } from "components/Dialog/DialogAction";

import InspectionAPIController from "../../services/api/InspectionAPIController";
import StructureAPIController from "../../services/api/StructureAPIController";
import ProjectAPIController from "../../services/api/ProjectAPIController";
import PreferencesAPIController from "../../services/api/PreferencesAPIController";
import { queryClient } from "config/queryClient";

const fetchPreferencesSuccess = (data) => {
  return {
    type: FETCH_PREFERENCES_SUCCESS,
    data: data,
  };
};

const fetchObservationByTypeSuccess = (data) => {
  return {
    type: FETCH_OBSERVATION_BY_TYPE_SUCCESS,
    data: data,
  };
};

const fetchDefectByComponentSuccess = (data) => {
  return {
    type: FETCH_DEFECT_BY_COMPONENT_SUCCESS,
    data: data,
  };
};

const addInspectionToTableFailed = () => {
  return {
    type: ADD_INSPECTION_FAILED,
  };
};

const addInspectionToTableStarted = () => {
  return {
    type: ADD_INSPECTION_STARTED,
  };
};

const addInspectionToTableSuccess = (inspection) => {
  return {
    type: ADD_INSPECTION_SUCCESS,
    inspection: inspection,
  };
};

const updateInspectionInTableFailed = () => {
  return {
    type: UPDATE_INSPECTION_FAILED,
  };
};

const updateInspectionInTableStarted = () => {
  return {
    type: UPDATE_INSPECTION_STARTED,
  };
};

const updateInspectionInTableSuccess = (inspection) => {
  return {
    type: UPDATE_INSPECTION_SUCCESS,
    inspection: inspection,
  };
};

const toggleArchiveInspectionInTableFailed = () => {
  return {
    type: ARCHIVE_INSPECTION_FAILED,
  };
};

const toggleArchiveInspectionInTableStarted = () => {
  return {
    type: ARCHIVE_INSPECTION_STARTED,
  };
};

const toggleArchiveInspectionInTableSuccess = (inspection) => {
  return {
    type: ARCHIVE_INSPECTION_SUCCESS,
    inspection: inspection,
  };
};

const deleteInspectionFromTableFailed = () => {
  return {
    type: DELETE_INSPECTION_FAILED,
  };
};

const deleteInspectionFromTableStarted = () => {
  return {
    type: DELETE_INSPECTION_STARTED,
  };
};

const deleteInspectionFromTableSuccess = (inspectionId) => {
  return {
    type: DELETE_INSPECTION_SUCCESS,
    inspectionId: inspectionId,
  };
};

const fetchInspectionsTableDataSuccess = (data) => {
  return {
    type: FETCH_INSPECTIONS_SUCCESS,
    data: data,
  };
};

const fetchInspectionsTableDataStarted = () => {
  return {
    type: FETCH_INSPECTIONS_STARTED,
  };
};

const fetchInspectionsTableDataError = (code) => {
  return {
    type: FETCH_INSPECTIONS_ERROR,
    errorCode: code,
  };
};

const fetchStructureDataSuccess = (structureData) => {
  return {
    type: FETCH_STRUCTURE_SUCCESS,
    structureData: structureData,
  };
};

const fetchStructureDataStarted = () => {
  return {
    type: FETCH_STRUCTURE_STARTED,
  };
};

const fetchProjectDataSuccess = (projectData) => {
  return {
    type: FETCH_PROJECT_SUCCESS,
    projectData: projectData,
  };
};

const fetchProjectDataStarted = () => {
  return {
    type: FETCH_PROJECT_STARTED,
  };
};

const downloadInspectionReportStarted = () => {
  return {
    type: DOWNLOAD_INSPECTION_STARTED,
  };
};

const downloadInspectionReportSuccess = () => {
  return {
    type: DOWNLOAD_INSPECTION_SUCCESS,
  };
};

const downloadInspectionReport = (inspectionID) => {
  return function(dispatch) {
    dispatch(downloadInspectionReportStarted());
    InspectionAPIController.getInspectionReport(inspectionID).then((blob) => {
      if (typeof window !== "undefined") {
        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = "inspection.docx";
        a.click();
        dispatch(downloadInspectionReportSuccess());
      }
    });
  };
};

const getStructureData = (structureId) => {
  return function(dispatch) {
    if (structureId === undefined) {
      dispatch(fetchStructureDataSuccess({}));
    } else {
      dispatch(fetchStructureDataStarted());
      StructureAPIController.getStructure(structureId)
        .then((response) => {
          dispatch(fetchStructureDataSuccess(response));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

const getProjectData = (projectId) => {
  return function(dispatch) {
    if (projectId === undefined) {
      dispatch(fetchProjectDataSuccess({}));
    } else {
      dispatch(fetchProjectDataStarted());
      ProjectAPIController.getObservationByType(projectId)
        .then((response) => {
          dispatch(fetchObservationByTypeSuccess(response));
        })
        .then(
          ProjectAPIController.getDefectByComponent(projectId).then((response) => {
            dispatch(fetchDefectByComponentSuccess(response));
          })
        )
        .then(
          ProjectAPIController.getProject(projectId).then((response) => {
            dispatch(fetchProjectDataSuccess(response));
          })
        )
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

const watchInspectionsTableData = (projectId, structureId) => {
  return function(dispatch) {
    dispatch(fetchInspectionsTableDataStarted());
    InspectionAPIController.getInspections(projectId, structureId)
      .then((response) => {
        dispatch(fetchInspectionsTableDataSuccess(response));
      })
      .then(() => {
        PreferencesAPIController.getStructurePreferences().then((response) => {
          dispatch(fetchPreferencesSuccess(response));
          queryClient.setQueryData(['dictionary'], response); 
        });
      })
      .catch((error) => {
        if (error.code === 401) {
          dispatch(fetchInspectionsTableDataError(error.code));
        }
      });
  };
};

const addInspection = (projectId, structureId, spansCount, inspectorIds, startDate) => {
  return function(dispatch) {
    dispatch(addInspectionToTableStarted());
    InspectionAPIController.addInspection(projectId, structureId, spansCount, startDate)
      .then((response) => {
        InspectionAPIController.updateInspectorsInInspection(response.uuid, inspectorIds)
          .then((inspectorResponse) => {
            var outputResponse = response;
            outputResponse["inspectors"] = inspectorResponse["inspectors"];
            dispatch(addInspectionToTableSuccess(outputResponse));
          })
          .catch((error) => {
            dispatch(addInspectionToTableFailed());
            dispatch(showInfoDialog(Strings.getInstance().getString("dialog_error_add_inspection_title"), Strings.getInstance().getString("dialog_error_add_inspection_description")));
          });
      })
      .catch((error) => {
        dispatch(addInspectionToTableFailed());
        dispatch(showInfoDialog(Strings.getInstance().getString("dialog_error_add_inspection_title"), Strings.getInstance().getString("dialog_error_add_inspection_description")));
      });
  };
};

const updateInspection = (inspectionId, startDate, projectId, structureId, spansCount, inspectorIds, status, termRating, generalSummary, endDate) => {
  return function(dispatch) {
    dispatch(updateInspectionInTableStarted());
    InspectionAPIController.updateInspectorsInInspection(inspectionId, inspectorIds)
      .then(() => {
        InspectionAPIController.updateInspection(
          inspectionId,
          startDate,
          projectId,
          structureId,
          spansCount,
          status,
          termRating,
          generalSummary,
          endDate
        )
          .then((response) => {
            dispatch(updateInspectionInTableSuccess(response));
          })
          .catch((error) => {
            dispatch(updateInspectionInTableFailed());
            dispatch(showInfoDialog(Strings.getInstance().getString("dialog_error_update_inspection_title"), Strings.getInstance().getString("dialog_error_update_inspection_description")));
          });
      })
      .catch((error) => {
        dispatch(updateInspectionInTableFailed());
        dispatch(showInfoDialog(Strings.getInstance().getString("error_update_inspection_title"), Strings.getInstance().getString("error_update_inspection_description")));
      });
  };
};

const archiveInspection = (inspectionId, archived) => {
  return function(dispatch) {
    dispatch(toggleArchiveInspectionInTableStarted());
    InspectionAPIController.archiveInspection(inspectionId, archived)
      .then((response) => {
        dispatch(toggleArchiveInspectionInTableSuccess(response));
      })
      .catch((error) => {
        dispatch(toggleArchiveInspectionInTableFailed());
        dispatch(showInfoDialog(Strings.getInstance().getString("dialog_error_archive_inspection_title"), Strings.getInstance().getString("dialog_error_archive_inspection_description")));
      });
  };
};

const deleteInspection = (inspectionId) => {
  return function(dispatch) {
    dispatch(deleteInspectionFromTableStarted());
    InspectionAPIController.deleteInspection(inspectionId)
      .then((response) => {
        dispatch(deleteInspectionFromTableSuccess(inspectionId));
      })
      .catch((error) => {
        dispatch(deleteInspectionFromTableFailed());
        dispatch(showInfoDialog(Strings.getInstance().getString("dialog_error_delete_inspection_title"), Strings.getInstance().getString("dialog_error_delete_inspection_description")));
      });
  };
};

export {
  watchInspectionsTableData,
  downloadInspectionReport,
  getProjectData,
  getStructureData,
  addInspection,
  updateInspection,
  deleteInspection,
  archiveInspection,
};
