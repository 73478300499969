import { Chip, Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { ArrowRight } from 'baseui/icon';
import { SIZE, Tag } from 'baseui/tag';
import Box from 'components/Box';
import MultilineEditTextTableField from 'components/MultilineEditTextTableField';
import MultilineTextTableField from 'components/MultilineTextTableField';
import SecuredImage from 'components/SecuredImage';
import Table from 'components/Table';
import Text from 'components/Text';
import { Strings } from 'config/Strings';
import ColumnBuilder from 'helper/ColumnBuilder';
import React, { Component } from 'react';
import * as yup from 'yup';
import { VisibilityFlag } from '../../../config/VisibilityFlag';
import TokenManager from '../../../storage/TokenManager';
import { DefectsImageGallery } from './DefectsImageGallery';
import { PositioningRange, positionRangeSchema } from './PostioningRange';
import SubdivisionSelect from './SubdivisionSelect';
import UploadDefectImage from './UploadDefectImage';
import NewInspectionsModal from './NewInspectionsModal';

export const FIELD_ID = 'id';
export const FIELD_SUB_COMPONENT_UUID = 'sub_component_uuid';
export const FIELD_THUMBNAIL_IMAGE_LINK = 'thumb_link';
export const FIELD_IMAGES = 'images';

export const FIELD_DEFECT_NUMBER = 'defect_number';
export const FIELD_DEFECT_STATUS = 'status';
export const FIELD_DEFECT_FINDINGS = 'critical_findings';
export const FIELD_COMPONENT = 'component';
export const FIELD_SUB_COMPONENT = 'sub_component';
export const FIELD_DEFECT_CLASSIFICATION = 'defect_classification';
export const FIELD_SPAN = 'span';
export const FIELD_STATIONING = 'stationing';
export const FIELD_CLOCK_POSITION = 'clock_position';
export const FIELD_DEFECT_TYPE = 'defect_type';
export const FIELD_DESCRIPTION = 'description';
export const FIELD_DIMENSION = 'dimension';
export const FIELD_CORRECTIVE_ACTION_PLAN = 'corrective_action_plan';
export const FIELD_CS_RATING = 'cs_rating';
export const FIELD_INSPECTOR_NAME = 'inspector_name';
export const FIELD_REPAIR_METHOD = 'repair_method';
export const FIELD_REPAIR_BY_DATE = 'repair_by_date';
export const FIELD_PREVIOUS_DEFECT = 'previous_defect';
export const FIELD_INSPECTED_DATE = 'inspected_date';

class ObservationsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowDataSelectedForUpload: undefined,
      multipleSelect: false,
      isNewInspectionModalOpen: false,
    };
  }

  handleNewInspectionModalClose(reload) {
    this.setState({ isNewInspectionModalOpen: false });
    if (reload) {
      this.props.onObservationsRefreshCallback();
    }
  }

  lookupSubComponentUUID(subComponentId) {
    const list = this.props.preferences['sub_to_uuid'] || {};
    return list[subComponentId];
  }

  getLookupValues() {
    var result = {};
    for (var i = 0; i < arguments.length; i++) {
      for (const [key, value] of Object.entries(arguments[i])) {
        if (key !== undefined) {
          result[key] = value === undefined ? '' : value['name'];
        }
      }
    }

    return result;
  }

  getColumns = () => {
    if (this.props.columns !== undefined) {
      return this.props.columns;
    }

    const componentsLookupValues = this.getLookupValues(
      this.props.preferences['components'],
    );
    const getVisibility = (x) => VisibilityFlag.getInstance().isVisible(x);

    var columns = [];
    if (getVisibility('inspection_observations_table_images')) {
      columns.push(
        new ColumnBuilder(
          Strings.getInstance().getString(
            'inspection_observations_table_images',
          ),
          FIELD_THUMBNAIL_IMAGE_LINK,
        )
          .editable('never')
          .render((rowData) => {
            return (
              <DefectsImageGallery
                defect={rowData.rawDefect}
                observation={rowData.rawObservation}
              />
            );
          })
          .build(),
      );
    }

    if (TokenManager.isAdmin()) {
      columns.push(
        new ColumnBuilder('UUID', FIELD_ID).editable('never').hidden().build(),
      );
    }

    if (getVisibility('inspection_observations_table_observation_id')) {
      columns.push(
        new ColumnBuilder(
          Strings.getInstance().getString(
            'inspection_observations_table_observation_id',
          ),
          FIELD_DEFECT_NUMBER,
        )
          .editable('never')
          .build(),
      );
    }

    if (getVisibility('inspection_observations_table_component')) {
      columns.push(
        new ColumnBuilder(
          Strings.getInstance().getString(
            'inspection_observations_table_component',
          ),
          FIELD_COMPONENT,
        )
          .lookup(componentsLookupValues)
          .editComponent((props) => {
            return (
              <Select
                {...props}
                value={props.value === undefined ? '' : props.value}
                onChange={(event) => {
                  var data = { ...props.rowData };
                  data[FIELD_COMPONENT] = event.target.value;
                  data[FIELD_SUB_COMPONENT] = '';
                  data[FIELD_DEFECT_TYPE] = '';
                  props.onRowDataChange(data);
                }}
                style={{
                  fontSize: 13,
                }}
              >
                {Object.keys(componentsLookupValues).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {componentsLookupValues[key]}
                    </MenuItem>
                  );
                })}
              </Select>
            );
          })
          .build(),
      );
    }

    if (getVisibility('inspection_observations_table_sub_component')) {
      columns.push(
        new ColumnBuilder(
          Strings.getInstance().getString(
            'inspection_observations_table_sub_component',
          ),
          FIELD_SUB_COMPONENT,
        )
          .lookup(this.getLookupValues(this.props.preferences['subcomponents']))
          .editComponent((props) => {
            return (
              <Select
                {...props}
                value={props.value === undefined ? '' : props.value}
                onChange={(event) => {
                  var data = { ...props.rowData };
                  data[FIELD_SUB_COMPONENT] = event.target.value;
                  data[FIELD_DEFECT_TYPE] = '';
                  data[FIELD_SUB_COMPONENT_UUID] = this.lookupSubComponentUUID(
                    event.target.value,
                  );
                  props.onRowDataChange(data);
                }}
                style={{
                  fontSize: 13,
                }}
              >
                {Object.keys(props.columnDef.lookup).map((key) => {
                  if (
                    this.props.preferences !== undefined &&
                    this.props.preferences['components'][
                      props.rowData[FIELD_COMPONENT]
                    ] !== undefined &&
                    (
                      this.props.preferences['components'][
                        props.rowData[FIELD_COMPONENT]
                      ]['subcomponents'] || []
                    ).includes(key)
                  ) {
                    return (
                      <MenuItem key={key} value={key}>
                        {props.columnDef.lookup[key]}
                      </MenuItem>
                    );
                  } else {
                    return undefined;
                  }
                })}
              </Select>
            );
          })
          .build(),
      );
    }

    columns.push(
      new ColumnBuilder('Status', FIELD_DEFECT_STATUS)
        .lookup({
          UNCHANGED: 'UNCHANGED',
          CHANGED: 'CHANGED',
          REPAIRED: 'REPAIRED',
          NEW: 'NEW',
          CLONED: 'CLONED',
        })
        .editComponent((props) => {
          return (
            <Select
              {...props}
              value={props.value ? props.value : !!props.rowData.rawDefect?.previousDefectNumber ? 'CLONED' : ""}
              onChange={(event) => {
                let value = event.target.value;

                if (value === 'CLONED') {
                  props.rowData.rawDefect.status = "";
                  props.rowData.status = "";
                }

                props.onChange(value);
              }}
              style={{
                fontSize: 13,
              }}
            >
              {Object.keys(props.columnDef.lookup).map((key) => {
                return (
                  <MenuItem key={key} value={key}>
                    {props.columnDef.lookup[key]}
                  </MenuItem>
                );
              })}               
            </Select>
          );
        })
        .customSort((a, b) => {
          const statusA = a.rawDefect?.status ?? '';
          const statusB = b.rawDefect?.status ?? '';
          return statusA.length - statusB.length;
        })
        .filterAndSearch((term = [], rowData) => {
          let status = rowData.rawDefect?.status ?? '';
          return term.includes(status);
        })
        .render((rowData) => {
          let status = rowData.rawDefect?.status ?? '';
          const isCloned = !!rowData.rawDefect.previousDefectNumber && !status;

          if(rowData.defect_number.includes("BR-B01-D-002"))
          {
            console.log("rowData", rowData.status);
           }

          /**
           * We override this value since both
           * `cloned` and `Reinspected: Not Changed` both have
           * a `UNCHANGED` status from the API. so we need to differentiate
           * it to have proper badge colors.
           */
          if (isCloned) {
            status = 'CLONED';
          }

          const STATUS_COLORS = {
            cloned: '#D04D4B',
            unchanged: '#E8E074',
            changed: '#E8E074',
            repaired: '#E8E074',
            new: '#91CB8F',
          };
          if (status) {
            return (
              <Tag
                variant="solid"
                size={SIZE.small}
                closeable={false}
                overrides={{
                  Root: {
                    style: {
                      backgroundColor:
                        STATUS_COLORS[status.toLowerCase()] ?? undefined,
                    },
                  },
                }}
              >
                {status}
              </Tag>
            );
          }
          return '---';
        })
        .build(),
    );

    columns.push(
      new ColumnBuilder('Subdivision Number', 'subdivisionUUID')
        .customSort((a, b) => {
          const numberA = a.rawDefect?.structureSubdivision?.number ?? '';
          const numberB = b.rawDefect?.structureSubdivision?.number ?? '';
          return numberA.length - numberB.length;
        })
        .render((data) => {
          const number = data.rawDefect?.structureSubdivision?.number;
          return number ?? '---';
        })
        .editComponent((props) => {
          const value = props.rowData.rawDefect?.structureSubdivisionId;

          return (
            <SubdivisionSelect
              value={value}
              onChange={(value) => {
                props.onChange(value);
              }}
            />
          );
        })
        .build(),
    );

    if (getVisibility('inspection_observations_table_class')) {
      columns.push(
        new ColumnBuilder(
          Strings.getInstance().getString(
            'inspection_observations_table_class',
          ),
          FIELD_DEFECT_CLASSIFICATION,
        )
          .lookup({
            MAINTENANCE: Strings.getInstance().mapString(
              'observation_class',
              'NSMI',
            ),
            STRUCTURAL: Strings.getInstance().mapString(
              'observation_class',
              'SD',
            ),
          })
          .hidden()
          .build(),
      );
    }

    if (TokenManager.hasBridges()) {
      if (getVisibility('inspection_observations_table_location')) {
        columns.push(
          new ColumnBuilder(
            Strings.getInstance().getString(
              'inspection_observations_table_location',
            ),
            FIELD_SPAN,
          ).build(),
        );
      }
      if (getVisibility('inspection_observations_table_stationing')) {
        columns.push(
          new ColumnBuilder(
            Strings.getInstance().getString(
              'inspection_observations_table_stationing',
            ),
            FIELD_STATIONING,
          )
            .hidden()
            .build(),
        );
      }
      if (getVisibility('inspection_observations_table_clock_position')) {
        columns.push(
          new ColumnBuilder(
            Strings.getInstance().getString(
              'inspection_observations_table_clock_position',
            ),
            FIELD_CLOCK_POSITION,
          )
            .editComponent((props) => {
              const value = props.value;
              let parsedValue = undefined;
              if (Number(value)) {
                parsedValue = [[value, '']];
              } else {
                parsedValue = JSON.parse(value ? value : '[]');
              }
              return (
                <PositioningRange
                  initialValue={parsedValue}
                  onChange={(value) => props.onChange(JSON.stringify(value))}
                />
              );
            })
            .render((rowData) => {
              const positions = JSON.parse(
                rowData[FIELD_CLOCK_POSITION] ?? '[]',
              );

              if (Array.isArray(positions)) {
                if (positions.length == 0) {
                  return <Text>---</Text>;
                }

                return (
                  <Box css={{ spaceY: '$scale100', width: 'min-content' }}>
                    {positions.slice(0, 2).map((position) => (
                      <Text
                        css={{
                          backgroundColor: '$primary50',
                          borderRadius: '$full',
                          layout: 'row',
                          spaceX: '$scale100',
                          alignItems: 'center',
                          py: '$scale100',
                          px: '$scale400',
                        }}
                      >
                        <Text css={{ flex: 1 }} weight="bold">
                          {position[0]}
                        </Text>
                        {!!position[1] && (
                          <>
                            <ArrowRight size={24} />
                            <Text css={{ flex: 1 }} weight="bold">
                              {position[1]}
                            </Text>
                          </>
                        )}
                      </Text>
                    ))}
                    {positions.length > 2 && (
                      <Text>+{positions.length - 2} more</Text>
                    )}
                  </Box>
                );
              } else {
                return <Text>---</Text>;
              }
            })
            .build(),
        );
      }
      if (getVisibility('inspection_observations_table_corrective_action')) {
        columns.push(
          new ColumnBuilder(
            Strings.getInstance().getString(
              'inspection_observations_table_corrective_action',
            ),
            FIELD_CORRECTIVE_ACTION_PLAN,
          )
            .lookup({
              CRITICAL: 'A - Critical',
              PRIORITY: 'B - Priority',
              ROUTINE: 'C - Routine',
              MONITOR: 'D - Monitor',
            })
            .hidden()
            .build(),
        );
      }
      if (
        getVisibility('inspection_observations_table_previous_defect_number')
      ) {
        columns.push(
          new ColumnBuilder(
            Strings.getInstance().getString(
              'inspection_observations_table_previous_defect_number',
            ),
            FIELD_PREVIOUS_DEFECT,
          )
            .hidden()
            .build(),
        );
      }
      if (getVisibility('inspection_observations_table_inspected_date')) {
        columns.push(
          new ColumnBuilder(
            Strings.getInstance().getString(
              'inspection_observations_table_inspected_date',
            ),
            FIELD_INSPECTED_DATE,
          )
            .type('date')
            .editable('never')
            .hidden()
            .build(),
        );
      }
    }
    if (getVisibility('inspection_observations_table_defect_type')) {
      columns.push(
        new ColumnBuilder(
          Strings.getInstance().getString(
            'inspection_observations_table_defect_type',
          ),
          FIELD_DEFECT_TYPE,
        )
          .lookup(
            this.getLookupValues(
              this.props.preferences['defects'],
              this.props.preferences['nonDefectObservations'],
            ),
          )
          .editComponent((props) => {
            return (
              <Select
                {...props}
                value={props.value === undefined ? '' : props.value}
                onChange={(event) => props.onChange(event.target.value)}
                style={{
                  fontSize: 13,
                }}
              >
                {Object.keys(props.columnDef.lookup).map((key) => {
                  if (
                    this.props.preferences !== undefined &&
                    this.props.preferences['subcomponents'][
                      props.rowData[FIELD_SUB_COMPONENT]
                    ] !== undefined &&
                    ((props.rowData[FIELD_DEFECT_CLASSIFICATION] ===
                      'STRUCTURAL' &&
                      (
                        this.props.preferences['subcomponents'][
                          props.rowData[FIELD_SUB_COMPONENT]
                        ]['defects'] || []
                      ).includes(key)) ||
                      (props.rowData[FIELD_DEFECT_CLASSIFICATION] ===
                        'MAINTENANCE' &&
                        (
                          this.props.preferences['subcomponents'][
                            props.rowData[FIELD_SUB_COMPONENT]
                          ]['nonDefectObservations'] || []
                        ).includes(key)))
                  ) {
                    return (
                      <MenuItem key={key} value={key}>
                        {props.columnDef.lookup[key]}
                      </MenuItem>
                    );
                  } else {
                    return undefined;
                  }
                })}
              </Select>
            );
          })
          .build(),
      );
    }

    columns.push(
      new ColumnBuilder('Critical Findings', 'criticalFindings', false)
        .lookup(
          this.getLookupValues(
            this.props.preferences.criticalFindings.map((item) => ({
              ...item,
              name: item.finding,
            })),
          ),
        )
        .filterAndSearch((term, rowData) => {
          if (!Array.isArray(term)) return true;
          if (Array.isArray(term) && term.length === 0) return true;
          if (!Array.isArray(rowData.criticalFindings)) return false;

          return rowData.criticalFindings.some((finding) =>
            term.find((value) => value.toUpperCase() === finding.toUpperCase()),
          );
        })
        .render((rowData) => {
          const findings = Array.isArray(rowData.criticalFindings)
            ? rowData.criticalFindings
            : [];
          if (findings.length === 0) {
            return <Text>---</Text>;
          }

          return rowData.criticalFindings.map((finding) => (
            <Tag size={SIZE.small} closeable={false}>
              {finding}
            </Tag>
          ));
        })
        .editComponent((props) => {
          const options =
            this.props.preferences.criticalFindings.map((item) => ({
              name: item.finding,
              value: item.value,
            })) ?? [];
          const parsedValue = props.value?.length
            ? props.value
                .map((itemValue) =>
                  options.find((item) => {
                    if (itemValue?.value) {
                      return (
                        item.name === itemValue.name ||
                        item.value === itemValue.name
                      );
                    }
                    return item.name === itemValue || item.value === itemValue;
                  }),
                )
                .filter(Boolean)
                .map((item) => item.value)
            : [];

          return (
            <Select
              onChange={(e) => {
                props.onChange(
                  this.props.preferences.criticalFindings
                    .filter((item) => e.target.value.includes(item.value))
                    .map((item) => ({
                      name: item.finding,
                      value: item.value,
                    })),
                );
              }}
              multiple
              value={parsedValue}
              renderValue={(selected) => (
                <Box
                  css={{
                    display: 'flex',
                    spaceX: '$scale200',
                    spaceY: '$scale200',
                    flexWrap: 'wrap',
                  }}
                >
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={
                        options.find((item) => item.value === value)?.name ?? ''
                      }
                    />
                  ))}
                </Box>
              )}
            >
              {options.map((item) => (
                <MenuItem key={item} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          );
        })
        .build(),
    );
    if (getVisibility('inspection_observations_table_description')) {
      columns.push(
        new ColumnBuilder(
          Strings.getInstance().getString(
            'inspection_observations_table_description',
          ),
          FIELD_DESCRIPTION,
        )
          .hidden()
          .render((rowData) => {
            return (
              <MultilineTextTableField value={rowData[FIELD_DESCRIPTION]} />
            );
          })
          .editComponent((props) => {
            return (
              <MultilineEditTextTableField
                value={props.value}
                onChange={(value) => {
                  var data = { ...props.rowData };
                  data[FIELD_DESCRIPTION] = value;
                  props.onRowDataChange(data);
                }}
              />
            );
          })
          .build(),
      );
    }
    if (getVisibility('inspection_observations_table_dimension')) {
      columns.push(
        new ColumnBuilder(
          Strings.getInstance().getString(
            'inspection_observations_table_dimension',
          ),
          FIELD_DIMENSION,
        )
          .type('numeric')
          .hidden()
          .render((rowData) => {
            let unit =
              this.props.preferences['subcomponents'][rowData['sub_component']];
            if (rowData[FIELD_DIMENSION] === undefined) {
              return '---';
            }
            return rowData[FIELD_DIMENSION].concat(' ', unit.measureUnit);
          })
          .build(),
      );
    }
    if (getVisibility('inspection_observations_table_cs_rating')) {
      columns.push(
        new ColumnBuilder(
          Strings.getInstance().getString(
            'inspection_observations_table_cs_rating',
          ),
          FIELD_CS_RATING,
        )
          .lookup(this.getLookupValues(this.props.preferences['conditions']))
          .editComponent((props) => {
            return (
              <Select
                {...props}
                value={props.value === undefined ? '' : props.value}
                onChange={(event) => props.onChange(event.target.value)}
                style={{
                  fontSize: 13,
                }}
              >
                {Object.keys(props.columnDef.lookup).map((key) => {
                  if (
                    this.props.preferences !== undefined &&
                    this.props.preferences['defects'][
                      props.rowData[FIELD_DEFECT_TYPE]
                    ] !== undefined &&
                    (
                      this.props.preferences['defects'][
                        props.rowData[FIELD_DEFECT_TYPE]
                      ]['conditions'] || []
                    ).includes(key)
                  ) {
                    return (
                      <MenuItem key={key} value={key}>
                        {props.columnDef.lookup[key]}
                      </MenuItem>
                    );
                  } else {
                    return undefined;
                  }
                })}
              </Select>
            );
          })
          .build(),
      );
    }
    if (getVisibility('inspection_observations_table_reported_by')) {
      columns.push(
        new ColumnBuilder(
          Strings.getInstance().getString(
            'inspection_observations_table_reported_by',
          ),
          FIELD_INSPECTOR_NAME,
        )
          .editable('never')
          .hidden()
          .build(),
      );
    }
    if (getVisibility('inspection_observations_table_repair_method')) {
      columns.push(
        new ColumnBuilder(
          Strings.getInstance().getString(
            'inspection_observations_table_repair_method',
          ),
          FIELD_REPAIR_METHOD,
        ).build(),
      );
    }
    if (getVisibility('inspection_observations_table_repair_date')) {
      columns.push(
        new ColumnBuilder(
          Strings.getInstance().getString(
            'inspection_observations_table_repair_date',
          ),
          FIELD_REPAIR_BY_DATE,
        ).build(),
      );
    }

    return columns;
  };

  getActions = () => {
    const isMultipleSelect = this.state.multipleSelect;
    var actions = [
      {
        icon: 'create',
        position: 'toolbarOnSelect',
        tooltip: 'Update Multiple',
        // isFreeAction: true,
        onClick: (event, rowData) => {
          const handler = this.props.onMultipleSelect;
          if (handler instanceof Function) {
            handler(rowData);
          }
        },
      },
    ];

    actions.push(
      {
        icon: 'refresh',
        tooltip: 'Refresh Data',
        isFreeAction: 'true',
        onClick: () => {
          this.props.onObservationsRefreshCallback();
        },
      },
      {
        icon: 'add',
        tooltip: 'Add new Observation',
        isFreeAction: 'true',
        onClick: () => {
          this.setState({ isNewInspectionModalOpen: true });
        },
      },
      {
        icon: 'image',
        tooltip: 'Download Photos',
        isFreeAction: 'true',
        onClick: () => {
          this.props.onDownloadImagesCallback();
        },
      },
    );

    if (this.props.isEditable) {
      // actions.push({
      //   icon: 'add_photo_alternate_outlined',
      //   tooltip: 'Add images',
      //   position: 'row',
      //   onClick: (e, row) => {
      //     this.showOpenFileDlg(e, row);
      //   },
      // });
      actions.push({
        action: (rowData) => ({
          icon: () => <UploadDefectImage rowData={rowData} />,
          position: 'row',
          onClick: () => {},
        }),
        position: 'row',
      });
      actions.push({
        icon: 'delete_outlined',
        tooltip: 'Remove',
        position: 'row',
        onClick: (event, rowData) => {
          this.props.onObservationRemoveCallback(rowData);
        },
      });
    }

    return actions;
  };

  getEditable = () => {
    if (!this.props.isEditable) {
      return {};
    } else {
      return {
        onRowUpdate: (newData, oldData) =>
          new Promise(async (resolve, reject) => {
            try {
              const validated = await positionRangeSchema.validate({
                ranges: JSON.parse(newData[FIELD_CLOCK_POSITION]),
              });

              if (newData.subdivisionUUID == newData.subdivisionNumber) {
                // fix bug when the user edits the row and doesn't change the subdivision
                newData.subdivisionUUID =
                  newData.rawDefect.structureSubdivisionId;
              }

              this.props.onObservationUpdateCallback(
                {
                  ...newData,
                  [FIELD_CLOCK_POSITION]: JSON.stringify(
                    validated.ranges.map((range) => [
                      range[0],
                      range?.[1] ?? '',
                    ]),
                  ),
                },
                oldData,
              );
              resolve();
            } catch (error) {
              console.log('error?.errors', error);
              reject();
            }
          }),
      };
    }
  };

  showOpenFileDlg = (event, rowData) => {
    this.setState({ rowDataSelectedForUpload: rowData });
    this.refs.fileUploader.click();
  };

  onFileChange = (event) => {
    if (event.target.files === undefined || event.target.files.length > 0) {
      this.props.onUploadImage(
        event.target.files[0],
        this.state.rowDataSelectedForUpload,
      );
    }
  };

  render() {
    return (
      <div>
        <input
          ref="fileUploader"
          type="file"
          style={{ display: 'none' }}
          onChange={this.onFileChange}
          accept="image/x-png,image/gif,image/jpeg"
        />

        {this.state.isNewInspectionModalOpen ? (
          <NewInspectionsModal
            onClose={(reload) => this.handleNewInspectionModalClose(reload)}
            isOpen={true}
            currentInspection={this.props.currentInspection}
            preferences={this.props.preferences}
          />
        ) : null}

        <Table
          id="OBSERVATIONS_TABLE"
          title="Observations"
          columns={this.getColumns()}
          options={{
            actionsColumnIndex: -1,
            // selection: this.state.multipleSelect,
            selection: true,
          }}
          data={this.props.data}
          actions={this.getActions()}
          editable={this.state.multipleSelect ? false : this.getEditable()}
          isLoading={this.props.loading}
        />
      </div>
    );
  }
}

export default ObservationsTable;
