import React, { Component } from "react";
import Table from "components/Table";
import { connect } from "react-redux";
import { watchUsersTableData, addUser, updateUser, deleteUser } from "./UsersAction";
import { showInfoDialog, showConfirmationDialog } from "components/Dialog/DialogAction";
import ValidatorArray from "helper/Validator/ValidatorArray";
import ValidatorFieldBuilder, { TYPE } from "helper/Validator/ValidatorFieldBuilder";
import UsersMapper from "./UsersMapper";
import PasswordDialog from "components/Dialog/PasswordDialog";

import ColumnBuilder from "../../helper/ColumnBuilder";
import { Strings } from "../../config/Strings";

export const FIELD_ID = "id";
export const FIELD_FIRST_NAME = "first_name";
export const FIELD_LAST_NAME = "last_name";
export const FIELD_EMAIL = "email";
export const FIELD_ADMIN = "admin";
export const FIELD_COMPANY_ID = "companyId";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      showPasswordDialog: false,
      pendingNewUser: {},
      viewingAdminUsers: this.isViewingAdminUsers(),
    };
  }

  viewOnly = this.props.viewOnly;

  isViewingAdminUsers() {
    if (this.props.companyId === undefined) {
      return true;
    }
    return false;
  }

  handlePasswordConfirmationClick = (password) => {
    this.props.addUser(
      this.state.pendingNewUser.first_name,
      this.state.pendingNewUser.last_name,
      this.state.pendingNewUser.email,
      this.state.pendingNewUser.admin,
      password,
      this.props.companyId
    );
    this.setState({ pendingNewUser: {} });
    this.togglePasswordDialog(false);
  };

  handlePendingNewUser(newData) {
    this.setState({ pendingNewUser: newData });
    this.togglePasswordDialog(true);
  }

  togglePasswordDialog(toggle) {
    this.setState({ showPasswordDialog: toggle });
  }

  validateFields = (firstName, lastName, email, admin) => {
    var validator = new ValidatorArray();
    validator.push(new ValidatorFieldBuilder(firstName, TYPE.TEXT).canBeEmpty(false).build());
    validator.push(new ValidatorFieldBuilder(lastName, TYPE.TEXT).canBeEmpty(false).build());
    validator.push(new ValidatorFieldBuilder(email, TYPE.EMAIL).canBeEmpty(false).build());
    if (this.state.viewingAdminUsers) {
      validator.push(new ValidatorFieldBuilder(admin, TYPE.NUMBER).canBeEmpty(false).build());
    }
    return validator;
  };

  getColumns = () => {
    var columns = [];
    columns.push(new ColumnBuilder(Strings.getInstance().getString("users_table_first_name"), FIELD_FIRST_NAME).build());
    columns.push(new ColumnBuilder(Strings.getInstance().getString("users_table_last_name"), FIELD_LAST_NAME).build());
    columns.push(new ColumnBuilder(Strings.getInstance().getString("users_table_email"), FIELD_EMAIL).build());
    if (this.state.viewingAdminUsers)
      columns.push(new ColumnBuilder(Strings.getInstance().getString("users_table_admin"), FIELD_ADMIN).lookup({ 0: "No", 1: "Yes" }).build());
    return columns;
  };

  componentDidMount() {
    this.props.watchUsersTableData(this.props.companyId);
  }

  getActions = () => {
    var actions = [];
    actions.push({
      icon: "refresh",
      tooltip: "Refresh Data",
      isFreeAction: "true",
      onClick: () => {
        this.props.watchUsersTableData(this.props.companyId);
      },
    });
    if (!this.viewOnly) {
      actions.push({
        icon: "delete_outlined",
        tooltip: "Remove",
        onClick: (event, rowData) => {
          const pendingAction = deleteUser(rowData["id"]);
          this.props.confirmDeleteUser(pendingAction);
        },
      });
    }
    return actions;
  };

  getEditable = () => {
    if (this.viewOnly) {
      return {};
    } else {
      return this.editable;
    }
  };

  editable = {
    onRowAdd: (newData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          let validator = this.validateFields(newData[FIELD_FIRST_NAME], newData[FIELD_LAST_NAME], newData[FIELD_EMAIL], newData[FIELD_ADMIN]);

          if (validator.validate()) {
            resolve();
            this.handlePendingNewUser(newData);
          } else {
            this.props.showError("Invalid Input", validator.generateColumnErrorDescription(this.getColumns()));
            reject();
          }
        }, 100);
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          let validator = this.validateFields(newData[FIELD_FIRST_NAME], newData[FIELD_LAST_NAME], newData[FIELD_EMAIL], newData[FIELD_ADMIN]);
          if (validator.validate()) {
            resolve();
            this.props.updateUser(
              oldData[FIELD_ID],
              newData[FIELD_FIRST_NAME],
              newData[FIELD_LAST_NAME],
              newData[FIELD_EMAIL],
              newData[FIELD_ADMIN],
              oldData[FIELD_COMPANY_ID]
            );
          } else {
            this.props.showError("Invalid Input", validator.generateColumnErrorDescription(this.getColumns()));
            reject();
          }
        }, 100);
      }),
  };

  render() {
    return (
      <div>
        <PasswordDialog
          open={this.state.showPasswordDialog}
          title={Strings.getInstance().getString("dialog_dialog_error_delete_inspection_description")}
          description={Strings.getInstance().getString("dialog_create_new_password_description")}
          confirmInputCallback={this.handlePasswordConfirmationClick}
          dismissDialogCallback={() => this.togglePasswordDialog(false)}
        />
        <Table
          id="USERS_TABLE"
          title={Strings.getInstance().getString("users_table_title")}
          columns={this.getColumns()}
          options={{ actionsColumnIndex: -1 }}
          data={this.props.data}
          actions={this.getActions()}
          editable={this.getEditable()}
          onRowClick={this.onRowClick}
          isLoading={this.props.loading}
          disableHideColumnOption
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: UsersMapper.mapMultipleRows(state.UsersReducer.data),
    loading: state.UsersReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    watchUsersTableData: (companyId) => dispatch(watchUsersTableData(companyId)),
    addUser: (firstName, lastName, email, admin, password, companyId) => dispatch(addUser(firstName, lastName, email, admin, password, companyId)),
    updateUser: (id, firstName, lastName, email, admin, companyId) => dispatch(updateUser(id, firstName, lastName, email, admin, companyId)),
    showError: (title, description) => dispatch(showInfoDialog(title, description)),
    confirmDeleteUser: (pendingAction) =>
      dispatch(showConfirmationDialog(Strings.getInstance().getString("dialog_delete_user_title"), Strings.getInstance().getString("dialog_delete_user_description"), pendingAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
