import React, { Component } from "react";
import EditableTreeItem from "./EditableTreeItem";
import { TYPE } from "helper/Validator/ValidatorFieldBuilder";
import { Strings } from "config/Strings";

export default class DefectItem extends Component {
  fields = [
    {
      label: Strings.getInstance().getString("preferences_defect_name"),
      name: "name",
      style: { marginRight: 30, flex: 3 },
      type: TYPE.ANY,
    },
    {
      label: Strings.getInstance().getString("preferences_defect_number"),
      name: "number",
      style: { flex: 1 },
      type: TYPE.NUMBER,
    },
  ];

  render() {
    return <EditableTreeItem nodeId={this.props.nodeId} data={this.props.data} fields={this.fields} {...this.props} />;
  }
}
