export const TOGGLE_MOBILE_OPEN = "TOGGLE_MOBILE_OPEN";

const initialState = {
    mobileOpen: false,
}
  
const MenuReducer = (state = initialState, action) => {
  switch(action.type) {
    case TOGGLE_MOBILE_OPEN:
      return { ...state, mobileOpen: !state.mobileOpen};
    default:
      return state;
  }
}
  
export {MenuReducer}