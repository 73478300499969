import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { KIND as ButtonKind } from 'baseui/button';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal';

import { Banner, KIND } from 'components/Banner';

import { useNewSubdivision } from 'services/api/inspection/mutation';

import SubDivisionForm from '../features/SubdivisionForm';
import { getErrorMessage } from 'utils/error';
import { useStitches } from 'theme';

export default function NewSubdivision({
  trigger,
  isOpen: isModalOpen = false,
}: {
  trigger: (args: { toggle: (open: boolean) => void }) => React.ReactNode;
  isOpen?: boolean;
}) {
  const { css } = useStitches();
  const [isOpen, setIsOpen] = useState(isModalOpen);
  const queryClient = useQueryClient();
  const { inspectionId } = useParams<{ inspectionId: string }>();
  const newSubdivision = useNewSubdivision();
  const errorMessage = JSON.parse(getErrorMessage(newSubdivision.error))
    ?.message;
  const handleClose = () => {
    newSubdivision.reset();
    if (newSubdivision.isLoading) return;
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(isModalOpen);
  }, [isModalOpen]);

  return (
    <>
      {trigger({ toggle: (open = !isOpen) => setIsOpen(open) })}
      <Modal
        onClose={() => handleClose()}
        isOpen={isOpen}
        overrides={{
          Dialog: {
            style: {
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              height: '80vh',
            },
          },
        }}
      >
        <ModalHeader>New Sub-division</ModalHeader>
        <ModalBody
          className={css({ flex: 1, overflow: 'hidden', layout: 'stack' })}
        >
          <Banner
            show={newSubdivision.isError}
            title="Error"
            kind={KIND.negative}
          >
            Failed to add subdivision {errorMessage}, try again.
          </Banner>
          <div className={css({ flex: 1, overflowY: 'auto' })}>
            <SubDivisionForm
              onSubmit={data => {
                const errorShape =
                  newSubdivision.error instanceof Error
                    ? JSON.parse(newSubdivision.error.message)
                    : '';
                const isAllocationError =
                  errorShape?.type === 'ALLOCATION_ERROR';
                const createdUUID = isAllocationError ? errorShape.id : '';

                newSubdivision.mutate(
                  {
                    payload: {
                      inspectionId: data.inspectionId,
                      name: data.name,
                      number: data.number,
                      uuid: uuid(),
                      sgrRating: '',
                      subComponents: Object.values(data.subComponents)
                        .filter(item => item.subComponent.selected)
                        .map(item => ({
                          id: item.subComponent.id,
                          measurement: Number(item.measurement),
                        })),
                      createdUUID,
                    },
                  },
                  {
                    onSuccess: () => {
                      handleClose();
                      queryClient.invalidateQueries([
                        'inspection',
                        inspectionId ?? '',
                      ]);
                    },
                  },
                );
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <ModalButton kind={ButtonKind.tertiary} onClick={handleClose}>
            Cancel
          </ModalButton>
          <ModalButton
            form="new-subdivision-form"
            isLoading={newSubdivision.isLoading}
          >
            Add Sub-division
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
}
