import {
  FETCH_CLIENTS_STARTED,
  FETCH_CLIENTS_SUCCESS,
  ADD_CLIENT_FAILED,
  ADD_CLIENT_STARTED,
  ADD_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILED,
  UPDATE_CLIENT_STARTED,
  UPDATE_CLIENT_SUCCESS,
  DELETE_CLIENT_STARTED,
  DELETE_CLIENT_FAILED,
  DELETE_CLIENT_SUCCESS,
} from "./ClientsReducer";
import { Strings } from "config/Strings";
import { showInfoDialog } from "components/Dialog/DialogAction";
import ClientAPIController from "../../services/api/ClientAPIController";

const fetchClientsTableDataSuccess = (data) => {
  return {
    type: FETCH_CLIENTS_SUCCESS,
    data: data,
  };
};

const fetchClientsTableDataStarted = () => {
  return {
    type: FETCH_CLIENTS_STARTED,
  };
};

const addClientToTableFailed = () => {
  return {
    type: ADD_CLIENT_FAILED,
  };
};

const addClientToTableStarted = () => {
  return {
    type: ADD_CLIENT_STARTED,
  };
};

const addClientToTableSuccess = (client) => {
  return {
    type: ADD_CLIENT_SUCCESS,
    client: client,
  };
};

const updateClientInTableFailed = () => {
  return {
    type: UPDATE_CLIENT_FAILED,
  };
};

const updateClientInTableStarted = () => {
  return {
    type: UPDATE_CLIENT_STARTED,
  };
};

const updateClientInTableSuccess = (client) => {
  return {
    type: UPDATE_CLIENT_SUCCESS,
    client: client,
  };
};

const deleteClientFromTableFailed = () => {
  return {
    type: DELETE_CLIENT_FAILED,
  };
};

const deleteClientFromTableStarted = () => {
  return {
    type: DELETE_CLIENT_STARTED,
  };
};

const deleteClientFromTableSuccess = (clientId) => {
  return {
    type: DELETE_CLIENT_SUCCESS,
    clientId: clientId,
  };
};

const watchClientsTableData = () => {
  return function (dispatch) {
    dispatch(fetchClientsTableDataStarted());
    ClientAPIController.getClients()
      .then((response) => dispatch(fetchClientsTableDataSuccess(response)))
      .catch((error) => {
        console.log(error);
      });
  };
};

const addClient = (name) => {
  return function (dispatch) {
    dispatch(addClientToTableStarted());
    ClientAPIController.addClient(name)
      .then((response) => {
        dispatch(addClientToTableSuccess(response));
      })
      .catch((error) => {
        dispatch(addClientToTableFailed());
        dispatch(showInfoDialog(Strings.getInstance().getString("dialog_error_add_client_title"), Strings.getInstance().getString("dialog_error_add_client_description")));
        console.log(error);
      });
  };
};

const updateClient = (id, name) => {
  return function (dispatch) {
    dispatch(updateClientInTableStarted());
    ClientAPIController.updateClient(id, name)
      .then((response) => dispatch(updateClientInTableSuccess(response)))
      .catch((error) => {
        dispatch(updateClientInTableFailed());
        dispatch(showInfoDialog(Strings.getInstance().getString("dialog_error_update_client_title"), Strings.getInstance().getString("dialog_error_update_client_description")));
        console.log(error);
      });
  };
};

const deleteClient = (clientId) => {
  return function (dispatch) {
    dispatch(deleteClientFromTableStarted());
    ClientAPIController.deleteClient(clientId)
      .then((response) => dispatch(deleteClientFromTableSuccess(clientId)))
      .catch((error) => {
        dispatch(deleteClientFromTableFailed());
        dispatch(showInfoDialog(Strings.getInstance().getString("dialog_error_delete_client_title"), Strings.getInstance().getString("dialog_error_delete_client_description")));
        console.log(error);
      });
  };
};

export { watchClientsTableData, addClient, updateClient, deleteClient };
