import React, { Component } from 'react';
import Table from 'components/Table';
import ColumnBuilder from 'helper/ColumnBuilder';
import { ListSubheader, Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import TokenManager from 'storage/TokenManager';
import NumberTextField from 'components/NumberTextField';
import { Checkbox } from '@material-ui/core';
import { Strings } from '../../../config/Strings';

import ValidatorArray from 'helper/Validator/ValidatorArray';
import ValidatorFieldBuilder, {
  TYPE,
} from 'helper/Validator/ValidatorFieldBuilder';
import MultilineEditTextTableField from 'components/MultilineEditTextTableField';
import theme from 'theme';

export const FIELD_LOCATIONS_ID = 'id';
export const FIELD_LOCATIONS_UUID = 'uuid';
export const FIELD_LOCATIONS_COMPONENT = 'component';
export const FIELD_LOCATIONS_SUB_COMPONENT = 'sub_component';
export const FIELD_LOCATIONS_SIZE = 'size';
export const FIELD_LOCATIONS_DEFECT_NUMBER = 'number_of_defects';
export const FIELD_LOCATION_MEASUREMENT_UNIT = 'measurement_unit';
export const FIELD_LOCATIONS_HI = 'hi';
export const FIELD_LOCATIONS_COMPUTED_HI = 'computed_hi';
export const FIELD_LOCATIONS_INSPECTED = 'inspected';

class SubComponentsTable extends Component {
  getLookupValues() {
    var result = {};
    for (var i = 0; i < arguments.length; i++) {
      for (const [key, value] of Object.entries(arguments[i])) {
        result[key] = value['name'];
      }
    }
    return result;
  }

  getSubComponentMeasurementUnit(subComponentId) {
    if (subComponentId === undefined) {
      return '';
    }

    const subComponent =
      this.props.preferences['subcomponents'][subComponentId];

    if (subComponent === undefined) {
      return '';
    }

    return subComponent['measureUnit'];
  }

  validateFields = (newData) => {
    var validator = new ValidatorArray();
    validator.push(
      new ValidatorFieldBuilder(newData[FIELD_LOCATIONS_COMPONENT], TYPE.ANY)
        .canBeEmpty(false)
        .build(),
    );
    validator.push(
      new ValidatorFieldBuilder(
        newData[FIELD_LOCATIONS_SUB_COMPONENT],
        TYPE.ANY,
      )
        .canBeEmpty(false)
        .build(),
    );
    return validator;
  };

  subComponentIsNotUsed(subComponentId) {
    for (var i = 0; i < this.props.data.length; i++) {
      var location = this.props.data[i];
      if (location['sub_component'] === subComponentId) {
        return false;
      }
    }
    return true;
  }

  getColumns = () => {
    if (this.props.columns !== undefined) {
      return this.props.columns;
    }

    const structureComponents = TokenManager.getStructureTypes()
      .sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
      .map((structure) => {
        const components = this.props.preferences.components;
        return [
          structure.name,
          Object.keys(components)
            .filter((key) => {
              return structure.id === components[key].type;
            })
            .map((key) => ({ ...components[key], id: key }))
            .sort(function (a, b) {
              var textA = a.name.toUpperCase();
              var textB = b.name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            }),
        ];
      });

    var columns = [];
    const getString = (x) => Strings.getInstance().getString(x);
    columns.push(
      new ColumnBuilder(
        getString('sub_component_table_component_col'),
        FIELD_LOCATIONS_COMPONENT,
      )
        .lookup(this.getLookupValues(this.props.preferences['components']))
        .editable('onAdd')
        .editComponent((props) => {
          return (
            <Select
              {...props}
              value={props.value === undefined ? '' : props.value}
              onChange={(event) => {
                var data = { ...props.rowData };
                data[FIELD_LOCATIONS_COMPONENT] = event.target.value;
                data[FIELD_LOCATIONS_SUB_COMPONENT] = '';
                data[FIELD_LOCATION_MEASUREMENT_UNIT] = '';
                props.onRowDataChange(data);
              }}
              style={{
                fontSize: 13,
              }}
            >
              {structureComponents
                .filter(
                  ([structure, structureComponents]) =>
                    structureComponents.length !== 0,
                )
                .map((structure) => {
                  const [structureName, structureComponents] = structure;
                  return [
                    <ListSubheader
                      style={{
                        backgroundColor: 'white',
                        fontWeight: 'bold',
                        color: theme.colors.primary,
                      }}
                    >
                      {structureName}
                    </ListSubheader>,
                    structureComponents.map((comp) => (
                      <MenuItem key={comp.id} value={comp.id}>
                        {comp.name}
                      </MenuItem>
                    )),
                  ];
                })}
            </Select>
          );
        })
        .build(),
    );
    columns.push(
      new ColumnBuilder(
        getString('sub_component_table_sub_component_col'),
        FIELD_LOCATIONS_SUB_COMPONENT,
      )
        .lookup(this.getLookupValues(this.props.preferences['subcomponents']))
        .editable('onAdd')
        .editComponent((props) => {
          return (
            <Select
              {...props}
              value={props.value === undefined ? '' : props.value}
              onChange={(event) => {
                var data = { ...props.rowData };
                data[FIELD_LOCATIONS_SUB_COMPONENT] = event.target.value;
                data[FIELD_LOCATION_MEASUREMENT_UNIT] =
                  this.getSubComponentMeasurementUnit(
                    data[FIELD_LOCATIONS_SUB_COMPONENT],
                  );
                props.onRowDataChange(data);
              }}
              style={{
                fontSize: 13,
              }}
            >
              {Object.keys(props.columnDef.lookup).map((key) => {
                if (
                  this.props.preferences !== undefined &&
                  this.props.preferences['components'][
                    props.rowData[FIELD_LOCATIONS_COMPONENT]
                  ] !== undefined &&
                  (
                    this.props.preferences['components'][
                      props.rowData[FIELD_LOCATIONS_COMPONENT]
                    ]['subcomponents'] || []
                  ).includes(key) &&
                  (this.subComponentIsNotUsed(key) || props.value === key)
                ) {
                  return (
                    <MenuItem key={key} value={key}>
                      {props.columnDef.lookup[key]}
                    </MenuItem>
                  );
                } else {
                  return undefined;
                }
              })}
            </Select>
          );
        })
        .build(),
    );
    columns.push(
      new ColumnBuilder(
        getString('sub_component_table_total_defects_col'),
        FIELD_LOCATIONS_DEFECT_NUMBER,
      )
        .editable('never')
        .type('numeric')
        .build(),
    );
    columns.push(
      new ColumnBuilder('Total Size/Quantity', FIELD_LOCATIONS_SIZE)
        .type('numeric')
        .build(),
    );
    columns.push(
      new ColumnBuilder('Overview Statement', 'generalSummary')
        .editComponent((props) => {
          return (
            <MultilineEditTextTableField
              value={props.value}
              onChange={(value) => {
                var data = { ...props.rowData };
                data.generalSummary = value;
                props.onRowDataChange(data);
              }}
            />
          );
        })
        .build(),
    );
    columns.push(
      new ColumnBuilder('Measurement Unit', FIELD_LOCATION_MEASUREMENT_UNIT)
        .render((rowData) => {
          if (rowData === undefined) {
            return '';
          }

          if (typeof rowData === 'string') {
            return rowData;
          }

          return this.getSubComponentMeasurementUnit(
            rowData[FIELD_LOCATIONS_SUB_COMPONENT],
          );
        })
        .editable('never')
        .build(),
    );

    columns.push(
      new ColumnBuilder('Computed HI', FIELD_LOCATIONS_COMPUTED_HI)
        .editable(false)
        .render((rowData) => {
          return rowData?.computedHealthIndex?.toFixed(3) ?? '---';
        })
        .build(),
    );

    if (TokenManager.isAdmin()) {
      columns.push(
        new ColumnBuilder(
          getString('inspection_info_manual_hi'),
          FIELD_LOCATIONS_HI,
        )
          .editComponent((props) => (
            <NumberTextField
              min={0}
              max={1}
              step={0.01}
              fixed={3}
              onChange={props.onChange}
              value={props.value}
            />
          ))
          .render((rowData) => {
            if (
              rowData !== undefined &&
              rowData[FIELD_LOCATIONS_HI] !== undefined
            ) {
              return rowData[FIELD_LOCATIONS_HI].toFixed(3);
            } else {
              return '';
            }
          })
          .editable('onUpdate')
          .build(),
      );
    }

    columns.push(
      new ColumnBuilder('Inspected', FIELD_LOCATIONS_INSPECTED)
        .editable('never')
        .render((rowData) => {
          if (rowData === undefined) {
            return <div />;
          }

          const checked =
            rowData === undefined
              ? false
              : rowData[FIELD_LOCATIONS_INSPECTED] || false;
          return (
            <Checkbox
              checked={checked}
              disabled={!this.props.isEditable}
              onChange={() =>
                this.props.onLocationInspectedCallback(
                  rowData[FIELD_LOCATIONS_UUID],
                  !rowData[FIELD_LOCATIONS_INSPECTED],
                )
              }
            />
          );
        })
        .build(),
    );

    return columns;
  };

  getActions = () => {
    var actions = [];
    actions.push({
      icon: 'refresh',
      tooltip: 'Refresh Data',
      isFreeAction: 'true',
      onClick: () => {
        this.props.onLocationsRefreshCallback();
      },
    });

    if (this.props.isEditable) {
      actions.push({
        icon: 'delete_outlined',
        tooltip: 'Remove',
        onClick: (event, rowData) => {
          this.props.onLocationRemoveCallback(rowData);
        },
      });
    }

    return actions;
  };

  getEditable = () => {
    if (!this.props.isEditable) {
      return {};
    } else {
      return {
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              this.props.onLocationUpdateCallback(newData);
              resolve();
            }, 100);
          }),
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              let validator = this.validateFields(newData);
              if (validator.validate()) {
                resolve();
                this.props.onLocationAddCallback(newData);
              } else {
                this.props.onInvalidInputCallback(
                  'Invalid Input',
                  validator.generateColumnErrorDescription(this.getColumns()),
                );
                reject();
              }
            }, 100);
          }),
      };
    }
  };

  render() {
    return (
      <Table
        title={Strings.getInstance().getString(
          'inspection_info_sub_component_table_title',
        )}
        columns={this.getColumns()}
        options={{ actionsColumnIndex: -1 }}
        data={this.props.data}
        onColumnSort={(column, order) => {
          if (this.props.onColumnSort instanceof Function) {
            this.props.onColumnSort(column, order);
          }
        }}
        actions={this.getActions()}
        editable={this.getEditable()}
        isLoading={this.props.loading}
      />
    );
  }
}

export default SubComponentsTable;
