import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";

class NumberTextField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      number: props.value,
    };
  }

  render() {
    return (
      <TextField
        size="small"
        type="number"
        inputProps={{ min: this.props.min, max: this.props.max, step: this.props.step }}
        value={this.state.number}
        onChange={(e) => {
          let v = e.target.value;
          if (v !== undefined) {
            if (v.toString().length > this.props.fixed + 2 || 3) {
              v = v.toString().substring(0, this.props.fixed + 2 || 3);
            }

            let f = parseFloat(v);
            if (Number.isNaN(f)) {
              f = undefined;
            }

            if (f > this.props.max) {
              this.setState({ number: this.props.max.toFixed(this.props.fixed || 1) });
              this.props.onChange(this.props.max);
            } else if (f < 0) {
              this.setState({ number: (0.0).toFixed(this.props.fixed || 1) });
              this.props.onChange(0.0);
            } else {
              this.setState({ number: f });
              this.props.onChange(f);
            }
          }
        }}
      />
    );
  }
}

export default NumberTextField;
