const SEARCH_KEY = "search_data";
const FILTER_KEY = "filter_data";
const SORT_KEY = "sort_data";
const HIDDEN_KEY = "hidden_data";
const URL_KEY = "current_url";

/*
The session storage is responsible for storing data during a specific user session. A session lives from the moment the user opens the portal until he closes his browser or tab. This storage is helpful when we want to store search variables, filters, or anything that is limited to a session. Once a session is destroyed, all the data saved in session storage is destroyed as well. The class SessionStorage.js helps us perform specific queries to the storage itself.
*/

export default class SessionStorage {

  /*
 * Saves the current page url any time Table.js is called.
 *
 * @param {string} url - The url of the current page
  */

  static saveCurrentPageUrl(url) {
    let currentUrl = JSON.parse(sessionStorage.getItem(URL_KEY) || "{}");
    currentUrl = url;
    sessionStorage.setItem(URL_KEY, JSON.stringify(currentUrl));
  }

    /*
 * Retrieves the saved page url.
 *
 * @param {string} url - The url of the saved page.
  */

  static getCurrentPageUrl() {
    let currentUrlString = sessionStorage.getItem(URL_KEY) || "{}";
    let currentUrl = JSON.parse(currentUrlString);
    return currentUrl || "";
  }



  /*
 * Saves the search query for a specific table.
 *
 * @param {string} tableKey - The key (id) of the table
 * @param {string} search - The search query to save

  */
  static saveSearchInTable(tableKey, search) {
    let searchDict = JSON.parse(sessionStorage.getItem(SEARCH_KEY) || "{}");
    searchDict[tableKey] = search;
    sessionStorage.setItem(SEARCH_KEY, JSON.stringify(searchDict));
  }

  /*
 * Retrieves the search query for a specific table.
 *
 * @param {string} tableKey - The key (id) of the table
 * @return {string} The search query saved for this table

  */
  static getSearchForTable(tableKey) {
    let searchDictString = sessionStorage.getItem(SEARCH_KEY) || "{}";
    let searchDict = JSON.parse(searchDictString);
    return searchDict[tableKey] || "";
  }

  /*
 * Saves the filter query for a specific table.
 *
 * @param {string} tableKey - The key (id) of the table
 * @param {object} filterTableDict - The filters used for the table. It takes the form of a dict where each key is the columnId and value is the filter value.

  */
  static saveFilterInTable(tableKey, filterTableDict) {
    let filterDict = JSON.parse(sessionStorage.getItem(FILTER_KEY) || "{}");
    filterDict[tableKey] = filterTableDict;
    sessionStorage.setItem(FILTER_KEY, JSON.stringify(filterDict));
  }

  /*
 * Retrieves the filter query for a specific table.
 *
 * @param {string} tableKey - The key (id) of the table
 * @return {object} - The filters used for the table. It takes the form of a dict where each key is the columnId and value is the filter value.

  */
  static getFilterForTable(tableKey) {
    let filterDictString = sessionStorage.getItem(FILTER_KEY) || "{}";
    let filterDict = JSON.parse(filterDictString);
    return filterDict[tableKey] || {};
  }

  /*
 * Saves the sorting query for a specific table.
 *
 * @param {string} tableKey - The key (id) of the table
 * @param {object} sortTableDict - The sort used for the table. It takes the form of a dict where each key is the columnId and value is the sort value.

  */
  static saveSortInTable(tableKey, sortTableDict) {
    let sortDict = JSON.parse(sessionStorage.getItem(SORT_KEY) || "{}");
    sortDict[tableKey] = sortTableDict;
    sessionStorage.setItem(SORT_KEY, JSON.stringify(sortDict));
  }

    /*
 * Retrieves the sorting query for a specific table.
 *
 * @param {string} tableKey - The key (id) of the table
 * @return {object} - The sorting used for the table. It takes the form of a dict where each key is the columnId and value is the sort value.

  */

  static getSortForTable(tableKey) {
    let sortDictString = sessionStorage.getItem(SORT_KEY) || "{}";
    let sortDict = JSON.parse(sortDictString);
    return sortDict[tableKey] || {};
  }

  /*
 * Saves the hidden columns for a specific table.
 *
 * @param {string} tableKey - The key (id) of the table
 * @param {object} hiddenColumns - The columns hidden in the table. It takes the form of a dict where each key is the columnId and value is a bool (hidden/visible).

  */
  static saveHiddenColumnsInTable(tableKey, hiddenColumns) {
    let hiddenColumnDict = JSON.parse(sessionStorage.getItem(HIDDEN_KEY) || "{}");
    hiddenColumnDict[tableKey] = hiddenColumns;
    sessionStorage.setItem(HIDDEN_KEY, JSON.stringify(hiddenColumnDict));
  }

  /*
 * Retrieves the hidden columns for a specific table.
 *
 * @param {string} tableKey - The key (id) of the table
 * @return {object} - The columns hidden in the table. It takes the form of a dict where each key is the columnId and value is a bool (hidden/visible).

  */
  static getHiddenColumnsForTable(tableKey) {
    let hiddenColumnDictString = sessionStorage.getItem(HIDDEN_KEY) || "{}";
    let hiddenColumnDict = JSON.parse(hiddenColumnDictString);
    return hiddenColumnDict[tableKey] || {};
  }
}
