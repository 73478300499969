export type Observation = {
  dimensionNumber: number;
  drawingNumber: string;
  healthIndex: number;
  id: string;
  inspected: boolean;
  locationDescription: string;
  observationDefects: ObservationDefect[];
  roomNumber: string;
  structuralComponentId: string;
  subComponentId: string;
  useHealthIndex: boolean;
  uuid: string;
};

export interface StructureSubdivision {
  uuid: string;
  inspectionId: string;
  name: string;
  number: number;
  computedSgrRating: string;
  observationStructureSubdivisions: ObservationStructureSubdivision[];
}
export interface ObservationStructureSubdivision {
  uuid: string;
  structureSubdivisionId: string;
  observationId: string;
  dimensionNumber: number;
  computedHealthIndex?: number;
}

// TODO: Change to much sensible name
enum Findings {
  STRUCTURAL = 'STRUCTURAL',
  MAINTENANCE = 'MAINTENANCE',
}

export enum DefectType {
  STRUCTURAL = 'STRUCTURAL',
  DAMAGE = 'DAMAGE',
}

enum ObservationType {
  CRITICAL = 'CRITICAL',
}

enum TermRating {
  EXCELLENT = 'EXCELLENT',
}

type Weather = {
  humidity: number;
  temperature: number;
  wind: number;
};

type Location = {
  latitude: number;
  longitude: number;
  altitude: number;
};

export type ObservationDefect = {
  id: string;
  uuid: string;
  createdBy: {
    email: string;
    firstName: string;
    id: number;
    lastName: string;
  };
  criticalFindings: Findings[];
  defectId: string;
  conditionId: string;
  description: string;
  materialId: string;
  observationId: string;
  photos: DefectPhoto[];
  span: string;
  stationMarker: string;
  observationType: ObservationType;
  size: string;
  type: Findings;
  weather: Weather;
  observationNameId: string;
  clockPosition: number;
  repairDate: string;
  repairMethod: string;
  previousDefectNumber: string;
  createdAt: Date;
  createdAtClient: Date;
};

export type DefectPhoto = {
  createdAt: Date;
  drawables: string;
  link: string;
  location: {
    latitude: number;
    longitude: number;
    altitude: number;
  };
  name: string;
  observationDefectId: string;
  thumbLink: string;
  uuid: string;
  annotatedLink: string;
};

export enum InspectionStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

type Inspector = {
  email: 'string';
  firstName: 'string';
  id: number;
  lastName: 'string';
};

export type Inspection = {
  editable: boolean;
  uuid: 'string';
  structureSubdivisions: StructureSubdivision[];
  observations: Observation[];
  isEditable: boolean;
  startDate: Date;
  previousInspectionId?: string;
  endDate?: Date;
  structureId: 'string';
  structureCode: 'string';
  structureName: 'string';
  weather: Weather;
  location: Location;
  status: InspectionStatus;
  report: {
    id: string;
    link: string;
    date: Date;
  };
  generalSummary: string;
  sgrRating?: string;
  termRating?: TermRating;
  spansCount: number;
  projectId: number;
  archived: boolean;
  inspectors: Inspector[];
};
