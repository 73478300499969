import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { Colors } from "config/StyleConfig";
import { withStyles } from "@material-ui/core/styles";

const buttonStyles = (theme) => ({
  customButtonStyle: {
    background: Colors.backgroundIconButton,
    "&:hover, &.Mui-focusVisible": { backgroundColor: Colors.backgroundIconButtonHover },
  },
});

class EditButton extends Component {
  render() {
    const beingEdited = this.props.isEditing;
    const { classes } = this.props;
    const buttonStyle = this.props.background ? classes.customButtonStyle : undefined;

    return (
      <div style={{ flex: 1, textAlign: "right" }}>
        {!beingEdited && (
          <IconButton className={buttonStyle} onClick={this.props.onEditButtonClicked}>
            <EditIcon fontSize="default" />
          </IconButton>
        )}
        {beingEdited && (
          <div>
            <IconButton className={buttonStyle} style={{ marginRight: 5 }} onClick={this.props.onConfirmButtonClicked}>
              <CheckIcon fontSize="default" />
            </IconButton>
            <IconButton className={buttonStyle} onClick={this.props.onCancelButtonClicked}>
              <CloseIcon fontSize="default" />
            </IconButton>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(buttonStyles)(EditButton);
