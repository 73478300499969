import { Colors } from '../../config/StyleConfig';

const styles = theme => ({
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: 'url(https://www.stantec.com/content/dam/stantec/images/projects/0032/mike-ocallaghan-pat-tillman-memorial-bridge-inspection-84775.jpg)',
      backgroundRepeat: 'no-repeat',
      backgroundColor: Colors.text,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: 50,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: 10,
      background: Colors.primary
    },
    form: {
      width: '100%',
      marginTop: 50
    },
    submit: {
      marginTop: 30,
      marginBottom: 30,
    },
    error: {
      marginTop: 10,
      color: 'red',
      textAlign: 'center'
    },
    button:{
      background: Colors.primary,
      color: 'white',
    },
    disabledButton:{
      background: Colors.disabled,
      color: 'white',
    },
    floatingLabelFocusStyle: {
      color: Colors.secondary
    }
  });

  export default styles;