import React, { Component } from "react";
import Table from "components/Table";
import { connect } from "react-redux";
import { watchDefectsTableData } from "./DefectsAction";
import ObservationGallery from "features/ObservationGallery";
import { mapMultipleRows, mapImagesToGallery } from "./DefectsMapper";
import ColumnBuilder from "helper/ColumnBuilder";
import { Strings } from "config/Strings";
import TokenManager from "storage/TokenManager";
import { VisibilityFlag } from "config/VisibilityFlag"

export const FIELD_ID = "id";
export const FIELD_STRUCTURE_ID = "structure_id";
export const FIELD_STRUCTURE_NAME = "structure_name";
export const FIELD_DEFECT_CLASSIFICATION = "defect_classification";
export const FIELD_DEFECT_NUMBER = "defect_number";
export const FIELD_COMPONENT = "component";
export const FIELD_SUB_COMPONENT = "sub_component";
export const FIELD_LOCATION = "location";
export const FIELD_INSPECTION_DATE = "inspection_date";
export const FIELD_STATIONING = "stationing";
export const FIELD_CLOCK_POSITION = "clock_position";
export const FIELD_DEFECT_TYPE = "defect_type";
export const FIELD_PICTURE_LINK = "picture_link";
export const FIELD_DESCRIPTION = "description";
export const FIELD_DIMENSION = "dimension";
export const FIELD_CORRECTIVE_ACTION_PLAN = "corrective_action_plan";
export const FIELD_CS_RATING = "cs_rating";
export const FIELD_REPAIR_METHOD = "repair_method";
export const FIELD_REPAIR_BY_DATE = "repair_by_date";
export const FIELD_PROJECT_NAME = "project_name";
export const FIELD_PROJECT_ID = "project_id";
export const FIELD_INSPECTION_ID = "inspection_id";
export const FIELD_INSPECTOR_NAME = "inspector_name";
export const FIELD_PREVIOUS_DEFECT = "previous_defect";
export const FIELD_SUB_COMPONENT_UNIT_OF_MEASUREMENT = "sub_component_unit_of_measurement";

class Defects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      observationInGallery: undefined,
    };
  }

  updateData() {
    this.props.watchDefectsTableData(this.props.companyId);
  }

  getColumns = () => {
    if (this.props.columns !== undefined) {
      return this.props.columns;
    }
    var columns = [];
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_observations_table_project_name"), FIELD_PROJECT_NAME).hidden().build());
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_observations_table_structure_id"), FIELD_STRUCTURE_ID).build());
    columns.push(
      new ColumnBuilder(Strings.getInstance().getString("client_observations_table_structure_name"), FIELD_STRUCTURE_NAME).hidden().build()
    );
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_observations_table_class"), FIELD_DEFECT_CLASSIFICATION).build());
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_observations_table_observation_id"), FIELD_DEFECT_NUMBER).build());
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_observations_table_component"), FIELD_COMPONENT).build());
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_observations_table_sub_component"), FIELD_SUB_COMPONENT).build());
    if (TokenManager.hasBridges()) {
      columns.push(new ColumnBuilder(Strings.getInstance().getString("client_observations_table_location"), FIELD_LOCATION).build());
      columns.push(new ColumnBuilder(Strings.getInstance().getString("client_observations_table_stationing"), FIELD_STATIONING).build());
      columns.push(
        new ColumnBuilder(Strings.getInstance().getString("client_observations_table_corrective_action"), FIELD_CORRECTIVE_ACTION_PLAN).build()
      );
      columns.push(
        new ColumnBuilder(Strings.getInstance().getString("client_observations_table_previous_defect_number"), FIELD_PREVIOUS_DEFECT)
          .hidden()
          .build()
      );
      columns.push(
        new ColumnBuilder(Strings.getInstance().getString("client_observations_table_clock_position"), FIELD_CLOCK_POSITION).hidden().build()
      );
    }
    columns.push(
      new ColumnBuilder(Strings.getInstance().getString("client_observations_table_inspection_date"), FIELD_INSPECTION_DATE).type("date").build()
    );

    if(VisibilityFlag.getInstance().isVisible("observations_all_tables_type")){
      columns.push(new ColumnBuilder(Strings.getInstance().getString("client_observations_table_defect_type"), FIELD_DEFECT_TYPE).build());
    }
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_observations_table_description"), FIELD_DESCRIPTION).hidden().build());
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_observations_table_dimension"), FIELD_DIMENSION).hidden().build());
    columns.push(
      new ColumnBuilder(
        Strings.getInstance().getString("client_observations_table_sub_comp_unit_of_measurement"),
        FIELD_SUB_COMPONENT_UNIT_OF_MEASUREMENT
      )
        .hidden()
        .build()
    );
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_observations_table_cs_rating"), FIELD_CS_RATING).build());
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_observations_table_reported_by"), FIELD_INSPECTOR_NAME).hidden().build());
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_observations_table_repair_method"), FIELD_REPAIR_METHOD).build());
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_observations_table_repair_date"), FIELD_REPAIR_BY_DATE).build());

    return columns;
  };

  componentDidMount() {
    this.updateData();
  }

  getActions = () => {
    var actions = [];
    actions.push({
      icon: "refresh",
      tooltip: "Refresh Data",
      isFreeAction: "true",
      onClick: () => {
        this.updateData();
      },
    });

    actions.push((rowData) => ({
      icon: "image",
      tooltip: (rowData[FIELD_PICTURE_LINK] || []).length === 0 ? "No images" : "View images",
      onClick: (e, row) => {
        this.setState({ observationInGallery: row });
      },
      disabled: (rowData[FIELD_PICTURE_LINK] || []).length === 0,
    }));
    return actions;
  };

  onOutsideGalleryClicked = () => {
    this.setState({ observationInGallery: undefined });
  };

  render() {
    const showGallery = this.state.observationInGallery !== undefined;

    return (
      <div>
        {showGallery && (
          <ObservationGallery
            images={mapImagesToGallery(this.state.observationInGallery)}
            onOutsideLightBoxClicked={this.onOutsideGalleryClicked}
            isEditable={false}
          />
        )}

        <Table
          id="CLIENT_OBSERVATIONS_TABLE"
          title={Strings.getInstance().getString("client_observations_table_title")}
          columns={this.getColumns()}
          options={{ actionsColumnIndex: -1 }}
          data={this.props.data}
          actions={this.getActions()}
          isLoading={this.props.loading}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: mapMultipleRows(state),
    loading: state.DefectsReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    watchDefectsTableData: (companyId, inspectionId, structureId) => dispatch(watchDefectsTableData(companyId, inspectionId, structureId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Defects);
