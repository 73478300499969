import Helper from 'helper/Helper';
import { pageRoutes } from 'Routes';

export default class Navigator {
  static navigateToClient(clientId) {
    if (typeof window !== 'undefined') {
      const url = Helper.parse('client/%s', clientId);
      window.location.href = url;
    }
  }

  static navigateToInspection(inspectionId) {
    if (typeof window !== 'undefined') {
      var url = window.location.origin + '/';
      url += Helper.parse('inspection/%s', inspectionId);
      window.location.href = url;
    }
  }

  static navigateToStructure(structureId) {
    if (typeof window !== 'undefined') {
      var url = window.location.origin + '/';
      url += Helper.parse('structure/%s', structureId);
      window.location.href = url;
    }
  }

  static navigateToInspections = (projectId, structureId) => {
    if (typeof window !== 'undefined') {
      var url = window.location.origin + '/';
      if (structureId !== null && structureId !== undefined) {
        url += Helper.parse('inspections/?structureId=%s', structureId);
      } else if (projectId !== null && projectId !== undefined) {
        url += Helper.parse('inspections/?projectId=%s', projectId);
      } else {
        url += pageRoutes.inspections.path;
      }
      window.location.href = url;
    }
  };

  static navigateToObservations(structureId, inspectionId) {
    if (typeof window !== 'undefined') {
      var url =
        window.location.origin +
        '/' +
        Helper.parse(
          'observations/?inspectionId=%s&structureId=%s',
          inspectionId,
          structureId,
        );

      window.location.href = url;
    }
  }

  static navigateToLogin() {
    if (typeof window !== 'undefined') {
      const url = window.location.origin;
      window.location.href = url;
    }
  }

  static navigateToLogout() {
    if (typeof window !== 'undefined') {
      const url = `${window.location.origin}/logout`;
      window.location.href = url;
    }
  }
}
