import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { login, isAuthenticated } from './LoginAction';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import DRLoader from 'components/DRLoader';
import Config from 'config/Config';
import styles from './LoginStyle';
import { withStyles } from '@material-ui/core/styles';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      userData: {},
      authenticating: false,
      error: '',
      authenticated: false,
    };
  }

  submitHandler = event => {
    event.preventDefault();
    this.props.login(this.state.email, this.state.password);
  };

  componentDidMount() {
    this.props.isAuthenticated();
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { from } = this.props.location.state || {
      from: { pathname: Config.DEFAULT_PAGE },
    };

    if (this.props.authenticated) {
      return <Redirect to={from} />;
    }

    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              DATA RECON
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={this.submitHandler}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={this.handleChange}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={this.handleChange}
                autoComplete="current-password"
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <div className={classes.submit}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={this.props.authenticating}
                  className={
                    this.props.authenticating
                      ? classes.buttonDisabled
                      : classes.button
                  }
                >
                  Sign In
                </Button>
                {this.props.error !== '' && (
                  <div className={classes.error}>{this.props.error}</div>
                )}
                {this.props.authenticating === true && (
                  <div className={classes.error}>
                    <DRLoader />
                  </div>
                )}
              </div>
              <Grid container>
                <Grid item>
                  <Link href="/forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {/* {"Don't have an account? Sign Up"} */}
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  }
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      Data Recon{" "} 
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const mapStateToProps = state => {
  return {
    authenticating: state.LoginReducer.authenticating,
    userData: state.LoginReducer.userData,
    error: state.LoginReducer.error,
    authenticated: state.LoginReducer.authenticated,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (email, password) => dispatch(login(email, password)),
    isAuthenticated: () => dispatch(isAuthenticated()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Login));
