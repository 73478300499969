import ImageGallery from "react-image-gallery";
import React, { Component } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import { Colors } from "config/StyleConfig";
import SecuredImage from "components/SecuredImage";

import InteractiveImage from "./InteractiveImage";

const BACKGROUND_DIV_ID = "backgroundDivId";

export default class ObservationGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationArrowsHidden: false,
    };
  }

  onRemoveButtonClicked = (id) => {
    this.props.onImageRemovedCallback(id);
  };

  onConfirmButtonClicked = (id, rectangle) => {
    this.props.onImageRectangleUpdatedCallback(id, rectangle);
  };

  onOutsideLightBoxClicked = (event) => {
    if (event.target.id === BACKGROUND_DIV_ID) {
      this.props.onOutsideLightBoxClicked();
    }
  };

  hideNavigationArrows = (hidden) => {
    if (hidden !== this.state.navigationArrowsHidden) {
      this.setState({ navigationArrowsHidden: hidden });
    }
  };

  render() {
    return (
      <div
        id={BACKGROUND_DIV_ID}
        style={{
          backgroundColor: Colors.lightboxBackground,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: "fixed",
          zIndex: 9000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={this.onOutsideLightBoxClicked}
      >
        <div style={{ maxWidth: "50%" }}>
          <ImageGallery
            items={this.props.images}
            showFullscreenButton={false}
            showPlayButton={false}
            disableSwipe={true}
            showNav={!this.state.navigationArrowsHidden}
            renderItem={(item) => {
              return (
                <InteractiveImage
                  id={item.id}
                  imageSource={item.original}
                  onConfirmButtonClicked={this.onConfirmButtonClicked}
                  onRemoveButtonClicked={this.onRemoveButtonClicked}
                  rectangle={item.drawables}
                  isEditable={this.props.isEditable}
                  isMouseDown={this.hideNavigationArrows}
                />
              );
            }}
            renderThumbInner={(item) => {
              return (
                <div style={{ marginRight: 10 }}>
                  <SecuredImage id={item.thumbnail} src={item.thumbnail} style={{ width: "auto", height: 80 }} />
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  }
}
