// dataMap is an array of a tuple (value, type)
import React from "react";

export default class ValidatorArray extends Array {
  constructor(...items) {
    super(...items);
    this.validation = true;
    this.details = {};
  }

  validate() {
    this.forEach((validatorField, index) => {
      const validationResult = validatorField.validate();
      if (validationResult !== "") {
        this.details[index] = validationResult;
        this.validation = false;
      }
    });

    return this.validation;
  }

  generateColumnErrorDescription(columns) {
    var text = [];
    var optimizationKey = 0;
    text.push(<div key={optimizationKey++}>You've entered a wrong input in one or more of the below fields:</div>);
    Object.entries(this.details).forEach(([key, value]) => {
      text.push(
        <li key={optimizationKey++}>
          {columns[key].title}
          {": "}
          {value}
        </li>
      );
    });
    return text;
  }
}
