import { TOGGLE_MOBILE_OPEN } from "./MenuReducer";
import TokenManager from "storage/TokenManager";
import Navigator from "Navigator";
import AuthenticationAPIController from "services/api/AuthenticationAPIController";

export const toggleMobileOpen = () => {
  return {
    type: TOGGLE_MOBILE_OPEN,
  };
};

export const logout = () => {
  return function (dispatch) {
    AuthenticationAPIController.logout()
      .then((response) => {
        // TODO: Move all navigators to actions?
        TokenManager.clearToken();
        Navigator.navigateToLogin();
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
