/* eslint-disable react/require-default-props */
import { Description } from '@material-ui/icons';
import { Button, KIND, SHAPE, SIZE as ButtonSize } from 'baseui/button';
import { FileUploader, FileUploaderProps } from 'baseui/file-uploader';
import { SIZE } from 'baseui/input';
import { LabelMedium, LabelSmall } from 'baseui/typography';
import React, { useEffect, useRef, useState } from 'react';

import { styled, useStitches } from 'theme';

export type FileInputProps = {
  defaultValue?: File[];
  onChange?: (value: File[]) => void;
} & FileUploaderProps;

const StyledRoot = styled<any, { $hasError: boolean }>(
  'div',
  ({ $theme, $hasError }) => ({
    display: 'block',
    ...$theme.borders.border400,
    borderRadius: $theme.sizing.scale400,
    ...($hasError
      ? {
          backgroundColor: $theme.colors.negative50,
          borderColor: $theme.colors.negative,
        }
      : { backgroundColor: $theme.colors.fileUploaderBackgroundColor }),
  }),
);

export default function FileInput({
  defaultValue = [],
  onChange,
  ...BaseFileUploadProps
}: FileInputProps) {
  const { css, theme } = useStitches();
  const [files, setFiles] = useState<File[]>(defaultValue);
  const onChangeRef = useRef<typeof onChange | null>(null);
  const { errorMessage, ...PropsWithoutError } = BaseFileUploadProps;
  const hasError = !!errorMessage;

  const blockPadding = {
    paddingLeft: theme.sizing.scale500,
    paddingRight: theme.sizing.scale500,
    paddingTop: theme.sizing.scale200,
    paddingBottom: theme.sizing.scale200,
  };

  useEffect(() => {
    if (onChangeRef.current) {
      onChangeRef.current(files);
    }
  }, [files]);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  return (
    <StyledRoot $hasError={hasError}>
      <FileUploader
        {...PropsWithoutError}
        onDrop={(acceptedFiles, rejectedFiles) => {
          // handle file upload...
          setFiles(prev =>
            [...prev, ...acceptedFiles].reduce((allfiles, file) => {
              const isAdded = allfiles.some(
                addedFile => addedFile.name === file.name,
              );
              if (isAdded) {
                return allfiles;
              }
              return [...allfiles, file];
            }, [] as File[]),
          );
        }}
        overrides={{
          ButtonComponent: {
            //   // Workaround to fix double click issue https://github.com/uber/baseweb/issues/5084
            // component: () => (
            //   <Button
            //     size={ButtonSize.compact}
            //     kind={KIND.secondary}
            //     shape={SHAPE.pill}
            //   >
            //     Browse
            //   </Button>
            // ),
            props: {
              kind: KIND.tertiary,
              overrides: {
                BaseButton: {
                  style: {
                    marginTop: 0,
                  },
                },
              },
            },
          },
          FileDragAndDrop: {
            style: {
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              border: 0,
              backgroundColor: 'transparent',
              ...blockPadding,
            },
          },
        }}
      />
      {files.length !== 0 && (
        <section
          className={css({ marginTop: theme.sizing.scale300, ...blockPadding })}
        >
          <LabelMedium>Added Files</LabelMedium>
          <ul
            className={css({
              mt: '$scale200',
              spaceY: '$scale600',
              paddingInlineStart: 0,
            })}
          >
            {files.map(file => (
              <li
                key={file.name.split(' ').join('-')}
                className={css({
                  display: 'flex',
                  alignItems: 'center',
                  spaceX: '$scale100',
                })}
              >
                <span className={css({ color: '$primary' })}>
                  <Description color={'inherit'} />
                </span>
                <LabelSmall flex={1} minWidth={0}>
                  {file.name}
                </LabelSmall>
                <Button
                  type="button"
                  onClick={() =>
                    setFiles(prev =>
                      prev.filter(fileItem => fileItem.name !== file.name),
                    )
                  }
                  colors={{
                    color: theme.colors.negative,
                    backgroundColor: theme.colors.negative50,
                  }}
                  size={SIZE.mini}
                  kind={KIND.tertiary}
                  // @ts-ignore
                  $style={{ marginLeft: theme.sizing.scale100 }}
                >
                  Remove
                </Button>
              </li>
            ))}
          </ul>
        </section>
      )}
    </StyledRoot>
  );
}
