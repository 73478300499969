import { FETCH_COMPONENTS_HI_STARTED, FETCH_COMPONENTS_HI_SUCCESS } from "./ComponentsHIReducer";
import StructureAPIController from "services/api/StructureAPIController";

const fetchComponentsHITableDataSuccess = (data) => {
  return {
    type: FETCH_COMPONENTS_HI_SUCCESS,
    data: data,
  };
};

const fetchComponentsHITableDataStarted = () => {
  return {
    type: FETCH_COMPONENTS_HI_STARTED,
  };
};

const watchComponentsHITableData = (companyId) => {
  return function (dispatch) {
    dispatch(fetchComponentsHITableDataStarted());
    StructureAPIController.getComponentsHI(companyId)
      .then((response) => dispatch(fetchComponentsHITableDataSuccess(response)))
      .catch((error) => {
        console.log(error);
      });
  };
};

export { watchComponentsHITableData };
