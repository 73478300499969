import Helper from "helper/Helper";

export const FETCH_USERS_STARTED = "FETCH_OBSERVATIONS_STARTED";
export const FETCH_USERS_SUCCESS = "FETCH_OBSERVATIONS_SUCCESS";

export const ADD_USER_STARTED = "ADD_USER_STARTED";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILED = "ADD_USER_FAILED";

export const UPDATE_USER_STARTED = "UPDATE_USER_STARTED";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";

export const DELETE_USER_STARTED = "DELETE_USER_STARTED";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

const initialState = {
  data: [],
  loading: true,
};

const UsersReducer = (state = initialState, action) => {
  var newData = null;
  switch (action.type) {
    case FETCH_USERS_STARTED:
      return { ...state, loading: true };
    case FETCH_USERS_SUCCESS:
      return { ...state, title: "Users", data: action.data, loading: false };

    case ADD_USER_STARTED:
      return { ...state, loading: true };
    case ADD_USER_SUCCESS:
      newData = Helper.insertItemImmutable(state.data, action.user);
      return { ...state, data: newData, loading: false };
    case ADD_USER_FAILED:
      return { ...state, loading: false };

    case UPDATE_USER_STARTED:
      return { ...state, loading: true };
    case UPDATE_USER_SUCCESS:
      newData = Helper.updateItemImmutable(state.data, action.user);
      return { ...state, data: newData, loading: false };
    case UPDATE_USER_FAILED:
      return { ...state, loading: false };

    case DELETE_USER_STARTED:
      return { ...state, loading: true };
    case DELETE_USER_SUCCESS:
      newData = Helper.removeItemImmutable(state.data, action.userId);
      return { ...state, data: newData, loading: false };
    case DELETE_USER_FAILED:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export { UsersReducer };
