import React, { Component } from "react";
import Table from "components/Table";
import { connect } from "react-redux";
import { watchProjectsTableData, addProject, updateProject, deleteProject } from "./ProjectsAction";
import { showInfoDialog, showConfirmationDialog } from "components/Dialog/DialogAction";
import { watchUsersTableData } from "features/Users/UsersAction";

import CheckboxTableField from "components/CheckBoxTableField";

import { watchStructuresTableData } from "../Structures/StructuresAction";
import ValidatorArray from "helper/Validator/ValidatorArray";
import ValidatorFieldBuilder, { TYPE } from "helper/Validator/ValidatorFieldBuilder";
import Navigator from "Navigator";
import ColumnBuilder from "../../helper/ColumnBuilder";
import { Strings } from "../../config/Strings";

import { mapStructureOptions, mapProjectManagerOptions, mapMultipleRows, extractIds } from "./ProjectsMapper";

export const FIELD_ID = "id";
export const FIELD_PROJECT_NAME = "project_name";
export const FIELD_PROJECT_DATE = "project_date";
export const FIELD_STRUCTURES = "structures";
export const FIELD_PROJECT_MANAGERS = "project_managers";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      structureOptions: [],
      projectManagerOptions: [],
    };
  }

  validateFields = (projectName, structures, projectManagers) => {
    var validator = new ValidatorArray();
    validator.push(new ValidatorFieldBuilder(projectName, TYPE.ANY).canBeEmpty(false).build());
    validator.push(new ValidatorFieldBuilder(structures, TYPE.LOOKUP).lookupOptions(this.props.structureOptions).build());
    validator.push(new ValidatorFieldBuilder(projectManagers, TYPE.LOOKUP).lookupOptions(this.props.projectManagerOptions).build());
    return validator;
  };

  getColumns = () => {
    var columns = [];
    columns.push(new ColumnBuilder(Strings.getInstance().getString("projects_table_project_name"), FIELD_PROJECT_NAME).build());
    columns.push(
      new ColumnBuilder(Strings.getInstance().getString("projects_table_project_date"), FIELD_PROJECT_DATE)
        .readOnly()
        .type("date")
        .editable("never")
        .build()
    );
    columns.push(
      new ColumnBuilder(Strings.getInstance().getString("projects_table_structures"), FIELD_STRUCTURES)
        .editComponent((props) => (
          <CheckboxTableField multiple options={this.props.structureOptions} value={props.value} onChange={(e, v) => props.onChange(v)} />
        ))
        .build()
    );
    columns.push(
      new ColumnBuilder(Strings.getInstance().getString("projects_table_project_managers"), FIELD_PROJECT_MANAGERS)
        .editComponent((props) => (
          <CheckboxTableField multiple options={this.props.projectManagerOptions} value={props.value} onChange={(e, v) => props.onChange(v)} />
        ))
        .build()
    );

    return columns;
  };

  onRowClick = (event, rowData) => {
    Navigator.navigateToInspections(rowData.id);
  };

  componentDidMount() {
    this.props.watchProjectsTableData();
    this.props.getUserOptions();
    this.props.getStructureOptions();
  }

  actions = [
    {
      icon: "refresh",
      tooltip: "Refresh Data",
      isFreeAction: "true",
      onClick: () => {
        this.props.watchProjectsTableData();
        this.props.getUserOptions();
      },
    },
    {
      icon: "delete_outlined",
      tooltip: "Remove",
      onClick: (event, rowData) => {
        const pendingAction = deleteProject(rowData["id"]);
        this.props.confirmDeleteProject(pendingAction);
      },
    },
  ];

  editable = {
    onRowAdd: (newData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          let validator = this.validateFields(newData[FIELD_PROJECT_NAME], newData[FIELD_STRUCTURES], newData[FIELD_PROJECT_MANAGERS]);
          if (validator.validate()) {
            resolve();
            this.props.addProject(newData[FIELD_PROJECT_NAME], extractIds(newData[FIELD_STRUCTURES]), extractIds(newData[FIELD_PROJECT_MANAGERS]));
          } else {
            this.props.showError("Invalid Input", validator.generateColumnErrorDescription(this.getColumns()));
            reject();
          }
        }, 100);
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          let validator = this.validateFields(newData[FIELD_PROJECT_NAME], newData[FIELD_STRUCTURES], newData[FIELD_PROJECT_MANAGERS]);
          if (validator.validate()) {
            resolve();
            this.props.updateProject(
              oldData[FIELD_ID],
              newData[FIELD_PROJECT_NAME],
              extractIds(newData[FIELD_STRUCTURES]),
              extractIds(newData[FIELD_PROJECT_MANAGERS])
            );
          } else {
            reject();
            this.props.showError("Invalid Input", validator.generateColumnErrorDescription(this.getColumns()));
          }
        }, 100);
      }),
  };

  render() {
    return (
      <Table
        id="PROJECTS_TABLE"
        title={Strings.getInstance().getString("projects_table_title")}
        columns={this.getColumns()}
        options={{ actionsColumnIndex: -1 }}
        data={this.props.data}
        actions={this.actions}
        editable={this.editable}
        onRowClick={this.onRowClick}
        isLoading={this.props.loading}
        disableHideColumnOption
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: mapMultipleRows(state),
    loading: state.ProjectsReducer.loading,
    structureOptions: mapStructureOptions(state),
    projectManagerOptions: mapProjectManagerOptions(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    watchProjectsTableData: () => dispatch(watchProjectsTableData()),
    addProject: (projectName, structures, projectManagers) => dispatch(addProject(projectName, structures, projectManagers)),
    updateProject: (id, projectName, structures, projectManagers) => dispatch(updateProject(id, projectName, structures, projectManagers)),
    showError: (title, description) => dispatch(showInfoDialog(title, description)),
    confirmDeleteProject: (pendingAction) =>
      dispatch(showConfirmationDialog(Strings.getInstance().getString("dialog_delete_project_title"), Strings.getInstance().getString("dialog_delete_project_description"), pendingAction)),
    getUserOptions: () => dispatch(watchUsersTableData()),
    getStructureOptions: () => dispatch(watchStructuresTableData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);