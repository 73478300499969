import React from "react";
import Checkbox from "components/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const styles = {
  fontSize: "14px",
};

export default function CheckboxTableField(props) {
  return (
    <Autocomplete
      multiple={props.multiple}
      size="small"
      value={props.value}
      options={props.options}
      onChange={props.onChange}
      disableCloseOnSelect
      disableClearable
      getOptionSelected={(option, value) => {
        return option["id"] === value["id"];
      }}
      getOptionLabel={(option) => option.name}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          <div style={styles}>{option.name}</div>
        </React.Fragment>
      )}
      renderInput={(params) => <TextField {...params} />}
    />
  );
}
