import React, { Component } from "react";
import Table from "components/Table";
import ColumnBuilder from "helper/ColumnBuilder";
import { Colors } from "config/StyleConfig";
import { Strings } from "config/Strings";
import Navigator from "Navigator";

export const FIELD_ID = "id";
export const FIELD_STRUCTURE = "structure_id";
export const FIELD_STRUCTURE_NAME = "structure_name";

export default class SGRHeatMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showArchived: false,
    };
  }

  getColumns = () => {
    var columns = [];
    columns.push(new ColumnBuilder(Strings.getInstance().getString("inspections_table_structure_id"), FIELD_STRUCTURE)
    .render((rowData) => {
      return <div>{rowData[FIELD_STRUCTURE_NAME]}</div>;
    })
    .build());

    var projectNames = {};
    (this.props.projectData).forEach( x => {
      const k = Object.keys(x)[0];
      projectNames[k] = x[k];
    });

    var columnProjects = {};
    (this.props.data).forEach( x => {
      const keys = Object.keys(x);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
      
        if(key !== 'id' && key !== 'structure_name' && !columnProjects.hasOwnProperty(key) && projectNames.hasOwnProperty(key)){
          columnProjects[key] = false;
          columns.push(
            new ColumnBuilder(projectNames[key],key)
            .render((rowData) => {
              if (rowData !== undefined) {
                return this.styleSGR(rowData[key]);
              }
            })
            .centerHeaders()
            .build())
        }
      }
    })

    return columns;
  };

  styleSGR(value) {
    var style = {
      border: null,
      borderRadius: "1.25rem",
      padding: "2px 25px",
      color: "black",
      textAlign: "center",
      fontWeight: "bold",
    };

    var displayText = "";

    if (value > 80) { //1
      style["backgroundColor"] = Colors.heat1;
      displayText = Strings.getInstance().mapSGR(value);
    } else if (value > 60) { //2
      style["backgroundColor"] = Colors.heat2;
      displayText = Strings.getInstance().mapSGR(value);
    } else if (value > 40) { //3
      style["backgroundColor"] = Colors.heat3;
      displayText = Strings.getInstance().mapSGR(value);
    } else if (value > 20) { //4
      style["backgroundColor"] = Colors.heat4;
      displayText = Strings.getInstance().mapSGR(value);
    } else if (value < 20) { //5
      style["backgroundColor"] = Colors.heat5;
      displayText = Strings.getInstance().mapSGR(value);
    } else if (value === undefined) {
      displayText = "";
    }

    return <div style={style}>{displayText}</div>;
  }

  getActions = () => {
    var actions = [];
    actions.push({
      icon: "refresh",
      tooltip: "Refresh Data",
      isFreeAction: "true",
      onClick: () => {
        this.props.refreshCallback();
        this.props.refreshProject();
      },
    });

    return actions;
  };

  onRowClick = (event, rowData) => {
    Navigator.navigateToStructure(rowData[FIELD_ID]);
  };


  render() {
    return (
      <Table
        id="SGR_HEAT_MAP"
        title={Strings.getInstance().getString("analytics_table_title")}
        columns={this.getColumns()}
        options={{
          actionsColumnIndex: -1,
          exportButton: false
        }}
        data={this.props.data}
        actions={this.getActions()}
        isLoading={this.props.isLoading}        
        onRowClick={this.onRowClick}
      />
    );
  }
}
