import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

export default function CircularProgressWithLabel(props) {
  const useStyles = makeStyles(() => ({
    overallColor: {
      color: props.color,
    },
  }));

  const classes = useStyles();

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress className={classes.overallColor} size={190} variant="static" value={props.value} />
      <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
        <Typography variant={"h3"} className={classes.overallColor} component="div">
          {props.label || props.value} {props.label === undefined ? "%" : ""}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and static variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
