import { useQuery } from '@tanstack/react-query';
import { Alert } from 'baseui/icon';
import { fixRotationOfFile } from 'helper/ImageRotator';
import React, { useEffect, useRef, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { fetcherBlob } from 'services/fetcher';
import { stitchesConfig } from 'theme';
import ReactPanZoom from 'react-image-pan-zoom-rotate';

const { css } = stitchesConfig;

const useProductImage = (
  link: string,
  options?: { onError?: () => void; onSuccess?: () => void },
) =>
  useQuery(
    ['defect-image', link, { path: link }],
    async () => {
      const response = await fetcherBlob(link);
      const image = await fixRotationOfFile(response);

      return image as Blob;
    },
    {
      enabled: Boolean(link),
      staleTime: Infinity,
      retryOnMount: false,
      retry: 1,
      onError: options?.onError,
      onSuccess: options?.onSuccess,
    },
  );

type ProductImageProps = {
  link: string;
  children?: (src: string) => React.ReactNode;
  className?: string;
  onError?: () => void;
  onImageLoaded?: () => void;
  showControls?: boolean;
};

export default function SecuredImage(props: ProductImageProps) {
  const [isShown, setIsShown] = useState(false);

  return (
    <VisibilitySensor
      active={!isShown}
      onChange={(isVisible: any) => setIsShown(isVisible)}
    >
      {({ isVisible }: { isVisible: boolean }) => (
        <ProductImage {...props} link={isVisible ? props.link : ''} />
      )}
    </VisibilitySensor>
  );
}

const defaultClassName = css({
  width: '$full',
  height: '$full',
  objectFit: 'cover',
});

function ProductImage({
  link = '',
  children,
  className = defaultClassName(),
  onError: onErrorHandler,
  onImageLoaded: onImageLoadedHandler,
  showControls = false,
}: ProductImageProps) {
  const [imageError, setImageError] = useState(false);

  if (link.length === 0) {
    return (
      <div
        className={defaultClassName({
          css: {
            layout: 'stack',
            alignItems: 'center',
            justifyContent: 'center',
            p: '$scale100',
            backgroundColor: '$accent100',
            width: '80px',
            height: '80px',
          },
        })}
      >
        No <br /> Image
      </div>
    );
  }

  if (imageError) {
    return (
      <div
        aria-label="Failed to get image. refresh page and try again."
        className={defaultClassName({
          css: {
            layout: 'stack',
            alignItems: 'center',
            justifyContent: 'center',
            p: '$scale100',
            backgroundColor: '$negative100',
            width: '80px',
            height: '80px',
          },
        })}
      >
        <p
          className={defaultClassName({
            css: {
              color: '$negative500',
              layout: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            },
          })}
        >
          <Alert color="negative" size={24} />
        </p>
      </div>
    );
  }

  function getImage() {
    return (
      <img
        src={link}
        alt="Defect"
        className={`${className} ${css({ backgroundColor: '$mono500' })}`}
        onError={() => setImageError(true)}
        draggable={false}
      />
    );
  }

  function getImageWithControlls() {
    return <ReactPanZoom image={link} alt="Defect" />;
  }

  return showControls ? getImageWithControlls() : getImage();
}
