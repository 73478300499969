import React from "react";
import stringsJson from "./strings.json";
import flavorsJson from "./flavors.json";
import TokenManager from "storage/TokenManager";

export const Strings = (function () {
  var instance;

  return {
    getInstance: function () {
      if (!instance) {
        instance = new StringsInstance();
      }
      return instance;
    },
  };
})();



class StringsInstance {
  constructor() {
    this.strings = stringsJson;
    this.flavors = flavorsJson;
  }

  getString(key) {
    var companyId = TokenManager.getCompanyId();
    if (companyId in this.flavors && "strings" in this.flavors[companyId]) {
      var result = this.flavors[companyId]["strings"][key];
      if (result) {
        return result;
      }
    }

    if ( this.strings[key] === "getPasswordDescription()") {
      return this.getPasswordDescription();
    } else {
      return this.strings[key] || "";
    }
  }

  mapString(key, string) {
    var companyId = TokenManager.getCompanyId();
    if (companyId in this.flavors && "mapping" in this.flavors[companyId] && key in this.flavors[companyId]["mapping"]) {
      var result = this.flavors[companyId]["mapping"][key][string];
      if (result) {
        return result;
      }
    }

    return string;
  }

  mapCR(value) {
    var companyId = TokenManager.getCompanyId();
    if (companyId in this.flavors && "sgr_ratings" in this.flavors[companyId]) {
      if (value > 0.8) {
        return this.flavors[companyId]["cr_values"]["0.8"]
      } else if (value > 0.6) {
        return this.flavors[companyId]["cr_values"]["0.6"]
      } else if (value > 0.4) {
        return this.flavors[companyId]["cr_values"]["0.4"]
      } else if (value > 0.2) {
        return this.flavors[companyId]["cr_values"]["0.2"]
      } else if (value < 0.2) {
        return this.flavors[companyId]["cr_values"]["0.0"]
      } else { return ""}
    }

    return value;
  }

  mapSGR(value) {
    var companyId = TokenManager.getCompanyId();
    if (companyId in this.flavors && "sgr_ratings" in this.flavors[companyId]) {
      if (value > 80) {
        return this.flavors[companyId]["sgr_ratings"]["80"]
      } else if (value > 60) {
        return this.flavors[companyId]["sgr_ratings"]["60"]
      } else if (value > 40) {
        return this.flavors[companyId]["sgr_ratings"]["40"]
      } else if (value > 20) {
        return this.flavors[companyId]["sgr_ratings"]["20"]
      } else if (value < 20) {
        return this.flavors[companyId]["sgr_ratings"]["0"]
      } else { return ""}
    }

    return value;
  }

  getPasswordDescription = () => {
    var optimizationKey = 0;
    var description = [];
    description.push(<div key={optimizationKey++}>Create a new password for this user. Password length must be from 8 to 16 and must contain:</div>);
    description.push(<li key={optimizationKey++}>1 capital letter</li>);
    description.push(<li key={optimizationKey++}>1 lowercase letter</li>);
    description.push(<li key={optimizationKey++}>1 digit</li>);
    description.push(<li key={optimizationKey++}>1 special symbol (e.g. !@#$%^*()-_,~)</li>);
    return description;
  }
}
