import { useMutation, useQuery } from '@tanstack/react-query';
import { Input } from 'baseui/input';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal';
import { Option, StatefulSelect } from 'baseui/select';
import { Skeleton } from 'baseui/skeleton';
import { Textarea } from 'baseui/textarea';
import { Banner, KIND as BannerKind } from 'components/Banner';
import { FormController } from 'components/FormController';
import Text from 'components/Text';
import Config from 'config/Config';
import Helper from 'helper/Helper';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getInspection } from 'services/api/inspection/queries';
import { ObservationDefect } from 'services/api/inspection/types';
import { fetcher } from 'services/fetcher';
import { useStitches } from 'theme';
import { useQuerystring } from 'utils/hooks';

type FormFields = {
  inspectionUUID: string;
  uuid: string[];
  class: string;
  location: string;
  stationing: string;
  correctiveAction: string;
  description: string;
  repairMethod: string;
  repairBy: string;
};

type UpdateObservationPayload = FormFields & {
  defectUUIDS: string[];
  inspectionUUID: string;
};

const updateObservation = async (payload: UpdateObservationPayload) => {
  const inspection = await getInspection({
    params: { inspectionId: payload.inspectionUUID },
  });
  const allDefects = inspection.observations.reduce((all, observation) => {
    return [...all, ...observation.observationDefects.map((defect) => defect)];
  }, [] as ObservationDefect[]);

  return Promise.all(
    payload.defectUUIDS.map((id) => {
      const matchedDefect = allDefects.find((defect) => defect.uuid === id);
      if (!matchedDefect) {
        return undefined;
      }
      return fetcher(
        Helper.parse(
          Config.UPDATE_OBSERVATION_API,
          payload.inspectionUUID,
          matchedDefect.observationId,
        ),
        {
          method: 'post',
          body: JSON.stringify({
            ...matchedDefect,
            ...(payload.repairMethod
              ? { repairMethod: payload.repairMethod }
              : {}),
            ...(payload.repairBy ? { repairBy: payload.repairBy } : {}),
            ...(payload.location ? { span: payload.location } : {}),
            ...(payload.description
              ? { description: payload.description }
              : {}),
            ...(payload.correctiveAction
              ? { observationType: payload.correctiveAction }
              : {}),
            ...(payload.class ? { type: payload.class } : {}),
            // stationMarker: payload.stationing,
          }),
        },
      );
    }),
  );
};
const useUpdateObservation = () => useMutation(updateObservation);

export default function UpdateObservation({
  onUpdated: onUpdatedHandler,
}: {
  onUpdated?: VoidFunction;
}) {
  const { css } = useStitches();
  const history = useHistory();
  const location = useLocation();
  const searchParams = useQuerystring();
  const isModalMatch = searchParams.get('modal') === 'bulk_update';
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isModalMatch);
  }, [isModalMatch]);

  const params = useParams();
  const observationIds = JSON.parse(
    searchParams.get('ids') ?? '[]',
  ) as string[];
  const updateObservation = useUpdateObservation();
  const { control, handleSubmit } = useForm<FormFields>();

  const closeModal = () => {
    if (updateObservation.isLoading) return;
    searchParams.delete('modal');
    searchParams.delete('ids');
    setIsOpen(false);
    setTimeout(() => {
      history.replace(`${location.pathname}?${searchParams.toString()}`);
    }, 100);
  };
  const onSubmit = handleSubmit((data) => {
    updateObservation.mutate(
      {
        defectUUIDS: observationIds,
        // @ts-ignore
        inspectionUUID: params.inspectionId ?? '',
        ...data,
      },
      {
        onSuccess: () => {
          closeModal();
          if (onUpdatedHandler) {
            onUpdatedHandler();
          }
        },
      },
    );
  });

  return (
    <Modal onClose={() => closeModal()} isOpen={isOpen}>
      <ModalHeader>Update Multiple Observation</ModalHeader>
      <ModalBody>
        <div>
          <Text>Updating the following observation/s</Text>
          <ul>
            {observationIds.map((id) => (
              <li>{id}</li>
            ))}
          </ul>
        </div>
        <Banner
          show={updateObservation.isError}
          title="Error"
          kind={BannerKind.negative}
        >
          Failed to update observations, try again.
        </Banner>
        <form
          id="update-observations"
          onSubmit={onSubmit}
          className={css({
            display: 'grid',
            gridTemplateColumns: 'repeat(2,1fr)',
            columnGap: '$scale600',
          })}
        >
          <FormController control={control} name="class" label="Class">
            {({ field }) => (
              <StatefulSelect
                options={[
                  { id: 'MAINTENANCE', label: 'NSMI' },
                  { id: 'STRUCTURAL', label: 'SD' },
                ]}
                onChange={({ option }) => field.onChange(option?.id ?? '')}
              />
            )}
          </FormController>
          <FormController control={control} name="location" label="Location">
            {({ field }) => <Input {...field} />}
          </FormController>
          {/* <FormController
            control={control}
            name="stationing"
            label="Stationing"
          >
            {({ field }) => <Input {...field} />}
          </FormController> */}
          <FormController
            control={control}
            name="correctiveAction"
            label="Observation Type"
          >
            {({ field }) => (
              <ObservationTypeSelect
                onChange={(option) => field.onChange(option?.id ?? '')}
              />
            )}
          </FormController>
          <FormController
            control={control}
            name="description"
            label="Description"
          >
            {({ field }) => <Input {...field} />}
          </FormController>
          <FormController control={control} name="repairBy" label="Repair By">
            {({ field }) => <Input {...field} />}
          </FormController>
          <FormController
            control={control}
            name="repairMethod"
            label="Repair Method"
            overrides={{
              Root: {
                style: {
                  gridColumn: '1/-1',
                },
              },
            }}
          >
            {({ field }) => <Textarea onChange={field.onChange} />}
          </FormController>
        </form>
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={() => closeModal()}>Cancel</ModalButton>
        <ModalButton
          form="update-observations"
          type="submit"
          isLoading={updateObservation.isLoading}
        >
          Update Observations
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}

type CustomSelectProps<T = Option> = {
  value?: T;
  onChange: (value?: T) => void;
};

function getStructures() {
  return fetcher(Config.GET_COMPLETE_DICTIONARY).then((r) => r);
}

const useStructureDictionary = () =>
  useQuery(['dictionary', 'structures'], () => getStructures(), {
    staleTime: Infinity,
  });

function ObservationTypeSelect({ onChange }: CustomSelectProps) {
  const structureQuery = useStructureDictionary();

  if (structureQuery.error) {
    return <div>Error</div>;
  }

  if (structureQuery.data) {
    const observationTypes = structureQuery.data.observationTypes as any[];
    const options: Option[] = observationTypes.map((item) => ({
      id: item.value,
      label: item.name,
    }));

    return (
      <StatefulSelect
        options={options}
        onChange={({ option }) => onChange(option ?? undefined)}
      />
    );
  }

  return <Skeleton rows={1} />;
}

function CriticalFindingsSelect({ onChange }: CustomSelectProps<string[]>) {
  const structureQuery = useStructureDictionary();

  if (structureQuery.error) {
    return <div>Error</div>;
  }

  if (structureQuery.data) {
    const criticalFindings = structureQuery.data.criticalFindings as any[];
    const options: Option[] = criticalFindings.map((item) => ({
      id: item.value,
      label: item.name,
    }));

    return (
      <StatefulSelect
        options={options}
        onChange={({ value }) => {
          if (value) {
            onChange(value.map((item) => `${item.id}`).filter(Boolean));
          }
        }}
      />
    );
  }

  return <Skeleton rows={1} />;
}
