import { useQuery } from '@tanstack/react-query';
import Config from 'config/Config';
import Helper from 'helper/Helper';

import {
  FetcherJSONResponse,
  fetcher,
  FetcherQueryArgs,
} from 'services/fetcher';

import { deserializeProject } from './deserializer';

async function getProjects() {
  return fetcher(Config.GET_PROJECTS_API).then((r: FetcherJSONResponse[]) =>
    r.map(deserializeProject),
  );
}

export function useProjects() {
  return useQuery(['projects'], getProjects);
}

type GetProjectArgs = FetcherQueryArgs<{ id: number }>;
async function getProject(args: GetProjectArgs) {
  const url = Helper.parse(Config.GET_PROJECT_API, args.params.id);
  return fetcher(url).then((r: FetcherJSONResponse) => deserializeProject(r));
}

export function useProject(id?: number) {
  return useQuery(
    ['projects', id],
    () => getProject({ params: { id: id ?? 0 } }),
    {
      enabled: Boolean(id),
    },
  );
}
