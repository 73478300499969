import {
  FETCH_PROJECTS_STARTED,
  FETCH_PROJECTS_SUCCESS,
  ADD_PROJECT_FAILED,
  ADD_PROJECT_STARTED,
  ADD_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILED,
  UPDATE_PROJECT_STARTED,
  UPDATE_PROJECT_SUCCESS,
  DELETE_PROJECT_STARTED,
  DELETE_PROJECT_FAILED,
  DELETE_PROJECT_SUCCESS,
} from "./ProjectsReducer";

import { Strings } from "config/Strings";
import { showInfoDialog } from "components/Dialog/DialogAction";

import ProjectAPIController from "../../services/api/ProjectAPIController";

const fetchProjectsTableDataSuccess = (data) => {
  return {
    type: FETCH_PROJECTS_SUCCESS,
    data: data,
  };
};

const fetchProjectsTableDataStarted = () => {
  return {
    type: FETCH_PROJECTS_STARTED,
  };
};

const addProjectToTableFailed = () => {
  return {
    type: ADD_PROJECT_FAILED,
  };
};

const addProjectToTableStarted = () => {
  return {
    type: ADD_PROJECT_STARTED,
  };
};

const addProjectToTableSuccess = (project) => {
  return {
    type: ADD_PROJECT_SUCCESS,
    project: project,
  };
};

const updateProjectInTableFailed = () => {
  return {
    type: UPDATE_PROJECT_FAILED,
  };
};

const updateProjectInTableStarted = () => {
  return {
    type: UPDATE_PROJECT_STARTED,
  };
};

const updateProjectInTableSuccess = (project) => {
  return {
    type: UPDATE_PROJECT_SUCCESS,
    project: project,
  };
};

const deleteProjectFromTableFailed = () => {
  return {
    type: DELETE_PROJECT_FAILED,
  };
};

const deleteProjectFromTableStarted = () => {
  return {
    type: DELETE_PROJECT_STARTED,
  };
};

const deleteProjectFromTableSuccess = (projectId) => {
  return {
    type: DELETE_PROJECT_SUCCESS,
    projectId: projectId,
  };
};

const watchProjectsTableData = () => {
  return function (dispatch) {
    dispatch(fetchProjectsTableDataStarted());
    ProjectAPIController.getProjects()
      .then((response) => dispatch(fetchProjectsTableDataSuccess(response)))
      .catch((error) => {
        console.log(error);
      });
  };
};

const addProject = (projectName, structures, projectManagers) => {
  return function (dispatch) {
    dispatch(addProjectToTableStarted());
    ProjectAPIController.addProject(projectName, structures, projectManagers)
      .then((response) => {
        dispatch(addProjectToTableSuccess(response));
      })
      .catch((error) => {
        dispatch(addProjectToTableFailed());
        dispatch(showInfoDialog(Strings.getInstance().getString("dialog_error_add_project_title"), Strings.getInstance().getString("dialog_error_add_project_description")));
        console.log(error);
      });
  };
};

const updateProject = (id, projectName, structures, projectManagers) => {
  return function (dispatch) {
    dispatch(updateProjectInTableStarted());
    ProjectAPIController.updateProject(id, projectName, structures, projectManagers)
      .then((response) => dispatch(updateProjectInTableSuccess(response)))
      .catch((error) => {
        dispatch(updateProjectInTableFailed());
        dispatch(showInfoDialog(Strings.getInstance().getString("dialog_error_update_project_title"), Strings.getInstance().getString("dialog_error_update_project_description")));
        console.log(error);
      });
  };
};

const deleteProject = (projectId) => {
  return function (dispatch) {
    dispatch(deleteProjectFromTableStarted());
    ProjectAPIController.deleteProject(projectId)
      .then((response) => dispatch(deleteProjectFromTableSuccess(projectId)))
      .catch((error) => {
        dispatch(deleteProjectFromTableFailed());
        dispatch(showInfoDialog(Strings.getInstance().getString("dialog_error_delete_project_title"), Strings.getInstance().getString("dialog_error_delete_project_description")));
        console.log(error);
      });
  };
};

export { watchProjectsTableData, addProject, updateProject, deleteProject };
