import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import GeneralCard from "components/GeneralCard";
import { Colors } from "config/StyleConfig";
import { Strings } from "config/Strings";

export default class TopInspectorsCard extends Component {
  extractData() {
    const data = this.props.data;

    var chartData = { labels: [], inProgress: [], completed: [] };
    for (var i = 0; i < data.length; i++) {
      chartData.labels.push(data[i].name);
      chartData.inProgress.push(data[i].inProgress);
      chartData.completed.push(data[i].completed);
    }

    return chartData;
  }

  render() {
    const title = Strings.getInstance().getString("inspections_most_active_inspectors");
    const chartData = this.extractData();
    const data = {
      labels: chartData.labels,
      datasets: [
        {
          label: "In Progress",
          backgroundColor: Colors.inProgress,
          data: chartData.inProgress,
        },
        {
          label: "Completed",
          backgroundColor: Colors.completed,
          data: chartData.completed,
        },
      ],
    };

    const options = {
      scales: {
        yAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              min: 0,
              maxTicksLimit: 10,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
    };

    const content = <Bar data={data} height={80} options={options} legend={{ position: "bottom" }} />;

    return <GeneralCard title={title} content={content} />;
  }
}
