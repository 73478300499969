import {
  FETCH_STRUCTURES_STARTED,
  FETCH_STRUCTURES_SUCCESS,
  ADD_STRUCTURE_FAILED,
  ADD_STRUCTURE_STARTED,
  ADD_STRUCTURE_SUCCESS,
  UPDATE_STRUCTURE_FAILED,
  UPDATE_STRUCTURE_STARTED,
  UPDATE_STRUCTURE_SUCCESS,
  DELETE_STRUCTURE_STARTED,
  DELETE_STRUCTURE_FAILED,
  DELETE_STRUCTURE_SUCCESS,
} from "./StructuresReducer";

import { Strings } from "config/Strings";
import { showInfoDialog } from "components/Dialog/DialogAction";
import StructureAPIController from "../../services/api/StructureAPIController";

const fetchStructuresTableDataSuccess = (data) => {
  return {
    type: FETCH_STRUCTURES_SUCCESS,
    data: data,
  };
};

const fetchStructuresTableDataStarted = () => {
  return {
    type: FETCH_STRUCTURES_STARTED,
  };
};

const addStructureToTableFailed = () => {
  return {
    type: ADD_STRUCTURE_FAILED,
  };
};

const addStructureToTableStarted = () => {
  return {
    type: ADD_STRUCTURE_STARTED,
  };
};

const addStructureToTableSuccess = (structure) => {
  return {
    type: ADD_STRUCTURE_SUCCESS,
    structure: structure,
  };
};

const updateStructureInTableFailed = () => {
  return {
    type: UPDATE_STRUCTURE_FAILED,
  };
};

const updateStructureInTableStarted = () => {
  return {
    type: UPDATE_STRUCTURE_STARTED,
  };
};

const updateStructureInTableSuccess = (structure) => {
  return {
    type: UPDATE_STRUCTURE_SUCCESS,
    structure: structure,
  };
};

const deleteStructureFromTableFailed = () => {
  return {
    type: DELETE_STRUCTURE_FAILED,
  };
};

const deleteStructureFromTableStarted = () => {
  return {
    type: DELETE_STRUCTURE_STARTED,
  };
};

const deleteStructureFromTableSuccess = (structureId) => {
  return {
    type: DELETE_STRUCTURE_SUCCESS,
    structureId: structureId,
  };
};

const watchStructuresTableData = (companyId) => {
  return function (dispatch) {
    dispatch(fetchStructuresTableDataStarted());
    StructureAPIController.getStructures(companyId, true)
      .then((response) => {
        dispatch(fetchStructuresTableDataSuccess(response));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const addStructure = (code, name, type, caltransBridgeNo, postMile, beginStationing, endStationing, clientId) => {
  return function (dispatch) {
    dispatch(addStructureToTableStarted());
    StructureAPIController.addStructure(code, name, type, caltransBridgeNo, postMile, beginStationing, endStationing, clientId)
      .then((response) => {
        dispatch(addStructureToTableSuccess(response));
      })
      .catch((error) => {
        dispatch(addStructureToTableFailed());
        dispatch(showInfoDialog(Strings.getInstance().getString("dialog_error_add_structure_title"), Strings.getInstance().getString("dialog_error_add_structure_description")));
        console.log(error);
      });
  };
};

const updateStructure = (id, code, name, type, caltransBridgeNo, postMile, beginStationing, endStationing, clientId) => {
  return function (dispatch) {
    dispatch(updateStructureInTableStarted());
    StructureAPIController.updateStructure(id, code, name, type, caltransBridgeNo, postMile, beginStationing, endStationing, clientId)
      .then((response) => dispatch(updateStructureInTableSuccess(response)))
      .catch((error) => {
        console.log(error);
        dispatch(updateStructureInTableFailed());
        dispatch(showInfoDialog(Strings.getInstance().getString("dialog_error_update_structure_title"), Strings.getInstance().getString("dialog_error_update_structure_description")));
        console.log(error);
      });
  };
};

const deleteStructure = (structureId) => {
  return function (dispatch) {
    dispatch(deleteStructureFromTableStarted());
    StructureAPIController.deleteStructure(structureId)
      .then((response) => {
        dispatch(deleteStructureFromTableSuccess(structureId));
      })
      .catch((error) => {
        dispatch(deleteStructureFromTableFailed());
        dispatch(showInfoDialog(Strings.getInstance().getString("dialog_error_delete_structure_title"), Strings.getInstance().getString("dialog_error_delete_structure_description")));
        console.log(error);
      });
  };
};

export { watchStructuresTableData, addStructure, updateStructure, deleteStructure };
