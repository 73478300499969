import React, { Component } from "react";
import CleanTreeItem from "components/CleanTreeItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import LabeledText from "components/LabeledText";
import { Colors } from "config/StyleConfig";
import { Checkbox } from "@material-ui/core";
import TightCardContent from "components/TightCardContent";

export default class SelectableTreeItem extends Component {
  constructor(props) {
    super(props);
  }

  onLabelClick(event) {
    if (event.target.type === "checkbox") event.stopPropagation();
  }

  render() {
    const { label, name, ...rest } = this.props;
    const selected = this.props.selected;

    return (
      <CleanTreeItem
        onLabelClick={(event) => this.onLabelClick(event)}
        style={{ marginBottom: 10, marginTop: 10, backgroundColor: "transparent" }}
        nodeId={this.props.id}
        label={
          <Card variant="outlined">
            <TightCardContent>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", flex: 8 }}>
                  <Checkbox style={{ marginRight: 10 }} checked={selected} onChange={() => this.props.onSelected(this.props.id, !selected)} />
                  <LabeledText style={{ flex: 1 }} label={label} value={name} />
                </div>
                <div style={{ flex: 1, textAlign: "right" }}>{this.props.content}</div>
              </div>
            </TightCardContent>
          </Card>
        }
        {...rest}
      />
    );
  }
}
