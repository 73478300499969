import Config from "config/Config";
import Helper from "helper/Helper";
import { APIException } from "Exceptions";
import SecureAPIBuilder from "./SecureAPIBuilder";

export default class ClientAPIController {
  // Get calls.
  static getClients() {
    return new SecureAPIBuilder(Config.GET_CLIENTS_API, SecureAPIBuilder.GET)
      .query({ type: "OWNER" })
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static getClient(clientId) {
    return new SecureAPIBuilder(Helper.parse(Config.GET_CLIENT_API, clientId), SecureAPIBuilder.GET).call().then((response) => {
      if (!response.ok) {
        throw new APIException(response.status, response.statusText);
      }
      return response.json();
    });
  }

  // Insert calls.
  static addClient(name) {
    const body = JSON.stringify({
      name: name,
      type: "OWNER",
    });

    return new SecureAPIBuilder(Config.SAVE_CLIENT_API, SecureAPIBuilder.POST)
      .body(body)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  // Delete calls.
  static deleteClient(clientId) {
    return new SecureAPIBuilder(Config.DELETE_CLIENT_API, SecureAPIBuilder.DELETE)
      .query({ id: clientId })
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  // Update calls.
  static updateClient(id, name) {
    const body = JSON.stringify({
      id: id,
      name: name,
      type: "OWNER",
    });

    return new SecureAPIBuilder(Config.SAVE_CLIENT_API, SecureAPIBuilder.POST)
      .body(body)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }
}
