import { FETCH_CLIENT_STARTED, FETCH_CLIENT_SUCCESS } from "./ClientReducer";
import ClientAPIController from "../../services/api/ClientAPIController";

const fetchClientsTableDataSuccess = (data) => {
  return {
    type: FETCH_CLIENT_SUCCESS,
    data: data,
  };
};

const fetchClientsTableDataStarted = () => {
  return {
    type: FETCH_CLIENT_STARTED,
  };
};

const getClientData = (clientId) => {
  return function (dispatch) {
    dispatch(fetchClientsTableDataStarted());
    ClientAPIController.getClient(clientId)
      .then((response) => dispatch(fetchClientsTableDataSuccess(response)))
      .catch((error) => {
        console.log(error);
      });
  };
};

export { getClientData };
