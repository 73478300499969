import { APIException } from 'Exceptions';
import { ErrorWrapper } from 'services/fetcher';

type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

export function isAPIError(
  error: unknown,
): error is ReturnType<typeof APIException> & { code: number } {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    'code' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}
function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message;
}

export function getAPIError(
  error: unknown,
  errorMap?: Record<'404' | '400' | '500' | '401', string>,
) {
  if (isAPIError(error)) {
    return error;
  }
  return toErrorWithMessage(error).message;
}
