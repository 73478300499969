import Config from 'config/Config';
import Helper from 'helper/Helper';
import { APIException } from 'Exceptions';
import SecureAPIBuilder from './SecureAPIBuilder';
import { v4 as uuid } from 'uuid';

export default class InspectionAPIController {
  // Get calls.
  static getInspection(inspectionId) {
    const url = Helper.parse(Config.GET_INSPECTION_API, inspectionId);
    var getInspectionAPICall = new SecureAPIBuilder(url, SecureAPIBuilder.GET);

    return getInspectionAPICall.call().then(response => {
      if (!response.ok) {
        throw new APIException(response.status, response.statusText);
      }
      return response.json();
    });
  }

  static getInspections(projectId, structureId) {
    var query = { withObservations: false };

    if (structureId !== undefined) {
      query.structureId = structureId;
    } else if (projectId !== undefined) {
      query.projectId = projectId;
    }

    return new SecureAPIBuilder(Config.INSPECTION_API, SecureAPIBuilder.GET)
      .query(query)
      .call()
      .then(response => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static getInspectionReport(inspectionID) {
    const url = Helper.parse(Config.INSPECTION_REPORT_API, inspectionID);
    return new SecureAPIBuilder(url, SecureAPIBuilder.POST)
      .call()
      .then(response => response.json())
      .then(jsonResponse =>
        new SecureAPIBuilder(
          Config.PROXY + jsonResponse['link'],
          SecureAPIBuilder.GET,
        ).call(),
      )
      .then(fileResponse => fileResponse.blob());
  }

  static getInspectionPhotosZip(inspectionId) {
    const url = Helper.parse(
      Config.DOWNLOAD_INSPECTION_PHOTOS_API,
      inspectionId,
    );
    return new SecureAPIBuilder(url, SecureAPIBuilder.GET)
      .call()
      .then(fileResponse => fileResponse.blob());
  }

  // Insert calls.
  static addInspection(projectId, structureId, spansCount, startDate) {
    const inspectionId = uuid();
    const body = JSON.stringify({
      projectId: projectId,
      structureId: structureId,
      uuid: inspectionId,
      startDate:
        Helper.convertToDBDateFormat(startDate) || Helper.getCurrentDateTime(),
      spansCount: spansCount,
      status: 'IN_PROGRESS',
    });

    return new SecureAPIBuilder(
      Config.POST_INSPECTION_API,
      SecureAPIBuilder.POST,
    )
      .body(body)
      .call()
      .then(response => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  // Delete calls.
  static deleteInspection(inspectionId) {
    const url = Helper.parse(Config.DELETE_INSPECTION_API, inspectionId);
    return new SecureAPIBuilder(url, SecureAPIBuilder.DELETE)
      .call()
      .then(response => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  // Update calls.
  static updateInspectorsInInspection(inspectionId, inspectorIds) {
    if (inspectorIds === undefined) {
      return Promise.resolve();
    }

    const inspectorAssignBody = JSON.stringify({
      inspectionId: inspectionId,
      inspectors: inspectorIds,
    });

    return new SecureAPIBuilder(
      Config.ASSIGN_INSPECTOR_TO_INSPECTION_API,
      SecureAPIBuilder.POST,
    )
      .body(inspectorAssignBody)
      .call()
      .then(response => {
        return response.json();
      });
  }

  static updateInspection(
    inspectionId,
    startDate,
    projectId,
    structureId,
    spansCount,
    status,
    termRating,
    generalSummary,
    endDate,
  ) {
    const body = JSON.stringify({
      projectId: projectId,
      startDate: startDate,
      structureId: structureId,
      uuid: inspectionId,
      spansCount: spansCount,
      status: status,
      endDate:
        status === 'COMPLETED'
          ? Helper.convertToDBDateFormat(endDate) || Helper.getCurrentDateTime()
          : Helper.convertToDBDateFormat(endDate),
      termRating: termRating,
      generalSummary: generalSummary,
    });

    return new SecureAPIBuilder(
      Config.POST_INSPECTION_API,
      SecureAPIBuilder.POST,
    )
      .body(body)
      .call()
      .then(response => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static updateInspectionSummary(inspectionId, summary) {
    const body = JSON.stringify({
      uuid: inspectionId,
      summary: summary,
    });

    return new SecureAPIBuilder(
      Config.UPDATE_INSPECTION_SUMMARY_API,
      SecureAPIBuilder.POST,
    )
      .body(body)
      .call()
      .then(response => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static archiveInspection(inspectionId, archived) {
    const body = JSON.stringify({ uuid: inspectionId, archived: archived });
    return new SecureAPIBuilder(
      Config.ARCHIVE_INSPECTION_API,
      SecureAPIBuilder.POST,
    )
      .body(body)
      .call()
      .then(response => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }
}
