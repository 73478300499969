import { CalendarToday, PinDrop, WarningSharp } from '@material-ui/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { KIND as ButtonKind } from 'baseui/button';
import { arrayMove, arrayRemove, List } from 'baseui/dnd-list';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal';
import { Select } from 'baseui/select';
import { Skeleton } from 'baseui/skeleton';
import {
  KIND as TagKind,
  SIZE as TagSize,
  Tag,
  VARIANT as TagVariant,
} from 'baseui/tag';
import { Banner, KIND as BannerKind } from 'components/Banner';
import { FormController } from 'components/FormController';
import SecuredImage from 'components/SecuredImage';
import Text from 'components/Text';
import Config from 'config/Config';
import { format } from 'date-fns';
import Helper from 'helper/Helper';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { deserializeObservationDefect } from 'services/api/inspection/deserializer';
import { useInspection } from 'services/api/inspection/queries';
import { Observation, ObservationDefect } from 'services/api/inspection/types';
import { useStructures } from 'services/api/structures/queries';
import {
  fetcher,
  fetcherBlob,
  FetcherJSONResponse,
  FetcherMutatergs,
  FetcherQueryArgs,
} from 'services/fetcher';
import { useStitches } from 'theme';
import { useQuerystring } from 'utils/hooks';

const isStructuralDefect = (defect: ObservationDefect) =>
  defect?.type === 'STRUCTURAL';
const isMaintenanceDefect = (defect: ObservationDefect) =>
  defect?.type === 'MAINTENANCE';

type FormFields = {
  order: 'asc' | 'desc';
  sortCriteria: string;
  defects: { observation: Observation; defect: ObservationDefect }[];
};

type GeneratedReport = {
  date: Date;
  id: string;
  link: string;
};

type DownloadReportArgs = FetcherMutatergs<GeneratedReport>;
const downloadReport = async ({ payload }: DownloadReportArgs) => {
  const blob = await fetcherBlob(payload.link);
  let a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob);
  a.download = `${format(payload.date, 'MMMM-dd-yyyy')}-${
    payload.id
  }-Inspection Report.docx`;
  a.click();
};

type GenertateInspectionReportPayload = FetcherMutatergs<{
  uuid: string;
  structuralDefects: Record<string, number>;
  maintainanceDefects: Record<string, number>;
}>;
const generateReport = ({ payload }: GenertateInspectionReportPayload) => {
  return fetcher(Helper.parse(Config.INSPECTION_REPORT_API, payload.uuid), {
    method: 'post',
    body: JSON.stringify({
      defectsOrder: payload.structuralDefects,
      maintenancesOrder: payload.maintainanceDefects,
    }),
  }).then((r) => {
    const report = {
      date: new Date(r.date),
      id: r.id,
      link: r.link,
    } as GeneratedReport;
    return downloadReport({ payload: report });
  });
};

const useGenerateInspectionReport = () => useMutation(generateReport);
const useDownloadReport = () => useMutation(downloadReport);

export default function InspectionReport() {
  const { css } = useStitches();
  const history = useHistory();
  const searchParams = useQuerystring();
  const hasMatch = searchParams.get('modal') === 'generate';

  const generateInspectionReport = useGenerateInspectionReport();
  const {
    control,
    handleSubmit,
    watch,
    reset: resetForm,
  } = useForm<FormFields>({
    defaultValues: {
      order: 'asc',
    },
  });
  const [sortOrder, sortCriteria] = watch(['order', 'sortCriteria']);

  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
    generateInspectionReport.reset();
    resetForm();
    setTimeout(() => {
      searchParams.delete('modal');
      searchParams.delete('inspectionId');
      history.replace(`?${searchParams.toString()}`);
    }, 100);
  };

  const onSubmit = handleSubmit((data) => {
    const uuid = searchParams.get('inspectionId');
    if (uuid) {
      generateInspectionReport.mutate({
        payload: {
          uuid: uuid,
          structuralDefects: data.defects
            .filter((item) => isStructuralDefect(item.defect))
            .reduce(
              (all, item, index) => ({ ...all, [item.defect.uuid]: index }),
              {} as Record<string, number>,
            ),
          maintainanceDefects: data.defects
            .filter((item) => !isStructuralDefect(item.defect))
            .reduce(
              (all, item, index) => ({ ...all, [item.defect.uuid]: index }),
              {} as Record<string, number>,
            ),
        },
      });
    }
  });

  // TODO: Enable rule of hooks in eslint
  // FIXME: This is code block repeats
  useEffect(() => {
    if (!hasMatch) {
      setIsOpen(false);
      setTimeout(() => {
        searchParams.delete('modal');
        history.replace(`?${searchParams.toString()}`);
      }, 100);
    } else {
      setIsOpen(true);
    }
  }, [hasMatch]);

  return (
    <Modal
      onClose={() => handleClose()}
      overrides={{
        Dialog: {
          style: {
            width: '90vw',
            height: '90vh',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          },
        },
      }}
      isOpen={isOpen}
    >
      <ModalHeader>
        <Text variant="HeadingSmall">Generate Report</Text>
        <Text variant="ParagraphMedium">Configure report generation order</Text>
      </ModalHeader>
      <ModalBody
        className={css({ overflow: 'hidden', layout: 'stack', flex: 1 })}
      >
        <Banner
          show={generateInspectionReport.isError}
          kind={BannerKind.negative}
          title="Error"
        >
          Failed to generate report. Try again.
        </Banner>
        <form
          id="inspection-generate-report"
          onSubmit={onSubmit}
          className={css({ flex: 1, layout: 'stack', overflow: 'hidden' })}
        >
          <div className={css({ layout: 'row', spaceX: '$scale600' })}>
            <FormController
              control={control}
              name="sortCriteria"
              label="Sort By"
              defaultValue=""
            >
              {({ field }) => (
                <Select
                  value={field.value ? [{ id: field.value }] : undefined}
                  placeholder="Select sort type"
                  options={[
                    { label: 'Stationing', id: 'STATIONING' },
                    { label: 'Location ID', id: 'LOCATION' },
                    { label: 'Inspected Date', id: 'INSPECTED_DATE' },
                    {
                      label: 'Component & Sub-Component',
                      id: 'COMPONENT_SUBCOMPONENT',
                    },
                  ]}
                  onChange={({ option }) => field.onChange(option?.id ?? '')}
                />
              )}
            </FormController>
            <FormController
              control={control}
              name="order"
              label="Order"
              defaultValue="asc"
            >
              {({ field }) => (
                <Select
                  value={[{ id: field.value }]}
                  onChange={({ option }) => field.onChange(option?.id ?? 'asc')}
                  options={[
                    { label: 'Ascending', id: 'asc' },
                    { label: 'Descending', id: 'desc' },
                  ]}
                  clearable={false}
                  creatable={false}
                />
              )}
            </FormController>
          </div>

          <FormController
            control={control}
            name="defects"
            defaultValue={[]}
            overrides={{
              Root: {
                style: {
                  flex: 1,
                  overflow: 'hidden',
                  display: 'flex',
                },
              },
              ControlContainer: {
                style: {
                  flex: 1,
                  display: 'flex',
                  overflow: 'hidden',
                },
              },
            }}
          >
            {({ field }) => (
              <SubComponents
                order={sortOrder}
                sortCriteria={sortCriteria ?? ''}
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                }}
              />
            )}
          </FormController>
        </form>
      </ModalBody>
      <ModalFooter className={css({ flexShrink: 0 })}>
        <ModalButton kind={ButtonKind.tertiary} onClick={handleClose}>
          Cancel
        </ModalButton>
        <ModalButton
          form="inspection-generate-report"
          isLoading={generateInspectionReport.isLoading}
        >
          Generate Report
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}

type InspectionDefectsParams = FetcherQueryArgs<{
  id: string;
  sortCriteria: string;
  order: 'asc' | 'desc';
}>;

const getInspectionDefects = (args: InspectionDefectsParams) => {
  const { id, sortCriteria, order } = args.params;

  const searchParams = new URLSearchParams(
    Object.entries({
      filter: sortCriteria ?? '',
      descending: order === 'desc' ? 'true' : 'false',
    })
      .filter(([key, value]) => Boolean(value))
      .reduce(
        (all, [key, value]) => ({ ...all, [key]: value }),
        {} as Record<string, string>,
      ),
  );
  return fetcher(
    `api/v2/inspection/${id}/defects?${searchParams.toString()}`,
  ).then((r: FetcherJSONResponse[]) => r.map(deserializeObservationDefect));
};

const useInspectionDefects = (args?: InspectionDefectsParams) => {
  const params = args?.params;
  return useQuery(
    [
      'inspection',
      params?.id ?? '',
      'defects',
      [params?.order, params?.sortCriteria],
    ],
    () =>
      getInspectionDefects({
        params: {
          id: params?.id ?? '',
          sortCriteria: params?.sortCriteria ?? '',
          order: params?.order ?? 'asc',
        },
      }),
    {
      enabled: !!args?.params.id,
      cacheTime: 0,
    },
  );
};

const isDefectItem = (
  item: SubComponentItem | undefined,
): item is SubComponentItem => {
  return !!item;
};

function SubComponents({
  order,
  sortCriteria,
  onChange: onChangeHandler,
  value = [],
}: {
  value?: FormFields['defects'];
  order: FormFields['order'];
  sortCriteria: string;
  onChange?: (args: FormFields['defects']) => void;
}) {
  const uuid = useQuerystring().get('inspectionId');
  const { css, theme } = useStitches();
  const hasChangedInitialSortCriteria = useRef(false);
  const defectsQuery = useInspectionDefects({
    params: {
      id: uuid ?? '',
      sortCriteria: sortCriteria,
      order: order,
    },
  });
  const inspectionQuery = useInspection(
    {
      params: { inspectionId: uuid ?? '' },
    },
    { staleTime: Infinity },
  );
  const structureQuery = useStructures();
  const hasErorred = [inspectionQuery, structureQuery, defectsQuery].some(
    (q) => q.isError,
  );

  if (hasErorred) {
    return (
      <Banner kind={BannerKind.negative} title="Error">
        Failed to load Subcomponents. Refresh the page and try again.
      </Banner>
    );
  }

  if (inspectionQuery.data && !!structureQuery.data && !!defectsQuery.data) {
    const dictionary = structureQuery.data;
    const defects = defectsQuery.data;
    const inspection = inspectionQuery.data;
    const defectItems: SubComponentItem[] = defects
      .map((defect) => {
        const observation = inspection.observations.find(
          (observation) => observation.uuid === defect.observationId,
        );
        const subcomponent = dictionary.subComponents.find(
          (subcomponent: any) =>
            subcomponent.id === observation?.subComponentId,
        );
        if (!!observation) {
          return {
            defect,
            observation,
            // FIXME: Update typing of 'dictionary'
            subComponent: subcomponent
              ? (subcomponent as SubComponent)
              : undefined,
          } as SubComponentItem;
        }
        return undefined;
      })
      .filter(isDefectItem);

    return (
      <div
        className={css({
          overflow: 'hidden',
          flex: 1,
          layout: 'stack',
        })}
      >
        {defects.length !== 0 ? (
          <DefectsList
            items={defectItems}
            onChange={(newValue) => {
              if (onChangeHandler) {
                onChangeHandler(newValue);
              }
            }}
          />
        ) : (
          <Banner title="No Defects">This inspection has no defects.</Banner>
        )}
      </div>
    );
  }

  return (
    <Skeleton
      rows={6}
      animation
      overrides={{
        Row: {
          style: {
            height: theme.sizing.scale1400,
          },
        },
        Root: {
          style: {
            flex: 1,
            height: '100%',
          },
        },
      }}
    />
  );
}

type SubComponent = {
  componentId: string;
  defectIds: string[];
  deleted: boolean;
  fdotBhiValue: number;
  id: string;
  measureUnit: string;
  name: string;
  observationNameIds: number[];
};

type SubComponentItem = {
  observation: Observation;
  subComponent?: SubComponent;
  defect: ObservationDefect;
};

function DefectsList({
  items,
  onChange: onChangeHandler,
}: {
  items: { observation: Observation; defect: ObservationDefect }[];
  onChange: (defects: FormFields['defects']) => void;
}) {
  const { css } = useStitches();
  const lastItems = useRef(items);
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return onChangeHandler(items);
    }
    const isUpdateItemsNew = () =>
      lastItems.current.every(
        (item, index) => item.defect.uuid === items[index].defect.uuid,
      );
    if (!isUpdateItemsNew()) {
      lastItems.current = items;
      onChangeHandler(items);
    }
  }, [items, onChangeHandler]);

  /**
   * We handle the sort order locally to always have the latest `items`
   */
  const [sortedItems, setSortedItems] = useState<FormFields['defects']>([]);
  const structuralDefects = (sortedItems.length ? sortedItems : items).filter(
    (item) => isStructuralDefect(item.defect),
  );
  const maintainanceDefects = (sortedItems.length ? sortedItems : items).filter(
    (item) => isMaintenanceDefect(item.defect),
  );

  return (
    <div
      className={css({
        layout: 'row',
        spaceX: '$scale600',
        overflow: 'hidden',
      })}
    >
      <div className={css({ flex: 1, layout: 'stack' })}>
        <Text>Structural Defects</Text>
        {structuralDefects.length === 0 && (
          <Banner title="No Defects" inset>
            No structural defects for this inspection.
          </Banner>
        )}
        <List
          items={structuralDefects as any}
          onChange={({ oldIndex, newIndex }) => {
            const newSort =
              newIndex === -1
                ? arrayRemove(structuralDefects, oldIndex)
                : arrayMove(structuralDefects, oldIndex, newIndex);
            if (onChangeHandler) {
              setSortedItems([...newSort, ...maintainanceDefects]);
              onChangeHandler([...newSort, ...maintainanceDefects]);
            }
          }}
          overrides={{
            Label: {
              component: DefectLabel,
            },
            Root: {
              style: {
                overflow: 'auto',
                flex: 1,
              },
            },
          }}
        />
      </div>
      <div className={css({ flex: 1, layout: 'stack' })}>
        <Text>Maintainance Defects</Text>
        {maintainanceDefects.length === 0 && (
          <Banner title="No Defects" inset>
            No maintainance defects for this inspection.
          </Banner>
        )}
        <List
          items={maintainanceDefects as any}
          onChange={({ oldIndex, newIndex }) => {
            const newSort =
              newIndex === -1
                ? arrayRemove(maintainanceDefects, oldIndex)
                : arrayMove(maintainanceDefects, oldIndex, newIndex);
            if (onChangeHandler) {
              setSortedItems([...structuralDefects, ...newSort]);
              onChangeHandler([...structuralDefects, ...newSort]);
            }
          }}
          overrides={{
            Label: {
              component: DefectLabel,
            },
            Root: {
              style: {
                overflow: 'auto',
              },
            },
          }}
        />
      </div>
    </div>
  );
}

function DefectLabel({
  $value,
}: {
  $value: {
    defect: ObservationDefect;
    observation: Observation;
  };
}) {
  const { defect, observation } = $value;
  const { css, theme } = useStitches();
  const defectDict = useStructures().data?.defects;
  const name =
    defectDict.find((obs: any) => obs.id === defect.defectId)?.name ??
    'No Defect Name';

  const structureQuery = useStructures();
  const component =
    (structureQuery.data?.structuralComponents as any[]).find(
      (comp) => comp.id === observation.structuralComponentId,
    ) ?? undefined;
  const subComponent =
    (structureQuery.data?.subComponents as any[]).find(
      (comp) => comp.id === observation.subComponentId,
    ) ?? undefined;

  return (
    <div
      className={css({
        flex: 1,
        layout: 'row',
        alignItems: 'flex-start',
        spaceX: '$scale600',
      })}
    >
      <div
        className={css({
          width: theme.sizing.scale2400,
          height: theme.sizing.scale2400,
          borderRadius: '$lg',
          overflow: 'hidden',
          flexShrink: 0,
        })}
      >
        {defect.photos.length ? (
          <SecuredImage link={defect.photos[0].thumbLink} />
        ) : (
          <Text>No Photo</Text>
        )}
      </div>
      <div>
        {!!defect.type && (
          <Tag
            closeable={false}
            kind={TagKind.primary}
            variant={TagVariant.light}
            size={TagSize.small}
          >
            {defect.type}
          </Tag>
        )}
        <Text variant="LabelLarge">
          {component?.name} - {subComponent.name}
        </Text>
        <div className={css({ spaceY: '$scale200', mt: '$scale500' })}>
          <Text
            css={{ layout: 'row', alignItems: 'center', spaceX: '$scale100' }}
          >
            <CalendarToday fontSize="small" />
            <span>
              {defect.createdAtClient
                ? format(new Date(defect.createdAtClient), 'MMMM dd,yyyy')
                : ''}
            </span>
          </Text>
          <Text
            css={{
              alignItems: 'center',
              layout: 'row',
              spaceX: '$scale100',
            }}
          >
            <WarningSharp />
            <span>{name}</span>
          </Text>{' '}
          {!!defect.stationMarker && (
            <Text
              weight="bold"
              css={{
                layout: 'row',
                alignItems: 'center',
                spaceX: '$scale100',
              }}
            >
              <PinDrop />
              {defect.stationMarker}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
}

const useProductImage = (link: string) =>
  useQuery(
    ['defect-image', link],
    async () => {
      const response = await fetcherBlob(link);
      return response as Blob;
    },
    { enabled: Boolean(link), staleTime: Infinity },
  );
