import React, { Component } from "react";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";
import FolderSpecialOutlinedIcon from "@material-ui/icons/FolderSpecialOutlined";
import EqualizerOutlinedIcon from "@material-ui/icons/EqualizerOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import { Colors } from "config/StyleConfig";
import { NavLink } from "react-router-dom";
import { pageRoutes, getCurrentRoute } from "Routes";
import { connect } from "react-redux";
import { toggleMobileOpen } from "./MenuAction";
import TokenManager from "storage/TokenManager";
import Config from "config/Config";
import Typography from "@material-ui/core/Typography";

export const DRAWER_WIDTH = 240;

const styles = (theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: DRAWER_WIDTH,
    background: Colors.drawer,
  },
  listItem: {
    color: Colors.drawerItems,
    "&.Mui-selected": {
      backgroundColor: Colors.selectedDrawerItems,
    },
  },
  versionTypography: {
    color: Colors.versionColor,
  },
});

class SideDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      selection: 0,
    };
  }

  generateListITem(classes, to, index, Icon, text, authorized) {
    if (!authorized) {
      return <div />;
    }

    return (
      <NavLink to={to} style={{ textDecoration: "none" }}>
        <ListItem button onClick={() => this.setState({ selection: index })} className={classes.listItem} selected={getCurrentRoute() === to}>
          <ListItemIcon className={classes.listItem}>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      </NavLink>
    );
  }

  render() {
    const { classes } = this.props;

    const getList = () => {
      return (
        <List>
          {this.generateListITem(
            classes,
            pageRoutes.analytics.path,
            0,
            AssessmentOutlinedIcon,
            "Analytics",
            pageRoutes.analytics.authorized.includes(TokenManager.getUserType())
          )}
          {this.generateListITem(
            classes,
            pageRoutes.inspections.path,
            0,
            AssignmentOutlinedIcon,
            "Inspections",
            pageRoutes.inspections.authorized.includes(TokenManager.getUserType())
          )}
          {this.generateListITem(
            classes,
            pageRoutes.overview.path,
            1,
            AssessmentOutlinedIcon,
            "Overview",
            pageRoutes.overview.authorized.includes(TokenManager.getUserType())
          )}
          {this.generateListITem(
            classes,
            pageRoutes.projects.path,
            1,
            FolderSpecialOutlinedIcon,
            "Projects",
            pageRoutes.projects.authorized.includes(TokenManager.getUserType())
          )}
          {this.generateListITem(
            classes,
            pageRoutes.clients.path,
            1,
            BusinessIcon,
            "Clients",
            pageRoutes.clients.authorized.includes(TokenManager.getUserType())
          )}
          {this.generateListITem(
            classes,
            pageRoutes.preferences.path,
            1,
            EqualizerOutlinedIcon,
            "Preferences",
            pageRoutes.preferences.authorized.includes(TokenManager.getUserType())
          )}
          {this.generateListITem(
            classes,
            pageRoutes.users.path,
            1,
            SupervisorAccountOutlinedIcon,
            "Users",
            pageRoutes.users.authorized.includes(TokenManager.getUserType())
          )}
        </List>
      );
    };

    const drawer = (
      <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", flex: 1 }}>
        <div>
          <div className={classes.toolbar}>
            <img style={{ width: "60%", height: "auto", margin: "30px auto 5px auto", display: "block", objectFit: "contain", WebkitFilter: "grayscale(100%) brightness(300%)"}} draggable={false} src={TokenManager.getCompanyLogo()}/>
          </div>
          <Divider />
          {getList()}
        </div>
        <Typography className={classes.versionTypography} style={{ marginBottom: 10, textAlign: "center" }}>
          {Config.VERSION}
        </Typography>
      </div>
    );

    return (
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={this.props.container}
            variant="temporary"
            open={this.props.mobileOpen}
            onClose={() => this.props.toggleMobileOpen()}
            classes={{ paper: classes.drawerPaper }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMobileOpen: () => dispatch(toggleMobileOpen()),
  };
};

const mapStateToProps = (state) => {
  return {
    mobileOpen: state.MenuReducer.mobileOpen,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SideDrawer));
