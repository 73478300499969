import React, { Component } from "react";
import Table from "components/Table";
import { connect } from "react-redux";
import { mapMultipleRows } from "./ComponentsHIMapper";
import { watchComponentsHITableData } from "./ComponentsHIAction";
import ColumnBuilder from "helper/ColumnBuilder";
import { Strings } from "../../config/Strings";
import TokenManager from "../../storage/TokenManager";
import { Colors } from "config/StyleConfig";

export const FIELD_ID = "id";
export const FIELD_STRUCTURE_NAME = "structure_name";
export const FIELD_COMPONENT_NAME = "component_name";
export const FIELD_SUB_COMPONENT_NAME = "sub_component_name";
export const FIELD_INSPECTION_DATE = "inspection_date";
export const FIELD_CONDITION_RATING = "condition_rating";
export const FIELD_CONDITION_RATING_SCALE = "condition_rating_scale";

class ComponentsHI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  updateData() {
    this.props.watchComponentsHITableData(this.props.companyId);
  }

  getColumns = () => {
    var columns = [];
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_com_sub_health_table_structure"), FIELD_STRUCTURE_NAME).build());
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_com_sub_health_table_comp"), FIELD_COMPONENT_NAME).build());
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_com_sub_health_table_sub"), FIELD_SUB_COMPONENT_NAME).build());
    columns.push(
      new ColumnBuilder(Strings.getInstance().getString("client_com_sub_health_table_inspection_date"), FIELD_INSPECTION_DATE).type("date").build()
    );
    if (TokenManager.hasBridges()) {
      columns.push(new ColumnBuilder(Strings.getInstance().getString("client_com_sub_health_table_cr_scale"), FIELD_CONDITION_RATING_SCALE).build());
    }
    columns.push(new ColumnBuilder(Strings.getInstance().getString("client_com_sub_health_table_cr"), FIELD_CONDITION_RATING).render((rowData) => {
      if (rowData !== undefined) {
        return this.styleCR(rowData[FIELD_CONDITION_RATING]);
      }
    })
    .centerHeaders()
    .build());
    return columns;
  };

  styleCR(value) {
    var displayText = Strings.getInstance().mapCR(value);
    var style = {
      border: null,
      borderRadius: "1.25rem",
      padding: "2px 25px",
      color: "black",
      textAlign: "center",
      fontWeight: "bold",
    };

    if (value > 0.8) { //1
      style["backgroundColor"] = Colors.heat1;
    } else if (value > 0.6) { //2
      style["backgroundColor"] = Colors.heat2;
    } else if (value > 0.4) { //3
      style["backgroundColor"] = Colors.heat3;
    } else if (value > 0.2) { //4
      style["backgroundColor"] = Colors.heat4;
    } else if (value < 0.2) { //5
      style["backgroundColor"] = Colors.heat5;
    } else if (value === undefined) {
      displayText = "";
    }
    return <div style={style}>{displayText}</div>;
  }

  componentDidMount() {
    this.updateData();
  }

  actions = [
    {
      icon: "refresh",
      tooltip: "Refresh Data",
      isFreeAction: "true",
      onClick: () => {
        this.updateData();
      },
    },
  ];

  render() {
    return (
      <Table
        id="SUB_COMP_HEALTH_TABLE"
        title={Strings.getInstance().getString("client_com_sub_health_table_title")}
        columns={this.getColumns()}
        options={{ actionsColumnIndex: -1 }}
        data={this.props.data}
        actions={this.actions}
        isLoading={this.props.loading}
        disableHideColumnOption
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: mapMultipleRows(state),
    loading: state.ComponentsHIReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    watchComponentsHITableData: (companyId) => dispatch(watchComponentsHITableData(companyId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentsHI);
