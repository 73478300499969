import { Input } from 'baseui/input';
import { DatePicker } from 'baseui/datepicker';
import { Option, Select, Value, SIZE as SelectSize } from 'baseui/select';
import { Skeleton } from 'baseui/skeleton';
import { FileInput } from 'components/FileInput';
import { FormController } from 'components/FormController';
import React from 'react';
import { useForm } from 'react-hook-form';
import { InspectionStatus } from 'services/api/inspection/types';
import { useProject } from 'services/api/project/queries';
import { useAllUsers } from 'services/api/user/queries';
import { useStitches } from 'theme';
import { useQuerystring } from 'utils/hooks';

const StatusLabels: Record<InspectionStatus, string> = {
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
};

type FormFields = {
  inspectionId: string;
  startDate: Date;
  projectId: number;
  structure: Value;
  spans: number;
  inspectors: Value;
  endDate: Date;
  status: InspectionStatus;
  file: File[];
  term: string;
  withExistingDocument: boolean;
};

export enum FormAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

type InspectionFormProps = {
  type?: FormAction;
  initialValue?: Partial<FormFields>;
  onSubmit: (data: FormFields) => void;
};

export default function InspectionForm({
  type = FormAction.CREATE,
  onSubmit: onSubmiHandler,
  initialValue,
}: InspectionFormProps) {
  const { css } = useStitches();
  const searchParams = useQuerystring();
  const projectId = Number(searchParams.get('projectId') || '0');

  const { control, handleSubmit, watch } = useForm<FormFields>({
    defaultValues: {
      projectId: projectId,
      ...(initialValue ?? {}),
    },
  });
  const withDocument = watch('withExistingDocument');
  const onSubmit = handleSubmit((data) => {
    onSubmiHandler(data);
  });

  return (
    <form
      onSubmit={onSubmit}
      id="create-inspection-form"
      className={css({
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)',
        gridColumnGap: '$scale600',
      })}
    >
      <FormController
        control={control}
        name="structure"
        label="Structure"
        overrides={{
          Root: {
            style: { gridColumn: '1/-1' },
          },
        }}
        rules={{
          required: 'This field is required.',
        }}
      >
        {({ field }) => (
          <StructureSelect value={field.value} onChange={field.onChange} />
        )}
      </FormController>
      <FormController control={control} name="spans" label="Spans">
        {({ field }) => <Input {...field} />}
      </FormController>
      <FormController
        control={control}
        name="startDate"
        label="Start Date"
        rules={{
          required: 'This field is required.',
        }}
      >
        {({ field }) => (
          <DatePicker
            minDate={new Date()}
            value={field.value}
            onChange={({ date }) => {
              if (date && date instanceof Date) {
                field.onChange(date);
              }
            }}
          />
        )}
      </FormController>
      <ConditionalField show={type === FormAction.UPDATE}>
        <FormController control={control} name="endDate" label="End Date">
          {({ field }) => (
            <DatePicker
              minDate={new Date()}
              value={field.value}
              onChange={({ date }) => {
                if (date && date instanceof Date) {
                  field.onChange(date);
                }
              }}
            />
          )}
        </FormController>
      </ConditionalField>
      <ConditionalField show={type === FormAction.UPDATE}>
        <FormController control={control} name="term" label="Term">
          {({ field }) => <Input {...field} />}
        </FormController>
      </ConditionalField>
      <ConditionalField show={type === FormAction.UPDATE}>
        <FormController control={control} name="status" label="Status">
          {({ field }) => (
            <Select
              placeholder="Select Status"
              value={[{ id: field.value }]}
              options={
                Object.entries(InspectionStatus).map(([key, value]) => ({
                  id: value,
                  label: StatusLabels[key as InspectionStatus],
                })) as Option[]
              }
              onChange={({ option }) => field.onChange(option?.id)}
              clearable={false}
            />
          )}
        </FormController>
      </ConditionalField>
      <FormController
        control={control}
        name="inspectors"
        label="Inspector"
        defaultValue={[]}
        overrides={{
          Root: {
            style: { gridColumn: '1/-1' },
          },
        }}
      >
        {({ field }) => (
          <InspectorSelect value={field.value} onChange={field.onChange} />
        )}
      </FormController>
      <FormController
        control={control}
        name="file"
        label={`Inspection Document ${
          type === FormAction.UPDATE ? '(Optional)' : ''
        }`}
        caption={
          withDocument
            ? 'Uploading a new file will replace the existing Inspection file for this inspection'
            : 'Available to the inspector during inspection'
        }
        rules={{
          required:
            type === FormAction.CREATE ? 'This field is required' : false,
        }}
        overrides={{
          Root: {
            style: { gridColumn: '1/-1' },
          },
        }}
      >
        {({ field, fieldState }) => (
          <FileInput
            accept={['.docx', '.doc']}
            defaultValue={field.value}
            errorMessage={fieldState.error?.message}
            onChange={(value) => field.onChange(value)}
          />
        )}
      </FormController>
    </form>
  );
}

function StructureSelect({
  value,
  onChange,
}: {
  value: Value;
  onChange: (selected: Value) => void;
}) {
  const searchParams = useQuerystring();
  const { data, isError } = useProject(
    Number(searchParams.get('projectId') ?? ''),
  );

  if (isError) {
    return <div>Error</div>;
  }

  if (data) {
    const options: Option[] = data.structures.map((structure) => ({
      label: `${structure.code}-${structure.name}`,
      id: structure.id,
    }));

    return (
      <Select
        placeholder="Select Structure"
        size={SelectSize.default}
        options={options}
        value={value}
        onChange={(params) => {
          onChange(params.value);
        }}
      />
    );
  }
  return <Skeleton rows={1} />;
}

function InspectorSelect({
  value,
  onChange,
}: {
  value: Value;
  onChange: (selected: Value) => void;
}) {
  const { data, isError } = useAllUsers();

  if (isError) {
    return <div>Error</div>;
  }

  if (data) {
    const options: Option[] = data.map((user) => ({
      label: `${user.firstName} ${user.lastName}`,
      id: Number(user.id),
    }));

    return (
      <Select
        placeholder="Select Inspector"
        size={SelectSize.default}
        options={options}
        value={value}
        onChange={(params) => {
          onChange(
            params.value.filter((item) => {
              if (params.type === 'remove') {
                return Number(params.option?.id ?? '0') !== item?.id;
              } else if (params.type === 'clear') {
                return false;
              } else {
                return true;
              }
            }),
          );
        }}
        multi
      />
    );
  }
  return <Skeleton rows={1} />;
}

function ConditionalField({
  children,
  show = true,
}: {
  show?: boolean;
  children: React.ReactNode;
}) {
  if (!show) {
    return null;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
