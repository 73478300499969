import { Button, KIND, SIZE as ButtonSize } from 'baseui/button';
import { Plus } from 'baseui/icon';
import { Input, SIZE } from 'baseui/input';
import { FormController } from 'components/FormController';
import React, { useEffect, useRef } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useStitches } from 'theme';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const rangeSchema = yup
  .number()
  .transform((v) => {
    return isNaN(v) ? undefined : v;
  })
  .notRequired()
  .typeError('Range must be a number')
  .min(1, 'Minimum range is 1')
  .max(12, 'Maximum range must not exceed 12');

export const schema = yup.object().shape({
  ranges: yup.array(yup.array(rangeSchema)).notRequired(),
});

type FormFields = {
  ranges: string[][];
};

export default function PostioningRange({
  initialValue,
  onChange: onChangeHandler = () => {},
}: {
  initialValue?: FormFields['ranges'];
  onChange?: (value: FormFields['ranges']) => void;
}) {
  const { css, theme } = useStitches();
  const { control, watch, trigger } = useForm<FormFields>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      ranges: initialValue ? initialValue : [],
    },
  });
  const { fields, append, remove } = useFieldArray({ control, name: 'ranges' });
  // const onChangeHandlerRef = useRef(onChangeHandler);
  useEffect(() => {
    const { unsubscribe } = watch((data) => {
      const ranges = data.ranges;
      if (ranges && ranges.length) {
        onChangeHandler(
          ranges.map((range) => [range?.[0] ?? '', range?.[1] ?? '']),
        );
      } else {
        onChangeHandler([]);
      }
    });

    return unsubscribe;
  }, [onChangeHandler]);

  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      onChangeHandler(initialValue ?? []);
      trigger();
    }
  }, [trigger, onChangeHandler, initialValue]);

  return (
    <div
      className={css({
        minWidth: theme.sizing.scale4800,
        width: '$full',
        layout: 'stack',
        alignItems: 'flex-end',
        spaceY: '$scale100',
      })}
    >
      <ul className={css({ spaceY: '$scale300' })}>
        {fields.map((field, index) => (
          <li
            key={field.id}
            className={css({
              layout: 'stack',
              alignItems: 'flex-end',
            })}
          >
            <div
              className={css({
                layout: 'row',
                spaceX: '$scale100',
                alignItems: 'center',
                flexShrink: 0,
              })}
            >
              <FormController
                name={`ranges.${index}.0`}
                control={control}
                overrides={{
                  ControlContainer: {
                    style: {
                      marginBottom: 0,
                    },
                  },
                }}
              >
                {({ field }) => (
                  <Input
                    size={SIZE.compact}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    onBlur={field.onBlur}
                  />
                )}
              </FormController>
              <FormController
                name={`ranges.${index}.1`}
                control={control}
                overrides={{
                  ControlContainer: {
                    style: {
                      marginBottom: 0,
                    },
                  },
                }}
              >
                {({ field }) => (
                  <Input
                    size={SIZE.compact}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    onBlur={field.onBlur}
                  />
                )}
              </FormController>
            </div>
            <Button
              size={ButtonSize.mini}
              kind={KIND.tertiary}
              onClick={() => remove(index)}
            >
              Remove
            </Button>
          </li>
        ))}
      </ul>

      <Button
        size={ButtonSize.mini}
        kind={KIND.tertiary}
        startEnhancer={<Plus />}
        onClick={() => {
          append([['', '']]);
        }}
      >
        Add Range
      </Button>
    </div>
  );
}
