import TokenManager from "storage/TokenManager";

export const LOGIN_USER_STARTED = "LOGIN_USER_STARTED";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

const initialState = {
  userData: TokenManager.isAuthenticated() ? TokenManager.getToken() : {},
  authenticating: false,
  error: "",
  authenticated: TokenManager.isAuthenticated(),
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_STARTED:
      return { ...state, error: "", authenticating: true };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        userData: action.userData,
        authenticating: false,
        authenticated: true,
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        error: action.error,
        authenticating: false,
        authenticated: false,
      };
    default:
      return state;
  }
};

export { LoginReducer };
