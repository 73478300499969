import {
  FETCH_CLIENT_SUCCESS,
  FETCH_STRUCTURE_SUCCESS,
  FETCH_DATA_FAILED,
  FETCH_DATA_STARTED,
  FETCH_DATA_SUCCESS,
  FETCH_INSPECTIONS_SUCCESS,
  FETCH_LAST_INSPECTION_SUCCESS,
  FETCH_SUB_COMPONENT_HEALTH_INDEX_SUCCESS,
  FETCH_DEFECT_BY_COMPONENT_SUCCESS,
  FETCH_OBSERVATION_BY_TYPE_SUCCESS,
} from "./StructureAction";

const initialState = {
  inspectionsData: [],
  latestInspectionData: {},
  structureData: {},
  clientData: {},
  preferencesData: {},
  subComponentHealthIndex: [],
  loading: true,
  defectByComponent: [],
  observationByType: {},
};

const StructureReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_STARTED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DATA_SUCCESS:
    case FETCH_DATA_FAILED:
      return {
        ...state,
        loading: false,
      };
    case FETCH_INSPECTIONS_SUCCESS:
      return {
        ...state,
        inspectionsData: action.data,
      };
    case FETCH_SUB_COMPONENT_HEALTH_INDEX_SUCCESS:
      return {
        ...state,
        subComponentHealthIndex: action.data,
      };
    case FETCH_LAST_INSPECTION_SUCCESS:
      return {
        ...state,
        latestInspectionData: action.data,
      };
    case FETCH_STRUCTURE_SUCCESS:
      return {
        ...state,
        structureData: action.data,
      };
    case FETCH_CLIENT_SUCCESS:
      return {
        ...state,
        clientData: action.data,
      };
    case FETCH_DEFECT_BY_COMPONENT_SUCCESS:
      return { ...state, defectByComponent: action.data };
    case FETCH_OBSERVATION_BY_TYPE_SUCCESS:
      return { ...state, observationByType: action.data };
    default:
      return state;
  }
};

export { StructureReducer };
