import { Modal, ModalBody, ModalHeader } from 'baseui/modal';
import SecuredImage from 'components/SecuredImage';
import React, { CSSProperties, useContext, useEffect, useState } from 'react';

import {
  ButtonBack,
  ButtonNext,
  CarouselContext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Alert, Check, ChevronLeft, ChevronRight, Delete, Search  } from 'baseui/icon';
import { Skeleton } from 'baseui/skeleton';
import { Tag } from 'baseui/tag';
import { Banner, KIND } from 'components/Banner';
import Text from 'components/Text';
import { useParams } from 'react-router-dom';
import { useDictionary } from 'services/api/dictionary/queries';
import { getInspection } from 'services/api/inspection/queries';
import {
  DefectPhoto,
  Inspection,
  Observation,
  ObservationDefect,
} from 'services/api/inspection/types';
import { useStitches } from 'theme';
import { format, set } from 'date-fns';
import Helper from 'helper/Helper';
import Config from 'config/Config';
import { Button } from 'components/Button';
import { fetcher } from 'services/fetcher';
import { DeleteOutline } from '@material-ui/icons';
import { queryClient } from 'config/queryClient';
import { useSnackbar } from 'baseui/snackbar';
import ModalImageZoom from 'features/Inspection/components/DefectsImageGallery/DefectImageModal';

const useRelatedInspections = ({
  currentInspectionId = '',
}: {
  currentInspectionId: string;
}) =>
  useQuery(
    ['inspection', currentInspectionId, 'related-cloned-inspections'],
    async () => {
      // const cachedInspection = queryClient.getQueryData(
      //   ['inspection', currentInspectionId, {}],
      //   { fetchStatus: 'idle' },
      // );

      // let inspectionData = (cachedInspection as Inspection) ?? undefined;
      let inspectionData = undefined;

      // if (!inspectionData) {
      //   const inspection = await getInspection({
      //     params: { inspectionId: currentInspectionId },
      //   });
      // }
      const inspection = await getInspection({
        params: { inspectionId: currentInspectionId },
      });
      inspectionData = inspection;

      if (!inspectionData.previousInspectionId) {
        return {
          currentInspection: inspectionData,
          previousInspection: null,
        };
      }

      const previousInspection = await getInspection({
        params: { inspectionId: inspectionData.previousInspectionId },
      });
      return {
        currentInspection: inspectionData,
        previousInspection: previousInspection,
      };
    },
    { staleTime: Infinity },
  );

export default function DefectsImageGallery({
  defect,
}: {
  defect: ObservationDefect;
  observation: Observation;
}) {
  const { css, theme } = useStitches();
  const [isOpen, setIsOpen] = useState(false);
  const images = defect.photos;
  const isCloned = !!defect.previousDefectNumber;
  const displayImageLink = images.length ? images[0].annotatedLink : '';

  return (
    <>
      <button
        onClick={(e) => {
          if (displayImageLink) {
            setIsOpen(true);
          }
        }}
      >
        <SecuredImage
          link={displayImageLink}
          className={css({
            width: theme.sizing.scale1600,
            height: theme.sizing.scale1600,
          })}
        />
      </button>
      <Modal
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        overrides={{
          Dialog: {
            style: {
              width: '100%',
              maxWidth: '96rem',
              height: '80vh',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            },
          },
        }}
      >
        <ModalHeader>
          {!isCloned ? (
            <Tag
              closeable={false}
              overrides={{
                Text: {
                  style: {
                    maxWidth: 'unset',
                  },
                },
              }}
            >
              No Previous Inspection
            </Tag>
          ) : null}
        </ModalHeader>
        <ModalBody
          $style={{
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            marginTop: 0,
          }}
          className={css({
            overflow: 'hidden',
            layout: 'row',
            flex: 1,
          })}
        >
          <div className={css({ flex: 1, overflow: 'hidden', layout: 'row' })}>
            {images.length ? (
              <>
                <div className={css({ width: isCloned ? '50%' : '100%' })}>
                  <CurrentDefectImages defectUUID={defect.uuid} />
                </div>
                {isCloned && (
                  <div className={css({ width: '50%' })}>
                    <PreviousDefectImages
                      defectUUID={defect.previousDefectNumber}
                    />
                  </div>
                )}
              </>
            ) : (
              <div
                className={css({
                  layout: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                })}
              >
                <Text>Defect has no images</Text>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

function SlidePosition() {
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext.state.currentSlide + 1,
  );
  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide + 1);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  return (
    <Text css={{ textAlign: 'center' }} weight="semibold">
      {currentSlide}/{carouselContext.state.totalSlides}
    </Text>
  );
}

function PreviousDefectImages({ defectUUID = '' }: { defectUUID?: string }) {
  const { css } = useStitches();
  const { inspectionId } = useParams<{ inspectionId: string }>();
  const inspectionQuery = useRelatedInspections({
    currentInspectionId: inspectionId,
  });

  if (inspectionQuery.isError) {
    return (
      <Banner
        title="Error"
        kind={KIND.negative}
        artwork={{
          icon: ({ size }) => <Alert size={size} />,
        }}
      >
        Failed to get previous defect, refresh page ang try again.
      </Banner>
    );
  }

  if (inspectionQuery.data && !inspectionQuery.isRefetching) {
    const { previousInspection } = inspectionQuery.data;
    if (previousInspection === null) {
      return null;
    }
    const previousDefect = previousInspection?.observations
      .reduce(
        (all, observation) => [...all, ...observation.observationDefects],
        [] as ObservationDefect[],
      )
      .find((d) => d.uuid === defectUUID);

    const observation = previousInspection?.observations.find(
      (observation) => observation.uuid === previousDefect?.observationId,
    );

    if (!observation || !previousDefect) {
      return <Text>Observation not found</Text>;
    }

    return (
      <div className={css({ layout: 'stack', height: '$full' })}>
        <div className={css({ height: '50%' })}>
          <DefectImages
            images={previousDefect.photos.map((photo) => {
              return {
                ...photo,
                defectImageType: 'prev',
              };
            })}
          />
        </div>
        <div className={css({ flexBasis: '50%' })}>
          <DefectInformation
            defect={previousDefect}
            observation={observation}
            isPreviousDefect
          />
        </div>
      </div>
    );
  }

  return <Skeleton width="100%" height="100%" animation />;
}

function CurrentDefectImages({ defectUUID = '' }: { defectUUID?: string }) {
  const { css } = useStitches();
  const { inspectionId } = useParams<{ inspectionId: string }>();
  const inspectionQuery = useRelatedInspections({
    currentInspectionId: inspectionId,
  });

  if (inspectionQuery.isError) {
    return (
      <Banner
        title="Error"
        kind={KIND.negative}
        artwork={{
          icon: ({ size }) => <Alert size={size} />,
        }}
      >
        Failed to get defect, refresh page ang try again.
      </Banner>
    );
  }

  if (inspectionQuery.data && !inspectionQuery.isFetching) {
    const relatedInspections = inspectionQuery.data;
    const currentDefect = relatedInspections.currentInspection.observations
      .reduce(
        (all, observation) => [...all, ...observation.observationDefects],
        [] as ObservationDefect[],
      )
      .find((defect) => defect.uuid === defectUUID);
    const observation = relatedInspections.currentInspection.observations.find(
      (observation) => observation.uuid === currentDefect?.observationId,
    );
    const previousDefect = relatedInspections.previousInspection?.observations
      .reduce(
        (all, observation) => [...all, ...observation.observationDefects],
        [] as ObservationDefect[],
      )
      .find((d) => d.uuid === currentDefect?.previousDefectNumber);

    if (currentDefect?.id === undefined || observation?.uuid === undefined) {
      return (
        <Banner
          artwork={{
            icon: ({ size }) => <Alert size={size} />,
          }}
        >
          Defect not found
        </Banner>
      );
    }
    const isClonedDefect = !!currentDefect.previousDefectNumber;
    const getPhotoType = (
      photo: DefectPhoto,
    ): keyof typeof DEFECT_IMAGE_TYPE => {
      const previousDefectPhotos = previousDefect?.photos;
      return previousDefectPhotos?.find(
        /**
         * We use the `name` property to compare since the photo `uuid`
         * changes when photos are cloned
         */
        (previousPhoto) => previousPhoto.name === photo.name,
      )
        ? DEFECT_IMAGE_TYPE.cloned
        : DEFECT_IMAGE_TYPE.new;
    };
    /**
     * NOTE:
     * if the defect opend by the user is the cloned defect
     * from the previous inspection we will not display its related photo since it will be the same
     * as the current photo/s
     */
    return (
      <div className={css({ layout: 'stack', height: '$full' })}>
        <div className={css({ height: '50%' })}>
          <DefectImages
            defect={currentDefect}
            images={[
              ...currentDefect.photos.map((photo) => ({
                ...photo,
                defectImageType: getPhotoType(photo),
              })),
            ]}
            editable
          />
        </div>
        <div className={css({ flexBasis: '50%' })}>
          <DefectInformation defect={currentDefect} observation={observation} />
        </div>
      </div>
    );
  }

  return <Skeleton width="100%" height="100%" animation />;
}

const DEFECT_IMAGE_TYPE = {
  new: 'new',
  cloned: 'cloned',
  prev: 'prev',
} as const;

function DefectImages({
  images,
  defect,
  editable,
}: {
  editable?: boolean;
  images: Array<
    DefectPhoto & {
      defectImageType: keyof typeof DEFECT_IMAGE_TYPE;
    }
  >;
  defect?: ObservationDefect;
}) {
  const { css } = useStitches();
  function getPhotoLabel(type: keyof typeof DEFECT_IMAGE_TYPE) {
    const LABELS: Record<keyof typeof DEFECT_IMAGE_TYPE, string> = {
      cloned: 'Photo taken during Previous Inspection',
      new: 'New Photo taken for Current Inspection',
      prev: '',
    };
    return LABELS[type];
  }
  const extractDrawableValue = (value: string) => {
    if(!value) return [];
    
    const result = value.match(/(?<=Rect\()(.*)(?=\))/);
    if (result !== null && result?.length) {
      return result[0].split(',');
    }
    return [];
  };

  return (
    <CarouselProvider
      naturalSlideWidth={16}
      naturalSlideHeight={9}
      totalSlides={images.length}
      className={css({ flex: 1, layout: 'row', height: '$full' })}
      dragEnabled={false}
      isIntrinsicHeight
      touchEnabled={false}
    >
      <div
        className={css({
          flex: 1,
          overflow: 'hidden',
          position: 'relative',
          height: '100%',
        })}
      >
        <Slider
          className={css({ height: '$full' })}
          classNameTray={css({ height: '$full' })}
          classNameTrayWrap={css({ height: '$full', position: 'relative' })}
        >
          {images.map((image, index) => (
            <Slide index={index} key={image.uuid}>
              <InteractiveImage
                rectangle={extractDrawableValue(image.drawables)}
                params={{
                  observationId: defect ? defect.observationId : '',
                  defectId: image.observationDefectId,
                  photoId: image.uuid,
                }}
                editable={editable}
                image={image}
              >
                <SecuredImage
                  link={image.annotatedLink}
                  className={css({
                    objectFit: 'contain',
                    width: '$full',
                    height: '$full',
                  })}
                />
              </InteractiveImage>
              {!!defect && (
                <div
                  className={css({
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    pb: '$scale600',
                    pr: '$scale1600',
                  })}
                >
                  <DeleteImage
                    params={{
                      observationId: defect.observationId,
                      defectId: image.observationDefectId,
                      photoId: image.uuid,
                    }}
                  />
                </div>
              )}
              {image.defectImageType !== 'prev' && (
                <div
                  className={css({
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: '$primary',
                    color: '$white',
                    p: '$scale100',
                  })}
                >
                  {getPhotoLabel(image.defectImageType)} -{' '}
                  {format(new Date(image.createdAt), 'MMMM-dd-yyyy')}
                </div>
              )}
            </Slide>
          ))}
        </Slider>

        <nav
          className={css({
            flexShrink: 0,
            position: 'absolute',
            inset: 0,
            pointerEvents: 'none',
            layout: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: '$scale600',
          })}
        >
          <ButtonBack
            className={css({
              pointerEvents: 'auto',
              backgroundColor: '$white !important',
              borderRadius: '$full !important',
              p: '$scale200',
            })}
          >
            <ChevronLeft size={32} />
          </ButtonBack>

          <ButtonNext
            className={css({
              pointerEvents: 'auto',
              backgroundColor: '$white !important',
              borderRadius: '$full !important',
              p: '$scale200',
            })}
          >
            <ChevronRight size={32} />
          </ButtonNext>
        </nav>
        <div
          className={css({
            p: '$scale600',
            position: 'absolute',
            right: 0,
            bottom: 0,
          })}
        >
          <div
            className={css({
              py: '$scale100',
              px: '$scale300',
              backgroundColor: '$mono200',
              borderRadius: '$full',
            })}
          >
            <SlidePosition />
          </div>
        </div>
      </div>
    </CarouselProvider>
  );
}

function DefectInformation({
  defect,
  observation,
  isPreviousDefect,
}: {
  defect: ObservationDefect;
  observation: Observation;
  isPreviousDefect?: boolean;
}) {
  const { css } = useStitches();
  const dictionaryQuery = useDictionary();

  const shownDefect = dictionaryQuery.data?.defects.find(
    (d) => d.id === defect.defectId,
  );
  const component = dictionaryQuery.data?.structuralComponents.find(
    (d) => d.id === observation.structuralComponentId,
  );

  const subcomponent = dictionaryQuery.data?.subComponents.find(
    (d) => d.id === observation.subComponentId,
  );

  return (
    <aside
      className={css({
        overflow: 'hidden',
        divideY: '$1',
        padY: '$scale600',
        padX: '$scale600',
        position: 'relative',
      })}
    >
      <div>
        <div>
          <Text
            variant="LabelXSmall"
            weight="bold"
            css={{
              textTransform: 'uppercase',
              letterSpacing: '$tracking-wide',
              p: '$scale100',
              backgroundColor: '$primary',
              color: '$white',
            }}
          >
            {isPreviousDefect ? 'Previous' : 'Current'} Defect#
          </Text>
          <Text variant="LabelLarge" weight="semibold">
            {defect.id}
          </Text>
          <div className={css({ marginTop: '$scale300' })}>
            <Text variant="LabelSmall">{component?.name}</Text>
            <Text
              variant="LabelSmall"
              weight="semibold"
              css={{ color: '$primary' }}
            >
              {subcomponent?.name}
            </Text>
          </div>
        </div>
      </div>
      <dl
        className={css({
          rowGap: '$scale600',
          display: 'grid',
          gridTemplateColumns: 'repeat(2,1fr)',
        })}
      >
        <div className={css({ spaceY: '$scale100' })}>
          <Text as={'dt'} variant="LabelSmall" css={{ color: '$mono600' }}>
            Inspector
          </Text>
          <dd>
            <Text>
              {defect.createdBy.firstName} {defect.createdBy.lastName}
            </Text>
          </dd>
        </div>
        <div className={css({ spaceY: '$scale100' })}>
          <Text as={'dt'} variant="LabelSmall" css={{ color: '$mono600' }}>
            Inspection Date
          </Text>
          <Text as={'dd'}>{defect?.createdAtClient ? defect.createdAtClient.toLocaleString() : 'No Date'}</Text>

        </div>
        <div className={css({ spaceY: '$scale100' })}>
          <Text as={'dt'} variant="LabelSmall" css={{ color: '$mono600' }}>
            Defect
          </Text>
          <Text as={'dd'}>{shownDefect?.name ?? 'No Defect Name'}</Text>
        </div>
      </dl>
    </aside>
  );
}

type UpdateImageParams = {
  inspectionId: string;
  observationId: string;
  defectId: string;
  photoId: string;
};
type UpdateImagePayload = {
  drawables: string;
};

const deleteDefectImage = ({
  inspectionId,
  observationId,
  defectId,
  photoId,
}: UpdateImageParams) => {
  const url = Helper.parse(
    Config.DELETE_IMAGE_API,
    inspectionId,
    observationId,
    defectId,
    photoId,
  );

  return fetcher(url, { method: 'DELETE' });
};
const updateImage = (args: UpdateImageParams & UpdateImagePayload) => {
  const { inspectionId, observationId, defectId, photoId, drawables } = args;
  const url = Helper.parse(
    Config.DELETE_IMAGE_API,
    inspectionId,
    observationId,
    defectId,
    photoId,
  );

  return fetcher(url, {
    method: 'PUT',
    body: JSON.stringify({ drawables: drawables }),
  });
};

const useDeleteDefectPhotoMutation = () => useMutation(deleteDefectImage);
const useUpdateImage = () => useMutation(updateImage);

function DeleteImage({
  params,
}: {
  params: Omit<UpdateImageParams, 'inspectionId'>;
}) {
  const { css } = useStitches();
  const mutation = useDeleteDefectPhotoMutation();
  const queryClient = useQueryClient();
  const { inspectionId } = useParams<{ inspectionId: string }>();
  const { enqueue } = useSnackbar();

  return (
    <Button
      size="mini"
      isLoading={mutation.isLoading}
      className={css({
        backgroundColor: '$negative400 !important',
        '&:hover': {
          color: 'white !important',
          backgroundColor: '$negative500 !important',
        },
      })}
      startEnhancer={<DeleteOutline fontSize="small" />}
      onClick={() =>
        mutation.mutate(
          { ...params, inspectionId },
          {
            async onSuccess(data, variables, context) {
              await queryClient.invalidateQueries({
                queryKey: [
                  'inspection',
                  variables.inspectionId,
                  'related-cloned-inspections',
                ],
              });
            },
            onError() {
              enqueue({
                message: 'Failed delete image',
              });
            },
          },
        )
      }
    >
      Delete Image
    </Button>
  );
}

function InteractiveImage({
  rectangle,
  children,
  params,
  editable = false,
  image,
}: {
  rectangle?: any[];
  children: React.ReactNode;
  params: Omit<UpdateImageParams, 'inspectionId'>;
  editable?: boolean;
  image: DefectPhoto & {
    defectImageType: keyof typeof DEFECT_IMAGE_TYPE;
  };
}) {
  const [isOpen, setIsOpen] = useState(false);
  const mutation = useUpdateImage();
  const { inspectionId } = useParams<{ inspectionId: string }>();
  const [state, setState] = useState<{
    rectangle: any[];
    initialPoint: any[];
    editable: boolean;
    mouseDown: boolean;
  }>({ rectangle: [], initialPoint: [], editable: editable, mouseDown: false });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      rectangle: rectangle ?? [],
    }));
  }, [rectangle]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      editable,
    }));
  }, [editable]);

  const getRectangleTLBR = (rect: any[]) => {
    const rectangle = [];

    rectangle[0] = Math.min(rect[2], rect[0]);
    rectangle[2] = Math.max(rect[2], rect[0]);
    rectangle[1] = Math.min(rect[1], rect[3]);
    rectangle[3] = Math.max(rect[1], rect[3]);

    return rectangle;
  };

  const getRectangleStyle = (rect: any[]) => {
    if (rect === null || !Array.isArray(rect) || rect.length < 4) {
      return {};
    }

    const rectangle = getRectangleTLBR(rect);

    const left = (rectangle[0] || 0) * 100 + '%';
    const top = (rectangle[1] || 0) * 100 + '%';
    const right = (1 - rectangle[2]) * 100 + '%';
    const bottom = (1 - rectangle[3]) * 100 + '%';

    return {
      left: left,
      top: top,
      bottom: bottom,
      right: right,
      borderColor: '#00ff00',
      borderStyle: 'solid',
      borderWidth: '3px',
      position: 'absolute',
      zIndex: 99,
      pointerEvents: 'none',
    };
  };

  const eventToPointPercentage = (event: any) => {
    let iBounds = [
      event.target.getBoundingClientRect().left,
      event.target.getBoundingClientRect().top,
    ];

    return [
      Math.max(
        Math.min((event.clientX - iBounds[0]) / event.target.offsetWidth, 1),
        0,
      ),
      Math.max(
        Math.min((event.clientY - iBounds[1]) / event.target.offsetHeight, 1),
        0,
      ),
    ];
  };

  const onMouseDown = (event: any) => {
    if (state.editable && event.target.tagName === 'IMG') {
      const point = eventToPointPercentage(event);
      setState((prev) => ({
        ...prev,
        mouseDown: true,
        rectangle: [point[0], point[1], point[0], point[1]],
        initialPoint: [point[0], point[1]],
      }));
    }
  };

  const onMouseMove = (event: any) => {
    if (state.editable && state.mouseDown && event.target.tagName === 'IMG') {
      const point = eventToPointPercentage(event);
      const topLeftX = Math.min(state.initialPoint[0], point[0]);
      const topLeftY = Math.min(state.initialPoint[1], point[1]);
      const bottomRightX = Math.max(state.initialPoint[0], point[0]);
      const bottomRightY = Math.max(state.initialPoint[1], point[1]);
      setState((prev) => ({
        ...prev,
        rectangle: [topLeftX, topLeftY, bottomRightX, bottomRightY],
      }));
    }
  };

  const onMouseUp = (event: any) => {
    setState((prev) => ({ ...prev, mouseDown: false }));
  };
  const { css } = useStitches();
  return (
    <div
      className={css({
        height: '$full',
        position: 'relative',
        backgroundColor: '#ecf0f1',
        layout: 'stack',
        alignItems: 'center',
      })}
      draggable={false}
    >
      <div
        className={css({
          height: '85%',
          position: 'relative',
        })}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseUp}
        draggable={false}
      >
        {children}
        <div style={getRectangleStyle(state.rectangle) as CSSProperties} />
      </div>

      <nav
        className={css({
          display: 'flex',
          gap: '20px',
          height: '15%',
          alignItems: 'center',

        })}
      >
        {editable && (
          <Button
            startEnhancer={<Check />}
            size="compact"
            isLoading={mutation.isLoading}
            onClick={() => {
              mutation.mutate(
                {
                  ...params,
                  inspectionId,
                  drawables: `Rect(${state.rectangle.join()})`,
                },
                {
                  async onSuccess(data, variables, context) {
                    await queryClient.invalidateQueries({
                      queryKey: [
                        'inspection',
                        variables.inspectionId,
                        'related-cloned-inspections',
                      ],
                    });
                  },
                },
              );
            }}
          >
            {rectangle && rectangle.length
              ? 'Update Annotation'
              : 'Apply Annotation'}
          </Button>
        )}
        {/* Create button to open the new modal with the image filling in all modal */}
        <ModalImageZoom
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          image={image}
        />
        <Button
          onClick={() => { setIsOpen(true) }}
          size="compact"
          startEnhancer={<Search />}
        >
          Zoom Image
        </Button>
      </nav>
    </div>
  );
}