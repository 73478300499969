import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeItem from "@material-ui/lab/TreeItem";

const useStyles = makeStyles(() => ({
  root: {
    ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: "red !important",
    },
  },
}));

export default function CleanTreeItem(props) {
  const classes = useStyles();

  return <TreeItem className={classes.root} {...props} />;
}
