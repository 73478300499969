import { Edit } from '@material-ui/icons';
import { Button, KIND as ButtonKind, SHAPE, SIZE } from 'baseui/button';
import { Delete, Plus } from 'baseui/icon';
import { Skeleton } from 'baseui/skeleton';
import { KIND, SIZE as TagSize, Tag, VARIANT } from 'baseui/tag';
import Text from 'components/Text';
import MaterialTable from 'material-table';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useInspection } from 'services/api/inspection/queries';
import {
  ObservationStructureSubdivision,
  StructureSubdivision as TStructureSubdivision,
} from 'services/api/inspection/types';

import { useStitches } from 'theme';

import { SGRBadge } from 'components/SGRBadge';
import { useDictionary } from 'services/api/dictionary/queries';
import { DeleteSubdivision } from '../DeleteSubdivision';
import { EditSubdivision } from '../EditSubdivision';
import { NewSubdivision } from '../NewSubdivision';

type RowData = {
  id: string;
  name: string;
  number: number;
  subComponents: ObservationStructureSubdivision[];
  sgrRating: number;
};

const NewSudivisionAction = () => {
  return (
    <NewSubdivision
      trigger={({ toggle }) => (
        <Button
          startEnhancer={<Plus />}
          size={SIZE.compact}
          onClick={() => toggle(true)}
        >
          Add Sub-division
        </Button>
      )}
    />
  );
};
export default function StructureSubdivision({
  structureId,
}: {
  structureId: string;
}) {
  const { css, theme } = useStitches();
  const { inspectionId } = useParams<{ inspectionId: string }>();
  const inspectionQuery = useInspection(
    { params: { inspectionId } },
    { staleTime: Infinity },
  );
  const dictionaryQuery = useDictionary();
  const [selectedSubdivision, setSelectedSubdivision] = useState<
    TStructureSubdivision | undefined
  >(undefined);

  if (inspectionQuery.isError) {
    return <div>Error</div>;
  }

  if (inspectionQuery.data) {
    const subdivisions = inspectionQuery.data.structureSubdivisions;
    return (
      <div className={css({ spaceY: '$scale600' })}>
        <MaterialTable<RowData>
          options={{
            search: true,
            columnsButton: true,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <div
                  className={css({
                    layout: 'stack',
                    alignItems: 'center',
                    spaceY: '$scale200',
                  })}
                >
                  <Text variant="HeadingXSmall">No Subdivisions</Text>
                </div>
              ),
            },
          }}
          columns={[
            { title: 'Number', field: 'number' },
            { title: 'Name', field: 'name' },
            {
              title: 'Subcomponents',
              render(row, type) {
                return (
                  <SubdivisionComponents
                    components={row.subComponents.map((subcomponent) => {
                      const observationComponent =
                        inspectionQuery.data.observations.find(
                          (observation) =>
                            observation.uuid === subcomponent.observationId,
                        );
                      return {
                        measurement: subcomponent.dimensionNumber,
                        subcomponentId:
                          observationComponent?.subComponentId ?? '',
                        healthIndex: subcomponent.computedHealthIndex ?? 0,
                      };
                    })}
                  />
                );
              },
            },
            {
              title: 'SGR Rating',
              field: 'sgrRating',
              render: (rowData) => (
                <SGRBadge
                  rating={parseFloat(
                    (parseFloat(rowData.sgrRating.toString()) * 100).toFixed(1),
                  )}
                />
              ),
            },
            {
              title: 'Actions',
              render(row, type) {
                const subdivision = subdivisions.find(
                  (subdivision) => subdivision.uuid === row.id,
                );

                return (
                  <nav className={css({ spaceX: '$scale600', layout: 'row' })}>
                    <DeleteSubdivision
                      subdivision={{
                        uuid: row.id,
                        name: row.name,
                      }}
                      trigger={({ toggle }) => (
                        <Button
                          size={SIZE.compact}
                          onClick={() => toggle(true)}
                          shape={SHAPE.circle}
                          colors={{
                            backgroundColor: theme.colors.negative400,
                            color: theme.colors.white,
                          }}
                        >
                          <Delete />
                        </Button>
                      )}
                    />
                    {!!subdivision && (
                      <Button
                        size={SIZE.compact}
                        onClick={() => setSelectedSubdivision(subdivision)}
                        shape={SHAPE.circle}
                      >
                        <Edit fontSize="inherit" />
                      </Button>
                    )}
                  </nav>
                );
              },
            },
          ]}
          data={subdivisions.map((subdivision, index) => ({
            id: subdivision.uuid,
            name: subdivision.name,
            number: subdivision.number,
            subComponents: subdivision.observationStructureSubdivisions,
            sgrRating: Number(subdivision.computedSgrRating ?? '0'),
          }))}
          title="Subdivisions"
          actions={[
            {
              icon: NewSudivisionAction,
              onClick(event, data) {
                //
              },
              isFreeAction: true,
            },
          ]}
        />
        <EditSubdivision
          isOpen={selectedSubdivision !== undefined}
          subdivision={selectedSubdivision}
          onClose={() => setSelectedSubdivision(undefined)}
        />
      </div>
    );
  }
  return (
    <Skeleton
      rows={1}
      height="500px"
      animation
      overrides={{
        Row: { style: { height: '100%' } },
      }}
    />
  );
}

function SubdivisionComponents({
  components,
}: {
  components: {
    subcomponentId: string;
    measurement: number;
    healthIndex?: number;
  }[];
}) {
  const dictionaryQuery = useDictionary();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { css } = useStitches();

  return (
    <div>
      <ul className={css({ spaceY: '$scale300' })}>
        {components.slice(0, isCollapsed ? 3 : undefined).map((component) => {
          const subcomponent = dictionaryQuery.data?.subComponents.find(
            (subcomponent) => component.subcomponentId === subcomponent.id,
          );
          return (
            <li
              key={component.subcomponentId}
              className={css({
                layout: 'row',
                spaceX: '$scale300',
                alignItems: 'center',
              })}
            >
              <Text css={{ color: '$primary500' }}>{subcomponent?.name}</Text>
              <Text>
                {component.measurement} {subcomponent?.measureUnit}
              </Text>
              {!!component.healthIndex && (
                <Tag
                  kind={KIND.green}
                  variant={VARIANT.solid}
                  size={TagSize.small}
                  overrides={{
                    Root: {
                      style: {
                        margin: 0,
                      },
                    },
                  }}
                  closeable={false}
                >
                  {component.healthIndex.toFixed(3)}
                </Tag>
              )}
            </li>
          );
        })}
      </ul>
      {components.length > 3 && (
        <Button
          kind={ButtonKind.tertiary}
          size={SIZE.mini}
          onClick={() => setIsCollapsed((prev) => !prev)}
        >
          {isCollapsed ? 'Show more' : 'Show less'}
        </Button>
      )}
    </div>
  );
}
