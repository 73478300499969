// Maps data coming from Store to UI and from UI to API

import {
  FIELD_ID,
  FIELD_FIRST_NAME,
  FIELD_LAST_NAME,
  FIELD_EMAIL,
  FIELD_ADMIN,
  FIELD_COMPANY_ID,
} from ".";

export default class UsersMapper {
  static mapMultipleRows(response) {
    return response.map((x) => this.mapSingleRow(x));
  }

  static mapSingleRow(singleResponse) {
    var mapResult = {};
    mapResult[FIELD_ID] = singleResponse["id"];
    mapResult[FIELD_FIRST_NAME] = singleResponse["firstName"];
    mapResult[FIELD_LAST_NAME] = singleResponse["lastName"];
    mapResult[FIELD_EMAIL] = singleResponse["email"];
    mapResult[FIELD_ADMIN] = singleResponse["admin"] ? 1 : 0;
    mapResult[FIELD_COMPANY_ID] = singleResponse["companyId"];
    return mapResult;
  }
}
