import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { KIND as ButtonKind } from 'baseui/button';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from 'baseui/modal';

import { Banner, KIND } from 'components/Banner';
import Text from 'components/Text';

import { StructureSubdivision } from 'services/api/inspection/types';
import { FetcherMutatergs, fetcher } from 'services/fetcher';

import SubDivisionForm from '../features/SubdivisionForm';

type DeleteSubdivisionPayload = {
  uuid: string;
};
const deleteSubdivision = async ({
  payload,
}: FetcherMutatergs<DeleteSubdivisionPayload>) => {
  return fetcher(`api/v2/structureSubdivision/${payload.uuid}`, {
    method: 'delete',
  });
};
const useDeleteSubdivision = () => useMutation(deleteSubdivision);

export default function DeleteSubdivision({
  trigger,
  subdivision,
}: {
  trigger: (args: { toggle: (open: boolean) => void }) => React.ReactNode;
  subdivision: Pick<StructureSubdivision, 'name' | 'uuid'>;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();
  const deleteSubdivision = useDeleteSubdivision();
  const { inspectionId } = useParams<{ inspectionId: string }>();

  const handleClose = () => {
    if (deleteSubdivision.isLoading) return;
    setIsOpen(false);
  };

  return (
    <>
      {trigger({ toggle: (open = !isOpen) => setIsOpen(open) })}
      <Modal onClose={() => handleClose()} isOpen={isOpen}>
        <ModalHeader>Delete Sub-division</ModalHeader>
        <ModalBody>
          <Banner
            show={deleteSubdivision.isError}
            title="Error"
            kind={KIND.negative}
          >
            Failed to delete new sub-division, try again.
          </Banner>
          <Text>
            Are you sure you want to delete Sub-division:
            <Text weight="bold">{subdivision.name}</Text>?
          </Text>
        </ModalBody>
        <ModalFooter>
          <ModalButton kind={ButtonKind.tertiary} onClick={handleClose}>
            Cancel
          </ModalButton>
          <ModalButton
            form="new-subdivision-form"
            isLoading={deleteSubdivision.isLoading}
            onClick={() => {
              deleteSubdivision.mutate(
                {
                  payload: {
                    uuid: subdivision.uuid,
                  },
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries([
                      'inspection',
                      inspectionId ?? '',
                    ]);
                  },
                },
              );
            }}
          >
            Delete Sub-division
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
}
