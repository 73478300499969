import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import GeneralCard from "components/GeneralCard";
import { Strings } from "config/Strings";

export default class ComponentsStatistics extends Component {
  labels = [];
  data = [];
  pointer = 0;
  backgroundColor = [];
  colors = [
    "#ff6767",
    "#ffb667",
    "#fffa67",
    "#9cff67",
    "#67ff90",
    "#67ffcf",
    "#67dcff",
    "#67a4ff",
    "#8567ff",
    "#c567ff",
    "#f767ff",
    "#ff67ca",
    "#ff6792",
  ];

  getNextColor() {
    return this.colors[++this.pointer % this.colors.length];
  }

  toPercentage(value, total) {
    return Math.round(((value / total) * 100 + Number.EPSILON) * 100) / 100;
  }

  parseData() {
    this.labels = [];
    this.data = [];
    var total = 0;
    for (var prop in this.props.data) {
      this.labels.push(prop + " (" + this.props.data[prop] + ")");
      total += this.props.data[prop];
      this.backgroundColor.push(this.getNextColor());
    }
    for (prop in this.props.data) {
      this.data.push(this.toPercentage(this.props.data[prop], total));
    }
  }

  render() {
    this.parseData();
    const title = Strings.getInstance().getString("inspections_components_stats");
    const data = {
      labels: this.labels,
      datasets: [
        {
          data: this.data,
          backgroundColor: this.backgroundColor,
        },
      ],
    };

    const content = <Pie data={data} height={100} options={{ legend: { position: "right" } }} />;

    return <GeneralCard title={title} content={content} />;
  }
}
