import TemplateAPIController from "services/api/TemplateAPIController";
import PreferencesAPIController from "services/api/PreferencesAPIController";

export const FETCH_SUB_COMPONENTS_OF_STRUCTURE_STARTED = "FETCH_SUB_COMPONENTS_OF_STRUCTURE_STARTED";
export const FETCH_SUB_COMPONENTS_OF_STRUCTURE_SUCCESS = "FETCH_SUB_COMPONENTS_OF_STRUCTURE_SUCCESS";
export const FETCH_SUB_COMPONENTS_OF_STRUCTURE_FAILED = "FETCH_SUB_COMPONENTS_OF_STRUCTURE_FAILED";
export const FETCH_PREFERENCES_SUB_COMPONENT_PICKER_SUCCESS = "FETCH_PREFERENCES_SUB_COMPONENT_PICKER_SUCCESS";
export const SET_SUB_COMPONENT_PICKER_VISIBILITY_SUCCESS = "SET_SUB_COMPONENT_PICKER_VISIBILITY";
export const SAVE_NEW_TEMPLATE_STARTED = "SAVE_NEW_TEMPLATE_STARTED";
export const SAVE_NEW_TEMPLATE_SUCCESS = "SAVE_NEW_TEMPLATE_SUCCESS";
export const SAVE_NEW_TEMPLATE_FAILED = "SAVE_NEW_TEMPLATE_FAILED";

export const saveNewTemplateStarted = () => {
  return {
    type: SAVE_NEW_TEMPLATE_STARTED,
  };
};

export const saveNewTemplateSuccess = (data) => {
  return {
    type: SAVE_NEW_TEMPLATE_SUCCESS,
    data: data,
  };
};

export const saveNewTemplateFailed = (error) => {
  return {
    type: SAVE_NEW_TEMPLATE_FAILED,
    error: "Error saving template. Please try again or contact support.",
  };
};

export const setSubComponentPickerVisibilitySuccess = (visibility) => {
  return {
    type: SET_SUB_COMPONENT_PICKER_VISIBILITY_SUCCESS,
    visibility: visibility,
  };
};

export const fetchPreferencesSubComponentPickerSuccess = (data) => {
  return {
    type: FETCH_PREFERENCES_SUB_COMPONENT_PICKER_SUCCESS,
    data: data,
  };
};

export const fetchSubComponentsOfStructureSuccess = (data) => {
  return {
    type: FETCH_SUB_COMPONENTS_OF_STRUCTURE_SUCCESS,
    data: data,
  };
};

export const fetchSubComponentsOfStructureFailed = (error) => {
  return {
    type: FETCH_SUB_COMPONENTS_OF_STRUCTURE_FAILED,
    error: "Error loading sub-components. Please try again or contact support.",
  };
};

export const fetchSubComponentsOfStructureStarted = () => {
  return {
    type: FETCH_SUB_COMPONENTS_OF_STRUCTURE_STARTED,
  };
};

const setSubComponentPickerVisibility = (visibility) => {
  return function (dispatch) {
    dispatch(setSubComponentPickerVisibilitySuccess(visibility));
  };
};

const getComponentsAndSubComponents = (structureId) => {
  return function (dispatch) {
    dispatch(fetchSubComponentsOfStructureStarted());
    PreferencesAPIController.getPreferences()
      .then((response) => {
        dispatch(fetchPreferencesSubComponentPickerSuccess(response));
      })
      .then(() => {
        TemplateAPIController.getSubComponentsOfStructure(structureId)
          .then((response) => {
            dispatch(fetchSubComponentsOfStructureSuccess(response));
          })
          .catch((error) => {
            console.log(error);
            dispatch(fetchSubComponentsOfStructureFailed(error));
          });
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchSubComponentsOfStructureFailed(error));
      });
  };
};

const saveNewTemplate = (newTemplate) => {
  return function (dispatch) {
    dispatch(saveNewTemplateStarted());
    TemplateAPIController.saveSubComponentsOfStructure(newTemplate)
      .then((response) => {
        dispatch(saveNewTemplateSuccess(newTemplate));
      })
      .catch((error) => {
        console.log(error);
        dispatch(saveNewTemplateFailed(error));
      });
  };
};

export { getComponentsAndSubComponents, setSubComponentPickerVisibility, saveNewTemplate };
