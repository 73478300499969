import {
  FETCH_USERS_STARTED,
  FETCH_USERS_SUCCESS,
  ADD_USER_FAILED,
  ADD_USER_STARTED,
  ADD_USER_SUCCESS,
  UPDATE_USER_FAILED,
  UPDATE_USER_STARTED,
  UPDATE_USER_SUCCESS,
  DELETE_USER_STARTED,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
} from "./UsersReducer";

import { Strings } from "config/Strings";
import { showInfoDialog } from "components/Dialog/DialogAction";

import UserAPIController from "services/api/UserAPIController";

// TODO: Complete all user actions especially mappers.

const fetchUsersTableDataSuccess = (data) => {
  return {
    type: FETCH_USERS_SUCCESS,
    data: data,
  };
};

const fetchUsersTableDataStarted = () => {
  return {
    type: FETCH_USERS_STARTED,
  };
};

const addUserToTableFailed = () => {
  return {
    type: ADD_USER_FAILED,
  };
};

const addUserToTableStarted = () => {
  return {
    type: ADD_USER_STARTED,
  };
};

const addUserToTableSuccess = (user) => {
  return {
    type: ADD_USER_SUCCESS,
    user: user,
  };
};

const updateUserInTableFailed = () => {
  return {
    type: UPDATE_USER_FAILED,
  };
};

const updateUserInTableStarted = () => {
  return {
    type: UPDATE_USER_STARTED,
  };
};

const updateUserInTableSuccess = (user) => {
  return {
    type: UPDATE_USER_SUCCESS,
    user: user,
  };
};

const deleteUserFromTableFailed = () => {
  return {
    type: DELETE_USER_FAILED,
  };
};

const deleteUserFromTableStarted = () => {
  return {
    type: DELETE_USER_STARTED,
  };
};

const deleteUserFromTableSuccess = (userId) => {
  return {
    type: DELETE_USER_SUCCESS,
    userId: userId,
  };
};

const watchUsersTableData = (companyId) => {
  return function (dispatch) {
    dispatch(fetchUsersTableDataStarted());
    UserAPIController.getUsers(companyId)
      .then((response) => dispatch(fetchUsersTableDataSuccess(response)))
      .catch((error) => {
        console.log(error);
      });
  };
};

const addUser = (firstName, lastName, email, admin, password, companyId) => {
  return function (dispatch) {
    dispatch(addUserToTableStarted());
    UserAPIController.addUser(firstName, lastName, email, parseInt(admin) === 1 ? true : false, password, companyId)
      .then((response) => {
        dispatch(addUserToTableSuccess(response));
      })
      .catch((error) => {
        dispatch(addUserToTableFailed());
        dispatch(showInfoDialog(Strings.getInstance().getString("dialog_error_add_user_title"), Strings.getInstance().getString("dialog_error_add_user_description")));
        console.log(error);
      });
  };
};

const updateUser = (id, firstName, lastName, email, admin, companyId) => {
  return function (dispatch) {
    dispatch(updateUserInTableStarted());
    UserAPIController.updateUser(id, firstName, lastName, email, parseInt(admin) === 1 ? true : false, companyId)
      .then((response) => dispatch(updateUserInTableSuccess(response)))
      .catch((error) => {
        dispatch(updateUserInTableFailed());
        dispatch(showInfoDialog(Strings.getInstance().getString("dialog_error_update_user_title"), Strings.getInstance().getString("dialog_error_update_user_description")));
        console.log(error);
      });
  };
};

const deleteUser = (userId) => {
  return function (dispatch) {
    dispatch(deleteUserFromTableStarted());
    UserAPIController.deleteUser(userId)
      .then((response) => dispatch(deleteUserFromTableSuccess(userId)))
      .catch((error) => {
        dispatch(deleteUserFromTableFailed());
        dispatch(showInfoDialog(Strings.getInstance().getString("dialog_error_delete_user_title"), Strings.getInstance().getString("dialog_error_delete_user_description")));
        console.log(error);
      });
  };
};

export { watchUsersTableData, addUser, updateUser, deleteUser };
