import {
  FIELD_ID,
  FIELD_END_DATE,
  FIELD_SGR_RATING,
  FIELD_START_DATE,
  FIELD_STATUS,
  FIELD_STRUCTURE,
  FIELD_TERM_RATING,
  FIELD_SPANS,
  FIELD_INSPECTORS,
  FIELD_STRUCTURE_NAME,
  FIELD_GENERAL_SUMMARY,
  FIELD_ARCHIVED,
} from "./components/InspectionsTable";

import { createSelector } from "reselect";
import Helper from "helper/Helper";

const getInspections = (state) => state.InspectionsReducer.data;
const getInspectors = (state) => state.UsersReducer.data;
const getProject = (state) => state.InspectionsReducer.projectData;
const getStructure = (state) => state.InspectionsReducer.structureData;
const getDefectByComponent = (state) => state.InspectionsReducer.defectByComponent;
const getObservationByType = (state) => state.InspectionsReducer.observationByType;

const mapStructureData = (structureId, structureCode) => {
  if (Helper.isEmpty(structureId)) {
    return undefined;
  }
  return { id: structureId, name: structureCode || "" };
};

const mapSingleRow = (singleResponse) => {
  var mapResult = {isCloned: singleResponse.previousInspectionId?singleResponse.previousInspectionId:false};
  mapResult[FIELD_ID] = singleResponse["uuid"];
  mapResult[FIELD_STRUCTURE] = mapStructureData(singleResponse["structureId"], singleResponse["structureCode"]);
  mapResult[FIELD_STRUCTURE_NAME] = singleResponse["structureName"];
  mapResult[FIELD_START_DATE] = singleResponse["startDate"];
  mapResult[FIELD_END_DATE] = singleResponse["endDate"];
  mapResult[FIELD_SGR_RATING] = isNaN(singleResponse["sgrRating"]) ? "" : (singleResponse["sgrRating"] * 1).toFixed(2) + "%";
  mapResult[FIELD_STATUS] = singleResponse["status"];
  mapResult[FIELD_SPANS] = singleResponse["spansCount"];
  mapResult[FIELD_TERM_RATING] = singleResponse["termRating"];
  mapResult[FIELD_GENERAL_SUMMARY] = singleResponse["generalSummary"];

  mapResult[FIELD_INSPECTORS] = mapInspectors(singleResponse["inspectors"]);
  mapResult[FIELD_ARCHIVED] = singleResponse["archived"];
  return mapResult;
};

const mapInspectors = (userData) => {
  if (Helper.isEmpty(userData)) {
    return [];
  }

  return userData.map((user) => {
    return {
      id: "" + user["id"],
      name: user["firstName"] + " " + user["lastName"],
    };
  });
};

export const extractId = (data) => {
  if (data === undefined) {
    return undefined;
  }

  return data["id"];
};

export const extractIds = (array) => {
  if (array === undefined) {
    return [];
  }

  return array.map((element) => {
    return element["id"];
  });
};

export const mapMultipleRows = createSelector([getInspections], (originalResponse) => {
  const response = originalResponse.slice();
  return response.map((x) => mapSingleRow(x));
});

export const mapInspectorsOptions = createSelector([getInspectors], (userData) => {
  if (Helper.isEmpty(userData)) {
    return [];
  }

  return userData.map((user) => {
    return {
      id: "" + user["id"],
      name: user["firstName"] + " " + user["lastName"],
    };
  });
});

export const mapDefectByComponent = createSelector([getDefectByComponent], (defectByComponent) => {
  var result = {};
  for (let i = 0; i < (defectByComponent || []).length; i++) {
    const element = defectByComponent[i];
    result[element["componentName"]] = element["observationDefectsCount"];
  }
  return result;
});

export const mapObservationByType = createSelector([getObservationByType], (observationByType) => {
  var result = { structural: 0, maintenance: 0 };
  if (observationByType === undefined) {
    return result;
  }
  return observationByType;
});

export const mapStructureOptions = createSelector([getProject], (projectData) => {
  if (Helper.isEmpty(projectData) || Helper.isEmpty(projectData["structures"])) {
    return [];
  }

  return projectData["structures"].map((structure) => {
    return {
      id: structure["id"],
      name: structure["code"] + " - " + structure["name"],
    };
  });
});

export const mapProjectId = createSelector([getProject], (projectData) => {
  if (Helper.isEmpty(projectData)) {
    return undefined;
  }

  return projectData["id"];
});

export const mapInspectorsData = createSelector([getInspections], (originalResponse) => {
  const response = originalResponse.slice();

  var mapResult = new Map();
  var totalInspectorScore = new Map();
  for (var i = 0; i < response.length; i++) {
    const data = response[i];
    var inspectors = [];

    if (Array.isArray(data["inspectors"])) {
      inspectors = data["inspectors"];
    } else if (data["inspectors"] !== undefined) {
      inspectors.push(data["inspectors"]);
    }

    inspectors.forEach((element) => {
      if (element["id"] !== undefined) {
        if (!mapResult.has(element["id"])) {
          totalInspectorScore.set(element["id"], 0);
          mapResult.set(element["id"], {
            name: element["firstName"] + " " + element["lastName"],
            inProgress: 0,
            completed: 0,
          });
        }

        var newElement = mapResult.get(element.id);
        if (data["status"] === "COMPLETED") {
          newElement["completed"]++;
        } else {
          newElement["inProgress"]++;
        }

        var score = totalInspectorScore.get(element.id);
        totalInspectorScore.set(element.id, score++);
        mapResult.set(element.id, newElement);
      }
    });
  }

  var sortedMap = new Map([...mapResult.entries()].sort((a, b) => b[1] - a[1]));

  return Array.from(sortedMap.values()).slice(0, 8);
});

export const mapTitle = createSelector([getStructure, getProject], (structureData, projectData) => {
  if (!Helper.isEmpty(structureData)) {
    return structureData["name"];
  }
  if (!Helper.isEmpty(projectData)) {
    return projectData["name"];
  }

  return "";
});

export const mapCompletedVsInProgressData = createSelector([getInspections], (originalResponse) => {
  const response = originalResponse.slice();
  var counts = { inProgress: 0, completed: 0 };
  for (var i = 0; i < response.length; i++) {
    const data = response[i];
    if (data["status"] === "COMPLETED") {
      counts.completed++;
    } else {
      counts.inProgress++;
    }
  }

  return counts;
});
