export const FETCH_CLIENT_STARTED = "FETCH_CLIENT_STARTED";
export const FETCH_CLIENT_SUCCESS = "FETCH_CLIENT_SUCCESS";

const initialState = {
  data: {},
  loading: true,
};

const ClientReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLIENT_STARTED:
      return { ...state, loading: true };
    case FETCH_CLIENT_SUCCESS:
      return { ...state, data: action.data, loading: false };
    default:
      return state;
  }
};

export { ClientReducer };
