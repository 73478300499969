import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useQuerystring() {
  const location = useLocation();
  const qs = useMemo<URLSearchParams>(() => {
    const params = location.pathname.split('?')?.[1] ?? location.search;
    return new URLSearchParams(location.search ? location.search : params);
  }, [location]);

  return qs;
}
