import { yupResolver } from '@hookform/resolvers/yup';
import { Straighten } from '@material-ui/icons';
import React from 'react';
import { Control, Controller, useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Checkbox } from 'baseui/checkbox';
import { Alert } from 'baseui/icon';
import { Input, SIZE } from 'baseui/input';
import { Skeleton } from 'baseui/skeleton';

import { Banner } from 'components/Banner';
import { FormController } from 'components/FormController';
import Text from 'components/Text';

import { useDictionary } from 'services/api/dictionary/queries';
import { useInspection } from 'services/api/inspection/queries';

import theme, { useStitches } from 'theme';

export type FormFields = {
  name: string;
  number: string;
  subComponents: Record<
    string,
    {
      subComponent: { id: string; selected: boolean; dimensionNumber: number };
      measurement: string;
    }
  >;
  inspectionId: string;
};

const schema = yup.object().shape({
  name: yup.string().required('This field is required'),
  number: yup
    .string()
    .test('only-numbers', 'This field only accepts numbers', function (value) {
      if (value === undefined) return false;
      return value.match(/^\d+$/)?.length !== 0;
    })
    .test(
      '6-characters-max',
      'A maximum of 6 digits are allowed.',
      function (value) {
        if (value === undefined) return false;
        return value.length <= 6;
      },
    )
    .required('This field is required'),
  subComponents: yup.lazy((value) => {
    return yup
      .object({
        ...Object.entries(value).reduce(
          (all, [key, value]) => ({
            ...all,
            [key]: yup.object({
              subComponent: yup.object({
                id: yup.string(),
                selected: yup.boolean(),
                dimensionNumber: yup.number(),
              }),
              measurement: yup
                .number()
                .when('subComponent', (subComponent, schema) => {
                  if (subComponent.selected) {
                    return schema
                      .required('This field is required')
                      .max(
                        subComponent.dimensionNumber,
                        'Measurement must not exceed the dimension number of subcomponent',
                      );
                  }
                  return schema.notRequired();
                })
                .typeError('measurement must be a number'),
            }),
          }),
          // FIXME: provide better types
          // Not the correct types but works good for now
          {} as Record<string, any>,
        ),
      })
      .test(
        'has-selected',
        'Select a subcomponent to allocate in this sub-division',
        function (value) {
          if (value === undefined) return false;
          const objValues = Object.values(value);
          const hasSelected = objValues.some(
            (item) => !!item?.subComponent?.selected,
          );

          if (objValues.length === 0 || !hasSelected) {
            return false;
          }

          return true;
        },
      );
  }),
});

export default function SubDivisionForm({
  onSubmit: onSubmitHandler = () => {},
  initialValues,
}: {
  onSubmit?: (data: FormFields) => void;
  initialValues?: FormFields;
}) {
  const { css } = useStitches();

  const params = useParams<{ inspectionId: string }>();
  const { control, handleSubmit } = useForm<FormFields>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      inspectionId: params.inspectionId,
      ...(initialValues ?? {}),
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = handleSubmit(onSubmitHandler);

  return (
    <form
      id="new-subdivision-form"
      className={css({
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)',
        columnGap: '$scale600',
      })}
      onSubmit={onSubmit}
    >
      <FormController
        control={control}
        name="name"
        label="Name"
        defaultValue=""
      >
        {({ field }) => <Input {...field} placeholder="Section 1" />}
      </FormController>
      <FormController
        control={control}
        name="number"
        label="Number"
        defaultValue=""
      >
        {({ field }) => <Input {...field} placeholder="000000" />}
      </FormController>
      <FormController
        control={control}
        name="subComponents"
        defaultValue={{}}
        label="Subcomponents"
        description="Select subcomponents to include in this subdivision."
        overrides={{
          Root: {
            style: {
              gridColumn: '1/-1',
            },
          },
        }}
      >
        <SubComponentsSelect control={control} />
      </FormController>
    </form>
  );
}

function SubComponentsSelect({
  control,
}: {
  control: Control<FormFields, any>;
}) {
  const selectedSubcomponents = Object.values(
    useWatch({ control }).subComponents ?? {},
  )
    ?.filter((i) => i?.subComponent?.selected)
    .map((i) => i?.subComponent?.id)
    .filter(Boolean);

  const { css } = useStitches();
  const params = useParams<{ inspectionId: string }>();
  const dictionaryQuery = useDictionary();
  const inspectionQuery = useInspection({
    params: { inspectionId: params.inspectionId },
  });

  if (inspectionQuery.isError || dictionaryQuery.isError) {
    return (
      <Banner
        title="Error"
        artwork={{
          icon: () => <Alert />,
        }}
      >
        Failed to get inspection data,refresh page and try again.
      </Banner>
    );
  }

  if (inspectionQuery.data) {
    const inspection = inspectionQuery.data;

    return (
      <ul>
        {inspection.observations.map((observation) => {
          const subComponent = dictionaryQuery.data?.subComponents.find(
            (s) => s.id === observation.subComponentId,
          );
          const component = dictionaryQuery.data?.structuralComponents.find(
            (s) => s.id === observation.structuralComponentId,
          );
          const allocatedDimension = inspection.structureSubdivisions.reduce(
            (all, sub) => {
              const dimensionNumber =
                sub.observationStructureSubdivisions.find(
                  (subdivision) =>
                    subdivision.observationId === observation.uuid,
                )?.dimensionNumber ?? 0;
              return all + dimensionNumber;
            },
            0,
          );

          return (
            <li key={observation.uuid}>
              <div
                className={css({
                  py: '$scale300',
                  spaceY: '$scale500',
                })}
              >
                <Controller
                  control={control}
                  name={`subComponents.${observation.uuid}.subComponent`}
                  defaultValue={{
                    id: observation.uuid,
                    selected: false,
                    dimensionNumber: observation.dimensionNumber,
                  }}
                  render={({ field }) => {
                    return (
                      <div
                        className={css({
                          layout: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        })}
                      >
                        <div>
                          <Text>{component?.name}</Text>
                          <div
                            className={css({
                              layout: 'row',
                              spaceX: '$scale100',
                              alignItems: 'center',
                            })}
                          >
                            <Text variant="LabelSmall" weight="bold">
                              {subComponent?.name}
                            </Text>
                            <span aria-hidden={true}>-</span>
                            <Text
                              variant="LabelSmall"
                              css={{ color: '$primary' }}
                            >
                              Remaining allocation:{' '}
                              {observation.dimensionNumber - allocatedDimension}
                            </Text>
                          </div>
                        </div>
                        <Checkbox
                          checked={field.value.selected}
                          onChange={(e) =>
                            field.onChange({
                              ...field.value,
                              id: observation.uuid,
                              selected: e.target.checked,
                            })
                          }
                        />
                      </div>
                    );
                  }}
                />

                <FormController
                  control={control}
                  name={`subComponents.${observation.uuid}.measurement`}
                  label="Measurement"
                  defaultValue={undefined}
                >
                  {({ field }) => (
                    <Input
                      {...field}
                      value={field.value === undefined ? '' : field.value}
                      startEnhancer={<Straighten />}
                      size={SIZE.compact}
                      placeholder={`Max measurement of ${observation.dimensionNumber}`}
                      overrides={{
                        StartEnhancer: {
                          style: {
                            paddingLeft: 0,
                          },
                        },
                      }}
                      disabled={
                        !selectedSubcomponents?.includes(observation.uuid)
                      }
                    />
                  )}
                </FormController>
              </div>
            </li>
          );
        })}
      </ul>
    );
  }

  return <Skeleton rows={3} height={theme.sizing.scale2400} animation />;
}
