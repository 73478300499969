import { useQueryClient } from '@tanstack/react-query';
import { KIND as ButtonKind } from 'baseui/button';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  ROLE,
  SIZE,
} from 'baseui/modal';
import { Spinner } from 'baseui/spinner';
import { Banner, KIND } from 'components/Banner';
import Text from 'components/Text';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEditInspection } from 'services/api/inspection/mutation';
import { useInspection, useOpOrdDoc } from 'services/api/inspection/queries';
import { useStitches } from 'theme';
import { useQuerystring } from 'utils/hooks';
import InspectionForm, { FormAction } from '../InspectionForm';

export default function EditInspection() {
  const location = useLocation();
  const { css, theme } = useStitches();
  const history = useHistory();
  const searchParams = useQuerystring();
  const queryClient = useQueryClient();
  const isActionAdd = searchParams.has('action')
    ? searchParams.get('action') === 'edit'
    : false;
  const [isOpen, setIsOpen] = useState(isActionAdd);

  const projectId = Number(searchParams.get('projectId') || '0');
  const inspectionQuery = useInspection({
    params: { inspectionId: searchParams.get('inspectionId') ?? '' },
  });
  const opOrdQuery = useOpOrdDoc(
    {
      params: { inspectionUUID: searchParams.get('inspectionId') ?? '' },
    },
    { enabled: isOpen },
  );
  const inspection = inspectionQuery.data;
  const isLoading = [inspectionQuery, opOrdQuery].some(
    (query) => query.isLoading,
  );
  const isError = [inspectionQuery, opOrdQuery].some((query) => query.isError);

  const updateInspection = useEditInspection();
  const handleClose = () => {
    if (updateInspection.isLoading) return;
    const params = new URLSearchParams(location.search);
    params.delete('action');
    setIsOpen(false);
    setTimeout(() => {
      history.replace(`${location.pathname}?${params.toString()}`);
    }, 100);
  };

  useEffect(() => {
    setIsOpen(isActionAdd);
  }, [isActionAdd]);

  useEffect(() => {
    if (!projectId) {
      setIsOpen(false);
    }
  }, [projectId]);

  return (
    <Modal
      onClose={() => handleClose()}
      isOpen={isOpen}
      size={SIZE.default}
      role={ROLE.dialog}
      closeable
    >
      <ModalHeader>Edit Inspection</ModalHeader>
      <ModalBody>
        {isLoading && (
          <div
            className={css({
              layout: 'stack',
              alignItems: 'center',
              spaceY: '$scale200',
            })}
          >
            <Spinner $size={theme.sizing.scale1200} />
            <Text>Loading Inspection</Text>
          </div>
        )}
        <Banner
          kind={KIND.negative}
          show={isError}
          title="Error"
          action={{
            label: 'Try again',
            onClick: () => inspectionQuery.refetch(),
          }}
        >
          Failed to load inspection data
        </Banner>
        {!isLoading && inspection !== undefined && (
          <InspectionForm
            type={FormAction.UPDATE}
            initialValue={{
              file: undefined,
              inspectionId: inspection.uuid,
              projectId,
              spans: inspection.spansCount,
              startDate: new Date(inspection.startDate),
              endDate: inspection.endDate
                ? new Date(inspection.endDate)
                : undefined,
              status: inspection.status,
              structure: [{ id: inspection.structureId }],
              inspectors: inspection.inspectors.map((inspector) => ({
                id: inspector.id,
              })),
              withExistingDocument: !!opOrdQuery.data?.link,
            }}
            onSubmit={(data) => {
              updateInspection.mutate(
                {
                  payload: {
                    ...(inspection?.previousInspectionId
                      ? {
                          previousInspectionId: inspection.previousInspectionId,
                        }
                      : {}),
                    file:
                      data.file && data.file.length ? data.file[0] : undefined,
                    inspectorId:
                      data.inspectors
                        .filter((item) => !!item.id)
                        .map((item) => Number(item.id ?? '0')) ?? [],
                    spans: data.spans,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    structureId: data.structure[0]?.id?.toString() ?? '',
                    projectId: data.projectId,
                    status: data.status,
                    inspectionId: data.inspectionId,
                    termRating: data.term,
                  },
                },
                {
                  onSuccess() {
                    handleClose();
                    queryClient.invalidateQueries(['inspection']);
                  },
                },
              );
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <ModalButton kind={ButtonKind.tertiary} onClick={() => handleClose()}>
          Cancel
        </ModalButton>
        <ModalButton
          form="create-inspection-form"
          type="submit"
          disabled={isLoading}
          isLoading={updateInspection.isLoading}
        >
          Edit Inspection
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}
