import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import Config from 'config/Config';
import Helper from 'helper/Helper';

import { FetcherJSONResponse, FetcherQueryArgs } from 'services/fetcher';
import { fetcher } from 'services/fetcher';

import { deserializeInspection } from './deserializer';

type InspectionFilters = {
  projectId: string;
  structureId: string;
  withObservations: boolean;
};

type GetIspectionsArgs = FetcherQueryArgs<InspectionFilters>;
export async function getInspections(args: GetIspectionsArgs) {
  const { params } = args;
  const defaultFilter: Partial<InspectionFilters> = { withObservations: false };
  const requestFilters = { ...defaultFilter, ...(params ?? {}) };
  const searchParams = new URLSearchParams(
    Object.keys(requestFilters)

      .map((key) => {
        const value = requestFilters[key as keyof InspectionFilters];
        if (value !== undefined) {
          return `${key}=${encodeURIComponent(value)}`;
        }
        return '';
      })
      .filter(Boolean)
      .join('&'),
  );
  const hasParams = Array.from(searchParams.entries()).length !== 0;
  const response = (await fetcher(
    `${Config.INSPECTION_API}${hasParams ? `?${params.toString()}` : ''}`,
  )) as any[];

  return response.map(deserializeInspection);
}

type GetInspectionArgs = FetcherQueryArgs<{ inspectionId: string }>;
export async function getInspection(args: GetInspectionArgs) {
  const { params } = args;

  return fetcher(
    `${Helper.parse(Config.GET_INSPECTION_API, params.inspectionId)}`,
  ).then((r: FetcherJSONResponse) => deserializeInspection(r));
}

export function useInspection(
  args: GetInspectionArgs & { keys?: Record<string, any> },
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getInspection>>,
    unknown,
    Awaited<ReturnType<typeof getInspection>>,
    (string | Record<string, any>)[]
  >,
) {
  return useQuery(
    ['inspection', args.params.inspectionId, args?.keys ?? {}],
    () => getInspection({ params: { inspectionId: args.params.inspectionId } }),
    {
      enabled: Boolean(args.params.inspectionId),
      staleTime: Infinity,
      ...(options ?? {}),
    },
  );
}

type GetOpOrdDocArgs = FetcherQueryArgs<{ inspectionUUID: string }>;
export function getOpOrdDoc({ params }: GetOpOrdDocArgs) {
  return fetcher(Helper.parse(Config.UPLOAD_OPORD, params.inspectionUUID)).then(
    (r) => ({ link: r.opOrdLink } as { link: string }),
  );
}

export function useOpOrdDoc(
  args: Partial<GetOpOrdDocArgs>,
  options?: { enabled: boolean },
) {
  return useQuery(
    ['inspection', args.params?.inspectionUUID, 'OpORD-Doc'],
    () =>
      getOpOrdDoc({
        params: {
          inspectionUUID: args.params?.inspectionUUID ?? '',
        },
      }),
    {
      enabled: !!args.params?.inspectionUUID && !!options?.enabled,
    },
  );
}
