import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import GeneralCard from "components/GeneralCard";
import { Colors } from "config/StyleConfig";
import { Strings } from "config/Strings";

export default class CompletedVsInProgressCard extends Component {
  toPercentage(value, total) {
    return Math.round(((value / total) * 100 + Number.EPSILON) * 100) / 100;
  }

  render() {
    const title = Strings.getInstance().getString("inspections_completed_vs_in_progress");
    const data = {
      labels: ["In Progress (" + this.props.inProgress + ")", "Completed (" + this.props.completed + ")"],
      datasets: [
        {
          data: [
            this.toPercentage(this.props.inProgress, this.props.completed + this.props.inProgress),
            this.toPercentage(this.props.completed, this.props.completed + this.props.inProgress),
          ],
          backgroundColor: [Colors.inProgress, Colors.completed],
        },
      ],
    };

    const options = {
      maintainAspectRatio: false,
      responsive: false,
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 10,
        },
      },
    };

    const content = <Pie data={data} height={300} width={300} options={options} />;
    return <GeneralCard title={title} content={content} centerContent={true} />;
  }
}
