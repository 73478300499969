import Config from "config/Config";
import Helper from "helper/Helper";
import { APIException } from "Exceptions";
import SecureAPIBuilder from "./SecureAPIBuilder";

export default class UserAPIController {
  // Get calls.
  static getUsers(companyId = undefined) {
    if (companyId !== undefined) {
      return new SecureAPIBuilder(Config.GET_USERS_API, SecureAPIBuilder.GET)
        .query({ companyId: companyId })
        .call()
        .then((response) => {
          if (!response.ok) {
            throw new APIException(response.status, response.statusText);
          }
          return response.json();
        });
    } else {
      return new SecureAPIBuilder(Config.GET_USERS_API, SecureAPIBuilder.GET).call().then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
    }
  }

  // Insert calls.
  static addUser(firstName, lastName, email, admin, password, companyId) {
    const body = JSON.stringify({
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      admin: admin,
      position: null,
      companyId: companyId === undefined ? null : companyId,
    });

    return new SecureAPIBuilder(Config.POST_USER_API, SecureAPIBuilder.POST)
      .body(body)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  // Delete calls.
  static deleteUser(userId) {
    const url = Helper.parse(Config.DELETE_USER_API, userId);
    return new SecureAPIBuilder(url, SecureAPIBuilder.DELETE).call().then((response) => {
      if (!response.ok) {
        throw new APIException(response.status, response.statusText);
      }
      return response.json();
    });
  }

  // Update calls.
  static updateUser(id, firstName, lastName, email, admin, companyId) {
    const body = JSON.stringify({
      email: email,
      firstName: firstName,
      lastName: lastName,
      admin: admin,
      position: null,
      companyId: companyId,
    });

    const url = Helper.parse(Config.UPDATE_USER_API, id);

    return new SecureAPIBuilder(url, SecureAPIBuilder.PUT)
      .body(body)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }
}
