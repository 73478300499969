import * as Stitches from '@stitches/react';
import { createStitches } from '@stitches/react';
import { useCallback } from 'react';
import { ColorTokens } from 'baseui/themes';
// eslint-disable-next-line import/extensions
import theme from './baseui.config';

const PrimitiveColorTokens = [
  'primaryA',
  'primaryB',
  'primary',
  'primary50',
  'primary100',
  'primary200',
  'primary300',
  'primary400',
  'primary500',
  'primary600',
  'primary700',
  'accent',
  'accent50',
  'accent100',
  'accent200',
  'accent300',
  'accent400',
  'accent500',
  'accent600',
  'accent700',
  'negative',
  'negative50',
  'negative100',
  'negative200',
  'negative300',
  'negative400',
  'negative500',
  'negative600',
  'negative700',
  'warning',
  'warning50',
  'warning100',
  'warning200',
  'warning300',
  'warning400',
  'warning500',
  'warning600',
  'warning700',
  'positive',
  'positive50',
  'positive100',
  'positive200',
  'positive300',
  'positive400',
  'positive500',
  'positive600',
  'positive700',
  'white',
  'black',
  'mono100',
  'mono200',
  'mono300',
  'mono400',
  'mono500',
  'mono600',
  'mono700',
  'mono800',
  'mono900',
  'mono1000',
  'ratingInactiveFill',
  'ratingStroke',
];

const widthValues = {
  'w-xs': '20rem;' /* 320px */,
  'w-sm': '24rem;' /* 384px */,
  'w-md': '28rem;' /* 448px */,
  'w-lg': '32rem;' /* 512px */,
  'w-xl': '36rem;' /* 576px */,
  'w-2xl': '42rem;' /* 672px */,
  'w-3xl': '48rem;' /* 768px */,
  'w-4xl': '56rem;' /* 896px */,
  'w-5xl': '64rem;' /* 1024px */,
  'w-6xl': '72rem;' /* 1152px */,
  'w-7xl': '80rem;' /* 1280px */,
  'w-full': '100%',
  'w-screen-sm': '640px',
  'w-screen-md': '768px',
  'w-screen-lg': '1024px',
  'w-screen-xl': '1280px',
  'w-screen-2xl': '1536px',
};

const percentageWidthValues = {
  '1-2': '50%',
  '1-3': '33.333333%',
  '2-3': '66.666667%',
  '1-4': '25%',
  '2-4': '50%',
  '3-4': '75%',
  '1-5': '20%',
  '2-5': '40%',
  '3-5': '60%',
  '4-5': '80%',
  '1-6': '16.666667%',
  '2-6': '33.333333%',
  '3-6': '50%',
  '4-6': '66.666667%',
  '5-6': '83.333333%',
  full: ' 100%',
  'screen-h': '100vh',
  'screen-w': '100vw',
};

const colors = Object.keys(theme.colors)
  .filter(key => PrimitiveColorTokens.includes(key))
  .reduce(
    (all, key) => ({
      ...all,
      [key as keyof ColorTokens]: theme.colors[key as keyof ColorTokens],
    }),
    {} as Record<keyof ColorTokens, string>,
  );

export const stitchesConfig = createStitches({
  media: {
    sm: '(min-width: 640px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 1024px)',
    xl: '(min-width: 1280px)',
    '2xl': '(min-width: 1536px)',
  },
  theme: {
    colors: {
      ...colors,
      ...Object.keys(theme.colorTokens).reduce(
        (all, itemKey) => ({
          ...all,
          [`token-${itemKey}`]:
            theme.colorTokens[itemKey as keyof typeof theme.colorTokens] ?? '',
        }),
        {} as Record<keyof typeof theme.colorTokens, string>,
      ),
    },
    borderWidths: { 1: '1px', 2: '2px', 3: '3px', 4: '4px' },
    space: {
      ...theme.sizing,
    },
    sizes: { ...widthValues, ...percentageWidthValues },
    radii: {
      none: '0px',
      sm: '0.125rem' /* 2px */,
      border: '0.25rem' /* 4px */,
      md: '0.375rem' /* 6px */,
      lg: '0.5rem' /* 8px */,
      xl: '0.75rem' /* 12px */,
      '2xl': '1rem;' /* 16px */,
      '3xl': '1.5rem' /* 24px */,
      /* 100%px */
      full: '9999px',
    },
    fontWeights: {
      light: 400,
      normal: 500,
      semibold: 600,
      bold: 700,
      black: 800,
    },
    letterSpacings: {
      'tracking-tighter': '-0.05em',
      'tracking-tight': '-0.025em',
      'tracking-normal': '0em',
      'tracking-wide': '0.025em',
      'tracking-wider': '0.05em',
      'tracking-widest': '0.1em',
    },
    lineHeights: {
      'leading-3': '.75rem',
      'leading-4': '1rem',
      'leading-5': '1.25rem',
      'leading-6': '1.5rem',
      'leading-7': '1.75rem',
      'leading-8': '2rem',
      'leading-9': '2.25rem',
      'leading-10': '2.5rem',
      'leading-none': '1',
      'leading-tight': '1.25',
    },
  },
  utils: {
    // Abbreviated margin properties
    m: (value: Stitches.PropertyValue<'margin'>) => ({
      margin: value,
    }),
    mt: (value: Stitches.PropertyValue<'marginTop'>) => ({
      marginTop: value,
    }),
    mr: (value: Stitches.PropertyValue<'marginRight'>) => ({
      marginRight: value,
    }),
    mb: (value: Stitches.PropertyValue<'marginBottom'>) => ({
      marginBottom: value,
    }),
    ml: (value: Stitches.PropertyValue<'marginLeft'>) => ({
      marginLeft: value,
    }),
    mx: (value: Stitches.PropertyValue<'margin'>) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: Stitches.PropertyValue<'margin'>) => ({
      marginTop: value,
      marginBottom: value,
    }),
    p: (value: Stitches.ScaleValue<'space'>) => ({
      padding: value,
    }),
    pt: (value: Stitches.ScaleValue<'space'>) => ({
      paddingTop: value,
    }),
    pr: (value: Stitches.ScaleValue<'space'>) => ({
      paddingRight: value,
    }),
    pb: (value: Stitches.ScaleValue<'space'>) => ({
      paddingBottom: value,
    }),
    pl: (value: Stitches.ScaleValue<'space'>) => ({
      paddingLeft: value,
    }),
    px: (value: Stitches.ScaleValue<'space'>) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: Stitches.ScaleValue<'space'>) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    spaceY: (value: Stitches.PropertyValue<'marginTop'>) => ({
      '& > :not([hidden]) ~ :not([hidden])': {
        marginTop: `${value}`,
      },
    }),
    spaceX: (value: Stitches.PropertyValue<'marginLeft'>) => ({
      '& > :not([hidden]) ~ :not([hidden])': {
        marginLeft: `${value}`,
      },
    }),
    padY: (value: Stitches.ScaleValue<'space'>) => ({
      '& > :not([hidden])': {
        paddingTop: value,
        paddingBottom: value,
      },
    }),
    padX: (value: Stitches.ScaleValue<'space'>) => ({
      '& > :not([hidden])': {
        paddingLeft: value,
        paddinRight: value,
      },
    }),
    divideX: (value: Stitches.ScaleValue<'borderWidths'>) => ({
      '& > :not([hidden]) ~ :not([hidden])': {
        borderLeftWidth: `${value} `,
        borderStyle: 'solid',
      },
    }),
    divideY: (value: Stitches.ScaleValue<'borderWidths'>) => ({
      '& > :not([hidden]) ~ :not([hidden])': {
        borderTopWidth: `${value}`,
        borderStyle: 'solid',
      },
    }),
    layout: (value: 'row' | 'stack') => {
      if (value === 'row') {
        return {
          display: 'flex',
          flexDirection: 'row',
        };
      }
      return {
        display: 'flex',
        flexDirection: 'column',
      };
    },
  },
});

export const useStitches = () => {
  const css = useCallback(
    (style: Stitches.CSS<typeof stitchesConfig.config>) =>
      stitchesConfig.css(style)(),
    [],
  );
  return { css, theme };
};

export function parseToUtils<
  T extends Record<string, string | number | boolean>
>(property: T, css: (key: keyof typeof property) => Stitches.CSS) {
  return Object.keys(property).reduce(
    (acc, cv) => ({
      ...acc,
      [cv]: css(cv),
    }),
    {},
  ) as { [key in keyof typeof property]: Stitches.CSS };
}
