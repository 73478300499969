import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";

export default class Unauthorized extends Component {
  render() {
    return (
      <div style={styles}>
        <Typography>
          You do not have permission to view this page. Please contact your
          administrator.
        </Typography>
      </div>
    );
  }
}

const styles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "50vh",
  backgroundColor: "rgba(255,255,255,0)",
};
