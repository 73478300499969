import { FETCH_DEFECTS_STARTED, FETCH_DEFECTS_SUCCESS } from "./DefectsReducer";
import ObservationAPIController from "../../services/api/ObservationAPIController";

const fetchDefectsTableDataSuccess = (data) => {
  return {
    type: FETCH_DEFECTS_SUCCESS,
    data: data,
  };
};

const fetchDefectsTableDataStarted = () => {
  return {
    type: FETCH_DEFECTS_STARTED,
  };
};

const watchDefectsTableData = (companyId, inspectionId, structureId) => {
  return function (dispatch) {
    dispatch(fetchDefectsTableDataStarted());
    ObservationAPIController.getDefects(companyId, inspectionId, structureId)
      .then((response) => dispatch(fetchDefectsTableDataSuccess(response)))
      .catch((error) => {
        console.log(error);
      });
  };
};

export { watchDefectsTableData };
