import { Structures } from './types';

// Converts JSON strings to/from your types
export class Convert {
  public static toStructures(json: string): Structures {
    return JSON.parse(json);
  }

  public static structuresToJson(value: Structures): string {
    return JSON.stringify(value);
  }
}

export function deserializeDictionary(data: any) {
  return Convert.toStructures(JSON.stringify(data));
}
