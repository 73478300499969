// Maps data coming from Store to UI and from UI to API

import {
  FIELD_ID,
  FIELD_STRUCTURE_ID,
  FIELD_STRUCTURE_NAME,
  FIELD_DEFECT_CLASSIFICATION,
  FIELD_DEFECT_NUMBER,
  FIELD_COMPONENT,
  FIELD_SUB_COMPONENT,
  FIELD_LOCATION,
  FIELD_INSPECTION_DATE,
  FIELD_STATIONING,
  FIELD_CLOCK_POSITION,
  FIELD_DEFECT_TYPE,
  FIELD_PICTURE_LINK,
  FIELD_DESCRIPTION,
  FIELD_DIMENSION,
  FIELD_CORRECTIVE_ACTION_PLAN,
  FIELD_CS_RATING,
  FIELD_REPAIR_METHOD,
  FIELD_REPAIR_BY_DATE,
  FIELD_PROJECT_NAME,
  FIELD_PROJECT_ID,
  FIELD_INSPECTOR_NAME,
  FIELD_PREVIOUS_DEFECT,
  FIELD_SUB_COMPONENT_UNIT_OF_MEASUREMENT,
} from ".";
import { Strings } from "../../config/Strings";

import { createSelector } from "reselect";

const getDefects = (state) => state.DefectsReducer.data;

export const mapMultipleRows = createSelector([getDefects], (response) => {
  return response.map((x) => mapSingleRow(x));
});

const mapSingleRow = (singleResponse) => {
  var mapResult = {};
  mapResult[FIELD_ID] = singleResponse["id"];
  mapResult[FIELD_STRUCTURE_ID] = singleResponse["structureCode"];
  mapResult[FIELD_STRUCTURE_NAME] = singleResponse["structureName"];
  mapResult[FIELD_COMPONENT] = singleResponse["componentName"];
  mapResult[FIELD_SUB_COMPONENT] = singleResponse["subcomponentName"];
  mapResult[FIELD_LOCATION] = singleResponse["locationId"];
  mapResult[FIELD_INSPECTION_DATE] = singleResponse["createdAt"];
  mapResult[FIELD_CLOCK_POSITION] = singleResponse["clockPosition"];
  mapResult[FIELD_STATIONING] = singleResponse["stationMarker"];
  mapResult[FIELD_DEFECT_CLASSIFICATION] = mapClass(singleResponse["classification"]);
  mapResult[FIELD_CS_RATING] = mapCS(singleResponse["csRating"]);
  mapResult[FIELD_REPAIR_METHOD] = singleResponse["repairMethod"];
  mapResult[FIELD_REPAIR_BY_DATE] = singleResponse["repairDate"];
  mapResult[FIELD_PROJECT_NAME] = singleResponse["projectName"];
  mapResult[FIELD_PROJECT_ID] = singleResponse["projectId"];
  mapResult[FIELD_INSPECTOR_NAME] = singleResponse["inspectorName"];
  mapResult[FIELD_CORRECTIVE_ACTION_PLAN] = mapCorrectiveActionPlan(singleResponse["observationType"]);
  mapResult[FIELD_PICTURE_LINK] = singleResponse["pictureLinks"];
  mapResult[FIELD_DEFECT_TYPE] = singleResponse["description"];
  mapResult[FIELD_DEFECT_NUMBER] = singleResponse["id"];
  mapResult[FIELD_DESCRIPTION] = singleResponse["summary"];
  mapResult[FIELD_DIMENSION] = singleResponse["size"];
  mapResult[FIELD_SUB_COMPONENT_UNIT_OF_MEASUREMENT] = singleResponse["subcomponentMeasureUnit"];
  mapResult[FIELD_PREVIOUS_DEFECT] = singleResponse["previousDefect"] || "";
  return mapResult;
};

const mapClass = (classification) => {
  var result = "";
  switch (classification) {
    case "STRUCTURAL":
      result = "SD";
      break;
    default:
      result = "NSMI";
      break;
  }

  return Strings.getInstance().mapString("observation_class", result);
};

const mapCS = (cs) => {
  return Strings.getInstance().mapString("client_observation_cs", cs);
};

export const mapCorrectiveActionPlan = (observationType) => {
  switch (observationType) {
    case "CRITICAL":
      return "A - Critical";
    case "PRIORITY":
      return "B - Priority";
    case "ROUTINE":
      return "C - Routine";
    case "MONITOR":
      return "D - Monitor";
    default:
      return "NA";
  }
};

export const mapImagesToGallery = (observation) => {
  var images = [];

  observation[FIELD_PICTURE_LINK].forEach((imageData) => {
    var image = {};
    image["original"] = imageData;
    image["thumbnail"] = imageData;
    images.push(image);
  });
  return images;
};
