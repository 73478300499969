import Helper from "helper/Helper";

export const FETCH_STRUCTURES_STARTED = "FETCH_STRUCTURES_STARTED";
export const FETCH_STRUCTURES_SUCCESS = "FETCH_STRUCTURES_SUCCESS";

export const ADD_STRUCTURE_STARTED = "ADD_STRUCTURE_STARTED";
export const ADD_STRUCTURE_SUCCESS = "ADD_STRUCTURE_SUCCESS";
export const ADD_STRUCTURE_FAILED = "ADD_STRUCTURE_FAILED";

export const UPDATE_STRUCTURE_STARTED = "UPDATE_STRUCTURE_STARTED";
export const UPDATE_STRUCTURE_SUCCESS = "UPDATE_STRUCTURE_SUCCESS";
export const UPDATE_STRUCTURE_FAILED = "UPDATE_STRUCTURE_FAILED";

export const DELETE_STRUCTURE_STARTED = "DELETE_STRUCTURE_STARTED";
export const DELETE_STRUCTURE_SUCCESS = "DELETE_STRUCTURE_SUCCESS";
export const DELETE_STRUCTURE_FAILED = "DELETE_STRUCTURE_FAILED";

const initialState = {
  data: [],
  loading: true,
};

const StructuresReducer = (state = initialState, action) => {
  var newData = null;
  switch (action.type) {
    case FETCH_STRUCTURES_STARTED:
      return { ...state, loading: true };
    case FETCH_STRUCTURES_SUCCESS:
      return { ...state, loading: false, data: action.data };

    case ADD_STRUCTURE_STARTED:
      return { ...state, loading: true };
    case ADD_STRUCTURE_SUCCESS:
      newData = Helper.insertItemImmutable(state.data, action.structure);
      return { ...state, data: newData, loading: false };
    case ADD_STRUCTURE_FAILED:
      return { ...state, loading: false };

    case UPDATE_STRUCTURE_STARTED:
      return { ...state, loading: true };
    case UPDATE_STRUCTURE_SUCCESS:
      newData = Helper.updateItemImmutable(state.data, action.structure);
      return { ...state, data: newData, loading: false };

    case UPDATE_STRUCTURE_FAILED:
      return { ...state, loading: false };

    case DELETE_STRUCTURE_STARTED:
      return { ...state, loading: true };
    case DELETE_STRUCTURE_SUCCESS:
      newData = Helper.removeItemImmutable(state.data, action.structureId);
      return { ...state, data: newData, loading: false };
    case DELETE_STRUCTURE_FAILED:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export { StructuresReducer };
