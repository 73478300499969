import React, { Component } from 'react';
import MaterialTable, { MTableBody, MTableHeader } from 'material-table';
import { Menu, MenuItem } from '@material-ui/core';
import CheckBoxWithLabel from 'components/CheckBoxWithLabel';
import SessionStorage from 'storage/SessionStorage';
import { CsvBuilder } from 'filefy';
import Helper from 'helper/Helper';

class Table extends Component {
  constructor(props) {
    super(props);

    const storedSearchText = SessionStorage.getSearchForTable(this.props.id);
    const storedFilterDict = SessionStorage.getFilterForTable(this.props.id);
    const storedSortDict = SessionStorage.getSortForTable(this.props.id);
    const storedHiddenColumnsDict = SessionStorage.getHiddenColumnsForTable(
      this.props.id,
    );
    const currentPageUrl = SessionStorage.getCurrentPageUrl();

    let columns = this.addDefaultFilterForColumns(
      this.props.columns,
      storedFilterDict,
    );
    columns = this.addDefaultSortForColumns(this.props.columns, storedSortDict);
    columns = this.updateHiddenStateForColumns(
      this.props.columns,
      storedHiddenColumnsDict,
    );

    this.state = {
      showFilter: Object.keys(storedFilterDict).length !== 0,
      columns: columns,
      anchorEl: null,
      storedSearchText: storedSearchText,
      storedFilterDict: storedFilterDict,
      storedSortDict: storedSortDict,
      currentPageUrl: currentPageUrl,
    };

    this.searchText = storedSearchText;
    this.filterDict = storedFilterDict;
    this.sortDict = storedSortDict;
    this.hiddenColumnsDict = storedHiddenColumnsDict;
    this.currentPageUrl = currentPageUrl;
  }

  updateColumns = () => {
    let columns = this.addDefaultFilterForColumns(
      this.props.columns,
      this.filterDict,
    );
    columns = this.updateHiddenStateForColumns(
      this.props.columns,
      this.hiddenColumnsDict,
    );
    columns = this.addDefaultSortForColumns(this.props.columns, this.sortDict);
    this.setState({ columns: columns });
  };

  hideColumn = index => {
    var columns = this.state.columns;
    columns[index].hidden = !columns[index].hidden;
    this.hiddenColumnsDict[index] = columns[index].hidden;
    this.setState({ columns: columns });
  };

  componentWillUnmount() {
    SessionStorage.saveSearchInTable(this.props.id, this.searchText);
    SessionStorage.saveFilterInTable(this.props.id, this.filterDict);
    SessionStorage.saveSortInTable(this.props.id, this.sortDict);
    SessionStorage.saveHiddenColumnsInTable(
      this.props.id,
      this.hiddenColumnsDict,
    );
    SessionStorage.saveCurrentPageUrl(window.location.href);
  }

  onSearchChange = searchText => {
    this.searchText = searchText;
  };

  onFilterChange = (columnId, value) => {
    this.filterDict[columnId] = value;
  };

  onOrderChange = (columnId, value) => {
    this.sortDict = {};
    const handler = this.props.onColumnSort;

    this.sortDict[columnId] = value;
    /**
     * TODO:
     * Remember that there is a `thirdSortClick` prop
     * that produces a falsy (a.k.a : undefined) value that prevents getting the columnName
     * for this handler. remember to ask this question with alta vista
     * to either disable this prop or work around it
     */
    if (handler instanceof Function) {
      const columnName = this.state.columns[columnId];
      handler({ name: columnName?.title, index: columnId }, value);
    }
  };

  getColumnMenu() {
    var columnMenuItems = [];
    this.state.columns.forEach((element, index) => {
      columnMenuItems.push(
        <MenuItem key={index} onClick={() => this.hideColumn(index)}>
          <CheckBoxWithLabel checked={!element.hidden} label={element.title} />
        </MenuItem>,
      );
    });

    return (
      <Menu
        keepMounted
        anchorEl={this.state.anchorEl}
        open={Boolean(this.state.anchorEl)}
        onClose={() => this.setState({ anchorEl: null })}
      >
        {columnMenuItems}
      </Menu>
    );
  }

  getActionsWithDefault() {
    var actions = [];
    actions.push({
      icon: 'filter_list',
      tooltip: 'Toggle Filter',
      isFreeAction: 'true',
      onClick: () => {
        this.setState({ showFilter: !this.state.showFilter });
      },
    });
    if (!this.props.disableHideColumnOption) {
      actions.push({
        icon: 'view_column',
        tooltip: 'Show/Hide Columns',
        isFreeAction: 'true',
        onClick: event => {
          this.setState({ anchorEl: event.currentTarget });
        },
      });
    }

    actions = actions.concat(this.props.actions || []);
    return actions;
  }

  addDefaultSortForColumns(columns, storedSortDict) {
    for (let i = 0; i < columns.length; i++) {
      if (i in storedSortDict) {
        columns[i]['defaultSort'] = storedSortDict[i];
      }
    }
    return columns;
  }

  addDefaultFilterForColumns(columns, storedFilterDict) {
    for (let i = 0; i < columns.length; i++) {
      if (i in storedFilterDict) {
        columns[i]['defaultFilter'] = storedFilterDict[i];
      }
    }
    return columns;
  }

  updateHiddenStateForColumns(columns, storedHiddenColumnsDict) {
    for (let i = 0; i < columns.length; i++) {
      if (i in storedHiddenColumnsDict) {
        columns[i]['hidden'] = storedHiddenColumnsDict[i];
      }
    }
    return columns;
  }

  getOptions = maxDataLength => {
    const pageSizeOptions = [5, 10, 20, 100, 500, 1000];
    let options = this.props.options || {};

    options.actionsColumnIndex = -1;
    options.filtering = this.state.showFilter;
    options.searchText = this.state.storedSearchText;
    options.exportButton =
      this.props.options.exportButton === false
        ? false
        : { csv: true, pdf: false };
    options.exportAllData = false;
    options.exportCsv = (columns, data) => this.exportFormat(columns, data);

    if (maxDataLength > 1000) {
      pageSizeOptions.push(maxDataLength);
    }

    options.pageSizeOptions = pageSizeOptions;
    return options;
  };

  exportFormat(columns, data) {
    const builder = new CsvBuilder(this.props.title + '.csv')
      .setColumns(columns.map(columnDef => columnDef.title))
      .addRows(
        data.map(rowData =>
          columns.map(columnDef => {
            if (Array.isArray(rowData[columnDef.field])) {
              var mergedData = [];
              rowData[columnDef.field].forEach(colArray =>
                // if colArray is an object, then get the name property
                typeof colArray === 'object'
                  ? mergedData.push(colArray.name)
                  : mergedData.push(colArray)
              );
              mergedData = mergedData.join(', ');
              return mergedData;
            } else if (
              columnDef.hasOwnProperty('type') &&
              (columnDef.type === 'date') &
                (rowData[columnDef.field] !== undefined)
            ) {
              return new Date(rowData[columnDef.field])
                .toISOString()
                .split('T')[0];
            } else if (columnDef.hasOwnProperty('lookup')) {
              if (columnDef.field == 'status' && !!rowData?.rawDefect?.previousDefectNumber && !rowData.status) {
                  return "CLONED";
                }
              return columnDef.lookup[rowData[columnDef.field]];
            }
            else {
              return typeof rowData[columnDef.field] === 'object'
                ? rowData[columnDef.field].name
                : rowData[columnDef.field];
            }
          }),
        ),
      )
      .exportFile();
  }

  componentDidUpdate(prevProps) {
    if (!Helper.areObjectsEqual(prevProps.columns, this.props.columns)) {
      this.updateColumns();
    }
  }

  render() {
    if (SessionStorage.getCurrentPageUrl() !== window.location.href) {
      sessionStorage.clear();
    }

    return (
      <div>
        {this.getColumnMenu()}
        <MaterialTable
          title={this.props.title}
          columns={this.state.columns}
          editable={this.props.editable}
          options={this.getOptions(this.props.data.length)}
          components={{
            Body: props => (
              <MTableBody
                {...props}
                onFilterChanged={(columnId, value) => {
                  props.onFilterChanged(columnId, value);
                  this.onFilterChange(columnId, value);
                }}
                onRowClick={(event, rowData) => {
                  if (!props.hasAnyEditingRow && this.props.onRowClick) {
                    this.props.onRowClick(event, rowData);
                  }
                }}
              />
            ),
          }}
          data={this.props.data}
          actions={this.getActionsWithDefault()}
          isLoading={this.props.isLoading}
          onSearchChange={this.onSearchChange}
          onRowClick={this.props.onRowClick}
          onOrderChange={this.onOrderChange}
          style={{
            boxShadow: '0 0 35px 0 rgba(154,161,171,.15)',
            color: '#6c757d',
            height: this.props.height,
          }}
        />
      </div>
    );
  }
}

export default Table;
