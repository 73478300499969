import React, { Component, useEffect, useState } from 'react';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import SideDrawer from 'components/Menu/SideDrawer';
import TopBar from 'components/Menu/TopBar';

import CssBaseline from '@material-ui/core/CssBaseline';

import { withStyles } from '@material-ui/core/styles';
import DialogBox from 'components/Dialog/DialogBox';
import { getRoutes } from './Routes/index';
import { connect } from 'react-redux';

import { isAuthenticated } from 'features/Login/LoginAction';
import { Colors } from 'config/StyleConfig';
import { useStitches } from 'theme';

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    position: 'relative',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

function App(props) {
  const { isUserAuthenticated } = props;
  const routes = getRoutes();
  const { css } = useStitches();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    isUserAuthenticated();
  }, [isUserAuthenticated]);

  const DefaultContainer = () => (
    <div
      className={css({
        height: '$screen-h',
        width: '$full',
        overflow: 'hidden',
        layout: 'row',
      })}
    >
      {/* <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          zIndex: -99999,
          backgroundColor: Colors.background,
        }}
      /> */}

      <CssBaseline />
      <aside className={css({ flexShrink: 0, zIndex: 0 })}>
        <SideDrawer />
      </aside>
      <div
        className={css({
          layout: 'stack',
          flex: 1,
          overflow: 'hidden',
          zIndex: 0,
        })}
      >
        <div>
          <TopBar />
        </div>
        <div
          className={css({
            flex: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
            p: '$scale600',
          })}
        >
          <DialogBox />
          {routes.privateRoutes}
        </div>
      </div>
    </div>
  );

  return (
    <Router>
      <Switch>
        {routes.publicRoutes}
        <Route component={DefaultContainer} />
      </Switch>
    </Router>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    isUserAuthenticated: () => dispatch(isAuthenticated()),
  };
};

const mapStateToProps = state => {
  return {
    authenticated: state.LoginReducer.authenticated,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(App));
