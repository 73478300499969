// Maps data coming from Store to UI and from UI to API

import {
  FIELD_ID,
  FIELD_CODE,
  FIELD_NAME,
  FIELD_TYPE,
  FIELD_STRUCTURE_NUMBER,
  FIELD_POST_MILE,
  FIELD_BEGIN_STATIONING,
  FIELD_END_STATIONING,
} from ".";

import { createSelector } from "reselect";

const getStructures = (state) => state.StructuresReducer.data;

const mapSingleRow = (singleResponse) => {
  var mapResult = {};
  mapResult[FIELD_ID] = singleResponse["id"];
  mapResult[FIELD_CODE] = singleResponse["code"];
  mapResult[FIELD_NAME] = singleResponse["name"];
  mapResult[FIELD_TYPE] = singleResponse["type"];
  mapResult[FIELD_STRUCTURE_NUMBER] = singleResponse["caltransBridgeNo"];
  mapResult[FIELD_POST_MILE] = singleResponse["postmile"];
  mapResult[FIELD_BEGIN_STATIONING] = singleResponse["beginStationing"];
  mapResult[FIELD_END_STATIONING] = singleResponse["endStationing"];
  return mapResult;
};

export const mapStructureTypes = (structureTypes) => {
  var mapResult = {};
  structureTypes.forEach((type) => {
    mapResult[type["id"]] = type["name"];
  });
  return mapResult;
};

export const mapMultipleRows = createSelector([getStructures], (response) => {
  if (response === undefined) return [];
  return response.map((x) => mapSingleRow(x));
});
