// Maps data coming from Store to UI and from UI to API

import { FIELD_ID, FIELD_NAME } from ".";
import { createSelector } from "reselect";

const getClients = (state) => state.ClientsReducer.data;

export const mapMultipleRows = createSelector([getClients], (response) => {
  return response.map((x) => mapSingleRow(x));
});

const mapSingleRow = (singleResponse) => {
  var mapResult = {};
  mapResult[FIELD_ID] = singleResponse["id"];
  mapResult[FIELD_NAME] = singleResponse["name"];
  return mapResult;
};
