import Config from "config/Config";
import { APIException } from "Exceptions";
import SecureAPIBuilder from "./SecureAPIBuilder";

export default class PreferencesAPIController {
  // Get calls.
  static getPreferences() {
    return new SecureAPIBuilder(Config.GET_COMPONENTS_DICTIONARY, SecureAPIBuilder.GET).call().then((response) => {
      if (!response.ok) {
        throw new APIException(response.status, response.statusText);
      }
      return response.json();
    });
  }

  static getStructurePreferences() {
    // Check if structures are saved in localStorage and the saved timestamp is less than 2 hours
    const savedStructures = localStorage.getItem('structures');
    const savedTimestamp = localStorage.getItem('structures_timestamp');
    if (savedStructures && savedTimestamp) {
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - savedTimestamp;
      // 1 (hours) * 60 (minutes) * 60 (seconds) * 1000 (milliseconds) = 1 hour
      const timeToRefresh = (1 * 60 * 60 * 1000);
      if (timeDifference < timeToRefresh) {
        return Promise.resolve(JSON.parse(savedStructures));
      }
    }
    // If not cached or cache is older than 1 hours, make an API call
    return new SecureAPIBuilder(
      Config.GET_COMPLETE_DICTIONARY,
      SecureAPIBuilder.GET,
    )
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      })
      .then((jsonResponse) => {
        // Save the jsonResponse in localStorage along with the current timestamp
        localStorage.setItem('structures', JSON.stringify(jsonResponse));
        localStorage.setItem('structures_timestamp', new Date().getTime());
        return jsonResponse;
      });
  }

  // Update calls.
  static saveComponentsDictionary(dictionary) {
    const body = JSON.stringify(dictionary);

    return new SecureAPIBuilder(Config.SAVE_DICTIONARY, SecureAPIBuilder.POST)
      .body(body)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }
}
