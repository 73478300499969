import { AddPhotoAlternateOutlined } from '@material-ui/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'baseui/snackbar';
import { Button } from 'components/Button';
import Config from 'config/Config';
import Helper from 'helper/Helper';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { fetcher } from 'services/fetcher';
import { useStitches } from 'theme';
import { v4 as uuid } from 'uuid';

function uploadImage(payload: {
  inspectionId: string;
  componentSubcomponentId: string;
  observationId: string;
  data: File;
}) {
  const { inspectionId, componentSubcomponentId, observationId, data } =
    payload;
  const url = Helper.parse(
    Config.UPLOAD_IMAGE_API,
    inspectionId,
    componentSubcomponentId,
    observationId,
  );
  const formData = new FormData();
  /**
   * appended new image twice to satisfy the endpoints
   * validation. The endpoint expects the original image + annotated image.
   */
  formData.append('data', data);
  formData.append('data', data);
  const params = new URLSearchParams({
    name: `${data.name}`,
    uuid: uuid().toString(),
    createdAt: Helper.getCurrentDateTime(),
    drawables: 'null',
  });

  return fetcher(`${url}?${params.toString()}`, {
    method: 'POST',
    body: formData,
    headers: {
      'Content-type': 'multipart/formdata',
    },
  });
}
const useAddDefectImage = () => useMutation(uploadImage);
export default function UploadDefectImage({ rowData }: { rowData: any }) {
  const params = useParams<{ inspectionId?: string }>();
  const inputRef = useRef<HTMLInputElement>(null);
  const { css } = useStitches();
  const { enqueue } = useSnackbar();
  const mutation = useAddDefectImage();
  const queryClient = useQueryClient();

  return (
    <div className={css({ position: 'relative' })}>
      <Button
        kind="tertiary"
        size="mini"
        onClick={() => inputRef.current?.click()}
      >
        <AddPhotoAlternateOutlined fontSize="small" />
      </Button>
      <input
        type="file"
        ref={inputRef}
        accept="image/x-png,image/gif,image/jpeg"
        style={{ opacity: 0, position: 'absolute', inset: 0 }}
        onChange={(e) => {
          const files = e.target.files;
          if (files !== null && files?.length) {
            enqueue({ message: 'Uploading Photo', progress: true });
            mutation.mutate(
              {
                componentSubcomponentId: rowData.sub_component_uuid,
                data: files[0],
                inspectionId: params?.inspectionId ?? '',
                observationId: rowData.id,
              },
              {
                onError: () => {
                  if (inputRef.current) {
                    inputRef.current.value = '';
                  }
                  enqueue({ message: 'Failed to upload image, try again.' });
                },
                onSuccess: async (data, variables) => {
                  if (inputRef.current) {
                    inputRef.current.value = '';
                  }
                  enqueue({ message: 'Image Uploaded!' });
                  await queryClient.invalidateQueries({
                    queryKey: [
                      'inspection',
                      variables.inspectionId,
                      'related-cloned-inspections',
                    ],
                  });
                },
              },
            );
          }
        }}
      />
    </div>
  );
}
