import Config from "config/Config";
import { APIException } from "Exceptions";
import SecureAPIBuilder from "./SecureAPIBuilder";

export default class TemplateAPIController {
  // Get calls.
  static getSubComponentsOfStructure(structureId) {
    return new SecureAPIBuilder(Config.GET_SUB_COMPONENTS_OF_STRUCTURE, SecureAPIBuilder.GET)
      .query({ structureId: structureId })
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static saveSubComponentsOfStructure(template) {
    return new SecureAPIBuilder(Config.SAVE_SUB_COMPONENTS_OF_STRUCTURE, SecureAPIBuilder.POST)
      .body(JSON.stringify({ subcomponentAndStructures: template }))
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static getSubComponentsOfInspection(inspectionId) {
    return new SecureAPIBuilder(Config.GET_SUB_COMPONENTS_OF_INSPECTION, SecureAPIBuilder.GET)
      .query({ inspectionId: inspectionId })
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static saveSubComponentsOfInspection(template) {
    return new SecureAPIBuilder(Config.SAVE_SUB_COMPONENTS_OF_INSPECTION, SecureAPIBuilder.POST)
      .body(JSON.stringify({ subcomponentInspections: template }))
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }
}
