import { createSelector } from "reselect";
import uuid from "uuid";

export const mapPreferences = (preferences, structureType) => {
  if (preferences === undefined) {
    return [];
  }

  let result = [];
  const components = preferences["components"] || [];
  for (let index = 0; index < components.length; index++) {
    const component = components[index];
    if (component.type === structureType) {
      result.push(component);
    }
  }

  return result;
};

const getSubComponentsOfStructure = (state) => state.SubComponentPickerReducer.subComponentsOfStructure;

export const mapQuickSearchSubComponents = createSelector([getSubComponentsOfStructure], (subComponents) => {
  let dict = {};

  for (let i = 0; i < (subComponents || []).length; i++) {
    const subComponent = subComponents[i];
    dict[subComponent["subcomponentId"]] = { id: subComponent["id"], size: subComponent["size"] };
  }

  return dict;
});

export const mapBackToAPI = (structureId, modifiedQuickSearchVersion, originalQuickSearchVersion) => {
  const result = [];
  for (const subComponentId in modifiedQuickSearchVersion) {
    const subComponent = modifiedQuickSearchVersion[subComponentId];
    let apiItem = {};
    apiItem["size"] = subComponent["size"];
    apiItem["structureId"] = structureId;
    apiItem["subcomponentId"] = subComponentId;
    if (originalQuickSearchVersion.hasOwnProperty(subComponentId)) {
      apiItem["id"] = originalQuickSearchVersion[subComponentId]["id"];
    }
    result.push(apiItem);
  }

  return result;
};
