import React, { Component } from "react";
import { connect } from "react-redux";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ComponentItem from "./TreeItem/ComponentItem";
import SubComponentItem from "./TreeItem/SubComponentItem";
import StructureItem from "./TreeItem/StructureItem";
import DefectItem from "./TreeItem/DefectItem";
import { fetchPreferences, resetModifiedAndCreatedData, submitModifiedData } from "./PreferencesAction";
import { Snackbar } from "@material-ui/core";
import Helper from "helper/Helper";
import Button from "@material-ui/core/Button";
import LoadingScreen from "../../components/LoadingScreen";
import { v4 as uuidv4 } from "uuid";
import TokenManager from "storage/TokenManager";

import { Colors } from "config/StyleConfig";
import { withStyles } from "@material-ui/core/styles";

/*
TODO: Optimize code as much as possible. Too many redundancies.
*/

const styles = {
  button: {
    color: Colors.secondary,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
};

class Preferences extends Component {
  constructor(props) {
    super(props);
    this.structureTypes = TokenManager.getStructureTypes();
  }

  componentDidMount() {
    this.props.fetchPreferences();
  }

  generateDefectData(subComponentData) {
    var defectItems = [];
    const defectData = subComponentData["defects"];
    const createdDefectData = this.props.createdData[subComponentData["id"]] || [];
    if (defectData !== undefined) {
      // Add defects that are already part of the original data and modify them accordingly.
      defectData.forEach((defectData) => {
        const originalData = {
          id: defectData["id"],
          name: defectData["name"],
          number: defectData["number"],
          subcomponentId: defectData["subcomponentId"],
          deleted: false,
        };

        var data;
        var modified = false;

        if (defectData["id"] in this.props.modifiedData) {
          data = Helper.deepCopy(this.props.modifiedData[defectData["id"]]);
          modified = true;
        } else {
          data = Helper.deepCopy(originalData);
        }

        defectItems.push(<DefectItem key={defectData["id"]} nodeId={defectData["id"]} data={data} originalData={originalData} modified={modified} />);
      });
    }

    // Add defects not present in original data.
    createdDefectData.forEach((defectData) => {
      var data = defectData;
      defectItems.push(<DefectItem key={data["id"]} nodeId={data["id"]} data={data} originalData={{}} modified={true} created />);
    });

    // Always add an empty one for adding new defects.
    const newDefectId = uuidv4();
    defectItems.push(
      <DefectItem
        key={newDefectId}
        nodeId={newDefectId}
        originalData={{}}
        data={{
          id: newDefectId,
          deleted: false,
          subcomponentId: subComponentData["id"],
        }}
        created
      />
    );
    return defectItems;
  }

  generateSubComponentData(componentData) {
    var subComponentsItems = [];

    const subComponentsData = componentData["subcomponents"];
    const createdSubComponentsData = this.props.createdData[componentData["id"]] || [];

    if (subComponentsData !== undefined) {
      // Add sub-components that are already part of the original data and modify them accordingly.
      subComponentsData.forEach((subComponentData) => {
        const originalData = {
          id: subComponentData["id"],
          name: subComponentData["name"],
          deleted: false,
          fdotBhiValue: subComponentData["fdotBhiValue"],
          measureUnit: subComponentData["measureUnit"],
          componentId: subComponentData["componentId"],
        };

        var data;
        var modified = false;

        if (subComponentData["id"] in this.props.modifiedData) {
          data = Helper.deepCopy(this.props.modifiedData[subComponentData["id"]]);
          modified = true;
        } else {
          data = Helper.deepCopy(originalData);
        }

        const deleted = "deleted" in data && data["deleted"];
        subComponentsItems.push(
          <SubComponentItem key={subComponentData["id"]} nodeId={subComponentData["id"]} data={data} originalData={originalData} modified={modified}>
            {!deleted && this.generateDefectData(subComponentData)}
          </SubComponentItem>
        );
      });
    }

    // Add sub-component not present in original data.
    createdSubComponentsData.forEach((subComponentData) => {
      const defects = this.generateDefectData(subComponentData);
      const data = subComponentData;
      subComponentsItems.push(
        <SubComponentItem key={data["id"]} nodeId={data["id"]} data={data} originalData={{}} modified={true} created>
          {defects}
        </SubComponentItem>
      );
    });

    // Always add an empty one for adding new sub-component.
    const newSubComponentId = uuidv4();
    subComponentsItems.push(
      <SubComponentItem
        key={newSubComponentId}
        nodeId={newSubComponentId}
        originalData={{}}
        data={{
          id: newSubComponentId,
          defects: [],
          deleted: false,
          componentId: componentData["id"],
          fdotBhiValue: undefined,
          measureUnit: undefined,
        }}
        created
      />
    );
    return subComponentsItems;
  }

  generateComponentData(treeData) {
    const componentsData = treeData["components"] || [];
    var componentItems = {};
    var componentCreatedData = {};
    this.structureTypes.forEach((structureType) => {
      componentItems[structureType["id"]] = [];
      componentCreatedData[structureType["id"]] = this.props.createdData[structureType["id"]] || [];
    });

    if (componentsData !== undefined) {
      componentsData.forEach((componentData) => {
        const originalData = {
          id: componentData["id"],
          name: componentData["name"],
          type: componentData["type"],
          companyId: componentData["companyId"],
          deleted: false,
        };

        var data;
        var modified = false;

        if (componentData["id"] in this.props.modifiedData) {
          data = Helper.deepCopy(this.props.modifiedData[componentData["id"]]);
          modified = true;
        } else {
          data = Helper.deepCopy(originalData);
        }
        const deleted = "deleted" in data && data["deleted"];

        const componentItem = (
          <ComponentItem key={componentData["id"]} nodeId={componentData["id"]} data={data} originalData={originalData} modified={modified}>
            {!deleted && this.generateSubComponentData(componentData)}
          </ComponentItem>
        );

        componentItems[componentData["type"]].push(componentItem);
      });
    }

    for (let structureType in componentCreatedData) {
      componentCreatedData[structureType].forEach((data) => {
        componentItems[structureType].push(
          <ComponentItem key={data["id"]} nodeId={data["id"]} data={data} originalData={{}} modified={true} created>
            {this.generateSubComponentData(data)}
          </ComponentItem>
        );
      });
    }

    for (let structureType in componentItems) {
      const uuid = uuidv4();
      componentItems[structureType].push(
        <ComponentItem
          key={uuid}
          nodeId={uuid}
          originalData={{}}
          data={{
            id: uuid,
            type: structureType,
            companyId: TokenManager.getCompanyId(),
            subcomponents: [],
            deleted: false,
          }}
          created
        />
      );
    }

    return componentItems;
  }

  generateTree() {
    const treeData = this.props.data;

    if (treeData === undefined) {
      return <div />;
    }

    const componentData = this.generateComponentData(treeData);
    var structureItems = [];
    this.structureTypes.forEach((structureType, i) => {
      structureItems.push(
        <StructureItem key={i} nodeId={i} data={{ id: structureType["id"], name: structureType["name"] }}>
          {componentData[structureType["id"]]}
        </StructureItem>
      );
    });

    return structureItems;
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  confirmChanges() {
    this.props.submitModifiedData(this.props.modifiedData, this.props.createdData, this.props.data, this.structureTypes);
  }

  cancelChanges() {
    // TODO: Collapse all items.
    this.props.resetModifiedAndCreatedData();
  }

  render() {
    const { classes } = this.props;

    if (this.props.loading) {
      return <LoadingScreen />;
    }

    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={!Helper.isEmpty(this.props.modifiedData) || !Helper.isEmpty(this.props.createdData)}
          onClose={this.handleClose}
          message="There are unsaved changes. Would you like to save them?"
          action={
            <React.Fragment>
              <Button color="secondary" size="small" className={classes.button} onClick={() => this.confirmChanges()}>
                CONFIRM
              </Button>
              <Button color="secondary" size="small" className={classes.button} onClick={() => this.cancelChanges()}>
                CANCEL
              </Button>
            </React.Fragment>
          }
        />
        <div>
          <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
            {this.generateTree()}
          </TreeView>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.PreferencesReducer.data,
    loading: state.PreferencesReducer.loading,
    modifiedData: state.PreferencesReducer.modifiedData,
    createdData: state.PreferencesReducer.createdData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPreferences: () => dispatch(fetchPreferences()),
    resetModifiedAndCreatedData: () => dispatch(resetModifiedAndCreatedData()),
    submitModifiedData: (modifiedData, createdData, originalData, structureTypes) =>
      dispatch(submitModifiedData(modifiedData, createdData, originalData, structureTypes)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Preferences));
