// Maps data coming from Store to UI and from UI to API

import { FIELD_ID, FIELD_PROJECT_DATE, FIELD_PROJECT_MANAGERS, FIELD_PROJECT_NAME, FIELD_STRUCTURES } from ".";
import { createSelector } from "reselect";

const getInspectors = (state) => state.UsersReducer.data;
const getProject = (state) => state.ProjectsReducer.data;
const getStructure = (state) => state.StructuresReducer.data;

const mapStructures = (structures) => {
  if (structures === undefined) {
    return [];
  }

  return structures.map((structure) => {
    return {
      id: structure["id"],
      name: structure["code"],
    };
  });
};

const mapProjectManagers = (users) => {
  if (users === undefined) {
    return [];
  }

  return users.map((user) => {
    return {
      id: "" + user["id"],
      name: user["firstName"] + " " + user["lastName"],
    };
  });
};

const mapSingleRow = (singleResponse) => {
  var mapResult = {};
  mapResult[FIELD_ID] = singleResponse["id"];
  mapResult[FIELD_PROJECT_NAME] = singleResponse["name"];
  mapResult[FIELD_PROJECT_DATE] = singleResponse["createdAt"];
  mapResult[FIELD_STRUCTURES] = mapStructures(singleResponse["structures"]);
  mapResult[FIELD_PROJECT_MANAGERS] = mapProjectManagers(singleResponse["projectManagers"]);
  return mapResult;
};

export const extractIds = (array) => {
  if (array === undefined) {
    return [];
  }

  return array.map((element) => {
    return element["id"];
  });
};

export const mapMultipleRows = createSelector([getProject], (response) => {
  return response.map((x) => mapSingleRow(x));
});

export const mapStructureOptions = createSelector([getStructure], (structures) => {
  if (structures === undefined) {
    return [];
  }

  return structures.map((structure) => {
    return {
      id: structure["id"],
      name: structure["code"] + " - " + structure["name"],
    };
  });
});

export const mapProjectManagerOptions = createSelector([getInspectors], (users) => {
  if (users === undefined) {
    return [];
  }

  return users.map((user) => {
    return {
      id: "" + user["id"],
      name: user["firstName"] + " " + user["lastName"],
    };
  });
});
