import { useQuery } from '@tanstack/react-query';
import Config from 'config/Config';
import { fetcher } from 'services/fetcher';

function getStructures() {
  return fetcher(Config.GET_COMPLETE_DICTIONARY);
}

export function useStructures() {
  return useQuery(['structures'], () => getStructures(), {
    staleTime: Infinity,
  });
}
