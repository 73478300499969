import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  cardcontent: {
    padding: 10,
    "&:last-child": {
      paddingBottom: 8,
    },
  },
}));

export default function TightCardContent(props) {
  const classes = useStyles();

  return <Card className={classes.cardcontent} {...props} />;
}
