import React, { Component } from "react";
import GeneralCard from "components/GeneralCard";
import { Colors } from "config/StyleConfig";
import CircularWithLabel from "components/CircularWithLabel";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import { Strings } from "config/Strings";

class SGRCard extends Component {
  render() {
    const title = Strings.getInstance().getString("inspection_sgr");
    const subtitle = this.props.subtitle;

    const sgr = Math.round(parseInt(this.props.sgr));
    var color = Colors.bad;
    var sgr_txt = Strings.getInstance().mapSGR(Math.round(parseInt(this.props.sgr)));

    if (this.props.sgr > 80) {
      color = Colors.good;
    } else if (this.props.sgr >= 50) {
      color = Colors.average;
    } else {
      color = Colors.bad;
    }

    const content = (
      <div
        align="center"
        style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <CircularWithLabel variant="static" color={color} value={sgr} label={sgr_txt}></CircularWithLabel>
      </div>
    );

    return <GeneralCard title={title} subtitle={subtitle} content={content} icon={<FavoriteBorderOutlinedIcon />} />;
  }
}

export default SGRCard;
