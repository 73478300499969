import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { Colors } from "config/StyleConfig";
import Checkbox from "@material-ui/core/Checkbox";

const styles = () => ({
  root: {
    "&$checked": {
      color: Colors.secondary,
    },
  },
  checked: {},
});

class CheckboxWrapper extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Checkbox
        classes={{
          root: classes.root,
          checked: classes.checked,
        }}
        icon={<CheckBoxOutlineBlankIcon />}
        checkedIcon={<CheckBoxIcon />}
        style={{ marginRight: 8 }}
        size="small"
        {...this.props}
      />
    );
  }
}

export default withStyles(styles)(CheckboxWrapper);
