import React from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  UseControllerProps,
  UseControllerReturn,
} from 'react-hook-form';
import { useController } from 'react-hook-form';

import { FormControl } from '../FormControl';
import { FormControlProps } from '../FormControl/FormControl';

export default function FormController<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>
>({
  children,
  control,
  name,
  rules,
  defaultValue,
  shouldUnregister,
  ...formControlProps
}: UseControllerProps<T, TName> &
  Omit<FormControlProps, 'children'> & {
    children:
      | ((props: {
          field: ControllerRenderProps<T, TName>;
          fieldState: ControllerFieldState;
          formState: UseControllerReturn<T, TName>['formState'];
        }) => React.ReactElement)
      | React.ReactNode;
  }) {
  const { field, fieldState, formState } = useController<T, TName>({
    control,
    name,
    rules,
    defaultValue,
    shouldUnregister,
  });

  return (
    <FormControl
      error={fieldState.error?.message}
      htmlFor={field.name}
      {...formControlProps}
    >
      {typeof children === 'function'
        ? children({ field, fieldState, formState })
        : children}
    </FormControl>
  );
}
