import React, { Component } from 'react';

import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import InspectionInfoCard from './components/InspectionInfoCard';
import StructureInfoCard from './components/StructureInfoCard';
import InspectionSummaryCard from './components/InspectionSummaryCard';
import SGRCard from './components/SGRCard';
import TermCard from './components/TermCard';
import NumberOfObservationsCard from './components/NumberOfObservationsCard';
import {
  getInspectionData,
  updateObservation,
  refreshObservationsData,
  deleteObservation,
  updateImageRectangle,
  deleteImage,
  uploadImage,
  addLocation,
  deleteLocation,
  updateLocation,
  updateInspectionSummary,
  updateLocationInspected,
  downloadInspectionPhotosZip,
} from './InspectionAction';
import Helper from 'helper/Helper';
import ObservationsTable, {
  FIELD_SUB_COMPONENT_UUID,
  FIELD_ID,
} from './components/ObservationsTable';
import LoadingScreen from 'components/LoadingScreen';
import { showConfirmationDialog } from 'components/Dialog/DialogAction';
import { Strings } from 'config/Strings';
import { VisibilityFlag } from '../../config/VisibilityFlag';
import ObservationGallery from 'features/ObservationGallery';
import SubComponentsTable, {
  FIELD_LOCATIONS_UUID,
} from './components/SubComponentsTable';
import {
  mapLocation,
  mapObservation,
  mapInspection,
  mapStructure,
  mapProject,
  mapPreferences,
  mapObservationToAPI,
  mapImagesToGallery,
  mapClient,
  mapLocationToAPI,
} from './InspectionMapper';
import { withRouter } from 'react-router-dom';
import { UpdateObservation } from 'features/ObservationActions/UpdateObservation';
import { SubdivisionTable } from 'features/StructureSubdivision';

class Inspection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inspectionId: this.extractInspectionID(),
      observationInGallery: undefined,
    };
  }
  componentDidMount() {
    this.props.getInspectionData(this.state.inspectionId);
  }

  getFilteredPreferences = () => {
    const locationData = this.props.locationData;
    var filteredPreferences = Helper.deepCopy(this.props.preferencesData);

    var filteredComponents = {};
    var filteredSubComponents = {};
    var filteredSubComponentsToUUID = {};

    for (var i = 0; i < locationData.length; i++) {
      filteredComponents[locationData[i]['component']] =
        filteredPreferences['components'][locationData[i]['component']];
      filteredSubComponents[locationData[i]['sub_component']] =
        filteredPreferences['subcomponents'][locationData[i]['sub_component']];
      filteredSubComponentsToUUID[locationData[i]['sub_component']] =
        locationData[i]['uuid'];
    }

    filteredPreferences['components'] = filteredComponents;
    filteredPreferences['subcomponents'] = filteredSubComponents;
    filteredPreferences['sub_to_uuid'] = filteredSubComponentsToUUID;
    return filteredPreferences;
  };

  extractInspectionID() {
    const parsedURL = Helper.parseURLLocation(
      this.props.location.pathname + '',
    );
    return parsedURL[parsedURL.length - 1];
  }

  structureName() {
    const structureName =
      this.props.structureData.structureCode +
      '_' +
      this.props.structureData.structureName;
    return structureName;
  }

  summaryUpdatedCallback = summary => {
    this.props.updateInspectionSummary(this.state.inspectionId, summary);
  };

  onObservationUpdateCallback = (observation, prevObservation) => {
    // const newObservation = mapObservationToAPI(observation);
    // console.log('onObservationUpdateCallback', newObservation);
    
    this.props.updateObservation(
      this.props.apiInspectionData.uuid,
      observation[FIELD_SUB_COMPONENT_UUID],
      mapObservationToAPI(
        observation,
        this.props.apiInspectionData.observations,
        prevObservation[FIELD_SUB_COMPONENT_UUID],
      ),
      prevObservation[FIELD_SUB_COMPONENT_UUID] !==
        observation[FIELD_SUB_COMPONENT_UUID],
    );
  };

  onDownloadImagesCallback = () => {
    this.props.downloadInspectionImages(
      this.state.inspectionId,
      this.structureName(),
    );
  };

  onImageRectangleUpdatedCallback = (id, rectangle) => {
    const drawable = 'Rect(' + rectangle.join() + ')';

    this.props.updateImageRectangle(
      this.props.apiInspectionData.uuid,
      this.state.observationInGallery[FIELD_SUB_COMPONENT_UUID],
      this.state.observationInGallery[FIELD_ID],
      id,
      drawable,
    );
  };

  onImageRemovedCallback = id => {
    const pendingAction = deleteImage(
      this.props.apiInspectionData.uuid,
      this.state.observationInGallery[FIELD_SUB_COMPONENT_UUID],
      this.state.observationInGallery[FIELD_ID],
      id,
    );
    this.props.confirmDeleteImage(pendingAction);
  };

  onObservationRemoveCallback = observation => {
    const pendingAction = deleteObservation(
      this.props.apiInspectionData.uuid,
      observation[FIELD_SUB_COMPONENT_UUID],
      observation[FIELD_ID],
    );
    this.props.confirmDeleteObservation(pendingAction);
  };

  onOutsideGalleryClicked = () => {
    this.setState({ observationInGallery: undefined });
  };

  onImageClick = observation => {
    this.setState({ observationInGallery: observation });
  };

  onUploadImage = (imageFile, rowData) => {
    this.props.uploadImage(
      this.props.apiInspectionData.uuid,
      rowData[FIELD_SUB_COMPONENT_UUID],
      rowData[FIELD_ID],
      imageFile,
    );
  };

  onLocationUpdateCallback = location => {
    this.props.updateLocation(
      this.props.apiInspectionData.uuid,
      mapLocationToAPI(location),
    );
  };

  onLocationRemoveCallback = location => {
    const pendingAction = deleteLocation(
      this.props.apiInspectionData.uuid,
      location[FIELD_LOCATIONS_UUID],
    );
    this.props.confirmDeleteLocation(pendingAction);
  };

  onLocationAddCallback = location => {
    this.props.addLocation(
      this.props.apiInspectionData.uuid,
      mapLocationToAPI(location),
    );
  };

  onInvalidInputCallback = (title, description) => {
    //this.props.showError(title, description);
  };

  onLocationInspectedCallback = (compSubId, inspected) => {
    this.props.updateLocationInspected(
      this.state.inspectionId,
      compSubId,
      inspected,
    );
  };

  componentDidUpdate = () => {
    if (this.state.observationInGallery !== undefined) {
      const observationIndex = Helper.indexOf(
        this.props.observationsData,
        this.state.observationInGallery['id'],
      );

      if (
        (this.props.observationsData[observationIndex].images || []).length !==
        (this.state.observationInGallery.images || []).length
      ) {
        this.setState({
          observationInGallery: this.props.observationsData[observationIndex],
        });
      } else {
        for (
          var i = 0;
          i < (this.state.observationInGallery.images || []).length;
          i++
        ) {
          if (
            this.state.observationInGallery.images[i].drawables !==
            this.props.observationsData[observationIndex].images[i].drawables
          ) {
            this.setState({
              observationInGallery: this.props.observationsData[
                observationIndex
              ],
            });
            break;
          }
        }
      }
    }
  };

  onColumnSort = (column, order) => {
    if (column) {
      const { name } = column;
      if (name === 'Total Defects') {
        const params = new URLSearchParams({ defect_order: order });
        this.props.history.replace(
          `${this.props.location.pathname}?${params.toString()}`,
        );
      }
    }
  };

  render() {
    const sgrRating = this.props.inspectionData.sgrRating;
    const term = this.props.inspectionData.term;
    const numberOfObservations = this.props.inspectionData.numberOfObservations;
    const numberofImages = this.props.observationsData
      .map(x => x.images.length)
      .reduce((a, b) => a + b, 0);
    const startDate = this.props.inspectionData.startDate;
    const endDate = this.props.inspectionData.endDate;
    const status = this.props.inspectionData.status;
    const inspectors = this.props.inspectionData.inspectors;
    const summary = this.props.inspectionData.summary;
    const structureCode = this.props.structureData.structureCode;
    const structureName = this.props.structureData.structureName;
    const spans = this.props.inspectionData.spans;
    const structureNumber = this.props.structureData.structureNumber;
    const postMile = this.props.structureData.postMile;
    const beginStationing = this.props.structureData.beginStationing;
    const endStationing = this.props.structureData.endStationing;
    const owner = this.props.clientData.clientName;
    const project = this.props.projectData.name;

    const showGallery = this.state.observationInGallery !== undefined;

    if (this.props.loading) {
      return <LoadingScreen />;
    }

    return (
      <div>
        {showGallery && (
          <ObservationGallery
            images={mapImagesToGallery(this.state.observationInGallery)}
            onOutsideLightBoxClicked={this.onOutsideGalleryClicked}
            onImageRectangleUpdatedCallback={
              this.onImageRectangleUpdatedCallback
            }
            onImageRemovedCallback={this.onImageRemovedCallback}
            isEditable={!this.props.viewOnly}
          />
        )}
        <Grid container direction="column" spacing={3}>
          <Grid item container direction="row" spacing={3}>
            <Grid item xs={2}>
              <SGRCard sgr={sgrRating} />
            </Grid>
            <Grid item container xs={2} direction="column" spacing={3}>
              {VisibilityFlag.getInstance().isVisible(
                'inspection_info_term_card',
              ) && (
                <Grid item xs>
                  <TermCard term={term} />
                </Grid>
              )}
              <Grid item xs>
                <NumberOfObservationsCard
                  observations={numberOfObservations}
                  imageCount={numberofImages}
                />
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <StructureInfoCard
                structureId={structureCode}
                structureName={structureName}
                structureOwner={owner}
                structureNumber={structureNumber}
                spans={spans}
                postMile={postMile}
                beginStationing={beginStationing}
                endStationing={endStationing}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing={3} xs={12}>
            <Grid item xs>
              <InspectionInfoCard
                startDate={startDate}
                endDate={endDate}
                project={project}
                status={status}
                inspectors={inspectors}
              />
            </Grid>
            <Grid item xs>
              <InspectionSummaryCard
                editable={!this.props.viewOnly}
                summaryUpdatedCallback={this.summaryUpdatedCallback}
                summary={summary}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing={3} xs>
            <Grid item xs>
              <SubComponentsTable
                data={this.props.locationData}
                preferences={this.props.preferencesData}
                onLocationUpdateCallback={this.onLocationUpdateCallback}
                loading={this.props.loadingObservations}
                onLocationsRefreshCallback={() =>
                  this.props.refreshObservationsData(
                    this.props.apiInspectionData.uuid,
                  )
                }
                onLocationRemoveCallback={this.onLocationRemoveCallback}
                isEditable={!this.props.viewOnly}
                onInvalidInputCallback={this.onInvalidInputCallback}
                onLocationAddCallback={this.onLocationAddCallback}
                onLocationInspectedCallback={this.onLocationInspectedCallback}
                onColumnSort={this.onColumnSort}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing={3} xs>
            <Grid item xs>
              <SubdivisionTable structureId={this.props.structureData.id} />
            </Grid>
          </Grid>
          <Grid item container direction="row" spacing={3} xs>
            <Grid item xs>
              <ObservationsTable
                data={this.props.observationsData}
                currentInspection={this.props.apiInspectionData}
                preferences={this.getFilteredPreferences()}
                onObservationUpdateCallback={this.onObservationUpdateCallback}
                loading={this.props.loadingObservations}
                onObservationsRefreshCallback={() =>
                  this.props.refreshObservationsData(
                    this.props.apiInspectionData.uuid,
                  )
                }
                onObservationRemoveCallback={this.onObservationRemoveCallback}
                onDownloadImagesCallback={this.onDownloadImagesCallback}
                onMultipleSelect={rowData => {
                  const params = new URLSearchParams(
                    this.props.location.search,
                  );
                  params.set('modal', 'bulk_update');
                  params.set(
                    'ids',
                    JSON.stringify(rowData.map(data => data.id)),
                  );
                  this.props.history.replace(
                    `${this.props.location.pathname}?${params.toString()}`,
                  );
                }}
                onImageClick={this.onImageClick}
                onUploadImage={this.onUploadImage}
                isEditable={!this.props.viewOnly}
              />
            </Grid>
          </Grid>
        </Grid>
        <UpdateObservation
          onUpdated={() => {
            this.props.refreshObservationsData(
              this.props.apiInspectionData.uuid,
            );
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    // TODO: Find a better strategy than keeping the original API data as props
    loading: state.InspectionReducer.loading,
    loadingObservations: state.InspectionReducer.loadingObservations,
    loadingLocations: state.InspectionReducer.loadingLocations,
    locationData: mapLocation(state),
    apiInspectionData: state.InspectionReducer.inspectionData,
    inspectionData: mapInspection(state),
    structureData: mapStructure(state),
    //observationsData: mapObservation(state),
    observationsData: mapObservation(
      state.InspectionReducer.inspectionData.observations,
    ),
    projectData: mapProject(state),
    clientData: mapClient(state),
    preferencesData: mapPreferences(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getInspectionData: inspectionId =>
      dispatch(getInspectionData(inspectionId)),
    updateObservation: (
      inspectionId,
      subComponentUuid,
      observation,
      compSubUpdated,
    ) =>
      dispatch(
        updateObservation(
          inspectionId,
          subComponentUuid,
          observation,
          compSubUpdated,
        ),
      ),
    refreshObservationsData: inspectionId =>
      dispatch(refreshObservationsData(inspectionId)),
    confirmDeleteObservation: pendingAction =>
      dispatch(
        showConfirmationDialog(
          Strings.getInstance().getString('dialog_delete_observation_title'),
          Strings.getInstance().getString(
            'dialog_delete_observation_description',
          ),
          pendingAction,
        ),
      ),
    confirmDeleteImage: pendingAction =>
      dispatch(
        showConfirmationDialog(
          Strings.getInstance().getString('dialog_delete_image_title'),
          Strings.getInstance().getString('dialog_delete_image_description'),
          pendingAction,
        ),
      ),
    updateImageRectangle: (
      inspectionId,
      componentSubcomponentId,
      observationId,
      photoId,
      drawable,
    ) =>
      dispatch(
        updateImageRectangle(
          inspectionId,
          componentSubcomponentId,
          observationId,
          photoId,
          drawable,
        ),
      ),
    deleteImage: (
      inspectionId,
      componentSubcomponentId,
      observationId,
      photoId,
    ) =>
      dispatch(
        deleteImage(
          inspectionId,
          componentSubcomponentId,
          observationId,
          photoId,
        ),
      ),
    uploadImage: (inspectionId, componentSubcomponentId, observationId, data) =>
      dispatch(
        uploadImage(inspectionId, componentSubcomponentId, observationId, data),
      ),
    addLocation: (inspectionId, location) =>
      dispatch(addLocation(inspectionId, location)),
    updateLocation: (inspectionId, location) =>
      dispatch(updateLocation(inspectionId, location)),
    confirmDeleteLocation: pendingAction =>
      dispatch(
        showConfirmationDialog(
          Strings.getInstance().getString('dialog_delete_location_title'),
          Strings.getInstance().getString('dialog_delete_location_description'),
          pendingAction,
        ),
      ),
    updateInspectionSummary: (inspectionId, summary) =>
      dispatch(updateInspectionSummary(inspectionId, summary)),
    updateLocationInspected: (inspectionId, compSubId, inspected) =>
      dispatch(updateLocationInspected(inspectionId, compSubId, inspected)),
    downloadInspectionImages: (inspectionId, structureName) =>
      dispatch(downloadInspectionPhotosZip(inspectionId, structureName)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Inspection));
