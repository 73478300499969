import React, { Component } from "react";
import SelectableTreeItem from "./SelectableTreeItem";
import Typography from "@material-ui/core/Typography";

export default class SelectableComponentTreeItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { name, id, selected, onSelected, subSelected, ...rest } = this.props;

    const selectedSubCount = (
      <div>
        <Typography size="small" color="textSecondary">
          {subSelected}
        </Typography>
      </div>
    );

    return (
      <SelectableTreeItem label={"Component"} name={name} id={id} onSelected={onSelected} selected={selected} content={selectedSubCount} {...rest} />
    );
  }
}
