import React from "react";

export default class Helper {
  static getCurrentDateTime() {
    var today = new Date();
    return (
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2) +
      "T" +
      ("0" + today.getHours()).slice(-2) +
      ":" +
      ("0" + today.getMinutes()).slice(-2) +
      ":" +
      ("0" + today.getSeconds()).slice(-2) +
      "." +
      today.getMilliseconds() +
      "Z"
    );
  }

  static convertToDBDateFormat(dateString) {
    if (dateString === undefined) {
      return undefined;
    }

    var date = new Date(dateString);
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2) +
      "T" +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2) +
      ":" +
      ("0" + date.getSeconds()).slice(-2) +
      "." +
      date.getMilliseconds() +
      "Z"
    );
  }

  static extractDate(stringDate) {
    if (stringDate === "NA") {
      return "NA";
    }
    return stringDate === "" || stringDate === undefined ? "" : new Date(stringDate).toISOString().split("T")[0];
  }

  static indexOf(arr, id, idName) {
    const idFieldName = idName === undefined ? "id" : idName;
    for (var index = 0; index < (arr || []).length; index++) {
      if (arr[index][idFieldName] === id) {
        return index;
      }
    }

    return -1;
  }

  static parse(str) {
    var args = [].slice.call(arguments, 1),
      i = 0;
    return str.replace(/%s/g, () => args[i++]);
  }

  static parseURLLocation(location) {
    return location.split("/");
  }

  static insertItemImmutable(array, item) {
    let newArray = array.slice();
    newArray.splice(array.length, 0, item);
    return newArray;
  }

  static removeItemImmutable(array, id, idName) {
    const idFieldName = idName === undefined ? "id" : idName;

    let newArray = array.slice();
    for (var index = 0; index < array.length; index++) {
      if (array[index][idFieldName] === id) {
        newArray.splice(index, 1);

        return newArray;
      }
    }

    return array;
  }

  static updateItemImmutable(array, itemToUpdate, idName) {
    const idFieldName = idName === undefined ? "id" : idName;

    return array.map((item, index) => {
      if (array[index][idFieldName] !== itemToUpdate[idFieldName]) {
        return item;
      }
      return {
        ...item,
        ...itemToUpdate,
      };
    });
  }

  static arrayToHTML(array) {
    var result = [];
    if (array !== null && array !== undefined) {
      Object.entries(array).forEach(([key, value]) => {
        result.push(<div key={key}>{value}</div>);
      });
    }

    return result;
  }

  static areObjectsEqual(a, b, omittedProperties = []) {
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // Omit properties as necessary.
    if (omittedProperties.length > 0) {
      aProps = aProps.filter((element) => !omittedProperties.includes(element));
      bProps = bProps.filter((element) => !omittedProperties.includes(element));
    }

    if (aProps.length !== bProps.length) {
      return false;
    }

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    return true;
  }

  static isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  static deepCopy(object) {
    var initTempData = {};
    Object.assign(initTempData, object);
    return initTempData;
  }
}
