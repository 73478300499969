import { FunctionComponent } from 'react';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  SIZE,
} from 'baseui/modal';
import { Input } from 'baseui/input';
import { Textarea } from 'baseui/textarea';
import { StatefulSelect } from 'baseui/select';
import React, {useEffect} from 'react';
import { FormController } from 'components/FormController';
import ObservationAPIController from "../../../services/api/ObservationAPIController";
import { useStitches } from 'theme';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import Helper from 'helper/Helper';
import moment from 'moment';
import TokenManager from "storage/TokenManager";

interface NewInspectionsModalProps {
  isOpen: boolean;
  onClose: (refreshTable: boolean) => void;
  preferences: any;
  currentInspection:any;
} 

let CS_:any = [
  {id:'CS-1' , label:'CS-1'},
  {id:'CS-2' , label:'CS-2'},
  {id:'CS-3' , label:'CS-3'},
  {id:'CS-4' , label:'CS-4'},
];

const NewInspectionsModal: FunctionComponent<NewInspectionsModalProps> = (
  props,
) => {
  const { css } = useStitches();
  const {control, handleSubmit, reset, watch} = useForm();
  const [selectedType, setSelectedType] = React.useState<any>(null);
  const [componentList, setComponentList] = React.useState<any>(null);
  const [subComponentsList, setSubComponentsList] = React.useState<any>(null);
  const [defectsList, setDefectsList] = React.useState<any>(null);
  const [selectedComponent, setSelectedComponent] = React.useState<any>(null);
  const [selectedSubComponent, setSelectedSubComponent] =React.useState<any>(null);
  const [selectedDefect, setSelectedDefect] = React.useState<any>(null);
  const [CSList, setCSList] = React.useState<any>(CS_);
  const [selectedCS, setSelectedCS] = React.useState<any>(null);

  useEffect(() => {
    if (selectedType) {
      setSelectedComponent(null);
      setSelectedSubComponent(null);
      setComponentList(getComponents());
    }
  }, [selectedType]);

  useEffect(() => {
    if (selectedComponent) {
      setSubComponentsList(getSubComponents());
    }
  }, [selectedComponent]);

  useEffect(() => {
    if (selectedSubComponent) {
      setDefectsList(getDefectType().sort((a:any, b:any) => (a.label > b.label) ? 1 : -1));
    }
  }, [selectedSubComponent]);

  useEffect(() => {
    if (selectedDefect) {
      let csList:any = [];
      try {
        let cs = selectedDefect.id && props.preferences.defects[selectedDefect.id]?.conditions ? props.preferences.defects[selectedDefect.id]?.conditions : [];
        if (cs.length > 0) {
          csList = []; 
          cs.map((condition:any) => {
            let cs = props?.preferences?.conditions[condition].name;
            csList.push({id:condition, label:cs})
          })
          setCSList(csList);
        }
      } catch (e){
        setCSList(csList);
      }
    }
  }, [selectedDefect]);

  const MAINTENANCE_OBSERVATION = 'MAINTENANCE';
  const STRUCTURAL_DEFECT       = 'STRUCTURAL';
  const STRUCTURAL_ID           = props?.currentInspection?.structureCode;

  if (!props.isOpen) return null;

  const getLookupValues = (
    ...args: ({ [s: string]: unknown } | ArrayLike<unknown>)[] | any
  ) => {
    let options = [];

    for (let i = 0; i < args.length; i++) {
      // array of strings
      if (
        typeof args[i] === 'object' &&
        Object.values(args[i]).every((value) => typeof value === 'string')
      ) {
        options.push(
          ...args[i].map((name: string) => ({ id: name, label: name })),
        );
      } else {
        // array of objects
        for (const [key, value] of Object.entries(args[i])) {
          if (key !== undefined) {
            options.push({
              id: key,
              label: value === undefined ? '' : (value as any)['name'],
            });
          }
        }
      }
    }
    return options;
  };

  const getComponents = () => {
    const components = props?.preferences?.components;

    if (!components || typeof components !== 'object') return [];

    const result = [];
    const entries = Object.entries(components);
    // Object.entries(components).map prevents compilation, only GOD knows why
    for (const [key, value] of entries) {
        if(typeof key != 'undefined' && typeof value != 'undefined'){
          result.push({
          id: key,
          label: (value as any).name,
          subComponents:value
        });
      }
    }

    return result;
  };

  const getSubComponents = () => {
    if (
      !selectedType ||
      !selectedType?.id ||
      !selectedComponent ||
      !selectedComponent.id ||
      !props.preferences?.subcomponents
    ) {
      return [];
    } 

    const componentSubs = selectedComponent['subComponents']['subcomponents'] ?? [];
    // If no subcomponents exist.
    let SubComponents:any = [];
    if(componentSubs.length > 0) {
      const entries:any = props?.preferences.subcomponents;
      componentSubs?.map((sub:any) => {
        if (entries[sub] !== undefined) {
           const subValues = entries[sub];
           SubComponents.push({id:sub, label:subValues?.name, defects:subValues?.defects, unit:subValues?.measureUnit});
        }
      })
    }

    return SubComponents;
  }

  const cs = () => {
    if (
      !selectedType ||
      !selectedType?.id ||
      !selectedSubComponent ||
      !selectedSubComponent?.id ||
      !props.preferences?.subcomponents
    ) {
      return [];
    }

    const result      = [];
    const entries:any = Object.entries(props.preferences.conditions);
    for (const [key, value] of entries) {
          result.push({
          id: key,
          label: value['name'],
        });
    }

    return result;
  };

  const getDefectType = () => {
    if (
      !selectedType ||
      !selectedType?.id ||
      !selectedComponent ||
      !selectedComponent.id ||
      !selectedSubComponent ||
      !selectedSubComponent.id ||
      !props.preferences?.subcomponents
    ) {
      return [];
    }

    let defects:any = [];

    try {
      if(selectedType?.id == 'MAINTENANCE') {
        const nonDefectObservations:any = Object.entries(props?.preferences?.nonDefectObservations);

        nonDefectObservations.map((defect:any) => {
          defects.push({id:defect[0], label:defect[1].name})
        })
      }
      else {
        const subID = props?.preferences?.sub_to_uuid[selectedSubComponent?.id];
        let defectsFormat = selectedSubComponent?.defects;
    
        defectsFormat.map((defect:any) => {
          const selectedDefect = props?.preferences?.defects[defect];
          defects.push({id:defect, label:selectedDefect?.name, conditions:selectedDefect?.conditions})
        })
      }
    } catch (e) {
      console.log(e);
    } finally {
      return defects;
    }
  };


  const getActualDefectNumber = () => {  
    const inspection = props?.currentInspection;

    if (inspection) {
      let defectNumber           = STRUCTURAL_ID;
      let formattedInspection:any = {'defectIds':{}};
      let defectIdentifier       = selectedDefect?.id.trim();
      let defectLetter           = selectedType?.id === MAINTENANCE_OBSERVATION ? 'M' : 'D' ;

      props?.currentInspection?.observations?.map((observation:any) => {
        observation.observationDefects.map((defect:any) => {
          let IDArray:any   =  defect.id.split('-');
          let count = parseInt(IDArray[3]);

          if (Object.keys(formattedInspection.defectIds).length == 0) {
            formattedInspection.defectIds[IDArray[4]] = count+1; 
          } else {
            let next = formattedInspection.defectIds[IDArray[4]] ?? false;
            if(next != false && next < count){
              formattedInspection.defectIds[IDArray[4]] = count+1; 
            } else if (next == false) {
              formattedInspection.defectIds[IDArray[4]] = count+1;
            }
          }
        })

        let obser = {
          id: observation.id,
          defects_count:observation.observationDefects.length,
          defects:observation.observationDefects,
        } 
        formattedInspection[observation.id] = obser;
      });

      let currentCount        = '001';
      let addCount            = false;
      let setCount            = 1
      let currentDate         = moment().format('MMDDYYYY');
      let existingDefects:any = [];
      let inspectionDefects   =  Object.entries(formattedInspection);

      inspectionDefects.forEach((defects:any) => {
        defects.forEach((defect:any) => {
          if (defect?.defects_count == undefined || defect?.defects_count == 0){
          } else {
            defect?.defects.forEach((items:any) =>{
              let item = items.id.split('-')
              existingDefects.push({
                count:item[item.length - 3],
                date:item.pop()
              })
            })
          }
        });
      });

      if(existingDefects.length != 0) {
        existingDefects.forEach((defect:any) => {          
          addCount = true;
          if(Number(defect.count) >= setCount){
            setCount = Number(defect.count);
          }          
        });
      }

      if(addCount != false){
        setCount = setCount + 1;
        if (setCount < 9){
          currentCount =`00${setCount}`;
        } else if( setCount <= 99 && setCount > 9) {
          currentCount =`0${setCount}`;
        } else if( setCount <= 999 && setCount > 99) {
          currentCount =`${setCount}`;
        }
      }

      return `${defectNumber}-${defectLetter}-${currentCount}-${TokenManager.getUserID()}-${moment().format('MMDDYYYY')}`;
    }
  }

  const getStructures = () => {
    let selectableSubDivisions:any = [];
    let subdivisions = props?.currentInspection?.structureSubdivisions.length > 0 == false ? [] : props?.currentInspection?.structureSubdivisions;
  
    if(subdivisions.length > 0) {
      subdivisions.map((sub:any) => {
        selectableSubDivisions.push({
          id:sub?.uuid,
          label:sub?.number 
        })
      })
    }

    return selectableSubDivisions;
  }
  
  const getCriticalFindings = () => {
    let findings:any = [];
    props.preferences.criticalFindings.map((finding:any) => {
      findings.push({label : finding.finding, id : finding.value});
    });
    return findings;
  } 

  // Hides shows fields when they are valid. 
  // Based upon Sergio's notes. 
  const isValid = (field:any, option:any) => {
    switch(field) {
      case 'observation_type':
        return false;
      case 'controller':
        return option === null || selectedType === null ? true : false;
      case 'subcomponent':
         return option === null || 
                selectedComponent === null || 
                selectedType === null ? true : false;
      case 'defect':
        return option === null || 
               selectedComponent === null || 
               selectedSubComponent === null || 
               selectedType === null ? true : false;
      case 'complete':
          return option === null || 
                 selectedComponent === null || 
                 selectedSubComponent === null || 
                 selectedDefect === null ||
                 selectedType === null ? true : false;
      default:
        return true;
    }
  }

  const addDefect = async (data:any) => {

    try {
      let defect = {
        inspectionId:props.currentInspection.uuid,
        id:getActualDefectNumber(),
        uuid:uuid().toString(),
        defectId:selectedDefect?.id,
        component:selectedComponent.id,
        conditionId:data?.conditionId,
        criticalFindings:data?.criticalFindings === undefined ? [] : [data.criticalFindings],
        criticalFindingsValues: [],
        description:data?.description,
        observationId: props?.preferences?.sub_to_uuid[data.subComponent],
        observationNameId:selectedDefect?.id,
        clockPosition:"[]",
        span:data?.location,
        size:data?.dimensionQty,
        status:data?.status != undefined ? data?.status.toUpperCase() : 'NEW',
        repairMethod: data?.repairMethod,
        stationMarker: data?.stationing,
        previousDefect:"",
        done:false,
        type:selectedType?.id ==  'MAINTENANCE' ? selectedType?.id : 'STRUCTURAL',
        structureSubdivisionId:data?.structureSubdivision,
        observationType:data?.correctiveAction,
        createdAt:Helper.getCurrentDateTime(),
        createdAtClient:Helper.getCurrentDateTime()
      }

      // if Maintenance, remove stationing
      if(selectedType?.id ==  'MAINTENANCE') {
        delete defect.conditionId;
      }

      // Add the new observation.
      ObservationAPIController.updateObservation(defect.inspectionId, defect.observationId, defect).then((result) => {
        props.onClose(true);
      })
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Modal onClose={() => props.onClose(false)} isOpen={true} size={SIZE.auto}>
      <ModalHeader>Create new Observation/Defect</ModalHeader>
      <ModalBody>
        <form
          id="new-observation"
          onSubmit={handleSubmit(addDefect)}
          className={css({
            display: 'grid',
            gridTemplateColumns: 'repeat(2,1fr)',
            columnGap: '$scale900',
          })}
        >
          <div
            className={css({
              gridColumn: 'span 2',
              marginBottom: '10px',
            })}
          >
            <FormController
              control={control}
              name="type"
              label="Observation Type"
            >
              {({ field }) => (
                <StatefulSelect
                  disabled={false}
                  options={[{
                    id: MAINTENANCE_OBSERVATION,
                    label: 'Maintenance Observation'
                  },{ 
                    id: STRUCTURAL_DEFECT, label: 'Defect' 
                  }]}
                  onChange={({ option }) => {                    
                    field.onChange(option?.id ?? '');
                    setSelectedType(option);
                    isValid('observation_type', option)
                  }}
                />
              )}
            </FormController>
          </div>
          <FormController data-id="component" control={control} name="component" defaultValue={''} label="Component">
            {({ field }) => (
              <StatefulSelect
                disabled={isValid('controller', selectedType)}
                options={componentList}
                value={selectedComponent}
                onChange={({ option }) => {
                    field.onChange(option?.id ?? '');
                    setSelectedComponent(option);
                  }}
              />
            )}
          </FormController>
          <FormController
            control={control}
            name="subComponent"
            label="Sub-Component"
            disabled={isValid('subcomponent', selectedComponent)}
          >
            {({ field }) => (
              <StatefulSelect
                options={subComponentsList}
                onChange={({ option }) => {
                  field.onChange(option?.id ?? '');
                  setSelectedSubComponent(option);
                }}
              />
            )}
          </FormController>
          <FormController
            control={control}
            name="defectType"
            label="Defect Type"
            disabled={isValid('defect', selectedSubComponent)}
          >
            {({ field }) => (
              <StatefulSelect
                options={defectsList}
                onChange={({ option }) => { 
                  field.onChange(option?.id ?? '')
                  setSelectedDefect(option)
                }}
              />
            )}
          </FormController>
          <FormController
            control={control}
            name="structureSubdivision"
            label="Structure Subdivision"
            disabled={isValid('complete', selectedSubComponent)}
          >
            {({ field }) => (
              <StatefulSelect
                options={getStructures()}
                onChange={
                  ({ option }) => {
                  field.onChange(option?.id ?? '')

                  // structureSubdivision
                  // structureSubdivisionId



                  }
              }
              />
            )}
          </FormController>
          <FormController 
            control={control} 
            name="conditionId" 
            label="CS"
            disabled={isValid('complete', selectedSubComponent)}
            >
            {({ field }) => (
              <StatefulSelect
                options={CSList}
                onChange={({ option }) => {
                    field.onChange(option?.id ?? '');
                    setSelectedCS(option);
                  }}
              />
            )}
          </FormController>
          <FormController
            control={control}
            name="stationing"
            label="Stationing"
            disabled={isValid('complete', selectedSubComponent)}
          >
            {({ field }) => <Input placeholder="Stationing" {...field} />}
          </FormController>
          <FormController
            control={control}
            name="dimensionQty"
            label="Dimension/Qty"
            disabled={isValid('complete', selectedSubComponent)}
          >
            {({ field }) =>(
              <div style={{display:'flex', alignItems: 'center'}}>
                <Input type='number'  placeholder="Dimension/Qty" disabled={isValid('complete', selectedSubComponent)} {...field} />
                <span style={{ marginLeft: '5px', fontWeight:700}}>{selectedSubComponent ? selectedSubComponent.unit : ''}</span>
              </div>
            )}

          </FormController>
          <FormController
            control={control}
            name="repairMethod"
            label="Repair Method"
            disabled={isValid('complete', selectedSubComponent)}
          >
            {({ field }) => <Input placeholder="Repair Method" {...field} />}
          </FormController>
          <FormController
            control={control}
            name="location"
            label="Location"
            disabled={isValid('complete', selectedSubComponent)}
          >
            {({ field }) => <Input placeholder="Location" {...field} />}
          </FormController>
          <FormController
            control={control}
            name="correctiveAction"
            label="Corrective Action"
          >
            {({ field }) => (
              <StatefulSelect
              disabled={isValid('complete', selectedSubComponent)}
              options={[
                {id:'CRITICAL', label:'A - Critical'},
                {id:'PRIORITY', label:'B - Priority'},
                {id:'ROUTINE', label:'C - Routine'},
                {id:'MONITOR', label:'D - Monitor'},
              ]}
              onChange={({ option }) => field.onChange(option?.id ?? '')}
              />
            )}
          </FormController>
          <FormController
            control={control}
            name="description"
            label="Description"
            overrides={{
              Root: {
                style: {
                  gridColumn: '1/-1',
                },
              },
            }}
          >
            {({ field }) => <Textarea onChange={field.onChange} />}
          </FormController>
          <FormController control={control} name="status" label="Status">
            {({ field }) => (
              <StatefulSelect
                disabled={isValid('complete', selectedSubComponent)}
                options={[
                  { id: 'unchanged', label: 'Unchanged' },
                  { id: 'changed', label: 'Changed' },
                  { id: 'repaired', label: 'Repaired' },
                  { id: 'new', label: 'New' },
                ]}
                onChange={({ option }) => field.onChange(option?.id ?? '')}
              />
            )}
          </FormController>
          <FormController
            control={control}
            name="criticalFindings"
            label="Critical Findings"
          >
            {({ field }) => (
              <StatefulSelect
                disabled={isValid('complete', selectedSubComponent)}
                options={getCriticalFindings()}
                onChange={({ option }) => field.onChange(option?.id ?? '')}
              />
            )}
          </FormController>
        </form>
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={() => {
          getActualDefectNumber();
          props.onClose(false);
          }}>Cancel</ModalButton>
        <ModalButton form="new-observation" disabled={isValid('complete', selectedSubComponent)} type="submit">
          Save Observation
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default NewInspectionsModal;
