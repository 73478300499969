import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import GeneralCard from "components/GeneralCard";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import LabeledText from "components/LabeledText";
import { Strings } from "config/Strings";
import TokenManager from "../../../storage/TokenManager";
import { VisibilityFlag } from "../../../config/VisibilityFlag"

const styles = () => ({
  textField: { marginBottom: 20 },
});

class StructureInfoCard extends Component {
  
  render() {
    const title = Strings.getInstance().getString("inspection_structure_info_title");
    const getVisibility = (x) => VisibilityFlag.getInstance().isVisible(x)
    const showBridgeInfo = TokenManager.hasBridges();

    const content = (
      <div>
        <Grid container direction="row">
          <Grid item>
            <Grid container direction="column">
              <LabeledText
                style={{ marginBottom: 20 }}
                label={Strings.getInstance().getString("inspection_structure_info_id")}
                value={this.props.structureId}
                InputProps={{ disableUnderline: true }}
              />
              <LabeledText
                label={Strings.getInstance().getString("inspection_structure_info_owner")}
                value={this.props.structureOwner}
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <LabeledText
                style={{ marginBottom: 20 }}
                label={Strings.getInstance().getString("inspection_structure_info_name")}
                value={this.props.structureName}
                InputProps={{ disableUnderline: true }}
              />
              <LabeledText
                label={Strings.getInstance().getString("inspection_structure_info_number")}
                value={this.props.structureNumber}
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
          </Grid>

          {showBridgeInfo && (
            <Grid item>
              <Grid container direction="column">
                {getVisibility("inspection_structure_info_spans") && (
                  <LabeledText
                    style={{ marginBottom: 20 }}
                    label={Strings.getInstance().getString("inspection_structure_info_spans")}
                    value={this.props.spans}
                    InputProps={{ disableUnderline: true }}
                  />
                )}
                {getVisibility("inspection_structure_info_post_mile") && (
                  <LabeledText
                    label={Strings.getInstance().getString("inspection_structure_info_post_mile")}
                    value={this.props.postMile}
                    InputProps={{ disableUnderline: true }}
                  />
                )}
              </Grid>
            </Grid>
          )}

          {showBridgeInfo && (
            <Grid item>
              <Grid container direction="column">
                {getVisibility("inspection_structure_info_begin_stationing") && (
                  <LabeledText
                    style={{ marginBottom: 20 }}
                    label={Strings.getInstance().getString("inspection_structure_info_begin_stationing")}
                    value={this.props.beginStationing}
                    InputProps={{ disableUnderline: true }}
                  />
                )}
                {getVisibility("inspection_structure_info_end_stationing") && (
                  <LabeledText
                    label={Strings.getInstance().getString("inspection_structure_info_end_stationing")}
                    value={this.props.endStationing}
                    InputProps={{ disableUnderline: true }}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    );

    return <GeneralCard title={title} content={content} icon={<AccountBalanceOutlinedIcon />} />;
  }
}

export default withStyles(styles)(StructureInfoCard);
