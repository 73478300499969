import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { QueryClientProvider } from '@tanstack/react-query';
import { BaseProvider } from 'baseui';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import theme from 'theme';
import App from './App';

import { SnackbarProvider } from 'baseui/snackbar';
// import './index.css';
import './reset.css';
import * as serviceWorker from './serviceWorker';
import { Store } from './Store';
import { queryClient } from 'config/queryClient';

const engine = new Styletron();
const muiTheme = createMuiTheme({
  typography: {
    fontFamily: 'Inter,sans-serif',
  },
});

ReactDOM.render(
  <StyletronProvider value={engine}>
    <BaseProvider theme={theme}>
      <SnackbarProvider placement={'bottomRight'}>
        <Provider store={Store}>
          <QueryClientProvider client={queryClient}>
            <MuiThemeProvider theme={muiTheme}>
              <App />
            </MuiThemeProvider>
          </QueryClientProvider>
        </Provider>
      </SnackbarProvider>
    </BaseProvider>
  </StyletronProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
