import { KIND as ButtonKind } from 'baseui/button';
import { DatePicker } from 'baseui/datepicker';
import { Input } from 'baseui/input';
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  ROLE,
  SIZE,
} from 'baseui/modal';
import { Option, Select, SIZE as SelectSize, Value } from 'baseui/select';
import { Skeleton } from 'baseui/skeleton';
import { Banner, KIND } from 'components/Banner';
import { FileInput } from 'components/FileInput';
import { FormController } from 'components/FormController';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useCreateInspection } from 'services/api/inspection/mutation';
import { useProject } from 'services/api/project/queries';
import { useAllUsers } from 'services/api/user/queries';
import { useStitches } from 'theme';
import { useQuerystring } from 'utils/hooks';
import InspectionForm from '../InspectionForm';

export default function AddInpsection() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = useQuerystring();
  const isActionAdd = searchParams.has('action')
    ? searchParams.get('action') === 'add'
    : false;
  const projectId = Number(searchParams.get('projectId') || '0');

  const [isOpen, setIsOpen] = useState(isActionAdd);
  const handleClose = () => {
    if (createInspection.isLoading) return;
    const params = new URLSearchParams(location.search);
    params.delete('action');
    setIsOpen(false);
    setTimeout(() => {
      history.replace(`${location.pathname}?${params.toString()}`);
    }, 100);
  };

  const createInspection = useCreateInspection();

  useEffect(() => {
    setIsOpen(isActionAdd);
  }, [isActionAdd]);

  useEffect(() => {
    if (!projectId) {
      setIsOpen(false);
    }
  }, [projectId]);

  return (
    <Modal
      onClose={() => handleClose()}
      isOpen={isOpen}
      size={SIZE.default}
      role={ROLE.dialog}
      closeable
    >
      <ModalHeader>Add Inspection</ModalHeader>
      <ModalBody>
        <Banner
          kind={KIND.negative}
          show={createInspection.isError}
          title="Error"
        >
          Failed add inspection
        </Banner>
        <InspectionForm
          onSubmit={data => {
            createInspection.mutate(
              {
                payload: {
                  file: data.file[0],
                  inspectorId:
                    data.inspectors
                      .filter(item => !!item.id)
                      .map(item => Number(item.id ?? 0)) ?? [],
                  spans: data.spans,
                  startDate: data.startDate,
                  structureId: data.structure[0]?.id?.toString() ?? '',
                  projectId: data.projectId,
                },
              },
              {
                onSuccess: () => {
                  handleClose();
                },
              },
            );
          }}
        />
      </ModalBody>
      <ModalFooter>
        <ModalButton kind={ButtonKind.tertiary} onClick={() => handleClose()}>
          Cancel
        </ModalButton>
        <ModalButton
          form="create-inspection-form"
          type="submit"
          isLoading={createInspection.isLoading}
        >
          Add New Inspection
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}

function StructureSelect({
  value,
  onChange,
}: {
  value: Value;
  onChange: (selected: Value) => void;
}) {
  const searchParams = useQuerystring();
  const { data, isError } = useProject(
    Number(searchParams.get('projectId') ?? ''),
  );

  if (isError) {
    return <div>Error</div>;
  }

  if (data) {
    const options: Option[] = data.structures.map(structure => ({
      label: `${structure.code}-${structure.name}`,
      id: structure.id,
    }));

    return (
      <Select
        placeholder="Select Structure"
        size={SelectSize.default}
        options={options}
        value={value}
        onChange={params => {
          onChange(params.value);
        }}
      />
    );
  }
  return <Skeleton rows={1} />;
}

function InspectorSelect({
  value,
  onChange,
}: {
  value: Value;
  onChange: (selected: Value) => void;
}) {
  const { data, isError } = useAllUsers();

  if (isError) {
    return <div>Error</div>;
  }

  if (data) {
    const options: Option[] = data.map(user => ({
      label: `${user.firstName} ${user.lastName}`,
      id: user.id,
    }));

    return (
      <Select
        placeholder="Select Inspector"
        size={SelectSize.default}
        options={options}
        value={value}
        onChange={params => {
          onChange(params.value);
        }}
        multi
      />
    );
  }
  return <Skeleton rows={1} />;
}
