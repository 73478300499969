import React from "react";
import Checkbox from "components/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "config/StyleConfig";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiFormControlLabel-label.Mui-disabled": {
      color: Colors.text,
    },
  },
}));

export default function CheckboxWithLabel(props) {
  const classes = useStyles();

  const { label, ...rest } = props;
  return (
    <FormControlLabel
      className={classes.root}
      style={{ width: "100%" }}
      control={<Checkbox disabled {...rest} />}
      label={label}
    />
  );
}
