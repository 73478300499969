import React, { Component } from "react";
import SGRHeatMap from "./components/SGRHeatMap";
import { connect } from "react-redux";
import { watchAnalyticsTableData, watchProjectsData } from "./AnalyticsAction";
import DRLoader from "components/DRLoader";


import { mapProjectNames, mapMultipleRows} from "./AnalyticsMapper";

export const FIELD_ID = "id";
export const FIELD_PROJECT_NAME = "project_name";
export const FIELD_STRUCTURES = "structures";

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      projectData: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.props.watchAnalyticsTableData();
    this.props.watchProjectsData();
  }

 

  render() {
    return (
      <div>
        {this.props.loading === true && (
        <div>
          <DRLoader />
        </div>
        )}
        {this.props.loading === false && (
          <SGRHeatMap 
          data={this.props.data}
          projectData={this.props.projectData}
          refreshCallback={() => this.props.watchAnalyticsTableData()}
          refreshProject={() => this.props.watchProjectsData()}
        />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: mapMultipleRows(state),
    projectData: mapProjectNames(state),
    loading: state.AnalyticsReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    watchAnalyticsTableData: () => dispatch(watchAnalyticsTableData()),
    watchProjectsData: () => dispatch(watchProjectsData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);