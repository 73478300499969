import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useMutation } from '@tanstack/react-query';
import { Button } from 'baseui/button';
import { Input } from 'baseui/input';
import { Banner, KIND as BannerKind } from 'components/Banner';
import { FormController } from 'components/FormController';
import Text from 'components/Text';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { fetcher, FetcherMutatergs } from 'services/fetcher';
import TokenManager from 'storage/TokenManager';
import { useStitches } from 'theme';
import { useQuerystring } from 'utils/hooks';

type FormFields = {
  newPassword: string;
  code: string;
};

type ResetPasswordPayload = FetcherMutatergs<FormFields & { email: string }>;
function resetPassword(args: ResetPasswordPayload) {
  return fetcher('api/v1/auth/forgot_password/reset', {
    body: JSON.stringify({
      email: args.payload.email,
      code: args.payload.code,
      password: args.payload.newPassword,
    }),
    method: 'post',
  });
}
const useResetPassword = () => useMutation(resetPassword);

export default function ForgotPassword() {
  const { css, theme } = useStitches();
  const sendEmail = useResetPassword();
  const searchParams = useQuerystring();
  const { control, handleSubmit } = useForm<FormFields>({
    defaultValues: {
      code: '',
      newPassword: '',
    },
  });

  const hasEmail = searchParams.has('email');
  const onSubmit = handleSubmit(data => {
    const email = searchParams.get('email');
    if (email) {
      sendEmail.mutate(
        {
          payload: {
            code: data.code,
            newPassword: data.newPassword,
            email: email,
          },
        },
        {
          onSuccess: data => {
            const success = !!data?.success === true;
            if (success) {
              history.replace('/login');
            }
          },
        },
      );
    }
  });
  const isError =
    sendEmail.isError ||
    (sendEmail.isSuccess && !!sendEmail.data?.success === false);

  const history = useHistory();
  useEffect(() => {
    if (TokenManager.isAuthenticated()) {
      history.replace('/inspections');
    }
  }, [history]);

  useEffect(() => {
    if (!hasEmail) {
      history.replace('/forgot-password');
    }
  }, [hasEmail]);

  return (
    <div
      className={css({
        layout: 'row',
        height: '$screen-h',
        overflow: 'hidden',
      })}
    >
      <div className={css({ flex: 1 })}>
        <img
          src="https://www.stantec.com/content/dam/stantec/images/projects/0032/mike-ocallaghan-pat-tillman-memorial-bridge-inspection-84775.jpg"
          alt=""
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
          }}
        />
      </div>
      <div
        className={css({
          flex: 1,
          layout: 'stack',
          alignItems: 'center',
          justifyContent: 'center',
          p: '$scale600',
        })}
      >
        <div
          className={css({
            maxWidth: '$w-sm',
            spaceY: '$scale600',
          })}
        >
          <div className={css({ layout: 'stack', alignItems: 'center' })}>
            <div
              className={css({
                width: theme.sizing.scale1600,
                height: theme.sizing.scale1600,
                borderRadius: '$full',
                layout: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '$primary',
                color: '$white',
              })}
            >
              <LockOutlinedIcon color="inherit" />
            </div>
            <Text
              variant="HeadingSmall"
              weight="bold"
              css={{ textAlign: 'center' }}
            >
              Reset Password
            </Text>
            <Text variant="ParagraphSmall" css={{ textAlign: 'center' }}>
              Enter the code we sent through your email along with the new
              password to complete the process.
            </Text>
          </div>
          <Banner title="Error" kind={BannerKind.negative} show={isError}>
            Failed to reset password. Try again.
          </Banner>
          <form className={css({ width: '$full' })} onSubmit={onSubmit}>
            <FormController
              control={control}
              name="code"
              label="Reset Code"
              rules={{
                required: 'Enter code.',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'This field only accepts numbers',
                },
              }}
            >
              {({ field }) => <Input {...field} />}
            </FormController>
            <FormController
              control={control}
              name="newPassword"
              defaultValue=""
              label="New Password"
              rules={{ required: 'Enter new password.' }}
            >
              {({ field }) => <Input {...field} type="password" />}
            </FormController>

            <Button
              type="submit"
              className={css({ width: '$full' })}
              isLoading={sendEmail.isLoading}
            >
              Reset Password
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
