import React from "react";
import { Route, Redirect } from "react-router-dom";
import TokenManager from "storage/TokenManager";
import { pageRoutes } from "./index";
import Unauthorized from "components/Unauthorized";
import PropTypes from "prop-types";

export default function RouteWrapper({ component: Component, isPrivate, isAuthorized, viewOnly, componentProps, ...rest }) {
  const isSignedIn = TokenManager.isAuthenticated();

  if (isPrivate && !isSignedIn) {
    return <Redirect to={pageRoutes.login.path} />;
  }

  if (!isAuthorized) {
    return <Route exact {...rest} component={Unauthorized} />;
  }

  return (
    <Route
      exact
      {...rest}
      render={(props) => {
        return <Component {...props} viewOnly={viewOnly} {...componentProps} />;
      }}
    />
  );
}

RouteWrapper.propTypes = {
  /**
    Determines if the route requires authentication or not.
  */
  isPrivate: PropTypes.bool,
  /**
    Determines if the route is accessible by the signed in user or not.
  */
  isAuthorized: PropTypes.bool,
  /**
    Determines if the component loaded can be edited or not (ie. Table values)
  */
  viewOnly: PropTypes.bool,
  /**
    The component that is loaded when this route is called successfully.
  */
  component: PropTypes.element,
  /**
    The props that the component requires.
  */
  componentProps: PropTypes.object,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isAuthorized: true,
  viewOnly: false,
  component: <Unauthorized />,
  componentProps: {},
};
