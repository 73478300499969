import Helper from "helper/Helper";

export const FETCH_CLIENTS_STARTED = "FETCH_CLIENTS_STARTED";
export const FETCH_CLIENTS_SUCCESS = "FETCH_CLIENTS_SUCCESS";

export const ADD_CLIENT_STARTED = "ADD_CLIENT_STARTED";
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_FAILED = "ADD_CLIENT_FAILED";

export const UPDATE_CLIENT_STARTED = "UPDATE_CLIENT_STARTED";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAILED = "UPDATE_CLIENT_FAILED";

export const DELETE_CLIENT_STARTED = "DELETE_CLIENT_STARTED";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILED = "DELETE_CLIENT_FAILED";

const initialState = {
  data: [],
  loading: true,
};

const ClientsReducer = (state = initialState, action) => {
  var newData = null;
  switch (action.type) {
    case FETCH_CLIENTS_STARTED:
      return { ...state, loading: true };
    case FETCH_CLIENTS_SUCCESS:
      return { ...state, title: "Clients", data: action.data, loading: false };

    case ADD_CLIENT_STARTED:
      return { ...state, loading: true };
    case ADD_CLIENT_SUCCESS:
      newData = Helper.insertItemImmutable(state.data, action.client);
      return { ...state, data: newData, loading: false };
    case ADD_CLIENT_FAILED:
      return { ...state, loading: false };

    case UPDATE_CLIENT_STARTED:
      return { ...state, loading: true };
    case UPDATE_CLIENT_SUCCESS:
      newData = Helper.updateItemImmutable(state.data, action.client);
      return { ...state, data: newData, loading: false };
    case UPDATE_CLIENT_FAILED:
      return { ...state, loading: false };

    case DELETE_CLIENT_STARTED:
      return { ...state, loading: true };
    case DELETE_CLIENT_SUCCESS:
      newData = Helper.removeItemImmutable(state.data, action.clientId);
      return { ...state, data: newData, loading: false };
    case DELETE_CLIENT_FAILED:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export { ClientsReducer };
