import React, { Component } from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import { Colors } from "config/StyleConfig";
import { updateModifiedData, updateCreatedData } from "features/Preferences/PreferencesAction";
import { connect } from "react-redux";
import Helper from "helper/Helper";
import ValidatorArray from "helper/Validator/ValidatorArray";
import ValidatorFieldBuilder, { TYPE } from "helper/Validator/ValidatorFieldBuilder";
import { showInfoDialog } from "components/Dialog/DialogAction";
import MenuItem from "@material-ui/core/MenuItem";

class EditableTreeItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tempData: Helper.deepCopy(this.props.data),
      isBeingEdited: false,
    };

    this.buttonClicked = false;
  }

  editClicked() {
    this.buttonClicked = true;
    this.setState({
      isBeingEdited: true,
      tempData: Helper.deepCopy(this.props.data),
    });
  }

  isNewItem() {
    return this.props.created !== undefined && this.props.created;
  }

  confirmButtonClicked() {
    this.buttonClicked = true;
    // temp data cannot be empty or null.
    if (this.state.tempData !== undefined && this.state.tempData !== "" && this.validateDataCheck()) {
      if (this.isNewItem()) {
        this.props.updateCreatedData(this.state.tempData);
      } else {
        this.props.updateModifiedData(this.state.tempData, this.props.originalData);
      }

      this.setState({ isBeingEdited: false });
    } else {
      this.setState({ isBeingEdited: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (!Helper.areObjectsEqual(prevProps.data, this.props.data)) {
      this.setState({ tempData: Helper.deepCopy(this.props.data) });
    }
  }

  removeClicked() {
    this.buttonClicked = true;
    var modifiedElementData = this.props.data;
    if (modifiedElementData["deleted"] === false || modifiedElementData["deleted"] === undefined) {
      modifiedElementData["deleted"] = true;
    } else {
      modifiedElementData["deleted"] = false;
    }

    if (!this.isNewItem()) {
      this.props.updateModifiedData(modifiedElementData, this.props.originalData);
    } else {
      this.props.updateCreatedData(modifiedElementData);
    }
  }

  validateDataCheck(showDialog = true) {
    var validatorArray = new ValidatorArray();
    var names = [];
    for (var i = 0; i < this.props.fields.length; i++) {
      var field = this.props.fields[i];
      names.push({ title: field.label });
      validatorArray.push(new ValidatorFieldBuilder(this.state.tempData[field.name], field.type).canBeEmpty(false).build());
    }

    const result = validatorArray.validate();
    if (!result && showDialog) {
      this.props.showError("Invalid Input", validatorArray.generateColumnErrorDescription(names));
    }
    return result;
  }

  isDataEmpty() {
    for (var i = 0; i < this.props.fields.length; i++) {
      var field = this.props.fields[i];
      if (this.props.data[field.name] === "" || this.props.data[field.name] === undefined) {
        return true;
      }
    }
    return false;
  }

  handleChange = (event) => {
    var updatedData = this.state.tempData;
    updatedData[event.target.name] = event.target.value;
    if (this.getFieldType(event.target.name) === TYPE.NUMBER) {
      var number = parseInt(event.target.value, 10);
      updatedData[event.target.name] = isNaN(number) ? 0 : number;
    }
    this.setState({ tempData: updatedData });
  };

  getFieldType(fieldName) {
    for (var i = 0; i < this.props.fields.length; i++) {
      var field = this.props.fields[i];
      if (field.name === fieldName) {
        return field.type;
      }
    }

    return undefined;
  }

  cancelButtonClicked() {
    this.buttonClicked = true;
    var obj = { tempData: Helper.deepCopy(this.props.data) };

    if (!this.isDataEmpty()) {
      obj["isBeingEdited"] = false;
    }
    this.setState(obj);
  }

  isBeingEdited() {
    return (this.isNewItem() && this.isDataEmpty()) || this.state.isBeingEdited;
  }

  generateTextFields() {
    var textFields = [];
    if (this.props.fields === undefined) {
      return textFields;
    }

    for (var i = 0; i < this.props.fields.length; i++) {
      var field = this.props.fields[i];

      if (field.options !== undefined) {
        const menuItems = [];
        const fieldsIndex = i;
        field.options.forEach((element, index) => {
          menuItems.push(
            <MenuItem key={fieldsIndex + "" + index} value={element}>
              {element}
            </MenuItem>
          );
        });
        textFields.push(
          <TextField
            select
            key={i}
            size="small"
            style={field.style}
            label={field.label}
            disabled={!this.isBeingEdited()}
            value={this.state.tempData[field.name] || ""}
            onChange={this.handleChange}
            name={field.name}
          >
            {menuItems}
          </TextField>
        );
      } else {
        textFields.push(
          <TextField
            key={i}
            size="small"
            style={field.style}
            label={field.label}
            disabled={!this.isBeingEdited()}
            value={this.state.tempData[field.name] || ""}
            onChange={this.handleChange}
            name={field.name}
          />
        );
      }
    }

    return textFields;
  }

  onLabelClick(event) {
    if (this.buttonClicked || this.state.isBeingEdited) {
      event.preventDefault();
      this.buttonClicked = false;
    }
  }

  render() {
    var cardStyle = {};

    const { modified, created, data, originalData, updateModifiedData, showError, updateCreatedData, ...rest } = this.props;

    const deleted = this.isNewItem() ? false : this.props.data.deleted;
    const beingEdited = this.isBeingEdited();
    if (modified) {
      cardStyle = { backgroundColor: Colors.markedForChange };
    }

    if (deleted) {
      cardStyle = { backgroundColor: Colors.markedForDeletion };
    }

    return (
      <TreeItem
        onLabelClick={(event) => this.onLabelClick(event)}
        style={{ marginBottom: 10, marginTop: 10 }}
        nodeId={this.props.nodeId}
        label={
          <Card style={cardStyle}>
            <CardContent>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", flex: 8 }}>{this.generateTextFields()}</div>
                <div style={{ flex: 1, textAlign: "right" }}>
                  {!beingEdited && (
                    <div>
                      {!deleted && (
                        <IconButton onClick={() => this.editClicked()}>
                          <EditIcon fontSize="default" />
                        </IconButton>
                      )}

                      <IconButton onClick={() => this.removeClicked()}>
                        <DeleteIcon fontSize="default" />
                      </IconButton>
                    </div>
                  )}
                  {beingEdited && (
                    <div>
                      <IconButton onClick={() => this.confirmButtonClicked()}>
                        <CheckIcon fontSize="default" />
                      </IconButton>
                      <IconButton onClick={() => this.cancelButtonClicked()}>
                        <CloseIcon fontSize="default" />
                      </IconButton>
                    </div>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        }
        {...rest}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateModifiedData: (dataElement, originalDataElement) => dispatch(updateModifiedData(dataElement, originalDataElement)),
    updateCreatedData: (dataElement) => dispatch(updateCreatedData(dataElement)),
    showError: (title, description) => dispatch(showInfoDialog(title, description)),
  };
};

export default connect(null, mapDispatchToProps)(EditableTreeItem);
