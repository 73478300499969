import { useQuery } from "@tanstack/react-query";
import Config from "config/Config";
import { fetcher, FetcherJSONResponse } from "services/fetcher";
import { deserializeUser } from "./deserializer";

async function getAllUsers() {
  return fetcher(Config.GET_USERS_API).then((r: FetcherJSONResponse[]) =>
    r.map(deserializeUser)
  );
}

export const useAllUsers = () => {
  return useQuery(["users"], getAllUsers);
};
