import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import EditButton from "components/EditButton";

import GeneralCard from "components/GeneralCard";
import { Strings } from "config/Strings";

class InspectionSummaryCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      originalSummary: this.props.summary,
      tempSummary: this.props.summary,
      isBeingEdited: false,
    };
  }

  onConfirmButtonClicked() {
    this.setState({ isBeingEdited: false });
    if (this.state.originalSummary !== this.state.tempSummary) {
      this.props.summaryUpdatedCallback(this.state.tempSummary);
    }
  }

  onCancelButtonClicked() {
    this.setState({
      isBeingEdited: false,
      tempSummary: this.state.originalSummary,
    });
  }

  onEditButtonClicked() {
    this.setState({ isBeingEdited: true });
  }

  onTextChange = (event) => {
    var summaryText = event.target.value;
    this.setState({ tempSummary: summaryText });
  };

  render() {
    const title = Strings.getInstance().getString("inspection_summary");
    const content = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          verticalAlign: "top",
        }}
      >
        <TextField style={{ flex: 6 }} disabled={!this.state.isBeingEdited} multiline value={this.state.tempSummary} onChange={this.onTextChange} />
        {this.props.editable && (
          <EditButton
            style={{ float: "right", flex: 4 }}
            isEditing={this.state.isBeingEdited}
            onConfirmButtonClicked={() => this.onConfirmButtonClicked()}
            onCancelButtonClicked={() => this.onCancelButtonClicked()}
            onEditButtonClicked={() => this.onEditButtonClicked()}
          />
        )}
      </div>
    );

    return <GeneralCard title={title} content={content} icon={<ChatOutlinedIcon />} />;
  }
}

export default InspectionSummaryCard;
