import theme from './baseui.config';

export default theme;

export { styled } from './baseui.config';
export { useStyletron } from './baseui.config';
export { withStyle } from './baseui.config';
export { stitchesConfig } from './stitches.config';
export { useStitches } from './stitches.config';
export { parseToUtils } from './stitches.config';
