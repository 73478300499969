import Config from "config/Config";
import Helper from "helper/Helper";
import { APIException } from "Exceptions";
import SecureAPIBuilder from "./SecureAPIBuilder";

export default class ProjectAPIController {
  // Get calls.
  static getProject(projectId) {
    const url = Helper.parse(Config.GET_PROJECT_API, projectId);
    return new SecureAPIBuilder(url, SecureAPIBuilder.GET).call().then((response) => {
      if (!response.ok) {
        throw new APIException(response.status, response.statusText);
      }
      return response.json();
    });
  }

  static getObservationByType(projectId, structureId) {
    var query = {};

    if (structureId !== undefined) {
      query.structureId = structureId;
    } else if (projectId !== undefined) {
      query.projectId = projectId;
    }

    return new SecureAPIBuilder(Config.OBSERVATION_BY_TYPE_API, SecureAPIBuilder.GET)
      .query(query)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static getDefectByComponent(projectId, structureId) {
    var query = { type: "STRUCTURAL" };

    if (structureId !== undefined) {
      query.structureId = structureId;
    } else if (projectId !== undefined) {
      query.projectId = projectId;
    }

    return new SecureAPIBuilder(Config.DEFECT_BY_COMPONENT_API, SecureAPIBuilder.GET)
      .query(query)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static getProjects() {
    return new SecureAPIBuilder(Config.GET_PROJECTS_API, SecureAPIBuilder.GET).call().then((response) => {
      if (!response.ok) {
        throw new APIException(response.status, response.statusText);
      }
      return response.json();
    });
  }

  // Insert calls.
  static addProject(projectName, structureIds, projectManagerIds) {
    const currentDateTime = Helper.getCurrentDateTime();
    const body = JSON.stringify({
      name: projectName,
      structures: structureIds,
      projectManagers: projectManagerIds,
      createdAt: currentDateTime,
      updatedAt: currentDateTime,
    });

    return new SecureAPIBuilder(Config.SAVE_PROJECTS_API, SecureAPIBuilder.POST)
      .body(body)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  // Delete calls.
  static deleteProject(projectId) {
    const query = { id: projectId };
    return new SecureAPIBuilder(Config.DELETE_PROJECTS_API, SecureAPIBuilder.DELETE)
      .query(query)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  // Update calls.
  static updateProject(id, projectName, structureIds, projectManagerIds) {
    const currentDateTime = Helper.getCurrentDateTime();
    const body = JSON.stringify({
      id: id,
      name: projectName,
      structures: structureIds,
      projectManagers: projectManagerIds,
      updatedAt: currentDateTime,
    });

    return new SecureAPIBuilder(Config.SAVE_PROJECTS_API, SecureAPIBuilder.POST)
      .body(body)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }
}
