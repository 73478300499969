export const FETCH_ANALYTICS_SGR_HEAT_MAP_TABLE_DATA_STARTED = "FETCH_ANALYTICS_SGR_HEAT_MAP_TABLE_DATA_STARTED";
export const FETCH_ANALYTICS_SGR_HEAT_MAP_TABLE_DATA_SUCCESS = "FETCH_ANALYTICS_SGR_HEAT_MAP_TABLE_DATA_SUCCESS";
export const FETCH_ANALYTICS_SGR_HEAT_MAP_TABLE_DATA_ERROR = "FETCH_ANALYTICS_SGR_HEAT_MAP_TABLE_DATA_ERROR";
export const FETCH_PROJECTS_STARTED = "FETCH_PROJECTS_ANALYTICS_STARTED";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_ANALYTICS_SUCCESS";

const initialState = {
    data: [],
    projectData: [],
    loading: true,
}

  const AnalyticsReducer = (state = initialState, action) => {
    switch(action.type) {
      case FETCH_ANALYTICS_SGR_HEAT_MAP_TABLE_DATA_STARTED:
        return { ...state, loading: true};
      case FETCH_ANALYTICS_SGR_HEAT_MAP_TABLE_DATA_SUCCESS:
        return { ...state, data: action.data };
      case FETCH_ANALYTICS_SGR_HEAT_MAP_TABLE_DATA_ERROR:
        return { ...state, loading: false };

      case FETCH_PROJECTS_STARTED:
        return { ...state, loading: true};
      case FETCH_PROJECTS_SUCCESS:
        return { 
          ...state,
          loading: false,
          projectData: action.projectData, 
        };
      default:
        return state;
    }
  }

  export { AnalyticsReducer }