import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { VisibilityFlag } from '../../config/VisibilityFlag';

import { connect } from 'react-redux';
import {
  watchInspectionsTableData,
  downloadInspectionReport,
  getProjectData,
  getStructureData,
  addInspection,
  updateInspection,
  archiveInspection,
  deleteInspection,
} from './InspectionsAction';
import queryString from 'query-string';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import { watchUsersTableData } from 'features/Users/UsersAction';
import {
  showInfoDialog,
  showConfirmationDialog,
} from 'components/Dialog/DialogAction';
import TopInspectorsCard from './components/TopInspectorsCard';
import { Strings } from 'config/Strings';
import CompletedVsInProgressCard from './components/CompletedVsInProgressCard';
import DefectsVsNSMI from './components/DefectsVsNSMI';
import ComponentsStatistics from './components/ComponentsStatistics';
import Switch from '@material-ui/core/Switch';
import {
  mapStructureOptions,
  mapInspectorsOptions,
  mapProjectId,
  mapInspectorsData,
  mapTitle,
  mapCompletedVsInProgressData,
  mapMultipleRows,
  mapDefectByComponent,
  mapObservationByType,
} from './InspectionsMapper';
import InspectionsTable from './components/InspectionsTable';
import { CloneInspection } from 'features/CloneInspection';
import { AddInpsection, EditInspection } from 'features/InspectionActions';
import { InspectionReport } from './components/InspectionReport';

class Inspections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: true,
      inspectorsOptions: [],
      structureOptions: [],
      viewOnly: true,
      analyticView: false,
    };
  }

  isProjectPage() {
    const values = queryString.parse(this.props.location.search);
    return values.projectId !== undefined;
  }

  updateData = () => {
    const values = queryString.parse(this.props.location.search);

    this.setState({
      viewOnly: this.props.viewOnly || values.projectId === undefined,
    });

    this.props.getStructureData(values.structureId);
    this.props.getProjectData(values.projectId);

    this.props.watchInspectionsTableData(values.projectId, values.structureId);
  };

  downloadInspectionReport = inspectionId => {
    this.props.downloadInspectionReport(inspectionId);
  };

  archiveInspectionCallback = (inspectionId, archived) => {
    this.props.archiveInspection(inspectionId, archived);
  };

  deleteInspectionCallback = inspectionId => {
    const pendingAction = deleteInspection(inspectionId);
    this.props.confirmDeleteInspection(pendingAction);
  };

  addInspectionCallback = (
    projectId,
    structureId,
    spansCount,
    inspectorIds,
    startDate,
  ) => {
    this.props.addInspection(
      projectId,
      structureId,
      spansCount,
      inspectorIds,
      startDate,
    );
  };

  updateInspectionCallback = (
    inspectionId,
    startDate,
    projectId,
    structureId,
    spansCount,
    inspectorIds,
    status,
    termRating,
    generalSummary,
    endDate,
  ) => {
    this.props.updateInspection(
      inspectionId,
      startDate,
      projectId,
      structureId,
      spansCount,
      inspectorIds,
      status,
      termRating,
      generalSummary,
      endDate,
    );
  };

  componentDidUpdate(prevProps) {
    // Check if values needs changing because of search query changes.
    if (this.props.location.search !== prevProps.location.search) {
      this.updateData();
    }
  }

  componentDidMount() {
    this.updateData();
    this.props.getUserOptions();
  }

  toggleAnalytics = () => {
    this.setState({ analyticView: !this.state.analyticView });
  };

  render() {
    const title =
      this.props.title === '' ? (
        <div />
      ) : (
        <div>
          <Typography variant="h5" style={{ marginBottom: '20px' }}>
            {this.props.title}
          </Typography>
          <Divider style={{ marginBottom: '20px' }} />
        </div>
      );

    const isSdChartVisible = VisibilityFlag.getInstance().isVisible(
      'SD_chart_visibile',
    );
    return (
      <div>
        {title}
        {this.isProjectPage() && (
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginBottom: 10,
            }}
          >
            <Typography variant="subtitle1">Insights</Typography>
            <Switch
              checked={this.state.analyticView}
              onChange={this.toggleAnalytics}
              name="Analytic View"
            />
          </div>
        )}
        <Grid
          item
          container
          direction="column"
          spacing={3}
          style={{ width: '100%' }}
        >
          {this.state.analyticView && this.isProjectPage() && (
            <Grid item container direction="row" spacing={3}>
              {isSdChartVisible && (
                <Grid item xs={4}>
                  <DefectsVsNSMI
                    defects={this.props.observationByType.structural}
                    nsmi={this.props.observationByType.maintenance}
                  />
                </Grid>
              )}
              <Grid item xs={isSdChartVisible ? 8 : 12}>
                <ComponentsStatistics data={this.props.defectByComponent} />
              </Grid>
            </Grid>
          )}

          {!this.state.analyticView && (
            <Grid item container direction="row" spacing={3}>
              <Grid item xs={4}>
                <CompletedVsInProgressCard
                  inProgress={
                    this.props.completedVsInProgressData.inProgress || 0
                  }
                  completed={
                    this.props.completedVsInProgressData.completed || 0
                  }
                />
              </Grid>
              <Grid item xs={8}>
                <TopInspectorsCard data={this.props.topInspectorData} />
              </Grid>
            </Grid>
          )}

          <Grid item style={{ width: '100%' }}>
            <InspectionsTable
              viewOnly={this.state.viewOnly}
              data={this.props.data}
              isLoading={this.props.loading}
              updateCallback={this.updateData}
              updateInspectionCallback={this.updateInspectionCallback}
              addInspectionCallback={this.addInspectionCallback}
              downloadInspectionCallback={this.downloadInspectionReport}
              deleteInspectionCallback={this.deleteInspectionCallback}
              archiveInspectionCallback={this.archiveInspectionCallback}
              addCallback={() => {
                const params = new URLSearchParams(this.props.location.search);
                params.set('action', 'add');
                this.props.history.replace(
                  `${this.props.location.pathname}?${params.toString()}`,
                );
              }}
              structureOptions={this.props.structureOptions}
              inspectorsOptions={this.props.inspectorsOptions}
              projectId={this.props.projectId}
              showError={this.props.showError}
            />
          </Grid>
        </Grid>
        <CloneInspection />
        <AddInpsection />
        <EditInspection />
        <InspectionReport />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: mapMultipleRows(state),
    topInspectorData: mapInspectorsData(state),
    completedVsInProgressData: mapCompletedVsInProgressData(state),
    loading: state.InspectionsReducer.loading,
    title: mapTitle(state),
    inspectorsOptions: mapInspectorsOptions(state),
    structureOptions: mapStructureOptions(state),
    projectId: mapProjectId(state),
    defectByComponent: mapDefectByComponent(state),
    observationByType: mapObservationByType(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    watchInspectionsTableData: (projectId, structureId) =>
      dispatch(watchInspectionsTableData(projectId, structureId)),
    downloadInspectionReport: inspectionID =>
      dispatch(downloadInspectionReport(inspectionID)),
    getStructureData: structureId => dispatch(getStructureData(structureId)),
    getProjectData: projectId => dispatch(getProjectData(projectId)),
    getUserOptions: () => dispatch(watchUsersTableData()),
    addInspection: (
      projectId,
      structureId,
      spansCount,
      inspectorIds,
      startDate,
    ) =>
      dispatch(
        addInspection(
          projectId,
          structureId,
          spansCount,
          inspectorIds,
          startDate,
        ),
      ),
    updateInspection: (
      inspectionId,
      startDate,
      projectId,
      structureId,
      spansCount,
      inspectorIds,
      status,
      termRating,
      generalSummary,
      endDate,
    ) =>
      dispatch(
        updateInspection(
          inspectionId,
          startDate,
          projectId,
          structureId,
          spansCount,
          inspectorIds,
          status,
          termRating,
          generalSummary,
          endDate,
        ),
      ),
    archiveInspection: (inspectionId, archived) =>
      dispatch(archiveInspection(inspectionId, archived)),

    showError: (title, description) =>
      dispatch(showInfoDialog(title, description)),
    confirmDeleteInspection: pendingAction =>
      dispatch(
        showConfirmationDialog(
          Strings.getInstance().getString('dialog_delete_inspection_title'),
          Strings.getInstance().getString(
            'dialog_delete_inspection_description',
          ),
          pendingAction,
        ),
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inspections);
