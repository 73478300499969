import {
  FETCH_SUB_COMPONENTS_OF_STRUCTURE_FAILED,
  FETCH_SUB_COMPONENTS_OF_STRUCTURE_STARTED,
  FETCH_SUB_COMPONENTS_OF_STRUCTURE_SUCCESS,
  FETCH_PREFERENCES_SUB_COMPONENT_PICKER_SUCCESS,
  SET_SUB_COMPONENT_PICKER_VISIBILITY_SUCCESS,
  SAVE_NEW_TEMPLATE_FAILED,
  SAVE_NEW_TEMPLATE_STARTED,
  SAVE_NEW_TEMPLATE_SUCCESS,
} from "./SubComponentPickerAction";

const initialState = {
  subComponentsOfStructure: [],
  components: [],
  loading: true,
  subComponentPickerVisibility: false,
  error: "",
};

const SubComponentPickerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUB_COMPONENTS_OF_STRUCTURE_STARTED:
    case SAVE_NEW_TEMPLATE_STARTED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SUB_COMPONENTS_OF_STRUCTURE_FAILED:
    case SAVE_NEW_TEMPLATE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_PREFERENCES_SUB_COMPONENT_PICKER_SUCCESS:
      return {
        ...state,
        components: action.data,
      };
    case FETCH_SUB_COMPONENTS_OF_STRUCTURE_SUCCESS:
      return {
        ...state,
        subComponentsOfStructure: action.data,
        loading: false,
      };
    case SET_SUB_COMPONENT_PICKER_VISIBILITY_SUCCESS:
      return {
        ...state,
        subComponentPickerVisibility: action.visibility,
      };
    case SAVE_NEW_TEMPLATE_SUCCESS:
      return { ...state, loading: false, subComponentPickerVisibility: false };
    default:
      return state;
  }
};

export { SubComponentPickerReducer };
