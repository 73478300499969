import React from 'react';
import { Tag, KIND, VARIANT, SIZE } from 'baseui/tag';
import { useStitches } from 'theme';
import { Strings } from 'config/Strings';
import { Colors } from 'config/StyleConfig';
import Text from 'components/Text';

const hexToRGB = (value: string) => {
  const aRgbHex = value.match(/.{1,2}/g);
  return {
    r: aRgbHex ? parseInt(aRgbHex[0], 16) : 0,
    g: aRgbHex ? parseInt(aRgbHex[1], 16) : 0,
    b: aRgbHex ? parseInt(aRgbHex[2], 16) : 0,
  };
};

const getHeatLevel = (rating: number) => {
  if (rating > 80) {
    //1
    return Colors.heat1;
  } else if (rating > 60) {
    //2
    return Colors.heat2;
  } else if (rating > 40) {
    //3
    return Colors.heat3;
  } else if (rating > 20) {
    //4
    return Colors.heat4;
  } else if (rating < 20) {
    //5
    return Colors.heat5;
  } else {
    return 'unset';
  }
};

type SGRTagProps = {
  rating: number;
};

export default function SGRBadge(props: SGRTagProps) {
  const { rating } = props;
  const { theme } = useStitches();
  const parsedRating = parseFloat(rating.toString());

  if (isNaN(parsedRating)) {
    return <Text css={{ textAlign: 'center' }}>---</Text>;
  }

  const label = Strings.getInstance().mapSGR(parsedRating);
  const heatColor = getHeatLevel(parsedRating);
  const heatToRGB = hexToRGB(heatColor.split('#')[1]);
  const brightness = Math.round(
    (heatToRGB.r * 299 + heatToRGB.r * 587 + heatToRGB.r * 114) / 1000,
  );

  return (
    <Tag
      kind={KIND.custom}
      color={`${heatColor}`}
      variant={VARIANT.solid}
      size={SIZE.small}
      overrides={{
        Text: {
          style: {
            minWidth: theme.sizing.scale950,
            textAlign: 'center',
            color: brightness > 125 ? 'black' : 'white',
          },
        },
      }}
      closeable={false}
    >
      {label}%
    </Tag>
  );
}
