// Maps data coming from Store to UI and from UI to API

import {
  FIELD_ID,
  FIELD_STRUCTURE_NAME,
  FIELD_COMPONENT_NAME,
  FIELD_SUB_COMPONENT_NAME,
  FIELD_INSPECTION_DATE,
  FIELD_CONDITION_RATING,
  FIELD_CONDITION_RATING_SCALE,
} from ".";

import { createSelector } from "reselect";

const getDefects = (state) => state.DefectsReducer.data;

export const mapMultipleRows = createSelector([getDefects], (response) => {
  return response.map((x) => mapSingleRow(x));
});

const mapSingleRow = (singleResponse) => {
  var mapResult = {};
  mapResult[FIELD_ID] = singleResponse["id"];
  mapResult[FIELD_STRUCTURE_NAME] = singleResponse["structureName"];
  mapResult[FIELD_COMPONENT_NAME] = singleResponse["componentName"];
  mapResult[FIELD_SUB_COMPONENT_NAME] = singleResponse["subcomponentName"];
  mapResult[FIELD_INSPECTION_DATE] = singleResponse["inspectionDate"];
  mapResult[FIELD_CONDITION_RATING] =
    singleResponse["subcomponentHealthIndex"] === undefined ? "NA" : singleResponse["subcomponentHealthIndex"].toFixed(2);
  mapResult[FIELD_CONDITION_RATING_SCALE] = "HI";
  return mapResult;
};
