import Navigator from 'Navigator';
import { Spinner } from 'baseui/spinner';
import React, { useEffect } from 'react';
import TokenManager from 'storage/TokenManager';
import { useStitches } from 'theme';

export default function Logout() {
  const { css } = useStitches();
  useEffect(() => {
    TokenManager.clearToken();
    Navigator.navigateToLogin();
  }, []);

  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '$full',
        height: '$full',
      })}
    >
      <Spinner />
    </div>
  );
}
