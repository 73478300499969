export const TYPE = {
  EMAIL: "email",
  TEXT: "text",
  NUMBER: "number",
  ANY: "any",
  LOOKUP: "lookup",
};

class ValidatorField {
  constructor(value, type, canBeEmpty, options, message) {
    this.value = value;
    this.type = type;
    this.canBeEmpty = canBeEmpty !== undefined ? canBeEmpty : true;
    this.options = options || [];
    this.message = message;
  }

  validate() {
    const emptyCheck = this.checkEmpty(this.canBeEmpty, this.value);
    switch (this.type) {
      case TYPE.ANY:
        return emptyCheck ? "" : "Cannot be empty";
      case TYPE.TEXT:
        return emptyCheck && this.validateText(this.value) ? "" : "Not a valid text";
      case TYPE.NUMBER:
        return emptyCheck && this.validateNumber(this.value) ? "" : "Not a valid number";
      case TYPE.EMAIL:
        return emptyCheck && this.validateEmail(this.value) ? "" : "Not a valid email";
      case TYPE.LOOKUP:
        return emptyCheck && this.validateLookup(this.value, this.options) ? "" : "One or more values are invalid";
      case TYPE.CONDITION:
        return this.value ? "" : this.message || "Condition not met";
      default:
        return false;
    }
  }

  validateText(str) {
    const regex = /^[A-Za-z\s]+$/;
    return str === undefined || regex.test(str);
  }

  validateEmail(str) {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/;
    return str === undefined || regex.test(str);
  }

  validateNumber(str) {
    const regex = /^\d+$/;
    return str === undefined || regex.test(str);
  }

  validateLookup(input, options) {
    const ids = options.map((x) => x["id"]);
    return input === undefined || input.every((val) => ids.includes("" + val["id"]));
  }

  isItemEmpty(input) {
    return input === null || input === "" || input === undefined || input.length === 0;
  }

  checkEmpty(emptyAllowed, input) {
    var itemEmpty = this.isItemEmpty(input);

    if (!emptyAllowed && itemEmpty) {
      return false;
    }

    if (input !== undefined && Array.isArray(input)) {
      for (var i = 0; i < input.length; i++) {
        if (!emptyAllowed && this.isItemEmpty(input[i])) {
          return false;
        }
      }
    }

    return true;
  }
}

export default class ValidatorFieldBuilder {
  constructor(value, type) {
    this.value = value;
    this.type = type;
    this.emptyAllowed = true;
    this.message = undefined;
  }

  canBeEmpty(empty) {
    this.emptyAllowed = empty;
    return this;
  }

  errorMessage(message) {
    this.message = message;
    return this;
  }

  lookupOptions(options) {
    this.options = options;
    return this;
  }

  build() {
    return new ValidatorField(this.value, this.type, this.emptyAllowed, this.options, this.message);
  }
}
