import Cookie from "js-cookie";
const TOKEN_KEY = "token";

/*
Token Manager is responsible for storing and retrieving the necessary data from the Token. Whenever an authentication is done, a JWT token is returned from backend along with information such as name of the user, company, along other user related data. In this case, a Cookie is used to store the data.
*/

export default class TokenManager {
  static DEFAULT = -1;
  static ADMIN = 0;
  static INSPECTOR = 1;
  static CLIENT = 2;
  static ALL = [this.ADMIN, this.INSPECTOR, this.CLIENT, this.DEFAULT];

  /*
 * Checks if the user is authenticated or not.
 *
 * @return {boolean} - if user is authenticated or not.

  */
  static isAuthenticated() {
    return Cookie.get(TOKEN_KEY) != null;
  }

  /*
 * Gets the token and all information with it.
 *
 * @return {object} - the json object stored in the Cookie.

  */
  static getToken() {
    return !this.isAuthenticated() ? {} : JSON.parse(Cookie.get(TOKEN_KEY));
  }

  /*
   * Clears all token related information from storage.
   */
  static clearToken() {
    Cookie.remove(TOKEN_KEY);
  }

  /*
   * Gets the first name and last name of the authenticated user.
   *
   * @return {string} - the first name and last name of the authenticated user.
   */
  static getUsername() {
    let inspectorInfo = JSON.parse(Cookie.get(TOKEN_KEY))["inspector"];
    return inspectorInfo["firstName"] + " " + inspectorInfo["lastName"];
  }

  static getUserID() {
    let inspectorInfo = JSON.parse(Cookie.get(TOKEN_KEY))["inspector"];
    return inspectorInfo["id"];
  }

  /*
   * Saves the token and all information with it inside a Cookie.
   *
   * @param {object} - the json object to be stored in the Cookie.
   */
  static setToken(token) {
    Cookie.set(TOKEN_KEY, token);
  }

  /*
   * Gets the Company Id of the authenticated user.
   *
   * @return {string} - the Company ID of the authenticated user.
   */
  static getCompanyId() {
    if (!this.isAuthenticated()) {
      return -1;
    }
    let inspectorInfo = JSON.parse(Cookie.get(TOKEN_KEY))["inspector"];
    return inspectorInfo["company"]["id"];
  }

  /*
   * Gets the Company name of the authenticated user.
   *
   * @return {string} - the Company name of the authenticated user.
   */
  static getCompanyName() {
    if (!this.isAuthenticated()) {
      return "";
    }
    let inspectorInfo = JSON.parse(Cookie.get(TOKEN_KEY))["inspector"];
    return inspectorInfo["company"]["name"];
  }

    /*
   * Gets the Company logo of the authenticated user.
   *
   * @return {string} - the Company logo of the authenticated user.
   */
  static getCompanyLogo() {
    if (!this.isAuthenticated()) {
      return "";
    }
    let inspectorInfo = JSON.parse(Cookie.get(TOKEN_KEY))["inspector"];
    return inspectorInfo["company"]["logo"]
  }

  /*
   * Gets the structure types associated with the company of the authenticated user.
   *
   * @return {string[]} - the structure types associated with the company of the authenticated user.
   */
  static getStructureTypes() {
    if (!this.isAuthenticated()) {
      return [];
    }

    let inspectorInfo = JSON.parse(Cookie.get(TOKEN_KEY))["inspector"];
    return inspectorInfo["company"]["structureTypes"];
  }

  /*
   * Gets the type of the authenticated user.
   *
   * @return {string} - the type of the authenticated user. It could be ADMIN, INSPECTOR or CLIENT. Each type gives the user access to a certain number of actions and visuals.
   */
  static getUserType() {
    if (!this.isAuthenticated()) {
      return this.DEFAULT;
    }

    let inspectorInfo = JSON.parse(Cookie.get(TOKEN_KEY))["inspector"];
    if (inspectorInfo["company"]["type"] === "OWNER") {
      return this.CLIENT;
    } else if (inspectorInfo["company"]["type"] === "INSPECTION") {
      return inspectorInfo["admin"] ? this.ADMIN : this.INSPECTOR;
    }
  }

  /*
   * Checks if the user is of admin type or not.
   *
   * @return {bool} - if the user is an admin, return true otherwise false.
   */
  static isAdmin() {
    return this.getUserType() === this.ADMIN;
  }

  /*
   * Checks if the user is of inspector type or not.
   *
   * @return {bool} - if the user is an inspector, return true otherwise false.
   */
  static isInspector() {
    return this.getUserType() === this.INSPECTOR;
  }

  /*
   * Checks if the user is of client type or not.
   *
   * @return {bool} - if the user is a client, return true otherwise false.
   */
  static isClient() {
    return this.getUserType() === this.CLIENT;
  }

  /*
   * Checks if the company of the user has access to Bridges.
   *
   * @return {bool} - if the company has access to bridges, return true otherwise false.
   */
  static hasBridges() {
    for (let i = 0; i < this.getStructureTypes().length; i++) {
      const element = this.getStructureTypes()[i];
      if (element["id"] === "BRIDGES_AND_AERIAL_STRUCTURE") {
        return true;
      }
    }
    return false;
  }
}
