import React, { Component } from "react";
import SelectableTreeItem from "./SelectableTreeItem";
import { Colors } from "config/StyleConfig";
import { TextField } from "@material-ui/core";

export default class SelectableSubComponentTreeItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimension: this.props.dimension,
    };
  }

  handleChange = (event) => {
    this.props.onDimensionChanged(this.props.id, event.target.value);
    this.setState({ dimension: event.target.value });
  };

  render() {
    const { name, id, dimension, selected, onSelected, size, ...rest } = this.props;

    const sizeField = (
      <div>
        <TextField size="small" label={"Dimension/Quantity"} disabled={!selected} value={this.state.dimension} onChange={this.handleChange} />
      </div>
    );

    return (
      <SelectableTreeItem label={"Sub-component"} name={name} id={id} selected={selected} onSelected={onSelected} content={sizeField} {...rest} />
    );
  }
}
