// Used to bypass CORS on localhost.
import { VERSION } from './Version';

const LOCAL = 'http://127.0.0.1:8080/';
const STAGING = 'http://35.223.226.173/';
const DEVELOPMENT = 'http://34.70.68.149/';
const PRODUCTION = 'https://portal.datarecon.com/';

const PROXY_URL = '';
const ORIGINAL_SERVER_API = process.env.REACT_APP_API_URL;
const MODIFIED_SERVER_API = PROXY_URL + ORIGINAL_SERVER_API;
const INTERNAL_VERSION = VERSION;

const Config = {
  // General related.
  VERSION:
    ORIGINAL_SERVER_API == DEVELOPMENT
      ? 'DEBUG ' + INTERNAL_VERSION
      : INTERNAL_VERSION,
  API: MODIFIED_SERVER_API,
  PROXY: PROXY_URL,
  DEFAULT_PAGE: '/inspections',

  // Authentication related.
  AUTHENTICATION_API: MODIFIED_SERVER_API + 'api/v1/auth/',

  // Inspection related.
  INSPECTION_API: MODIFIED_SERVER_API + 'api/v2/inspection/',
  GET_INSPECTION_API: MODIFIED_SERVER_API + 'api/v2/inspection/%s',
  POST_INSPECTION_API: MODIFIED_SERVER_API + 'api/v2/inspection/',
  CLONE_INSPECTION_API: MODIFIED_SERVER_API + 'api/v2/inspection/clone',
  UPLOAD_OPORD: MODIFIED_SERVER_API + 'api/v2/inspection/%s/opOrd',
  INSPECTION_REPORT_API:
    MODIFIED_SERVER_API + 'api/v1/report/inspection/%s/report',
  DEFECT_BY_COMPONENT_API:
    MODIFIED_SERVER_API + 'api/v1/report/components-usage',
  OBSERVATION_BY_TYPE_API:
    MODIFIED_SERVER_API + 'api/v1/report/component-types-usage',
  ASSIGN_INSPECTOR_TO_INSPECTION_API:
    MODIFIED_SERVER_API + 'api/v1/inspection/inspectors',
  DELETE_INSPECTION_API: MODIFIED_SERVER_API + 'api/v1/inspection/%s',
  ARCHIVE_INSPECTION_API: MODIFIED_SERVER_API + 'api/v2/inspection/archive',
  UPDATE_INSPECTION_SUMMARY_API:
    MODIFIED_SERVER_API + 'api/v2/inspection/summary',
  DOWNLOAD_INSPECTION_PHOTOS_API:
    MODIFIED_SERVER_API + 'api/v2/downloadInspectionPhotosZip?inspectionId=%s',

  // Observation related.
  OBSERVATION_API: MODIFIED_SERVER_API + 'api/v2/inspection/', // same one as inspection as observation is included in the response.
  UPDATE_OBSERVATION_API:
    MODIFIED_SERVER_API +
    'api/v1/inspection/%s/observation/%s/observationDefect',
  DELETE_OBSERVATION_API:
    MODIFIED_SERVER_API +
    'api/v1/inspection/%s/observation/%s/observationDefect/%s',
  UPDATE_OBSERVATION_INSPECTED_API:
    MODIFIED_SERVER_API + 'api/v1/inspection/%s/observation/inspect',

  // Structure related.
  GET_STRUCTURES_API: MODIFIED_SERVER_API + 'api/v1/admin/structures',
  GET_STRUCTURE_API: MODIFIED_SERVER_API + 'api/v1/admin/structures/%s',
  DELETE_STRUCTURE_API: MODIFIED_SERVER_API + 'api/v1/admin/structures/%s',
  POST_STRUCTURE_API: MODIFIED_SERVER_API + 'api/v1/admin/structures',
  UPDATE_STRUCTURE_API: MODIFIED_SERVER_API + 'api/v1/admin/structures/%s',

  UPDATE_LOCATION_API: MODIFIED_SERVER_API + 'api/v1/inspection/%s/observation',
  DELETE_LOCATION_API:
    MODIFIED_SERVER_API + 'api/v1/inspection/%s/observation/%s',
  UPDATE_COMP_SUB_OF_OBSERVATION_API:
    MODIFIED_SERVER_API + 'api/v1/observation-defect/%s/new-observation/%s',

  SAVE_DICTIONARY: MODIFIED_SERVER_API + 'api/v1/dictionaries/save',
  GET_COMPONENTS_DICTIONARY: MODIFIED_SERVER_API + 'api/v1/dictionaries/',
  GET_COMPLETE_DICTIONARY: MODIFIED_SERVER_API + 'api/v1/structures/',

  // Project related.
  GET_PROJECTS_API: MODIFIED_SERVER_API + 'api/v1/projects',
  GET_PROJECT_API: MODIFIED_SERVER_API + 'api/v1/projects/%s',
  DELETE_PROJECTS_API: MODIFIED_SERVER_API + 'api/v1/projects/delete',
  SAVE_PROJECTS_API: MODIFIED_SERVER_API + 'api/v1/projects/save',

  // User related.
  GET_USERS_API: MODIFIED_SERVER_API + 'api/v1/admin/users',
  POST_USER_API: MODIFIED_SERVER_API + 'api/v1/admin/users',
  DELETE_USER_API: MODIFIED_SERVER_API + 'api/v1/admin/users/%s',
  UPDATE_USER_API: MODIFIED_SERVER_API + 'api/v1/admin/users/%s',

  // Client related.
  GET_CLIENTS_API: MODIFIED_SERVER_API + 'api/v1/companies',
  DELETE_CLIENT_API: MODIFIED_SERVER_API + 'api/v1/companies/delete',
  SAVE_CLIENT_API: MODIFIED_SERVER_API + 'api/v1/companies/save',
  GET_CLIENT_API: MODIFIED_SERVER_API + 'api/v1/companies/%s',

  // Image related.
  UPDATE_IMAGE_RECTANGLE:
    MODIFIED_SERVER_API +
    'api/v1/inspection/%s/observation/%s/observationDefect/%s/photo/%s',
  DELETE_IMAGE_API:
    MODIFIED_SERVER_API +
    'api/v1/inspection/%s/observation/%s/observationDefect/%s/photo/%s',
  UPLOAD_IMAGE_API:
    ORIGINAL_SERVER_API +
    'api/v1/inspection/%s/observation/%s/observationDefect/%s/photo',

  // Analytics related.
  GET_DEFECTS_CLIENT_API:
    MODIFIED_SERVER_API + 'api/v1/report/defects/company/%s',
  GET_DEFECTS_STRUCTURE_API:
    MODIFIED_SERVER_API + 'api/v1/report/defects/structure/%s',
  GET_SUB_COMPONENTS_HI_API:
    MODIFIED_SERVER_API + 'api/v1/report/subcomponents/company/%s',
  GET_SUB_COMPONENTS_HI_BY_STRUCTURE_API:
    MODIFIED_SERVER_API + 'api/v1/report/subcomponents/structure/%s',

  // Preferences related.
  SAVE_DICTIONARY: MODIFIED_SERVER_API + 'api/v1/dictionaries/save',
  GET_COMPONENTS_DICTIONARY: MODIFIED_SERVER_API + 'api/v1/dictionaries/',
  GET_COMPLETE_DICTIONARY: MODIFIED_SERVER_API + 'api/v1/structures/',

  // Template related.
  GET_SUB_COMPONENTS_OF_STRUCTURE:
    MODIFIED_SERVER_API + 'api/v1/templates/subcomponent-and-structures',
  SAVE_SUB_COMPONENTS_OF_STRUCTURE:
    MODIFIED_SERVER_API + 'api/v1/templates/subcomponent-and-structures',
  GET_SUB_COMPONENTS_OF_INSPECTION:
    MODIFIED_SERVER_API + 'api/v1/templates/subcomponent-inspections',
  SAVE_SUB_COMPONENTS_OF_INSPECTION:
    MODIFIED_SERVER_API + 'api/v1/templates/subcomponent-inspections',
};

export default Config;
