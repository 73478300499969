import { DIALOG_BOX_TYPE } from "./DialogBox"

export const SHOW_INFO_DIALOG = "SHOW_INFO_DIALOG";
export const SHOW_CONFIRMATION_DIALOG = "SHOW_CONFIRMATION_DIALOG";
export const DISMISS_DIALOG = "DISMISS_DIALOG";

const initialState = {
    open: false,
    title: '',
    description: '',
    type: -1,
    pendingAction: null,
}
  
const DialogReducer = (state = initialState, action) => {
  switch(action.type) {
    case SHOW_INFO_DIALOG:
      return { ...state, open: true, title: action.title, description: action.description, dialogType: DIALOG_BOX_TYPE.DISMISSIBLE_DIALOG };
    case SHOW_CONFIRMATION_DIALOG:
        return { ...state, open: true, title: action.title, description: action.description, 
          dialogType: DIALOG_BOX_TYPE.ACCEPT_CANCEL_DIALOG, pendingAction: action.pendingAction };
    case DISMISS_DIALOG:
      return { ...state, open: false };
    default:
      return state;
  }
}
  
export { DialogReducer }