import StructureAPIController from "services/api/StructureAPIController";
import ClientAPIController from "services/api/ClientAPIController";
import InspectionAPIController from "services/api/InspectionAPIController";
import ProjectAPIController from "services/api/ProjectAPIController";

export const FETCH_DATA_STARTED = "FETCH_DATA_STARTED";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILED = "FETCH_DATA_FAILED";

export const FETCH_INSPECTIONS_SUCCESS = "FETCH_INSPECTIONS_SUCCESS";
export const FETCH_LAST_INSPECTION_SUCCESS = "FETCH_LAST_INSPECTION_SUCCESS";
export const FETCH_STRUCTURE_SUCCESS = "FETCH_STRUCTURE_SUCCESS";
export const FETCH_CLIENT_SUCCESS = "FETCH_CLIENT_SUCCESS";
export const FETCH_SUB_COMPONENT_HEALTH_INDEX_SUCCESS = "FETCH_SUB_COMPONENT_HEALTH_INDEX_SUCCESS";

export const FETCH_DEFECT_BY_COMPONENT_SUCCESS = "FETCH_DEFECT_BY_COMPONENT_SUCCESS";
export const FETCH_OBSERVATION_BY_TYPE_SUCCESS = "FETCH_OBSERVATION_BY_TYPE_SUCCESS";

export const fetchSubComponentHealthIndexSuccess = (data) => {
  return {
    type: FETCH_SUB_COMPONENT_HEALTH_INDEX_SUCCESS,
    data: data,
  };
};

export const fetchDataStarted = () => {
  return {
    type: FETCH_DATA_STARTED,
  };
};

export const fetchDataSuccess = (data) => {
  return {
    type: FETCH_DATA_SUCCESS,
    data: data,
  };
};

export const fetchDataFailed = (error) => {
  return {
    type: FETCH_DATA_FAILED,
    error: error,
  };
};

export const fetchInspectionsSuccess = (data) => {
  return {
    type: FETCH_INSPECTIONS_SUCCESS,
    data: data,
  };
};

export const fetchLastInspectionSuccess = (data) => {
  return {
    type: FETCH_LAST_INSPECTION_SUCCESS,
    data: data,
  };
};

export const fetchStructureSuccess = (data) => {
  return {
    type: FETCH_STRUCTURE_SUCCESS,
    data: data,
  };
};

export const fetchClientSuccess = (data) => {
  return {
    type: FETCH_CLIENT_SUCCESS,
    data: data,
  };
};

export const fetchObservationByTypeSuccess = (data) => {
  return {
    type: FETCH_OBSERVATION_BY_TYPE_SUCCESS,
    data: data,
  };
};

export const fetchDefectByComponentSuccess = (data) => {
  return {
    type: FETCH_DEFECT_BY_COMPONENT_SUCCESS,
    data: data,
  };
};

const getLatestInspection = (inspections) => {
  if (inspections.length == 0 || inspections === undefined) {
    return undefined;
  }

  let latestInspection = inspections[0];
  for (let i = 0; i < inspections.length; i++) {
    const inspection = inspections[i];
    if (inspection["startDate"] > latestInspection["startDate"]) {
      latestInspection = inspection;
    }
  }

  return latestInspection;
};

const getStructureData = (structureId) => {
  return function (dispatch) {
    dispatch(fetchDataStarted());

    StructureAPIController.getStructure(structureId)
      .then((response) => {
        dispatch(fetchStructureSuccess(response));
        ClientAPIController.getClient(response["clientId"])
          .then((response) => {
            dispatch(fetchClientSuccess(response));
          })
          .catch((error) => {
            console.log(error);
            dispatch(fetchDataFailed(error));
          });
      })

      .then(() => {
        ProjectAPIController.getObservationByType(undefined, structureId)
        .then((response) => {
          dispatch(fetchObservationByTypeSuccess(response));
        })
        .then(
          ProjectAPIController.getDefectByComponent(undefined, structureId).then((response) => {
            dispatch(fetchDefectByComponentSuccess(response));
          })
        )
        .catch((error) => {
          console.log(error);    
          dispatch(fetchDataFailed(error));
        });
      })

      .then(() => {
        InspectionAPIController.getInspections(undefined, structureId)
          .then((response) => {
            dispatch(fetchInspectionsSuccess(response));
            // Get the latest inspection.
            const latestInspection = getLatestInspection(response);
            if (latestInspection !== undefined) {
              dispatch(fetchLastInspectionSuccess(latestInspection));
              StructureAPIController.getComponentsHIbyStructureId(structureId)
                .then((response) => {
                  let latestSubComponentsHI = [];
                  for (let index = 0; index < response.length; index++) {
                    const element = response[index];
                    if (element["inspectionId"] === latestInspection["uuid"]) {
                      latestSubComponentsHI.push(element);
                    }
                  }
                  dispatch(fetchSubComponentHealthIndexSuccess(latestSubComponentsHI));
                  dispatch(fetchDataSuccess());
                })
                .catch((error) => {
                  console.log(error);
                  dispatch(fetchDataFailed(error));
                });
            } else {
              dispatch(fetchDataSuccess());
            }
          })

          .catch((error) => {
            console.log(error);
            dispatch(fetchDataFailed(error));
          });
      })
      
      .catch((error) => {
        console.log(error);
        dispatch(fetchDataFailed(error));
      });
  };
};

export { getStructureData };
