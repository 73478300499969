import Config from 'config/Config';
import Helper from 'helper/Helper';
import { APIException } from 'Exceptions';
import SecureAPIBuilder from './SecureAPIBuilder';

export default class StructureAPIController {
  // Get calls.
  static getStructure(structureId) {
    return new SecureAPIBuilder(
      Helper.parse(Config.GET_STRUCTURE_API, structureId),
      SecureAPIBuilder.GET,
    )
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static getStructures(companyId, force = false) {
    // Check if structures are saved in localStorage and the saved timestamp is less than 2 hours
    const savedStructuresSE = localStorage.getItem(
      'structures_SE_' + companyId,
    );
    const savedTimestampSE = localStorage.getItem(
      'structures_timestamp_SE_' + companyId,
    );
    if (!force && savedStructuresSE && savedTimestampSE) {
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - savedTimestampSE;
      // 1 (hours) * 60 (minutes) * 60 (seconds) * 1000 (milliseconds) = 1 hour
      const timeToRefresh = 1 * 60 * 60 * 1000;
      if (timeDifference < timeToRefresh) {
        return Promise.resolve(JSON.parse(savedStructuresSE));
      }
    }

    let getStructuresAPICall = new SecureAPIBuilder(
      Config.GET_STRUCTURES_API,
      SecureAPIBuilder.GET,
    );
    if (companyId !== undefined) {
      getStructuresAPICall = getStructuresAPICall.query({
        companyId: companyId,
      });
    }

    return getStructuresAPICall
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      })
      .then((jsonResponse) => {
        // Save the jsonResponse in localStorage along with the current timestamp
        localStorage.setItem(
          'structures_SE_' + companyId,
          JSON.stringify(jsonResponse),
        );
        localStorage.setItem(
          'structures_timestamp_SE_' + companyId,
          new Date().getTime(),
        );
        return jsonResponse;
      });
  }

  static getComponentsHI(clientId) {
    return new SecureAPIBuilder(
      Helper.parse(Config.GET_SUB_COMPONENTS_HI_API, clientId),
      SecureAPIBuilder.GET,
    )
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  static getComponentsHIbyStructureId(structureId) {
    return new SecureAPIBuilder(
      Helper.parse(Config.GET_SUB_COMPONENTS_HI_BY_STRUCTURE_API, structureId),
      SecureAPIBuilder.GET,
    )
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  // Insert calls.
  static addStructure(
    code,
    name,
    type,
    caltransBridgeNo,
    postmile,
    beginStationing,
    endStationing,
    clientId,
  ) {
    const body = JSON.stringify({
      code: code,
      name: name,
      type: type,
      clientId: clientId,
      caltransBridgeNo: caltransBridgeNo,
      postmile: postmile,
      beginStationing: beginStationing,
      endStationing: endStationing,
    });

    return new SecureAPIBuilder(
      Config.POST_STRUCTURE_API,
      SecureAPIBuilder.POST,
    )
      .body(body)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }

  // Delete calls.
  static deleteStructure(structureId) {
    const url = Helper.parse(Config.DELETE_STRUCTURE_API, structureId);
    return new SecureAPIBuilder(url, SecureAPIBuilder.DELETE)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response;
      });
  }

  // Update calls.
  static updateStructure(
    id,
    code,
    name,
    type,
    caltransBridgeNo,
    postmile,
    beginStationing,
    endStationing,
    clientId,
  ) {
    const body = JSON.stringify({
      id: id,
      code: code,
      name: name,
      type: type,
      caltransBridgeNo: caltransBridgeNo,
      postmile: postmile,
      beginStationing: beginStationing,
      endStationing: endStationing,
      clientId: clientId,
    });

    const url = Helper.parse(Config.UPDATE_STRUCTURE_API, id);

    return new SecureAPIBuilder(url, SecureAPIBuilder.PUT)
      .body(body)
      .call()
      .then((response) => {
        if (!response.ok) {
          throw new APIException(response.status, response.statusText);
        }
        return response.json();
      });
  }
}
