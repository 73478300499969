import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class LoadingScreen extends Component {
  render() {
    return (
      <div style={styles}>
        <CircularProgress style={{ marginBottom: 25 }} />
        {this.props.message}
      </div>
    );
  }
}

LoadingScreen.defaultProps = {
  message: "Loading your data. Please wait...",
};

const styles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "50vh",
};
