/* eslint-disable react/require-default-props */
import { ComponentProps } from 'react';

import { stitchesConfig } from 'theme';

const { styled } = stitchesConfig;

const StyledBox = styled('div', {});

type OwnProps = {
  as?: keyof JSX.IntrinsicElements;
};

export type BoxProps = Omit<ComponentProps<typeof StyledBox>, keyof OwnProps> &
  OwnProps;

export default StyledBox;
