import Helper from "helper/Helper";

import {
  DOWNLOAD_INSPECTION_STARTED,
  DOWNLOAD_INSPECTION_SUCCESS,
  DOWNLOAD_IMAGES_STARTED,
  DOWNLOAD_IMAGES_SUCCESS,
  FETCH_INSPECTION_SUCCESS,
  FETCH_STRUCTURE_SUCCESS,
  FETCH_CLIENT_SUCCESS,
  FETCH_PROJECT_SUCCESS,
  FETCH_PREFERENCES_SUCCESS,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_ERROR,
  FETCH_DATA_STARTED,
  UPDATE_INSPECTION_SUMMARY_STARTED,
  UPDATE_INSPECTION_SUMMARY_SUCCESS,
  UPDATE_INSPECTION_SUMMARY_ERROR,
  UPDATE_OBSERVATION_SUCCESS,
  UPDATE_OBSERVATION_ERROR,
  UPDATE_OBSERVATION_STARTED,
  FETCH_OBSERVATIONS_STARTED,
  FETCH_OBSERVATIONS_SUCCESS,
  FETCH_OBSERVATIONS_ERROR,
  DELETE_OBSERVATION_SUCCESS,
  DELETE_OBSERVATION_STARTED,
  UPDATE_IMAGE_RECTANGLE_STARTED,
  UPDATE_IMAGE_RECTANGLE_SUCCESS,
  UPDATE_IMAGE_RECTANGLE_ERROR,
  DELETE_IMAGE_STARTED,
  DELETE_IMAGE_SUCCESS,
  DELETE_IMAGE_ERROR,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_ERROR,
  UPLOAD_IMAGE_STARTED,
  UPDATE_LOCATION_INSPECTED_STARTED,
  UPDATE_LOCATION_INSPECTED_SUCCESS,
  UPDATE_LOCATION_INSPECTED_ERROR,
  FETCH_SUB_COMPONENTS_OF_INSPECTION_SUCCESS,
  FETCH_SUB_COMPONENT_OF_STRUCTURE_SUCCESS,
  ADD_LOCATION_STARTED,
  DELETE_LOCATION_STARTED,
  UPDATE_LOCATION_STARTED,
  ADD_LOCATION_SUCCESS,
  UPDATE_LOCATION_SUCCESS,
  DELETE_LOCATION_SUCCESS,
} from "./InspectionAction";

const initialState = {
  inspectionData: {},
  structureData: {},
  projectData: {},
  clientData: {},
  preferencesData: {},
  loading: true,
  loadingObservations: false,
  loadingLocations: false,
  photoId: "",
  rectangle: "",
  subComponentInspectionData: [],
  subComponentStructureData: [],
};

const InspectionReducer = (state = initialState, action) => {
  var updatedInspectionData = undefined;

  switch (action.type) {
    case UPDATE_LOCATION_INSPECTED_STARTED:
      return {
        ...state,
        loadingLocations: true,
      };
    case FETCH_DATA_STARTED:
    case DOWNLOAD_INSPECTION_STARTED:
    case UPDATE_INSPECTION_SUMMARY_STARTED:
      return { ...state, loading: true };
    case DOWNLOAD_INSPECTION_SUCCESS:
      return { ...state, loading: false };
    case FETCH_INSPECTION_SUCCESS:
      return {
        ...state,
        inspectionData: action.inspectionData,
      };

    case FETCH_OBSERVATIONS_SUCCESS:
      return {
        ...state,
        inspectionData: action.inspectionData,
        loadingObservations: false,
      };
    case UPDATE_INSPECTION_SUMMARY_SUCCESS:
      return {
        ...state,
        inspectionData: action.inspectionData,
        loading: false,
      };
    case FETCH_STRUCTURE_SUCCESS:
      return {
        ...state,
        structureData: action.structureData,
      };
    case FETCH_CLIENT_SUCCESS:
      return {
        ...state,
        clientData: action.clientData,
      };
    case FETCH_SUB_COMPONENTS_OF_INSPECTION_SUCCESS:
      return {
        ...state,
        subComponentInspectionData: action.data,
      };
    case FETCH_SUB_COMPONENT_OF_STRUCTURE_SUCCESS:
      return {
        ...state,
        subComponentStructureData: action.data,
      };
    case FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        projectData: action.projectData,
      };
    case UPDATE_IMAGE_RECTANGLE_STARTED:
    case UPDATE_OBSERVATION_STARTED:
    case FETCH_OBSERVATIONS_STARTED:
    case DELETE_OBSERVATION_STARTED:
    case DELETE_IMAGE_STARTED:
    case UPLOAD_IMAGE_STARTED:
    case ADD_LOCATION_STARTED:
    case DELETE_LOCATION_STARTED:
    case UPDATE_LOCATION_STARTED:
    case DOWNLOAD_IMAGES_STARTED:
      return {
        ...state,
        loadingObservations: true,
      };
    case DOWNLOAD_IMAGES_SUCCESS:
      return {
        ...state,
        loadingObservations: false,
      };
    case UPDATE_OBSERVATION_SUCCESS:
      updatedInspectionData = state.inspectionData;

      const componentSubcomponentId = action.componentSubcomponentId;
      const observation = action.observation;

      // We have to move the observation and place it into the correct compSub.
      for (var i = 0; i < (updatedInspectionData.observations || []).length; i++) {
        if (componentSubcomponentId !== undefined) {
          // We want to remove the defect from his previous compSub.
          updatedInspectionData.observations[i].observationDefects = Helper.removeItemImmutable(
            updatedInspectionData.observations[i].observationDefects || [],
            observation.uuid,
            "uuid"
          );

          // We want to create the defect in the corresponding new compSub found.
          if (updatedInspectionData.observations[i].uuid === componentSubcomponentId) {
            updatedInspectionData.observations[i].observationDefects.push(observation);
          }
        } else {
          updatedInspectionData.observations[i].observationDefects = Helper.updateItemImmutable(
            updatedInspectionData.observations[i].observationDefects || [],
            action.observation,
            "uuid"
          );
        }
      }

      return {
        ...state,
        inspectionData: updatedInspectionData,
        loadingObservations: false,
      };
    case ADD_LOCATION_SUCCESS:
      updatedInspectionData = state.inspectionData;
      updatedInspectionData.observations = Helper.insertItemImmutable(updatedInspectionData.observations, action.location);
      return {
        ...state,
        inspectionData: updatedInspectionData,
        loadingObservations: false,
      };
    case UPDATE_LOCATION_SUCCESS:
      updatedInspectionData = state.inspectionData;
      const location = action.location;
      if (!("healthIndex" in location)) {
        location["healthIndex"] = undefined;
      }

      updatedInspectionData.observations = Helper.updateItemImmutable(updatedInspectionData.observations, action.location, "uuid");
      return {
        ...state,
        inspectionData: updatedInspectionData,
        loadingObservations: false,
      };
    case UPDATE_IMAGE_RECTANGLE_SUCCESS:
      updatedInspectionData = state.inspectionData;
      const observationIndex = Helper.indexOf(updatedInspectionData.observations, action.componentSubcomponentId, "uuid");
      const defectIndex = Helper.indexOf(updatedInspectionData.observations[observationIndex].observationDefects, action.observationId, "uuid");
      const photoIndex = Helper.indexOf(
        updatedInspectionData.observations[observationIndex].observationDefects[defectIndex].photos,
        action.photoId,
        "uuid"
      );
      updatedInspectionData.observations[observationIndex].observationDefects[defectIndex].photos[photoIndex].drawables = action.rectangle;
      return {
        ...state,
        inspectionData: updatedInspectionData,
        loadingObservations: false,
      };
    case DELETE_IMAGE_SUCCESS:
      updatedInspectionData = state.inspectionData;
      const observationIndexB = Helper.indexOf(updatedInspectionData.observations, action.componentSubcomponentId, "uuid");
      const defectIndexB = Helper.indexOf(updatedInspectionData.observations[observationIndexB].observationDefects, action.observationId, "uuid");

      updatedInspectionData.observations[observationIndexB].observationDefects[defectIndexB].photos = Helper.removeItemImmutable(
        updatedInspectionData.observations[observationIndexB].observationDefects[defectIndexB].photos,
        action.photoId,
        "uuid"
      );

      return {
        ...state,
        inspectionData: updatedInspectionData,
        loadingObservations: false,
      };

    case UPLOAD_IMAGE_SUCCESS:
      updatedInspectionData = state.inspectionData;
      const observationIndexC = Helper.indexOf(updatedInspectionData.observations, action.componentSubcomponentId, "uuid");
      const defectIndexC = Helper.indexOf(updatedInspectionData.observations[observationIndexC].observationDefects, action.observationId, "uuid");

      updatedInspectionData.observations[observationIndexC].observationDefects[defectIndexC].photos = Helper.insertItemImmutable(
        updatedInspectionData.observations[observationIndexC].observationDefects[defectIndexC].photos,
        action.photo
      );

      return {
        ...state,
        inspectionData: updatedInspectionData,
        loadingObservations: false,
      };
    case DELETE_OBSERVATION_SUCCESS:
      updatedInspectionData = state.inspectionData;
      for (var j = 0; j < (updatedInspectionData.observations || []).length; j++) {
        updatedInspectionData.observations[j].observationDefects = Helper.removeItemImmutable(
          updatedInspectionData.observations[j].observationDefects || [],
          action.observationId,
          "uuid"
        );
      }
      return {
        ...state,
        inspectionData: updatedInspectionData,
        loadingObservations: false,
      };
    case DELETE_LOCATION_SUCCESS:
      updatedInspectionData = state.inspectionData;
      updatedInspectionData.observations = Helper.removeItemImmutable(updatedInspectionData.observations || [], action.locationUUID, "uuid");
      return {
        ...state,
        inspectionData: updatedInspectionData,
        loadingObservations: false,
      };
    case UPDATE_LOCATION_INSPECTED_ERROR:
      return {
        ...state,
        loadingLocations: false,
      };
    case UPDATE_LOCATION_INSPECTED_SUCCESS:
      updatedInspectionData = state.inspectionData;
      updatedInspectionData.observations = Helper.updateItemImmutable(updatedInspectionData.observations, action.location, "uuid");
      return {
        ...state,
        inspectionData: updatedInspectionData,
        loadingLocations: false,
      };
    case FETCH_PREFERENCES_SUCCESS:
      return {
        ...state,
        preferencesData: action.preferencesData,
      };
    case FETCH_DATA_SUCCESS:
    case FETCH_DATA_ERROR:
    case UPDATE_IMAGE_RECTANGLE_ERROR:
    case DELETE_IMAGE_ERROR:
    case UPLOAD_IMAGE_ERROR:
      return { ...state, loading: false };
    case UPDATE_LOCATION_INSPECTED_STARTED:

    default:
      return state;
  }
};

export { InspectionReducer };
