import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import { Colors } from "config/StyleConfig";
import TextField from "@material-ui/core/TextField";

const styles = () => ({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: Colors.text,
    },
  },
});

class LabeledText extends Component {
  render() {
    const { classes } = this.props;

    return (
      <TextField
        className={classes.root}
        disabled
        InputProps={{ disableUnderline: true }}
        {...this.props}
      />
    );
  }
}

export default withStyles(styles)(LabeledText);
