import Analytics from 'features/Analytics';
import Users from 'features/Users';
import Login from 'features/Login';
import Inspections from 'features/Inspections';
import Projects from 'features/Projects';
import Client from 'features/Client';
import Clients from 'features/Clients';
import Defects from 'features/Defects';
import TokenManager from 'storage/TokenManager';
import React from 'react';
import Route from './Route';
import { Redirect } from 'react-router-dom';
import Inspection from 'features/Inspection';
import Preferences from 'features/Preferences';
import Structure from 'features/Structure';
import { ForgotPassword } from 'features/ForgotPassword';
import { ResetPassword } from 'features/ResetPassword';
import { Logout } from 'features/Logout';

const getCurrentRoute = () => {
  if (typeof window !== 'undefined') {
    return window.location.pathname;
  }
};

const pathIds = {
  default: 'default',
  login: 'login',
  users: 'users',
  analytics: 'analytics',
  inspections: 'inspections',
  observations: 'observations',
  projects: 'projects',
  client: 'client',
  clients: 'clients',
  overview: 'overview',
  inspection: 'inspection',
  preferences: 'preferences',
  structure: 'structure',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
};

const pathRouting = {
  default: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  users: '/users',
  analytics: '/analytics',
  inspections: '/inspections',
  observations: '/observations',
  projects: '/projects',
  client: '/client/:clientId',
  clients: '/clients',
  overview: '/client',
  inspection: '/inspection/:inspectionId',
  preferences: '/preferences',
  structure: '/structure/:structureId',
};

const Default = () => {
  return <Redirect to={pathRouting.inspections} />;
};

const pageRoutes = {
  [pathIds.default]: {
    path: pathRouting.default,
    component: Default,
    private: true,
    authorized: TokenManager.ALL,
  },
  [pathIds.login]: {
    path: pathRouting.login,
    component: Login,
    private: false,
    authorized: TokenManager.ALL,
  },
  ['logout']: {
    path: '/logout',
    component: Logout,
    private: true,
    authorized: TokenManager.ALL,
  },
  [pathIds.forgotPassword]: {
    path: pathRouting.forgotPassword,
    component: ForgotPassword,
    private: false,
    authorized: TokenManager.ALL,
  },
  [pathIds.resetPassword]: {
    path: `${pathRouting.forgotPassword}/reset`,
    component: ResetPassword,
    private: false,
    authorized: TokenManager.ALL,
  },
  [pathIds.users]: {
    path: pathRouting.users,
    component: Users,
    private: true,
    authorized: [TokenManager.ADMIN],
  },
  [pathIds.analytics]: {
    path: pathRouting.analytics,
    component: Analytics,
    private: true,
    authorized: [TokenManager.ADMIN],
  },
  [pathIds.inspections]: {
    path: pathRouting.inspections,
    component: Inspections,
    private: true,
    viewOnly: [TokenManager.CLIENT],
    authorized: TokenManager.ALL,
  },
  [pathIds.projects]: {
    path: pathRouting.projects,
    component: Projects,
    private: true,
    authorized: [TokenManager.ADMIN, TokenManager.INSPECTOR],
  },
  [pathIds.observations]: {
    path: pathRouting.observations,
    component: Defects,
    private: true,
    authorized: TokenManager.ALL,
  },
  [pathIds.client]: {
    path: pathRouting.client,
    component: Client,
    private: true,
    authorized: [TokenManager.ADMIN, TokenManager.INSPECTOR],
  },
  [pathIds.clients]: {
    path: pathRouting.clients,
    component: Clients,
    private: true,
    authorized: [TokenManager.ADMIN],
  },
  [pathIds.overview]: {
    path: pathRouting.overview,
    component: Client,
    private: true,
    viewOnly: [TokenManager.CLIENT],
    authorized: [TokenManager.CLIENT],
  },
  [pathIds.inspection]: {
    path: pathRouting.inspection,
    component: Inspection,
    private: true,
    viewOnly: [TokenManager.CLIENT],
    authorized: TokenManager.ALL,
  },
  [pathIds.structure]: {
    path: pathRouting.structure,
    component: Structure,
    private: true,
    viewOnly: [TokenManager.CLIENT],
    authorized: TokenManager.ALL,
  },
  [pathIds.preferences]: {
    path: pathRouting.preferences,
    component: Preferences,
    private: true,
    authorized: [TokenManager.ADMIN],
  },
};

const getRoutes = () => {
  var privateRoutes = [];
  var publicRoutes = [];

  // Key props are made to optimize rendering performance.
  var optimizationKey = 0;
  const routeArray = Object.values(pageRoutes);

  routeArray.forEach((route) => {
    var r = (
      <Route
        key={optimizationKey++}
        exact
        path={route.path}
        isPrivate={route.private}
        component={route.component}
        isAuthorized={route.authorized.includes(TokenManager.getUserType())}
        viewOnly={
          'viewOnly' in route
            ? route.viewOnly.includes(TokenManager.getUserType())
            : false
        }
        componentProps={route.componentProps}
      />
    );

    if (route.private) {
      privateRoutes.push(r);
    } else {
      publicRoutes.push(r);
    }
  });

  return {
    privateRoutes,
    publicRoutes,
  };
};

export { pageRoutes, pathIds, pathRouting, getCurrentRoute, getRoutes };
